import React from "react";
import styled from "@emotion/styled";
import { LeaguesList } from "./leaguesList";
import { useLeagues } from "../../contexts/leaguesContext";
import { useAuthentication } from "../../contexts/AuthContext";
import { ZoomList } from "../../components/common/zoomList/zoomList";
import { useLeaguesScreen } from "./useLeaguesScreen";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ButtonComponent from "../../components/styleGuide/atoms/buttonComponent.atom";
import Space from "../../components/styleGuide/atoms/space.atom";
import TextComponent from "../../components/styleGuide/atoms/textComponent.atom";
import { useLoading } from "../../contexts/loadingContext";
import { useAnalytics } from "../../hooks/useAnalyticsHook";
import { Header } from "../../components/common/header/header";
import { ScrollableProvider } from "../../contexts/Scrollable";

export const LeaguesScreenComp = () => {
  const { t } = useTranslation();
  const { auth } = useAuthentication();
  const { leagues, leaguesInitialized } = useLeagues();
  useLoading();
  const navigate = useNavigate();
  const { showLeague, showJoinModal } = useLeaguesScreen();
  useAnalytics({ screenName: "leagues" });

  const hasLeagues = leagues.length > 0;
  return (
    <ScrollableProvider>
      {leaguesInitialized && (
        <Container>
          <ZoomList>
            {hasLeagues && (
              <StyledPaper>
                <LeaguesList leagues={leagues} onLeagueClick={showLeague} />
              </StyledPaper>
            )}
            {!hasLeagues && (
              <EmptyLeagueContainer>
                <StyledTitle>
                  Still have no leagues?
                  <br />
                  Why don't you create one.
                </StyledTitle>
              </EmptyLeagueContainer>
            )}
            {/* <ButtonComponent
                variant="text"
                size={"normal"}
                onClick={() => navigate("/create")}
              >
                {t(!hasLeagues ? "CREATE_FIRST_LEAGUE" : "CREATE_LEAGUE")}
              </ButtonComponent> */}
            {/* <ButtonComponent
                variant={"outline"}
                size={"normal"}
                onClick={showJoinModal}
              >
                {t("JOIN_LEAGUE")}
              </ButtonComponent> */}
          </ZoomList>
        </Container>
      )}
    </ScrollableProvider>
  );
};

export const LeaguesScreen = () => {
  return <LeaguesScreenComp />;
};

const Container = styled.div`
  padding: 0 5px;
`;
const StylesMatchList = styled.div`
  padding: 0 10px;
  box-sizing: border-box;
`;

const StyledPaper = styled.div`
  width: 100%;
`;
const StyledTitle = styled(TextComponent)`
  margin: 0 10px;
`;
const EmptyLeagueContainer = styled(TextComponent)`
  margin: 0 10px;
  text-align: center;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: ${(props) => props.theme.hotShotColors.cardBackground};
  border-radius: 12px;
  margin: 10px;
`;
