import VELTINSArena from "../../../assets/stadiums/VELTINS-Arena.png";
import AllianzArena from "../../../assets/stadiums/Allianz Arena.png";
import DeutscheBankPark from "../../../assets/stadiums/Deutsche Bank Park.png";
import SignalIdunaPark from "../../../assets/stadiums/Signal Iduna Park.png";
import RedBullArena from "../../../assets/stadiums/Red Bull Arena Leipzig.png";
import MercedesBenzArena from "../../../assets/stadiums/Mercedes-Benz Arena.png";
import MerkurSpielArena from "../../../assets/stadiums/Merkur Spiel-Arena.png";
import Olympiastadion from "../../../assets/stadiums/Olympiastadion.png";
import Volksparkstadion from "../../../assets/stadiums/Volksparkstadion.png";
import RheinEnergieSTADION from "../../../assets/stadiums/RheinEnergieSTADION.png";

export const stadiums: any = {
  "VELTINS-Arena": VELTINSArena,
  "Allianz Arena": AllianzArena,
  "Deutsche Bank Park": DeutscheBankPark,
  "Signal Iduna Park": SignalIdunaPark,
  "Red Bull Arena Leipzig": RedBullArena,
  "Mercedes-Benz Arena": MercedesBenzArena,
  "Merkur Spiel-Arena": MerkurSpielArena,
  Olympiastadion: Olympiastadion,
  Volksparkstadion: Volksparkstadion,
  RheinEnergieSTADION: RheinEnergieSTADION,
};

export const defaultStadium = () => {
  return stadiums["Allianz Arena"];
  // const keys = Object.keys(stadiums);
  // const randomIndex = Math.floor(Math.random() * keys.length);
  // // @ts-ignore
  // return stadiums[keys[randomIndex]];
};
