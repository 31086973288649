import _ from "lodash";
import React from "react";
import styles from "./welcome.module.scss";
import { useScrollToTop } from "../../../hooks/useScrollToTop";
import { useLoading } from "../../../contexts/loadingContext";
import { useLeague } from "../../../contexts/leagueContext";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../components/styleGuide/atoms/buttonComponent.atom";
import TextComponent from "../../../components/styleGuide/atoms/textComponent.atom";
import Space from "../../../components/styleGuide/atoms/space.atom";

export const Welcome = () => {
  const { league } = useLeague();
  const navigate = useNavigate();
  useLoading();

  return (
    <div className={styles.root}>
      <Space height={10} />
      <TextComponent variant={"extra-large"}>Welcome</TextComponent>
      <Space height={10} />
      <TextComponent variant={"medium"} textAlign="center">
        {league?.name} is ready,
        <br />
        it is time to add some matches to bet on{" "}
      </TextComponent>
      <Space height={10} />
      <ButtonComponent
        size="normal"
        variant={"contained"}
        onClick={() => navigate("../update")}
      >
        {"Add Matches to your league"}
      </ButtonComponent>
    </div>
  );
};
