import React, { FC, Fragment, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { BookingsItem, Goal, LiveMatchProps, Player, Sub } from "./lineUpTypes";
import TextComponent from "../../../../components/styleGuide/atoms/textComponent.atom";
import { useTheme } from "@mui/material/styles";
import Space from "../../../../components/styleGuide/atoms/space.atom";
import { Row } from "../../../../components/styleGuide/atoms/baseStyles";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as Football } from "../summary/football.svg";
import _ from "lodash";

const getPlayerNameString = (name: string) => {
  try {
    const playerName = name?.split(" ", 2);
    const playerSecondName = playerName[0]?.split("-");
    let playerSecondNameString;
    if (playerSecondName.length > 1) {
      playerSecondNameString = `${playerSecondName[0]?.[0]}.${playerSecondName[1]?.[0]}`;
    }

    return {
      firstName: `${playerName[1] ? `${playerSecondNameString ? playerSecondNameString : playerName[0]?.[0]}.` : playerName[0]}`,
      lastName: playerName[1],
    };
  } catch (error) {
    return {
      firstName: "",
      lastName: "",
    };
  }
};
interface PlayerLineUp {
  name: string;
  shirtNumber: number;
  goal?: Goal[];
  card?: BookingsItem[];
  sub?: Sub;
}

const ListItemLineUp = ({ player }: { player: PlayerLineUp }) => {
  const theme = useTheme();
  const playerName = getPlayerNameString(player.name);

  const [redCard, setRedCard] = useState(false);
  const [yellowCard, seYellowCard] = useState(false);
  const [yellowRedCard, setYellowRedCard] = useState(false);

  useEffect(() => {
    if (player.card && player.card?.length > 0) {
      player.card?.map((item) => {
        if (item.card === "RED") {
          setRedCard(true);
        }
        if (item.card === "YELLOW") {
          seYellowCard(true);
        }
        if (item.card === "YELLOW_RED") {
          setYellowRedCard(true);
        }
      });
    }
  }, [player.card]);

  return (
    <Fragment key={player.name + player.shirtNumber}>
      <TeamRow>
        <Number>
          <TextComponent
            color={theme.hotShotColors.Grayscale40}
            variant={"extra-small"}
            fontWeight={400}
          >
            {`${player.shirtNumber}. `}
          </TextComponent>
        </Number>

        <TextComponent
          color={theme.hotShotColors.Grayscale40}
          variant={"extra-small"}
          fontWeight={400}
        >
          {playerName.firstName}
        </TextComponent>
        <TextComponent
          color={theme.hotShotColors.Grayscale40}
          variant={"extra-small"}
          fontWeight={600}
        >
          {playerName.lastName}
        </TextComponent>
        {!_.isEmpty(player?.goal) && (
          <>
            <Football
              style={{
                marginLeft: 5,
                width: 15,
                height: 15,
                color: theme.hotShotColors.primaryTextColor,
              }}
            />
            {player?.goal && player?.goal?.length > 1 ? (
              <TextComponent
                color={theme.hotShotColors.Grayscale40}
                variant={"extra-small"}
                fontWeight={600}
              >{`x${player?.goal?.length}`}</TextComponent>
            ) : (
              ""
            )}
          </>
        )}
        {yellowCard && <YellowCard backgroundColor={"yellow"} />}
        {redCard && <YellowCard backgroundColor={"red"} />}
        {yellowRedCard && <YellowCard backgroundColor={"yellow"} />}
        {player.sub && (
          <>
            <Space width={3} />
            {/* <SyncAlt style={{ marginLeft:5,width:15,height:15, color: theme.hotShotColors.primaryTextColor }} /> */}
            <TextComponent
              fontWeight={600}
              color={theme.hotShotColors.success}
              variant="tiny"
            >{`(${player.sub.minute}')`}</TextComponent>
          </>
        )}
      </TeamRow>
      <Space height={8} />
    </Fragment>
  );
};

const YellowCard = styled.div<{ backgroundColor: string }>`
  margin-left: 5px;
  width: 10px;
  height: 10px;
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.theme.hotShotColors.Grayscale20};
`;
const Number = styled.div`
  width: 20px;
  display: flex;
  justify-content: flex-end;
  margin-right: 3px;
`;

const getLineUp = (
  players: Player[],
  matchDetails?: LiveMatchProps,
  showPlayerIn?: boolean,
) => {
  const { bookings, goals, substitutions } = matchDetails as LiveMatchProps;
  return players.map((player) => {
    const bookingArray = bookings.filter(
      (item: any) => item.player.id === player.id,
    );
    const goalsArray = goals.filter(
      (item: any) => item.scorer.id === player.id,
    );
    let substitutionsIndex;
    if (showPlayerIn) {
      substitutionsIndex = substitutions.findIndex(
        (item: any) => item.playerIn.id === player.id,
      );
    } else {
      substitutionsIndex = substitutions.findIndex(
        (item: any) => item.playerOut.id === player.id,
      );
    }

    return {
      ...player,
      card: bookingArray,
      goal: goalsArray,
      sub:
        substitutionsIndex !== -1
          ? substitutions[substitutionsIndex]
          : undefined,
    };
  });
};

const getCoachName = (coachName: string) => {
  const SecondName = coachName?.split(" ");
  const FirstName = SecondName?.shift();
  SecondName && SecondName.join(" ");

  return { SecondName, FirstName };
};

const PlayersList = ({
  players,
  flexSize,
}: {
  players: PlayerLineUp[];
  flexSize?: number;
}) => {
  return (
    <TeamColum flexSize={flexSize}>
      {players.map((player: PlayerLineUp) => (
        <ListItemLineUp
          key={player.name + player.shirtNumber}
          player={player}
        />
      ))}
    </TeamColum>
  );
};

const CoachName = ({ coachName }: { coachName: string }) => {
  const coachNameArray = getCoachName(coachName);

  const theme = useTheme();

  return (
    <Row>
      <TextComponent
        color={theme.hotShotColors.Grayscale40}
        variant={"extra-small"}
        fontWeight={400}
      >
        {coachNameArray.FirstName}
      </TextComponent>
      <Space width={5} />
      <TextComponent
        color={theme.hotShotColors.Grayscale40}
        variant={"extra-small"}
        fontWeight={600}
      >
        {coachNameArray.SecondName}
      </TextComponent>
    </Row>
  );
};

const LineUpDetails: FC<{ matchDetails: LiveMatchProps | undefined }> = ({
  matchDetails,
}) => {
  const theme = useTheme();
  const { awayTeam, homeTeam } = matchDetails as LiveMatchProps;
  if (!homeTeam?.lineup?.length || !awayTeam?.lineup?.length) {
    return null;
  }

  const homeLineUp = getLineUp(homeTeam?.lineup, matchDetails);
  const awayLineUp = getLineUp(awayTeam?.lineup, matchDetails);
  const homeBench = getLineUp(homeTeam?.bench, matchDetails, true);
  const awayBench = getLineUp(awayTeam?.bench, matchDetails, true);

  return (
    <StyledContainer>
      <Space height={15} />
      <TextComponent
        textAlign="center"
        color={theme.hotShotColors.Grayscale40}
        variant="small"
        fontWeight={600}
      >
        {"LineUps"}
      </TextComponent>
      <Space height={15} />
      <LineUpContainer>
        <PlayersList flexSize={1.6} players={homeLineUp} />
        <PlayersList players={awayLineUp} />
      </LineUpContainer>
      <Space height={15} />
      <TextComponent
        textAlign="center"
        color={theme.hotShotColors.Grayscale40}
        variant="small"
        fontWeight={600}
      >
        {"Bench"}
      </TextComponent>
      <Space height={15} />
      <LineUpContainer>
        <PlayersList flexSize={1.6} players={homeBench} />
        <PlayersList players={awayBench} />
      </LineUpContainer>
      <Space height={10} />
      <TextComponent
        textAlign="center"
        color={theme.hotShotColors.Grayscale40}
        variant="small"
        fontWeight={600}
      >
        {"Manager"}
      </TextComponent>
      <StyledRow>
        <CoachName coachName={homeTeam?.coach?.name} />
        <CoachName coachName={awayTeam?.coach?.name} />
      </StyledRow>
      <Space height={15} />
    </StyledContainer>
  );
};

const TeamColum = styled.div<{ flexSize?: number }>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flexSize ? props.flexSize : "1")};
  align-items: flex-start;
`;

const TeamRow = styled.div<{ flexSize?: number }>`
  display: flex;
  flex: ${(props) => (props.flexSize ? props.flexSize : "1")};
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
`;

const LineUpContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledRow = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledContainer = styled.div`
  margin: 10px;
`;

export default LineUpDetails;
