import _ from "lodash";
import React from "react";
import styles from "./adminMessages.module.scss";
import { Header } from "../../../components/common/header/header";
import { Avatar, Zoom, List, ListItem, ListItemText } from "@mui/material";
import { useFirestoreState } from "../../../contexts/firestoreContext";
import { useLoading } from "../../../contexts/loadingContext";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../../contexts/AuthContext";

const AdminMessages = () => {
  const navigate = useNavigate();
  useLoading();
  const { profile } = useAuthentication();
  const { firestoreState } = useFirestoreState();
  const messages = firestoreState?.ordered?.messages || [];
  if (!profile?.isAppAdmin) {
    navigate("/");
    return <></>;
  }
  let index = 0;
  return (
    <div className={styles.root}>
      <Header onBack={() => navigate("/")} title="Messages" />
      <div className={styles.container}>
        <List>
          {_.map(messages, (message, key) => {
            return (
              <Zoom
                key={key}
                in={true}
                style={{ transitionDelay: (index++ * 50).toString() }}
              >
                <ListItem classes={{ root: styles.item }}>
                  <Avatar src={message.avatarUrl} />
                  <ListItemText
                    classes={{ root: styles.itemText }}
                    primary={message.displayName + "\n" + message.email}
                    secondary={message.message}
                  />
                </ListItem>
              </Zoom>
            );
          })}
        </List>
      </div>
    </div>
  );
};

export default AdminMessages;
