import React, { FC } from "react";
import styles from "./userBets.module.scss";
import _ from "lodash";
import {
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
  List,
  ListItem,
  Paper,
} from "@mui/material";
import { BetItem } from "../bet/betItem";
import styled from "@emotion/styled";
import TextComponent from "../../styleGuide/atoms/textComponent.atom";
import { Row } from "../../styleGuide/atoms/baseStyles";
import Space from "../../styleGuide/atoms/space.atom";
import {
  isOddsLeague,
  isOddsScoreLeague,
  isPointsLeague,
} from "../../../services/matchService";
import { getOddBetResult } from "../../../services/betService";
import { OddsBetsComponent } from "../match/OddsBetComponent";
import { League, Match, OddsBet, PointsBet } from "../../../../types";

type UserBetsProps = {
  userBets: OddsBet[] | PointsBet[];
  league: League;
  selectedLeaguedMatch: Match;
};

export const UserBets: FC<UserBetsProps> = (props) => {
  const { userBets, league, selectedLeaguedMatch } = props;
  return (
    <Container className={styles.userBetsWrapper}>
      <List>
        {_.map(userBets, (bet: any) => {
          const uid = bet.uid;
          if (!uid) {
            return;
          }
          const user = league.users[uid];
          if (!user || !user.valid || !bet) {
            return null;
          }
          // const betData = user.bets[selectedLeaguedMatch.id];

          const isBetValid =
            Object.values(bet || {}).filter((x) => x).length > 0;
          const isUserJoinedAfterGameStart =
            user.dateAdded > (selectedLeaguedMatch.date as number);
          if (!isBetValid || isUserJoinedAfterGameStart) {
            return null;
          }
          return (
            <div key={uid}>
              <ListItemContainer key={uid}>
                <DetailsRow>
                  <Avatar src={user.avatarUrl} />
                  <Space width={10} />
                  <TextComponent variant={"small"}>
                    {user.displayName}
                  </TextComponent>
                </DetailsRow>
                <DetailsRow>
                  {(isPointsLeague(league) || isOddsScoreLeague(league)) && (
                    <BetItem
                      league={league}
                      leagueMatch={selectedLeaguedMatch}
                      auth={user}
                    />
                  )}
                </DetailsRow>
                {isOddsLeague(league) && (
                  <FullRow>
                    <OddsBetsComponent
                      leagueMatch={selectedLeaguedMatch}
                      myBet={bet}
                      betResult={getOddBetResult(bet, selectedLeaguedMatch)}
                    />
                  </FullRow>
                )}
              </ListItemContainer>
              <Divider component="li" key={`${uid}_d`} />
            </div>
          );
        })}
      </List>
    </Container>
  );
};

const DetailsRow = styled(Row)`
  width: 50%;
`;
const FullRow = styled(Row)`
  width: 100%;
`;
const Container = styled.div`
  background-color: ${(props) => props.theme.hotShotColors.cardBackground};
  border-radius: 18px;
`;

const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
`;
