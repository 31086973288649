import React, { FC, createContext, useEffect, useState } from "react";
import _ from "lodash";
import { useDb, useLeagueMatches } from "./firestoreContext";
import { useAuthentication } from "./AuthContext";
import { LEAGUE_TYPE } from "../services/leagueProcessService";

type LeagueType = {
  createLeague: (initialData: any) => Promise<any>;
  updateLeagueData: (data: any) => void;
  leagueData: any;
};

type CreateLeagueProviderProps = {
  children: React.ReactNode;
};
export const CreateLeagueContext = createContext<LeagueType>({} as LeagueType);

export const CreateLeagueProvider: FC<CreateLeagueProviderProps> = ({
  children,
}) => {
  const { auth, profile } = useAuthentication();
  const { addLeague } = useDb();
  const league = {
    createdAt: new Date().getTime(),
    initialBalance: 10000, //0
    leagueType: LEAGUE_TYPE.POINTS_LEAGUE,
    competitionIds: [],
    matchedIds: [],
    active: true,
    isExponentRanking: true,
    message: "Lets see you win...",
    name: "",
    matchesIds: [],
  };
  const [leagueData, setLeagueData] = useState(league);

  const createLeague = async (initialData = {}) => {
    const data = {
      ...leagueData,
      ...initialData,
      allowUserToJoin: true,
      admin: {
        uid: auth.uid,
        avatarUrl: profile.avatarUrl,
        displayName: profile.displayName,
      },
    };
    const users = [
      {
        avatarUrl: profile.avatarUrl,
        displayName: profile.displayName,
        valid: true,
        dateAdded: new Date().getTime(),
        bets: {},
        uid: auth.uid,
      },
    ];
    return await addLeague({ data, users });
  };

  const updateLeagueData = (data: any) => {
    setLeagueData({ ...leagueData, ...data });
  };

  const value = {
    createLeague,
    updateLeagueData,
    leagueData,
  };

  return (
    <CreateLeagueContext.Provider value={value}>
      {children}
    </CreateLeagueContext.Provider>
  );
};
