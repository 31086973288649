import { League, LeagueExtended, Match } from "../../types";
import { LEAGUE_TYPE } from "./leagueProcessService";

export const isStarted = (match: Match) => {
  const { status, utcDate } = match;
  const date = new Date(utcDate).getTime() - 60000;
  const now = new Date().getTime();

  return (
    !(
      [STATUS.POSTPONED, STATUS.SUSPENDED, STATUS.CANCELLED].indexOf(status) >
      -1
    ) &&
    (now > date ||
      [STATUS.IN_PLAY, STATUS.LIVE, STATUS.PAUSED, STATUS.FINISHED].includes(
        status,
      ))
  );
};

export const isFinished = (match: Match) => {
  const { status } = match;
  return status === STATUS.FINISHED || match.minute >= 91;
};

export const isCancelled = (match: Match) => {
  const { status, ignored } = match;
  return (
    !ignored &&
    [STATUS.POSTPONED, STATUS.SUSPENDED, STATUS.CANCELLED].indexOf(status) > -1
  );
};

export const getMatchResult = (match: Match) => {
  if (match?.score?.duration === "REGULAR") {
    return {
      homeScore: Number(match?.score?.fullTime?.home || 0),
      awayScore: Number(match?.score?.fullTime?.away || 0),
    };
  }
  if (
    match?.score?.duration === "PENALTY_SHOOTOUT" ||
    match?.score?.duration === "EXTRA_TIME"
  ) {
    return {
      homeScore: Number(match?.score?.regularTime?.home || 0),
      awayScore: Number(match?.score?.regularTime?.away || 0),
    };
  }
  return {
    homeScore: Number(
      match?.score?.regularTime?.home || match?.score?.fullTime?.home || 0,
    ),
    awayScore: Number(
      match?.score?.regularTime?.away || match?.score?.fullTime?.away || 0,
    ),
  };
};

export const isMatchInMyLeagues = (
  match: Match,
  myLeagues: LeagueExtended[],
) => {
  return myLeagues.some(
    (league) =>
      league.matchesIds.includes(match.id) ||
      league.competitionId === match.competition.id,
  );
};

export const isOddsLeague = (league: League) =>
  league && league.leagueType === LEAGUE_TYPE.ODDS_LEAGUE;

export const isPointsLeague = (league: League) =>
  league && league.leagueType === LEAGUE_TYPE.POINTS_LEAGUE;

export const isOddsScoreLeague = (league: League) =>
  league && league.leagueType === LEAGUE_TYPE.ODDS_SCORE;

export const getFromToDate = (fromOffsetDays = 0, toOffsetDays = 0) => {
  const fromDate = new Date();
  fromDate.setDate(fromDate.getDate() + fromOffsetDays);
  const toDate = new Date();
  toDate.setDate(toDate.getDate() + toOffsetDays);
  const fromMonth = String(fromDate.getMonth() + 1).padStart(2, "0");
  const toMonth = String(toDate.getMonth() + 1).padStart(2, "0");
  const fromDateDay = String(fromDate.getDate()).padStart(2, "0");
  const toDateDay = String(toDate.getDate()).padStart(2, "0");
  const fromDateDtring =
    fromDate.getFullYear() + "-" + fromMonth + "-" + fromDateDay;
  const toDateString = toDate.getFullYear() + "-" + toMonth + "-" + toDateDay;
  return { dateFrom: fromDateDtring, dateTo: toDateString };
};

export const STATUS = {
  SCHEDULED: "SCHEDULED",
  TIMED: "TIMED",
  IN_PLAY: "IN_PLAY",
  LIVE: "LIVE",
  PAUSED: "PAUSED",
  FINISHED: "FINISHED",
  SUSPENDED: "SUSPENDED",
  POSTPONED: "POSTPONED",
  CANCELLED: "CANCELLED",
  AWARDED: "AWARDED",
};

export const STAGE = {
  FINAL: "FINAL",
  THIRD_PLACE: "THIRD_PLACE",
  SEMI_FINALS: "SEMI_FINALS",
  QUARTER_FINALS: "QUARTER_FINALS",
  LAST_16: "LAST_16",
  LAST_32: "LAST_32",
  LAST_64: "LAST_64",
  ROUND_4: "ROUND_4",
  ROUND_3: "ROUND_3",
  ROUND_2: "ROUND_2",
  ROUND_1: "ROUND_1",
  GROUP_STAGE: "GROUP_STAGE",
  PRELIMINARY_ROUND: "PRELIMINARY_ROUND",
  QUALIFICATION: "QUALIFICATION",
  QUALIFICATION_ROUND_1: "QUALIFICATION_ROUND_1",
  QUALIFICATION_ROUND_2: "QUALIFICATION_ROUND_2",
  QUALIFICATION_ROUND_3: "QUALIFICATION_ROUND_3",
  PLAYOFF_ROUND_1: "PLAYOFF_ROUND_1",
  PLAYOFF_ROUND_2: "PLAYOFF_ROUND_2",
  PLAYOFFS: "PLAYOFFS",
  REGULAR_SEASON: "REGULAR_SEASON",
  CLAUSURA: "CLAUSURA",
  APERTURA: "APERTURA",
  CHAMPIONSHIP_ROUND: "CHAMPIONSHIP_ROUND",
  RELEGATION_ROUND: "RELEGATION_ROUND",
};

export const GROUP = {
  GROUP_A: "GROUP_A",
  GROUP_B: "GROUP_B",
  GROUP_C: "GROUP_C",
  GROUP_D: "GROUP_D",
  GROUP_E: "GROUP_E",
  GROUP_F: "GROUP_F",
  GROUP_G: "GROUP_G",
  GROUP_H: "GROUP_H",
  GROUP_I: "GROUP_I",
  GROUP_J: "GROUP_J",
  GROUP_K: "GROUP_K",
  GROUP_L: "GROUP_L",
};

export const WINNER = {
  AWAY_TEAM: "AWAY_TEAM",
  HOME_TEAM: "HOME_TEAM",
  DRAW: "DRAW",
};

export const DURATION = {
  REGULAR: "REGULAR",
};

export const MATCH_TYPE = {
  REGULAR_SEASON: "REGULAR_SEASON",
  GROUP_STAGE: "GROUP_STAGE",
};
export const USER_BET_TYPE = {
  SCORE: "SCORE",
  ODDS: "ODDS",
};
export const USER_ODDS_BET = {
  HOME: "homeWin",
  DRAW: "draw",
  AWAY: "awayWin",
};

export const COMPETITION_CODE = {
  AC: "AC",
  ACL: "ACL",
  QCAF: "QCAF",
  PBN: "PBN",
  ASL: "ASL",
  QAFC: "QAFC",
  AAL: "AAL",
  AEL: "AEL",
  ABL: "ABL",
  APL: "APL",
  CB: "CB",
  BJL: "BJL",
  BJPP: "BJPP",
  CDB: "CDB",
  BSB: "BSB",
  BSA: "BSA",
  CPD: "CPD",
  CSL: "CSL",
  CLP: "CLP",
  PRVA: "PRVA",
  DSU: "DSU",
  DELP: "DELP",
  FLC: "FLC",
  COM: "COM",
  FAC: "FAC",
  ENL: "ENL",
  EL2: "EL2",
  EL1: "EL1",
  ELC: "ELC",
  PL: "PL",
  EL: "EL",
  CL: "CL",
  UCL: "UCL",
  EC: "EC",
  QUFA: "QUFA",
  CLQ: "CLQ",
  ECQ: "ECQ",
  ESC: "ESC",
  VEI: "VEI",
  FL2: "FL2",
  FL1: "FL1",
  FPL: "FPL",
  CDF: "CDF",
  DFB: "DFB",
  REG: "REG",
  BL3: "BL3",
  BL2: "BL2",
  RLN: "RLN",
  BL1: "BL1",
  GSC: "GSC",
  RBY: "RBY",
  RLW: "RLW",
  RSW: "RSW",
  BLREL: "BLREL",
  RNO: "RNO",
  GSL: "GSL",
  HNB: "HNB",
  ISL: "ISL",
  ILH: "ILH",
  ISC: "ISC",
  CIT: "CIT",
  SB: "SB",
  SA: "SA",
  ISCB: "ISCB",
  ISCC: "ISCC",
  ISCA: "ISCA",
  IPL: "IPL",
  JJL: "JJL",
  SCMX: "SCMX",
  LMX: "LMX",
  KNV: "KNV",
  DJL: "DJL",
  DED: "DED",
  NIP: "NIP",
  TIP: "TIP",
  QOFC: "QOFC",
  PPD: "PPD",
  TDP: "TDP",
  PPL: "PPL",
  SCO: "SCO",
  TDL: "TDL",
  RL1: "RL1",
  RFPL: "RFPL",
  SPL: "SPL",
  SPRV: "SPRV",
  CLI: "CLI",
  CA: "CA",
  QCBL: "QCBL",
  CDR: "CDR",
  SD: "SD",
  PD: "PD",
  SDE: "SDE",
  ALL: "ALL",
  SSL: "SSL",
  T1L: "T1L",
  TSL: "TSL",
  UPL: "UPL",
  MLS: "MLS",
  MLSP: "MLSP",
  SUCU: "SUCU",
  FCWC: "FCWC",
  WC: "WC",
  QCCF: "QCCF",
  OLY: "OLY",
  WCF: "WCF",
};

export const ORDERED_COMPETITION_CODES = [
  COMPETITION_CODE.CL,
  COMPETITION_CODE.PL,
  COMPETITION_CODE.EC,
  COMPETITION_CODE.UCL,
  COMPETITION_CODE.EL,
  COMPETITION_CODE.PD,
  COMPETITION_CODE.ILH,
  COMPETITION_CODE.SA,
  COMPETITION_CODE.BL1,
  COMPETITION_CODE.FL1,
  COMPETITION_CODE.BSA,
  COMPETITION_CODE.ELC,
  COMPETITION_CODE.PPL,
  COMPETITION_CODE.CLI,
  COMPETITION_CODE.SPL,
  COMPETITION_CODE.SSL,
  COMPETITION_CODE.TSL,
  COMPETITION_CODE.UPL,
  COMPETITION_CODE.MLS,
  COMPETITION_CODE.WC,
  COMPETITION_CODE.FCWC,
  COMPETITION_CODE.SUCU,
  COMPETITION_CODE.OLY,
  COMPETITION_CODE.WCF,
  COMPETITION_CODE.QCCF,
  COMPETITION_CODE.QCAF,
  COMPETITION_CODE.QOFC,
  COMPETITION_CODE.QAFC,
  COMPETITION_CODE.ESC,
  COMPETITION_CODE.ECQ,
  COMPETITION_CODE.EL2,
  COMPETITION_CODE.EL1,
  COMPETITION_CODE.FPL,
  COMPETITION_CODE.FAC,
  COMPETITION_CODE.FLC,
  COMPETITION_CODE.BSB,
  COMPETITION_CODE.BJPP,
  COMPETITION_CODE.BJL,
  COMPETITION_CODE.BL3,
  COMPETITION_CODE.BL2,
  COMPETITION_CODE.BLREL,
  COMPETITION_CODE.CDB,
  COMPETITION_CODE.CPD,
  COMPETITION_CODE.CIT,
  COMPETITION_CODE.COM,
  COMPETITION_CODE.CSL,
  COMPETITION_CODE.CA,
  COMPETITION_CODE.CDR,
  COMPETITION_CODE.DED,
  COMPETITION_CODE.DSU,
  COMPETITION_CODE.DFB,
  COMPETITION_CODE.DELP,
  COMPETITION_CODE.DJL,
];
export const orderMatchesByCompetition = (matches: Match[]) => {
  let orderedMatches: Match[] = [];
  ORDERED_COMPETITION_CODES.forEach((competitionCode) => {
    orderedMatches = orderedMatches.concat(
      matches.filter((match) => match.competition.code === competitionCode),
    );
  });
  return orderedMatches;
};
