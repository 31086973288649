import styled from "@emotion/styled";
import React, { FC, Fragment } from "react";
import pitchDark from "../../../../assets/stadiums/pitch.webp";
import pitchLight from "./pitchLight.svg";
import { Column } from "../../../../components/styleGuide/atoms/baseStyles";
import { NoDataMessage } from "../noDataMessage";
import { useHotShotTheme } from "../../../../components/styleGuide/useTheme";
import { LiveMatchProps } from "./lineUpTypes";
import { useTheme } from "@mui/material/styles";

const TeamComponent = ({
  lineUp,
  teamColor,
}: {
  lineUp: any;
  teamColor: string;
}) => {
  return (
    <LineUpContainer>
      {lineUp.map((row: any, indexRow = 1) => {
        return (
          <Fragment key={indexRow}>
            <ColumnContainer>
              {row.map((item: any) => {
                return (
                  <Column
                    key={item.shirtNumber}
                    style={{ alignItems: "center" }}
                  >
                    {/* <TextComponent variant='tiny'>{item.name.split(' ')[1]}</TextComponent> */}
                    <Player backgroundColor={teamColor}>
                      {item.shirtNumber}
                    </Player>
                  </Column>
                );
              })}
            </ColumnContainer>
          </Fragment>
        );
      })}
    </LineUpContainer>
  );
};

// const getLineUp = ({formation,lineup}:{formation:string,lineup:any}) => {
//     let lineUpIndex = 0;
//     let awayLineUp: any = [];
//     const awayLineUpFormation = [1, formation?.split('-')].map((item:any) => parseInt(item));

//     for (let i = 0; i < awayLineUpFormation.length; i++) {
//         const element = awayLineUpFormation[i];
//         awayLineUp = [...awayLineUp, lineup?.slice(lineUpIndex, lineUpIndex + element)]
//         lineUpIndex = lineUpIndex + element;
//     }
// }

const Pitch: FC<{ matchDetails: LiveMatchProps | undefined }> = ({
  matchDetails,
}) => {
  const theme = useTheme();
  const { isDarkMode } = useHotShotTheme();
  const image = isDarkMode ? pitchDark : pitchLight;

  let hasLineup = true;
  if (!matchDetails?.homeTeam?.lineup || !matchDetails?.homeTeam?.formation)
    hasLineup = false;
  if (!matchDetails?.awayTeam?.lineup || !matchDetails?.awayTeam?.formation)
    hasLineup = false;

  if (!hasLineup) {
    return <NoDataMessage />;
  }

  const homeLineUpFormation = [
    1,
    // eslint-disable-next-line no-unsafe-optional-chaining
    ...matchDetails?.homeTeam?.formation?.split("-"),
  ].map((item) => parseInt(item));
  const awayLineUpFormation = [
    1,
    // eslint-disable-next-line no-unsafe-optional-chaining
    ...matchDetails?.awayTeam?.formation?.split("-"),
  ].map((item) => parseInt(item));
  let lineUpIndex = 0;

  let homeLineUp: any = [];
  for (let i = 0; i < homeLineUpFormation.length; i++) {
    const element = homeLineUpFormation[i];
    homeLineUp = [
      ...homeLineUp,
      matchDetails?.homeTeam?.lineup?.slice(lineUpIndex, lineUpIndex + element),
    ];
    lineUpIndex = lineUpIndex + element;
  }

  lineUpIndex = 0;
  let awayLineUp: any = [];
  for (let i = 0; i < awayLineUpFormation.length; i++) {
    const element = awayLineUpFormation[i];
    awayLineUp = [
      ...awayLineUp,
      matchDetails?.awayTeam?.lineup?.slice(lineUpIndex, lineUpIndex + element),
    ];
    lineUpIndex = lineUpIndex + element;
  }

  return (
    <ImageContainer image={image}>
      <StyledContainer>
        <TeamComponent
          lineUp={homeLineUp}
          teamColor={theme.hotShotColors.error}
        />
        <TeamComponent lineUp={awayLineUp.reverse()} teamColor={"#153D8A"} />
      </StyledContainer>
    </ImageContainer>
  );
};

const Player = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px;
  width: 18px;
  height: 18px;
  background: ${(props) => props.backgroundColor};
  box-shadow: 2px 2px 12px rgba(40, 42, 60, 0.24);
  border-radius: 12px;
  color: white;
  margin: 8px 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
`;

const ImageContainer = styled.div<{ image: any }>`
  height: 250px;
  background-image: ${(props) => `url(${props.image})`};
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  padding: 5px;
  padding-left: 10px;
`;

const LineUpContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex: 1;
  padding: 9px 0;
`;

export default Pitch;
