import styled from "@emotion/styled";
import React, { FC } from "react";
import Space from "../../../../components/styleGuide/atoms/space.atom";
import LineUpDetails from "./lineUpDetails";
import LineUpHeader from "./lineUpHeader";
import { LiveMatchProps } from "./lineUpTypes";
import PitchComponent from "./pitch";

const LineUp: FC<{
  matchDetails: LiveMatchProps | undefined;
  standings: any;
}> = ({ matchDetails, standings }) => {
  const homeTeamForm = standings?.standings?.[0]?.table?.find(
    (x: any) => x?.team?.id === matchDetails?.homeTeam?.id,
  )?.form;
  const awayTeamForm = standings?.standings?.[0]?.table?.find(
    (x: any) => x?.team?.id === matchDetails?.awayTeam?.id,
  )?.form;

  return (
    <StyledContainer>
      <LineUpHeader
        matchDetails={matchDetails}
        homeTeamForm={homeTeamForm}
        awayTeamForm={awayTeamForm}
      />
      <Space height={5} />
      <PitchComponent matchDetails={matchDetails} />
      <LineUpDetails matchDetails={matchDetails} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin-top: 10px;
`;

export default LineUp;
