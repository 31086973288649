import React, { useState, createContext, useContext, FC } from "react";
import { ConfirmModal } from "../components/modals/confirmModal/confirmModal";
import _ from "lodash";

type Confirm = {
  show?: boolean;
  message?: string;
  title: string;
  okText: string;
  cancelText?: string;
  hideTextField: boolean;
  label: string;
  description?: string;
};
type ConfirmProviderProps = {
  children: React.ReactNode;
};

type ConfirmFunction = (
  props: Confirm & { okHandler: (val: any) => void },
) => void;

type ConfirmType = {
  confirm: ConfirmFunction;
};
export const ConfirmContext = createContext<ConfirmType>({} as ConfirmType);

export const ConfirmProvider: FC<ConfirmProviderProps> = ({ children }) => {
  const [state, setState] = useState<Confirm>({} as Confirm);
  const [onOkClick, setOnClick] = useState(_.noop);

  const confirm = ({
    title,
    message,
    okText = "OK",
    cancelText = "Cancel",
    hideTextField,
    label,
    okHandler,
    description,
  }: Confirm & { okHandler: (val: any) => void }) => {
    setOnClick((prev) => (val: any) => {
      hideConfirm();
      okHandler(val);
    });

    setState({
      show: true,
      message,
      title,
      okText,
      cancelText,
      hideTextField,
      label,
      description,
    });
  };

  const hideConfirm = () => {
    setState({
      show: false,
      message: "",
      title: "",
      okText: "OK",
      cancelText: "Cancel",
      hideTextField: false,
      label: "",
      description: undefined,
    });
  };

  const {
    show,
    message,
    title,
    okText,
    cancelText,
    hideTextField,
    label,
    description,
  } = state;

  return (
    <ConfirmContext.Provider
      value={{
        confirm,
      }}
    >
      {children}
      <ConfirmModal
        key="confirm"
        opened={show!}
        closeClicked={hideConfirm}
        message={message}
        title={title}
        okText={okText}
        cancelText={cancelText}
        hideTextField={hideTextField}
        label={label}
        description={description}
        okClicked={onOkClick!}
      />
    </ConfirmContext.Provider>
  );
};

export const useConfirm = () => {
  const { confirm } = useContext(ConfirmContext);
  return confirm;
};
