import { green } from "@mui/material/colors";
const pink = "#FF8C00";
const orange = "#0D47A1"; // Replacing darkPurple with orange
const blue = "#2196F3";
const darkPurple = "#37003C";
const greenSuccess = "#00C566";
const greenSuccessDark = green[600];
const redError = "#FF4747";
const yellowWarning = "#FACC15";
const white = "#FFFFFF";
const Grayscale900 = "#1C1C1E";
const Grayscale800 = "#2C2C2E";
const background = "#F7F7F7";
const line = "#E3E7EC";
const lineDark = "#4A4A65";
const Grayscale10 = "#F2F2F7";
const Grayscale20 = "#D1D1D6";
const Grayscale30 = "#C7C7CC";
const Grayscale40 = "#AEAEB2";
const Grayscale50 = "#8E8E93";
const Grayscale60 = "#09080D";
const Grayscale70 = "#08070C";
const Grayscale80 = "#07060A";
const Grayscale90 = "#060508";
const Grayscale100 = "#040406";
const darkShadow = "rgba(40, 42, 60, 0.24)";
const darkLineSummary = "#38384C";
const lightLineSummary = "#EAEDF1";
const tableHeaderLight = "#F0F0F0";
const lightShadow = "rgba(255, 255, 255, 0.3)";
const DarkShadow2 = "rgba(0, 0, 0, 0.3)";
const exact = "#2196F3";

export const hotShotBetThemeLight = {
  hotShotColors: {
    primaryTextColor: Grayscale100,
    secondaryTextColor: Grayscale30,
    thirdBackground: blue,
    primary: pink,
    secondary: "darkPurple", // Updated to orange
    success: greenSuccessDark,
    exact: exact,
    error: redError,
    warning: yellowWarning,
    white: white,
    background: background,
    line: line,
    lineDark: lineDark,
    disabledBackground: "silver",
    disabledFont: Grayscale20,
    menuCircleGray: lightLineSummary,
    menuIconColor: Grayscale900,
    tabsIconColor: Grayscale50,
    iconSelectedColor: orange, // Updated to orange
    cardBackground: white,
    headerBackground: white,
    headerDividerColor: background,
    lightBackground: Grayscale30,
    shadow: darkShadow,
    liveMatchBackground: white,
    lineSummary: lightLineSummary,
    tableHeader: tableHeaderLight,
    headerNavigationShadow: DarkShadow2,
    Grayscale10,
    Grayscale20,
    Grayscale30,
    Grayscale40,
    Grayscale50,
    Grayscale60,
    Grayscale70,
    Grayscale80,
    Grayscale90,
    Grayscale100,
  },
  hotShotTypography: {
    desktop: {
      h1: "72px",
      h2: "48px",
      h3: "40px",
      h4: "32px",
      h5: "24px",
      h6: "20px",
    },
    mobile: {
      h1: "40px",
      h2: "32px",
      h3: "28px",
      h4: "24px",
      h5: "20px",
    },
  },
};

export const hotShotBetThemeDark = {
  ...hotShotBetThemeLight,
  hotShotColors: {
    ...hotShotBetThemeLight.hotShotColors,
    background: "black",
    thirdBackground: blue,
    success: greenSuccess,
    primaryTextColor: white,
    secondaryTextColor: Grayscale70,
    menuIconColor: white,
    iconSelectedColor: white,
    cardBackground: Grayscale800,
    headerBackground: "black",
    headerDividerColor: Grayscale800,
    lightBackground: Grayscale900,
    shadow: lineDark,
    exact: exact,
    liveMatchBackground: darkPurple,
    lineSummary: darkLineSummary,
    tableHeader: Grayscale800,
    disabledBackground: Grayscale40,
  },
};
