import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import { useTheme } from "@emotion/react";
import _ from "lodash";
import { Error } from "@mui/icons-material";
import { SaveStatus } from "./PointsBetComponent";
import styled from "@emotion/styled";

type CircularIntegrationProps = {
  status?: SaveStatus;
  onClick?: (e: any) => void;
};
export default function CircularIntegration({
  status,
  onClick = _.noop,
}: CircularIntegrationProps) {
  const theme = useTheme();
  const isSaving = status === SaveStatus.SAVING;
  const isSaved = status === SaveStatus.SAVED;
  const isError = status === SaveStatus.ERROR;

  return (
    <Container>
      {!isSaving && (
        <IconWrapper success={isSaved} error={isError} onClick={onClick}>
          {isError && <Error sx={{ width: 14 }} color="error" />}
          {isSaved && (
            <CheckIcon
              sx={{ width: 14, color: theme.hotShotColors.primaryTextColor }}
            />
          )}
        </IconWrapper>
      )}
      {isSaving && <Progress size={20} />}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  margin: 12px 10px 8px;
`;

const IconWrapper = styled.div<{ success?: boolean; error?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.success ? props.theme.hotShotColors.primaryTextColor : props.theme.hotShotColors.error)},
  border: solid 1px ${(props) => props.theme.hotShotColors.primaryTextColor},
}`;

const Progress = styled(CircularProgress)`
  color: ${(props) => props.theme.hotShotColors.primaryTextColor};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
`;
