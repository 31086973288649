import React from "react";
import TextComponent from "../../styleGuide/atoms/textComponent.atom";
import styles from "./multiColorBar.module.scss";

export type Reading = {
  name: string;
  value: number;
  color: string;
};

type MultiColorProgressBarProps = {
  readings: Reading[];
};
export class MultiColorProgressBar extends React.Component<MultiColorProgressBarProps> {
  render() {
    const { readings } = this.props;

    const values =
      readings &&
      readings.length &&
      readings.map(function (item, i) {
        if (item.value > 0) {
          return (
            <div
              className={styles.value}
              style={{ color: item.color, width: item.value + "%" }}
              key={i}
            >
              <span>{item.value}%</span>
            </div>
          );
        }
      }, this);

    const calibrations =
      readings &&
      readings.length &&
      readings.map(function (item, i) {
        if (item.value > 0) {
          return (
            <div
              className={styles.graduation}
              style={{ color: item.color, width: item.value + "%" }}
              key={i}
            >
              <span>|</span>
            </div>
          );
        }
      }, this);

    const bars =
      readings &&
      readings.length &&
      readings.map(function (item, i) {
        if (item.value > 0) {
          return (
            <div
              className={styles.bar}
              style={{ backgroundColor: item.color, width: item.value + "%" }}
              key={i}
            ></div>
          );
        }
      }, this);

    const legends =
      readings &&
      readings.length &&
      readings.map(function (item, i) {
        return (
          <div className={styles.legend} key={i}>
            <span className={styles.dot} style={{ color: item.color }}>
              ●
            </span>
            <TextComponent className={styles.label}>{item.name}</TextComponent>
          </div>
        );
      }, this);

    return (
      <div className={styles["multicolor-bar"]}>
        <div className={styles.values}>{!values ? "" : values}</div>
        {/* <div className={styles.scale}>
				{calibrations == ''?'':calibrations}
			</div> */}
        <div className={styles.bars}>{!bars ? "" : bars}</div>
        <div className={styles.legends}>{!legends ? "" : legends}</div>
      </div>
    );
  }
}
