import React, { FC } from "react";
import styled from "@emotion/styled";
import { css, cx } from "@emotion/css";

type textVariant =
  | "header"
  | "large"
  | "medium"
  | "small"
  | "extra-small"
  | "tiny"
  | "caption"
  | "extra-large";

interface TextInputProps {
  children: any;
  variant?: textVariant;
  color?: string;
  textAlign?: "center" | "left" | "right";
  fontWeight?: 400 | 500 | 600;
  textTransform?: "capitalize";
  whiteSpace?: "unset" | "nowrap";
  onClick?: () => void;
  backgroundColor?: string;
  fontSize?: any;
  [key: string]: any;
}

const TextComponent: FC<TextInputProps> = ({
  children,
  variant,
  color,
  textAlign,
  fontWeight,
  textTransform,
  whiteSpace,
  onClick,
  fontSize,
  backgroundColor = "inherit",
  ...props
}) => {
  let className;
  if (variant === "header") {
    className = cx(Header);
  }
  if (variant === "large") {
    className = cx(Large);
  }
  if (variant === "medium") {
    className = cx(Medium);
  }
  if (variant === "small") {
    className = cx(Small);
  }
  if (variant === "extra-small") {
    className = cx(ExtraSmall);
  }

  if (variant === "tiny") {
    className = cx(Tiny);
  }
  if (variant === "extra-large") {
    className = cx(ExtraLarge);
  }
  return (
    <Container
      onClick={onClick}
      textTransform={textTransform}
      fontWeight={fontWeight}
      className={className}
      textAlign={textAlign}
      textColor={color}
      variant={variant}
      style={{ whiteSpace }}
      fontSize={fontSize}
      backgroundColor={backgroundColor}
      {...props}
    >
      {children}
    </Container>
  );
};

const Container = styled.div<{
  variant?: textVariant;
  textColor?: string;
  textAlign?: "center" | "left" | "right";
  fontWeight?: number;
  backgroundColor?: string;
  textTransform?: "capitalize";
  fontSize: any;
}>`
  color: ${(props) =>
    props.textColor
      ? props.textColor
      : props.variant === "medium"
        ? props.theme.hotShotColors.Grayscale50
        : props.theme.hotShotColors.primaryTextColor};
  text-align: ${(props) => (props.textAlign ? props.textAlign : undefined)};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : undefined)};
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : undefined};
  font-size: ${(props) => props.fontSize}px;
  background-color: ${(props) => props.backgroundColor};
`;

const Header = css`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.005em;
  text-align: left;
`;

const ExtraLarge = css`
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.005em;
  text-align: center;
`;

const Large = css`
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;

const Medium = css`
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
`;
const Small = css`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;

const ExtraSmall = css`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.005em;
  text-align: center;
`;

const Tiny = css`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
`;

export default TextComponent;
