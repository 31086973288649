import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  FC,
} from "react";
import { hotShotBetThemeDark, hotShotBetThemeLight } from "./hotShotTheme";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// @ts-expect-error
import { statusBar } from "webtonative";
// @ts-expect-error
import { hide } from "webtonative/BottomNavigation";

// @ts-expect-error
const lightTheme = createTheme({
  ...hotShotBetThemeLight,
});

// @ts-expect-error
const darkTheme = createTheme({
  ...hotShotBetThemeDark,
});

type HotShotThemeContextType = {
  changeTheme: () => void;
  isDarkMode: boolean;
  selectedTheme: any;
};

type HotShotThemeProviderProps = {
  children: React.ReactNode;
};
const HotShowThemeContext = createContext<HotShotThemeContextType>(
  {} as HotShotThemeContextType,
);

export const HotShotThemeProvider: FC<HotShotThemeProviderProps> = ({
  children,
}) => {
  const defaultTheme = "dark";
  const [theme, setTheme] = useState("");
  const isCurrentDark = theme === "dark";

  const changeTheme = () => {
    setTheme(isCurrentDark ? "light" : "dark");
  };

  window.document.body.style.backgroundColor = isCurrentDark
    ? "black"
    : "white";
  const selectedTheme = theme === "dark" ? darkTheme : lightTheme;

  const updateStatusBar = (_theme: any) => {
    statusBar({
      style: _theme === "dark" ? "light" : "dark",
      color: _theme === "dark" ? "#000000" : "#ffffff",
      overlay: true, //Only for android
    });
  };
  useEffect(() => {
    updateStatusBar(theme || "defaultTheme");
    const key = setInterval(() => updateStatusBar(theme || defaultTheme), 500);
    theme && localStorage.setItem("theme", theme);
    hide();
    return () => clearInterval(key);
  }, [theme]);

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme) {
      setTheme(theme);
    } else {
      setTheme(defaultTheme);
    }
  }, []);

  return (
    <ThemeProvider theme={selectedTheme}>
      <HotShowThemeContext.Provider
        value={{
          changeTheme,
          isDarkMode: theme === "dark",
          selectedTheme,
        }}
      >
        {children}
      </HotShowThemeContext.Provider>
    </ThemeProvider>
  );
};

export const useHotShotTheme = () => {
  const { changeTheme, isDarkMode, selectedTheme } =
    useContext(HotShowThemeContext);

  return { changeTheme, isDarkMode, selectedTheme };
};
