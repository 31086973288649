import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import styles from "./login.module.scss";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../../contexts/loadingContext";
import { AuthStatus, useAuthentication } from "../../contexts/AuthContext";
import styled from "@emotion/styled";
import TextComponent from "../../components/styleGuide/atoms/textComponent.atom";
import { useTranslation } from "react-i18next";
import { useHotShotTheme } from "../../components/styleGuide/useTheme";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import TextInputComponent from "../../components/styleGuide/atoms/textInputComponent.atom";
import ButtonComponent from "../../components/styleGuide/atoms/buttonComponent.atom";
import { Row } from "../../components/styleGuide/atoms/baseStyles";
import Space from "../../components/styleGuide/atoms/space.atom";
// @ts-expect-error
import { ReactComponent as GoogleIcon } from "./google.svg";
// @ts-expect-error
import { ReactComponent as AppleIcon } from "./apple.svg";
import { useTheme } from "@mui/material/styles";
import { Header } from "../../components/common/header/header";

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};
//const initialRoute = (window.location.hash.toLowerCase().indexOf('/login') > -1 ? '/' : window.location.hash).replace('#','');
const initialRoute = "/";
const LoginPage = () => {
  const { showLoading, hideLoading } = useLoading();
  const {
    signInWithGoogle,
    authStatus,
    registerWithEmailAndPassword,
    signInWithEmail,
    signInWithApple,
  } = useAuthentication();
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValidForm, setIsValidForm] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  const [viewType, setViewType] = useState("login");
  const isViewTypeRegister = viewType === "register";

  useEffect(() => {
    if (authStatus === AuthStatus.SIGNED_IN) {
      navigate(initialRoute);
    }
  }, [authStatus]);

  useEffect(() => {
    if (
      validateEmail(email) &&
      password?.length > 5 &&
      (!isViewTypeRegister || nickname?.length > 3)
    ) {
      setIsValidForm(true);
    } else {
      setIsValidForm(false);
    }
  }, [email, password, nickname]);
  const { changeTheme, isDarkMode } = useHotShotTheme();

  const loginWithGoogle = async () => {
    showLoading();
    await signInWithGoogle();
    hideLoading();
  };

  const loginWithApple = async () => {
    showLoading();
    await signInWithApple();
    hideLoading();
  };

  const registerWithEmail = async () => {
    showLoading();
    await registerWithEmailAndPassword(email, password, nickname);
    hideLoading();
  };
  const loginWithEmail = async () => {
    showLoading();
    await signInWithEmail(email, password);
    hideLoading();
  };

  const isTest = window.location.search.indexOf("checklogin") > -1;
  //TODO: STUCK WITH LOADING AFTER LOGIN
  return (
    <Container>
      <HeaderContainer>
        <DarkModeSwitch checked={isDarkMode} onChange={changeTheme} size={20} />
        <Space height={40} />

        <TextComponent variant={"large"}>
          {isViewTypeRegister ? t("CREATE_ACCOUNT") : t("SIGN_IN")}
        </TextComponent>
        <Space height={10} />
        {!isViewTypeRegister && (
          <TextComponent variant={"medium"}>
            {t("WE_HAPPY_SIGN_IN")}
          </TextComponent>
        )}
        <TextComponent variant={"medium"}>
          {isViewTypeRegister ? t("SIGN_UP_TO_YOUR") : t("SIGN_INTO_YOUR")}
        </TextComponent>
      </HeaderContainer>
      <Space height={40} />
      <ButtonContainer>
        <ButtonComponent
          variant={"contained"}
          outlineColor={(theme.hotShotColors as any).secondaryTextColor}
          size={"normal"}
          onClick={loginWithGoogle}
        >
          <GoogleIcon />
          <Space width={6} />
          <TextComponent variant={"small"}>
            {isViewTypeRegister ? t("GOOGLE_REGISTER") : t("GOOGLE_SIGN_IN")}
          </TextComponent>
        </ButtonComponent>
        <ButtonComponent
          variant={"contained"}
          outlineColor={(theme.hotShotColors as any).secondaryTextColor}
          size={"normal"}
          onClick={loginWithApple}
        >
          <AppleIcon />
          <Space width={6} />
          <TextComponent variant={"small"}>
            {isViewTypeRegister ? t("APPLE_REGISTER") : t("APPLE_SIGN_IN")}
          </TextComponent>
        </ButtonComponent>
      </ButtonContainer>
      <Space height={20} />
      <TextComponent textAlign="center" variant={"medium"}>
        {t("OR_CONTINUE")}
      </TextComponent>
      <Space height={20} />
      {isViewTypeRegister && (
        <TextInputComponent
          type="text"
          title={t<string>("DISPLAY_NAME")}
          placeholder={t<string>("ENTER_DISPLAY_NAME")}
          value={nickname}
          onChange={setNickname}
        />
      )}
      <Space height={20} />
      <TextInputComponent
        title={t<string>("EMAIL")}
        placeholder={t<string>("ENTER_EMAIL")}
        value={email}
        onChange={setEmail}
      />
      <Space height={20} />
      <TextInputComponent
        title={t<string>("PASSWORD")}
        placeholder={t<string>("ENTER_PASSWORD")}
        type="password"
        value={password}
        onChange={(value) => setPassword(value)}
      />
      <Space height={20} />
      <ButtonComponent
        isDisable={!isValidForm}
        size={"normal"}
        variant={"contained"}
        onClick={isViewTypeRegister ? registerWithEmail : loginWithEmail}
      >
        {isViewTypeRegister ? t("REGISTER") : t("SIGN_IN")}
      </ButtonComponent>
      <Space height={20} />
      <RowSignUp>
        <TextComponent
          color={theme.hotShotColors.Grayscale40}
          variant={"small"}
        >
          {isViewTypeRegister
            ? t("ALREADY_HAVE_AN_ACCOUNT")
            : t("DONT_HAVE_ACCOUNT")}
        </TextComponent>
        <Space width={5} />
        <TextComponent
          onClick={() =>
            isViewTypeRegister ? setViewType("login") : setViewType("register")
          }
          variant={"small"}
          color={"#FF2882"}
        >
          {isViewTypeRegister ? t("LOGIN") : t("SIGN_UP")}
        </TextComponent>
      </RowSignUp>
      <Space height={20} />
      <TextComponent
        textAlign="center"
        variant="small"
      >{`${t("BY_SIGNING_UP")} ${t("TERMS_AND_CONDITIONS")}`}</TextComponent>
      {/* <Button variant="contained" onClick={loginWithGoogle} color="secondary" style={{ margin: 10 }}>
        <i className="fab fa-google"></i> Login With Google
      </Button> */}
    </Container>
  );
};

const RowSignUp = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.hotShotColors.background};
`;

export default LoginPage;
