import styled from "@emotion/styled";
import {
  BottomNavigation,
  BottomNavigationAction,
  IconButton,
  useTheme,
} from "@mui/material";
import { Header } from "../../components/common/header/header";
import styles from "./appHome.module.scss";
// @ts-ignore
import { ReactComponent as TrophyIcon } from "../league/icons/trophy.svg";
// @ts-ignore
import { ReactComponent as HomeIcon } from "../league/icons/home.svg";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import TextComponent from "../../components/styleGuide/atoms/textComponent.atom";
import React, { useEffect, useState } from "react";
import { getFromToDate } from "../../services/matchService";
import { getMatchesData } from "../../services/footballDataService";
import { useLeaguesScreen } from "../leagues/useLeaguesScreen";
import { Match } from "../../../types";
import { useAuthentication } from "../../contexts/AuthContext";
import { Add, Login, Logout, Sports } from "@mui/icons-material";
import { useLoading } from "../../contexts/loadingContext";
import { ScrollableProvider } from "../../contexts/Scrollable";

export const AppHomeWrapper = () => {
  const theme = useTheme();
  const location = useLocation();
  const { showJoinModal } = useLeaguesScreen();
  const { hideLoading } = useLoading();
  const navigate = useNavigate();
  const [allMatches, setAllMatches] = useState<Match[]>([]);
  const page = location.pathname.split("/").pop();
  const { profile, authInitialized, signOut } = useAuthentication();

  useEffect(() => {
    (async () => {
      const { dateFrom, dateTo } = getFromToDate(-5, 5);
      const data = await getMatchesData(dateFrom, dateTo);
      data.forEach((x) => (x.date = new Date(x.utcDate)));
      setAllMatches(data);
      hideLoading();
    })();
  }, []);

  const handleChange = (obj: any, value: string) => {
    navigate(`${value === "home" ? "/" : `/${value}`}`);
  };
  let title = "HotShotBet";
  let rightElement;
  switch (page) {
    case "schedule": {
      title = "Matches Schedule";
      break;
    }
    case "leagues": {
      title = "Matches Schedule";
      break;
    }
    default: {
      title = profile?.displayName
        ? `Hey ${profile.displayName.split(" ")[0]}`
        : "HotShotBet";

      if (profile) {
        rightElement = (
          <IconButton onClick={() => navigate("/create")} color="inherit">
            <Add />
          </IconButton>
        );
      }
    }
  }
  const onBack =
    page === ""
      ? undefined
      : () => {
          navigate("/");
        };
  return (
    <Container>
      <Header
        title={title}
        showLogin={page === ""}
        onBack={onBack}
        rightElement={rightElement}
      />
      {authInitialized && <Outlet context={{ allMatches }} />}

      <StyledBottomNavigation
        value={page}
        showLabels
        onChange={handleChange}
        style={{ backgroundColor: theme.hotShotColors.background }}
        className={styles.navigation}
      >
        <BottomNavigationAction
          label={page === "" ? "*" : ""}
          value="home"
          icon={
            <HomeIcon
              color={
                page === "home"
                  ? theme.hotShotColors.iconSelectedColor
                  : theme.hotShotColors.tabsIconColor
              }
            />
          }
        />
        {/* <BottomNavigationAction label={page === 'schedule' ? '*' : ''} value="schedule" icon={<CalendarIcon color={page === 'schedule' ? theme.hotShotColors.iconSelectedColor : theme.hotShotColors.tabsIconColor} />} /> */}
        <BottomNavigationAction
          label={page === "leagues" ? "*" : ""}
          value="leagues"
          icon={
            <Sports
              style={{
                color: theme.hotShotColors.tabsIconColor,
              }}
            />
          }
        />
      </StyledBottomNavigation>
    </Container>
  );
};

const StyledBottomNavigation = styled(BottomNavigation)`
  box-shadow: 0px 1px 10px 0px
    ${(props) => props.theme.hotShotColors.headerNavigationShadow};
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
