import React, { FC } from "react";
import styled from "@emotion/styled";
import { Avatar, CircularProgress } from "@mui/material";
import TextComponent from "../../../../components/styleGuide/atoms/textComponent.atom";
import Space from "../../../../components/styleGuide/atoms/space.atom";
import { ZoomList } from "../../../../components/common/zoomList/zoomList";
import _ from "lodash";
import { TeamColorsAvatar } from "../../../../components/common/colorsAvatar/colorsAvatar";

const TablesMatchScreen: FC<any> = ({ data, teamsToHighlight = [] }) => {
  if (_.isEmpty(data)) {
    return (
      <LoadingWrapper>
        <CircularProgress />
      </LoadingWrapper>
    );
  }
  const { competition, standings } = data;
  const filteredStandings = standings.filter((standing: any) =>
    standing.table.find((item: any) =>
      teamsToHighlight.find((x: any) => x === item.team.id),
    ),
  );

  return (
    <>
      {filteredStandings.map(({ table, group }: any) => (
        <>
          <TitleRow>
            <TextComponent fontWeight={600} variant={"medium"}>
              {group || competition?.name}
            </TextComponent>
          </TitleRow>
          <Space height={10} />
          <StyledContainer>
            <TableHeader>
              <StyledRow>
                <ListItem>
                  <TextComponent fontWeight={600} variant={"small"}>
                    {""}
                  </TextComponent>
                </ListItem>
                <ListItemTeam>
                  <TextComponent fontWeight={600} variant={"small"}>
                    {"Team"}
                  </TextComponent>
                </ListItemTeam>
                <ListItem>
                  <TextComponent fontWeight={600} variant={"small"}>
                    {"MP"}
                  </TextComponent>
                </ListItem>
                <ListItem>
                  <TextComponent fontWeight={600} variant={"small"}>
                    {"L"}
                  </TextComponent>
                </ListItem>
                <ListItem>
                  <TextComponent fontWeight={600} variant={"small"}>
                    {"D"}
                  </TextComponent>
                </ListItem>
                <ListItem>
                  <TextComponent fontWeight={600} variant={"small"}>
                    {"W"}
                  </TextComponent>
                </ListItem>
                <ListItem>
                  <TextComponent fontWeight={600} variant={"small"}>
                    {"GD"}
                  </TextComponent>
                </ListItem>
                <ListItem>
                  <TextComponent fontWeight={600} variant={"small"}>
                    {"Pts"}
                  </TextComponent>
                </ListItem>
              </StyledRow>
            </TableHeader>
            <Space height={3} />
            <TableContainer>
              <ZoomList>
                {table?.map((item: any, index: any) => {
                  return (
                    <StyledRow
                      key={index + item.shortName + item?.team?.crest}
                      selected={teamsToHighlight.find(
                        (x: any) => x === item?.team?.id,
                      )}
                    >
                      <ListItem>
                        <TextComponent variant={"small"}>
                          {index + 1}
                        </TextComponent>
                      </ListItem>
                      <ListItemTeam>
                        {/* {item?.team?.crest ? <Avatar style={{ width: 30, height: 30 }} src={item?.team?.crest} /> : <Avatar>?</Avatar>} */}
                        <TeamColorsAvatar team={item?.team} size={30} />
                        <Space width={10} />
                        <TextComponent textAlign="left" variant={"extra-small"}>
                          <EllipsisSpan>
                            {item?.team?.shortName || item?.team?.name}
                          </EllipsisSpan>
                        </TextComponent>
                      </ListItemTeam>
                      <ListItem>
                        <TextComponent variant={"extra-small"}>
                          {item.playedGames}
                        </TextComponent>
                      </ListItem>
                      <ListItem>
                        <TextComponent variant={"extra-small"}>
                          {item.lost}
                        </TextComponent>
                      </ListItem>
                      <ListItem>
                        <TextComponent variant={"extra-small"}>
                          {item.draw}
                        </TextComponent>
                      </ListItem>
                      <ListItem>
                        <TextComponent variant={"extra-small"}>
                          {item.won}
                        </TextComponent>
                      </ListItem>
                      <ListItem>
                        <TextComponent variant={"extra-small"}>
                          <span style={{ whiteSpace: "nowrap" }}>
                            {item.goalsFor}-{item.goalsAgainst}
                          </span>
                        </TextComponent>
                      </ListItem>
                      <ListItem>
                        <TextComponent variant={"extra-small"}>
                          {item.points}
                        </TextComponent>
                      </ListItem>
                    </StyledRow>
                  );
                })}
              </ZoomList>
            </TableContainer>
          </StyledContainer>
          <Space height={10} />
        </>
      ))}
    </>
  );
};

const ListItem = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 6px 0;
`;

const ListItemTeam = styled(ListItem)`
  flex: 3;
  flex-direction: row;
  justify-content: flex-start;
`;

const TitleRow = styled.div`
  margin-left: 10px;
`;

const StyledRow = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: ${(props) =>
    props.selected ? props.theme.hotShotColors.tableHeader : undefined};
`;

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.theme.hotShotColors.cardBackground};
  border-radius: 12px 12px 0px 0px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: Column;
  flex: 1;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: Column;
  justify-content: space-between;
  flex: 1;
  border-radius: 20px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  margin: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const EllipsisSpan = styled.span`
  width: 62px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export default TablesMatchScreen;
