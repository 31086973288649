import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import styles from "./matchScreen.module.scss";
import { getMatchUserBets } from "../../../services/betService";
import { UserBets } from "../../../components/matchComponents/userBets/userBets";
import { BetsDistributionPanel } from "../../../components/stats/distributionPanel/betsDistributionPanel";
import { ResultsDistributionPanel } from "../../../components/stats/distributionPanel/resultsDistributionPanel";
import { useLeague } from "../../../contexts/leagueContext";
import { useAuthentication } from "../../../contexts/AuthContext";
import { useMatchScreen } from "./useMatchScreen";
import { isPointsLeague, isStarted } from "../../../services/matchService";
import DetailedMatch from "../../../components/matchComponents/DetailedMatch/DetailedMatch";
import Space from "../../../components/styleGuide/atoms/space.atom";
import TabsHeader from "../tabsHeader/tabsHeader";
import LineUp from "./lineUp/lineUp";
import styled from "@emotion/styled";
import GameSummary from "./summary/summary";
import { SimpleMatches } from "./SimpleMatchesAndTables/SimpleMatches";
import TablesMatchScreen from "./SimpleMatchesAndTables/TablesMatchScreen";
import Statistics from "./statistics/statistics";
import { useAdMob } from "../../../contexts/adMobContext";
import { LeagueExtended, Match } from "../../../../types";
import ButtonComponent from "../../../components/styleGuide/atoms/buttonComponent.atom";
import {
  defaultStadium,
  stadiums,
} from "../../../components/matchComponents/match/stadiums";
import { PointsBetComponent } from "../../../components/matchComponents/match/PointsBetComponent";
import useEmblaCarousel from "embla-carousel-react";
import TopScorer from "./SimpleMatchesAndTables/topScorer";

type MatchScreenProps = {
  matchId?: number;
};

export const MatchScreen: FC<MatchScreenProps> = ({ matchId }) => {
  const { leagueId: leagueIdParam } = useParams();
  // const { showLoading, hideLoading } = useLoading();
  const leagueContext = useLeague();
  const { league, leagueMatches } = leagueContext;
  const [selectedMatch, setSelectedMatch] = useState<Match>();
  const { auth, profile } = useAuthentication();
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const {
    getLeagueMatchesOfTeams,
    navigateToAdminMatch,
    handleChangeTab,
    tab,
    matchDetails,
    matchDetailsLoaded,
    resetMatchDetails,
    getStandings,
    getHead2Head,
    getScorerData,
    scorerData,
    head2Head,
    standings,
  } = useMatchScreen(selectedMatch?.id || matchId);
  const { showAd } = useAdMob();

  const tabsItems: any[] = [];
  useEffect(() => {
    if (matchId) {
      setSelectedMatch(_.find(leagueMatches, { id: matchId }) as Match);
    }
  }, [matchId]);
  useEffect(() => {
    if (Math.random() > 0.85) {
      showAd();
    }
  }, []);

  useEffect(() => {
    handleChangeTab(0);
    if (!matchId) {
      resetMatchDetails();
      setSelectedMatch(undefined);
    }
  }, [selectedMatch?.id, matchId]);
  useEffect(() => {
    if (selectedMatch) {
      setSelectedMatch(selectedMatch);
    } else if (matchDetails && !league) {
      setSelectedMatch(matchDetails);
    }
  }, [matchDetails, selectedMatch]);

  useEffect(() => {
    getHead2Head();
    getStandings();
    getScorerData();
  }, [matchDetails]);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.scrollTo(tab);
    }
  }, [tab]);
  useEffect(() => {
    if (emblaApi) {
      const onSlide = (emblaApi: any, eventName: any) => {
        handleChangeTab(emblaApi.selectedScrollSnap());
      };
      emblaApi.on("select", onSlide);
      return () => {
        emblaApi.off("select", onSlide);
      };
    }
  }, [selectedMatch?.id, emblaApi]);

  if (!selectedMatch) {
    return null;
  }
  const { homeTeam, awayTeam } = selectedMatch;
  const title = `${homeTeam.shortName || homeTeam.name} - ${awayTeam.shortName || awayTeam.name}`;
  const userBets = leagueMatches
    ? getMatchUserBets(league!, selectedMatch)
    : [];
  const isMatchStarted = isStarted(selectedMatch);
  const showGoToAdmin = profile?.canUpdateMatches || profile?.isAppAdmin;
  const showPlaceBetSection = !isMatchStarted;
  const showUserBetDistribution = leagueIdParam && isMatchStarted && tab === 0;
  const showUserBetsSection = leagueIdParam && isMatchStarted;

  const hasBets = isStarted(selectedMatch) && leagueIdParam;

  const hasMatchSummary =
    matchDetails?.substitutions?.length ||
    matchDetails?.goals?.length ||
    matchDetails?.bookings?.length;

  const hasLineup =
    matchDetails?.homeTeam?.lineup?.length &&
    matchDetails?.awayTeam?.lineup?.length;

  const { homeTeamMatches, awayTeamMatches } = getLeagueMatchesOfTeams();
  const hasHead2Head =
    head2Head?.length || homeTeamMatches?.length || awayTeamMatches?.length;

  const hasStatistics =
    _.find(matchDetails?.awayTeam?.statistics, (x) => x) ||
    _.find(matchDetails?.homeTeam?.statistics, (x) => x);

  const hasScorerData = scorerData?.scorers?.length;
  if (hasBets) {
    tabsItems.push({ title: "Bets", name: "bets" });
  }
  if (hasLineup) {
    tabsItems.push({ title: "Line Up", name: "lineup" });
  }
  if (hasMatchSummary) {
    tabsItems.push({ title: "Summary", name: "summary" });
  }

  tabsItems.push({ title: "Standings", name: "standings" });

  if (hasHead2Head) {
    tabsItems.push({ title: "Matches", name: "matches" });
  }
  if (hasStatistics) {
    tabsItems.push({ title: "Statistics", name: "statistics" });
  }
  if (hasScorerData) {
    tabsItems.push({ title: "Top Scorer", name: "topScorer" });
  }

  tabsItems.forEach((tab, index) => {
    tab.id = index;
  });
  const goToAdminMatch = showGoToAdmin && (
    <div className={styles.adminLink}>
      <ButtonComponent
        variant="text"
        size="small"
        onClick={navigateToAdminMatch}
      >
        Change Game Score (Admin)
      </ButtonComponent>
    </div>
  );

  let placeBetSection = null;

  if (league && isPointsLeague(league)) {
    placeBetSection = showPlaceBetSection && (
      <PointsBetComponent match={selectedMatch} league={league} />
    );
  }

  const userBetsDistributionSection = showUserBetDistribution && (
    <div>
      <BetsDistributionPanel
        title="Bets Distribution"
        league={league!}
        selectedLeaguedMatch={selectedMatch}
        userBets={userBets}
        homeTeam={homeTeam}
        awayTeam={awayTeam}
      />
      <ResultsDistributionPanel
        title="Results Distribution"
        league={league!}
        selectedLeaguedMatch={selectedMatch}
        userBets={userBets}
      />
    </div>
  );

  const stadium =
    stadiums[selectedMatch.venue || "VELTINS-Arena"] || defaultStadium();

  const onMatchClick = (match: Match) => {
    resetMatchDetails();
    setSelectedMatch(match);
  };

  const mergedMatchDetails = {
    ...matchDetails,
    ...selectedMatch,
  };
  return (
    <>
      <DetailedMatchContainer className={styles.container} stadium={stadium}>
        <DetailedMatch
          leagueMatch={mergedMatchDetails}
          currentUserAuth={auth}
        />
      </DetailedMatchContainer>
      <Space height={10} />
      {/* {placeBetSection} */}
      {tabsItems.length > 1 && (
        <TabsHeader
          sticky
          selectedId={tab}
          onClick={handleChangeTab}
          items={tabsItems}
        />
      )}
      {/* {userBetsDistributionSection} */}
      <Embla ref={emblaRef}>
        <EmblaContainer>
          {!!showUserBetsSection && (
            <EmblaSlide isActive={tabsItems?.[tab]?.name === "bets"}>
              <UserBets
                userBets={userBets}
                league={league as LeagueExtended}
                selectedLeaguedMatch={selectedMatch}
              />
            </EmblaSlide>
          )}
          {!matchDetailsLoaded && (
            <LoadingWrapper>
              <CircularProgress />
            </LoadingWrapper>
          )}
          {matchDetailsLoaded && (
            <>
              {!!hasLineup && (
                <EmblaSlide isActive={tabsItems?.[tab]?.name === "lineup"}>
                  <LineUp matchDetails={matchDetails} standings={standings} />
                </EmblaSlide>
              )}
              {!!hasMatchSummary && (
                <EmblaSlide isActive={tabsItems?.[tab]?.name === "summary"}>
                  <GameSummary matchDetails={matchDetails} />
                </EmblaSlide>
              )}
              <EmblaSlide
                isActive={
                  tabsItems?.[tab]?.name === "standings" ||
                  tabsItems.length === 1
                }
              >
                <TablesMatchScreen
                  data={standings}
                  teamsToHighlight={[
                    matchDetails?.homeTeam?.id,
                    matchDetails?.awayTeam?.id,
                  ]}
                />
              </EmblaSlide>
              {!!hasHead2Head && (
                <EmblaSlide isActive={tabsItems?.[tab]?.name === "matches"}>
                  {head2Head?.length > 0 && (
                    <SimpleMatches
                      matches={head2Head}
                      title={"Head to Head"}
                      onClick={onMatchClick}
                    />
                  )}
                  {homeTeamMatches?.length && (
                    <SimpleMatches
                      matches={homeTeamMatches}
                      title={`${homeTeam.name} Matches`}
                      onClick={onMatchClick}
                    />
                  )}
                  {awayTeamMatches?.length && (
                    <SimpleMatches
                      matches={awayTeamMatches}
                      title={`${awayTeam.name} Matches`}
                      onClick={onMatchClick}
                    />
                  )}
                </EmblaSlide>
              )}
              {!!hasStatistics && (
                <EmblaSlide isActive={tabsItems?.[tab]?.name === "statistics"}>
                  <Statistics matchDetails={matchDetails} />
                </EmblaSlide>
              )}
              {!!hasScorerData && (
                <EmblaSlide isActive={tabsItems?.[tab]?.name === "topScorer"}>
                  <TopScorer
                    scorerData={scorerData}
                    teamsToHighlight={[
                      matchDetails?.homeTeam?.id,
                      matchDetails?.awayTeam?.id,
                    ]}
                  />
                </EmblaSlide>
              )}
            </>
          )}
        </EmblaContainer>
      </Embla>
      {goToAdminMatch}
    </>
  );
};

type DetailedMatchProps = {
  stadium: string;
};
const DetailedMatchContainer = styled.div<DetailedMatchProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("${(props) => props.stadium}");
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 5;
  }
  > div {
    z-index: 9;
  }
`;

const LoadingWrapper = styled.div`
  width: 100%;
  margin: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Embla = styled.div`
  overflow: hidden;
  padding: 0 3px;
`;
const EmblaContainer = styled.div`
  display: flex;
`;

type EmblaSlideProps = {
  isActive: boolean;
};
const EmblaSlide = styled.div<EmblaSlideProps>`
  flex: 0 0 100%;
  min-width: 0;
  max-height: ${(props) => (props.isActive ? "inherit" : "30vh")};
  padding: 0 5px;
  box-sizing: border-box;
`;
