import React from "react";
import { Header } from "../../components/common/header/header";
import { MatchesList } from "../../components/matchComponents/matches/matchesList";
import styles from "./adminMatches.module.scss";
import { useLeagueMatches } from "../../contexts/firestoreContext";
import { useLeagues } from "../../contexts/leaguesContext";
import { useLoading } from "../../contexts/loadingContext";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../contexts/AuthContext";
import { getProcessMatches } from "../../services/leagueProcessService";
import MatchesScreen from "../league/matches/matchesScreen";

const AdminMatches = () => {
  const navigate = useNavigate();
  const { leagues } = useLeagues();
  const { auth, profile } = useAuthentication();
  const { matches } = useLeagueMatches();
  const processedMatches = getProcessMatches(matches);

  useLoading();
  if (!profile?.isAppAdmin) {
    navigate("/");
    return <></>;
  }

  const goToAdminMatch = (value: number) => {
    navigate("/admin/match/" + value);
  };

  return (
    <div className={styles.container}>
      <Header title="Admin Matches" onBack={() => navigate(-1)} />
      <div>
        <MatchesScreen overrideMatches={processedMatches} />
      </div>
    </div>
  );
};

export default AdminMatches;
