import React, { FC } from "react";
import moment from "moment";
import styles from "./leagueMatchPoints.module.scss";
import styled from "@emotion/styled";
import { useLeague } from "../../../contexts/leagueContext";
import {
  getMatchResult,
  isFinished,
  isOddsScoreLeague,
  isStarted,
} from "../../../services/matchService";
import TextComponent from "../../styleGuide/atoms/textComponent.atom";
import Space from "../../styleGuide/atoms/space.atom";
import { getBet, isBetCorrect, isBetExact } from "../../../services/betService";
import { useAnalyticsLogEvent } from "../../../hooks/useAnalyticsHook";
import { TeamColorsAvatar } from "../../common/colorsAvatar/colorsAvatar";
import { Column } from "../../styleGuide/atoms/baseStyles";
import { LiveMinute } from "./liveMinute";
import { PointsText } from "../DetailedMatch/PointsText";
import { useTheme } from "@emotion/react";
import { Match, User } from "../../../../types";
import { OddsBetsComponent } from "./OddsBetComponent";
import { stadiums } from "./stadiums";
import { PointsBetComponent } from "./PointsBetComponent";
type LeagueMatchPointsProps = {
  leagueMatch: Match;
  onClick: () => void;
  showPoints?: boolean;
  showDate?: boolean;
  currentUserAuth: User;
  auth: User;
  removeable?: boolean;
  slim?: boolean;
  removeGame?: () => void;
  addGame?: () => void;
  showOdds?: boolean;
};

const wait = (timeout: number) =>
  new Promise((resolve) => setTimeout(resolve, timeout));
export const LeagueMatchPoints: FC<LeagueMatchPointsProps> = (props) => {
  const {
    leagueMatch,
    onClick,
    showDate = false,
    currentUserAuth,
    auth,
    removeable,
    slim,
    removeGame,
    addGame,
    showOdds = false,
  } = props;
  const { league } = useLeague();
  const theme = useTheme();
  const logEvent = useAnalyticsLogEvent();
  const { homeTeam, awayTeam } = leagueMatch;
  const isMatchLive = isStarted(leagueMatch) && !isFinished(leagueMatch);
  const isForLoggedInUser =
    !currentUserAuth || currentUserAuth?.uid === auth?.uid;
  const showBet = isForLoggedInUser && !isStarted(leagueMatch) && !!league;
  const { homeScore, awayScore } = getMatchResult(leagueMatch);
  const userToshow = currentUserAuth || auth;
  let myBet = userToshow?.uid && getBet(league!, leagueMatch, userToshow);
  myBet = {
    homeScore: myBet?.homeScore || 0,
    awayScore: myBet?.awayScore || 0,
  };
  const exact = myBet && isBetExact(myBet, leagueMatch);
  const correct = myBet && isBetCorrect(myBet, leagueMatch) && !exact;
  const missed = myBet && !correct && !exact;
  let pointsText = "";
  let pointsTextColor = theme.hotShotColors.primary;
  if (isStarted(leagueMatch)) {
    if (exact) pointsText = `(+${leagueMatch.exactScore || 3})`;
    else if (correct) pointsText = `(+${leagueMatch.correctScore || 1})`;

    if (exact) pointsTextColor = theme.hotShotColors.exact;
    else if (correct) pointsTextColor = theme.hotShotColors.success;
    else if (missed) pointsTextColor = theme.hotShotColors.error;
  }

  const showPoints = !!league && isStarted(leagueMatch) && !!league;
  const staduim = !!league && stadiums[leagueMatch.venue || "VELTINS-Arena"];

  const showBettingNearScore = !slim && !showBet && !!league;
  return (
    <>
      {/* @ts-ignore */}
      <Wrapper stadium={staduim}>
        {isMatchLive && (
          <MinuteContainer>
            <LiveMinute match={leagueMatch} />
          </MinuteContainer>
        )}
        {showPoints && isStarted(leagueMatch) && (
          <PointsTextWrapper>
            <PointsText
              bet={myBet}
              match={leagueMatch}
              useOdds={isOddsScoreLeague(league!)}
            />
          </PointsTextWrapper>
        )}
        {!isStarted(leagueMatch) && !!league && (
          <TimeWrapper>
            <TextComponent
              color={theme.hotShotColors.Grayscale50}
              fontSize={13}
            >
              {moment(leagueMatch.date).format("HH:mm")}
            </TextComponent>
          </TimeWrapper>
        )}

        {addGame &&
          (!leagueMatch?.selected ? (
            <AddGame onClick={onClick} leftComponent>
              {"+"}
            </AddGame>
          ) : (
            <BlankButton leftComponent />
          ))}
        <Column onClick={onClick}>
          <Container
            slim={!league || isStarted(leagueMatch)}
            className={styles.container}
          >
            <Row
              style={{
                justifyContent: "flex-end",
                overflow: "hidden",
              }}
            >
              <TeamNameWrapper>
                <TextComponent textAlign={"right"} variant="extra-small">
                  {homeTeam.shortName || homeTeam.name || "N/A"}
                </TextComponent>
              </TeamNameWrapper>
              <Space width={5} />
              <TeamColorsAvatar team={homeTeam} size={30} />
            </Row>
            {!isStarted(leagueMatch) ? (
              <GreenTextContainer className={styles.matchDate}>
                {showBet && !isStarted(leagueMatch) && (
                  <PointsBetComponent league={league!} match={leagueMatch} />
                )}
                {!league && (
                  <TextComponent
                    color={theme.hotShotColors.primaryTextColor}
                    variant="small"
                  >
                    {moment(leagueMatch.date).format("HH:mm")}
                  </TextComponent>
                )}
              </GreenTextContainer>
            ) : (
              <GreenTextContainer className={styles.matchDate}>
                <div className={styles.scoreWrapper}>
                  {showBettingNearScore && (
                    <DisabledBet color={pointsTextColor}>
                      {myBet.homeScore || 0}
                    </DisabledBet>
                  )}
                  <TextComponent fontWeight={500} variant="header">
                    {homeScore} : {awayScore}
                  </TextComponent>
                  {showBettingNearScore && (
                    <DisabledBet color={pointsTextColor}>
                      {myBet.awayScore || 0}
                    </DisabledBet>
                  )}
                </div>
                {isFinished(leagueMatch) && !league && (
                  <TextComponent variant="extra-small">FT</TextComponent>
                )}
                {!isMatchLive && !isFinished(leagueMatch) && showDate && (
                  <TextComponent
                    color={theme.hotShotColors.Grayscale50}
                    variant="extra-small"
                    textAlign="center"
                  >
                    {moment(leagueMatch.date).format("MM/DD/YYYY")}
                  </TextComponent>
                )}
              </GreenTextContainer>
            )}
            <Row
              style={{
                justifyContent: "flex-start",
                overflow: "hidden",
              }}
            >
              <TeamColorsAvatar team={awayTeam} size={30} />
              <Space width={5} />
              <TeamNameWrapper>
                <TextComponent textAlign={"left"} variant="extra-small">
                  {awayTeam.shortName || awayTeam.name || "N/A"}
                </TextComponent>
              </TeamNameWrapper>
            </Row>
          </Container>
          <div style={{ width: "100%" }}>
            {showOdds && (
              <Row full={true}>
                <OddsBetsComponent
                  leagueMatch={leagueMatch}
                  myBet={myBet}
                  betClickable={false}
                  roundBet={isOddsScoreLeague(league!)}
                />
              </Row>
            )}
          </div>
        </Column>
        {removeGame &&
          (leagueMatch?.selected ? (
            <RemoveGame onClick={onClick}>{"-"}</RemoveGame>
          ) : (
            <BlankButton />
          ))}
      </Wrapper>
    </>
  );
};

type BlankButtonProps = {
  leftComponent?: boolean;
};
export const BlankButton = styled.div<BlankButtonProps>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: white;
  font-size: 15px;
  position: absolute;
  left: ${(props) => (props.leftComponent ? "10px" : undefined)};
  right: ${(props) => (!props.leftComponent ? "10px" : undefined)};
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
`;

type AddGameProps = {
  leftComponent?: boolean;
};
export const AddGame = styled.div<AddGameProps>`
  background-color: ${(props) => props.theme.hotShotColors.success};
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: white;
  font-size: 15px;
  position: absolute;
  left: ${(props) => (props.leftComponent ? "10px" : undefined)};
  right: ${(props) => (!props.leftComponent ? "10px" : undefined)};
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
`;

type RemoveGameProps = {
  leftComponent?: boolean;
  onClick?: () => void;
};
export const RemoveGame = styled.div<RemoveGameProps>`
  background-color: ${(props) => props.theme.hotShotColors.error};
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: white;
  position: absolute;
  left: ${(props) => (props.leftComponent ? "10px" : undefined)};
  right: ${(props) => (!props.leftComponent ? "10px" : undefined)};
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
`;

type WrapperProps = {
  stadium: string;
};
const Wrapper = styled.div<WrapperProps>`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(130, 130, 130, 0.15);
  border-radius: 12px;
  margin-bottom: 10px;
  padding: 10px 0;
  background-size: cover;
  z-index: 5;
  max-height: 108px;
  }
  > * {
    z-index: 9;
  }
`;

const GreenTextContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;
type RowProps = {
  full?: boolean;
  justifyContent?: string;
};
const Row = styled.div<RowProps>`
  align-items: center;
  justify-content: ${(props) => props.justifyContent || "center"};
  display: flex;
  flex: 1;
  width: 100%;
`;
type ContainerProps = {
  slim: boolean;
};
const Container = styled.div<ContainerProps>`
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-radius: 12px;
  height: 100%;
  flex: 1;
`;

const TeamNameWrapper = styled(TextComponent)`
  max-width: 100px;
  // overflow: hidden;
  text-overflow: ellipsis;
`;

const MinuteContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
`;
const PointsTextWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px;
`;

const DisabledBet = styled(TextComponent)<{
  color: string;
}>`
  color: ${(props) => props.theme.hotShotColors.Grayscale50};
  font-size: 20px;
  background-color: transparent;
  font-weight: 600;
  border: solid 1px ${(props) => props.theme.hotShotColors.Grayscale50};
  box-sizing: border-box;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  opacity: 0.9;
`;

const TimeWrapper = styled.div`
  margin-top: 1px;
  position: absolute;
  top: 0;
`;
