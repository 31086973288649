import _ from "lodash";
import React, { useEffect } from "react";
import { useLoading } from "../../../contexts/loadingContext";
import { useLeague } from "../../../contexts/leagueContext";
import { useAuthentication } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { isOddsLeague } from "../../../services/matchService";
import styled from "@emotion/styled";
import Space from "../../../components/styleGuide/atoms/space.atom";
import { Welcome } from "../welcome/welcome";
import TextComponent from "../../../components/styleGuide/atoms/textComponent.atom";
import ButtonComponent from "../../../components/styleGuide/atoms/buttonComponent.atom";
import { LinkButton } from "../../../components/styleGuide/atoms/LinkComponent.Atom";
import { LeagueExtended, PointsLeaderboardEntry } from "../../../../types";
import MatchesScreen from "../matches/matchesScreen";
import { useRateAppPrompt } from "../../../contexts/rateAppPromptContext";

export const Home = () => {
  const { league, leagueMatches } = useLeague();
  const { auth } = useAuthentication();
  const navigate = useNavigate();
  useLoading();
  const { showRateApp } = useRateAppPrompt();

  useEffect(() => {
    //if league last leaderboard, in my entry, I got more than 1 exact, alert
    if (!isOddsLeague(league!) && league?.lastLeaderboardThatStarted) {
      const myEntry = league.lastLeaderboardThatStarted[
        auth.uid
      ] as PointsLeaderboardEntry;
      if (myEntry?.exactDiff >= 1) {
        setTimeout(() => showRateApp(), 45000);
      }
    }
  }, [league?.lastLeaderboard]);

  if (!leagueMatches?.length && league?.admin.uid === auth.uid) {
    return <Welcome />;
  }
  const hasPlayers = !!league?.users && Object.keys(league.users).length > 1;

  const leagueExtended = league as LeagueExtended;
  return (
    <Root>
      {/* {!hasPlayers && (
        <Wrapper>
          <Space height={10} />
          <TextComponent variant={"small"} textAlign="center">
            You seem lonely here,
          </TextComponent>
          <TextComponent variant={"small"} textAlign="center">
            Why don't you invite your
            <br />
            friends to join the league
          </TextComponent>
          <Space height={10} />
          <LinkButton
            url={`whatsapp://send?text=You are invited to play in ${league?.name} Join http://${window.location.host}/?leagueCode=${league?.id}`}
            displayLabel={"Invite friends to play on Whatsapp"}
          />
          <ButtonComponent
            size="normal"
            variant="contained"
            onClick={() => {
              navigator.clipboard.writeText(
                `http://${window.location.host}/?leagueCode=${league?.id}`,
              );
              setTimeout(() => alert("Copied to clipboard"), 100);
            }}
          >
            {"Copy league join link"}
          </ButtonComponent>
        </Wrapper>
      )} */}
      <MatchesScreen showTitle={false} showPoints={true} heightOffset={120} />
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 5px;
  // height: calc(100vh - 124px);
  // margin-top: 56px;
`;

const SummaryContainer = styled.div`
  background-color: ${(props) => props.theme.hotShotColors.background};
`;
const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 10px 0;
  align-items: center;
  background-color: ${(props) => props.theme.hotShotColors.cardBackground};
  border-radius: 12px;
  margin-bottom: 10px;
`;

const Sticky = styled.div`
  position: sticky;
  display: flex;
  top: 0;
  z-index: 1;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
`;
