import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { REGULAR_SEASON, GROUP_STAGE } from "../../../consts/general";
import { useForceUpdate } from "../../../hooks/forceUpdate";
import { useLeague } from "../../../contexts/leagueContext";
import {
  getHead2HeadData,
  getMatchFullData,
  getScorersData,
  getStandingsData,
} from "../../../services/footballDataService";
import { Match, Team } from "../../../../types";
import { isStarted } from "../../../services/matchService";
import { Scorers } from "./SimpleMatchesAndTables/topScorer";

export const useMatchScreen = (id?: number) => {
  const { selectedLeaguedMatch, league } = useLeague();
  const navigate = useNavigate();
  const [tab, setTab] = useState<number>(0);
  const [matchDetails, setMatchDetails] = useState<any>();
  const [matchDetailsLoaded, setMatchDetailsLoaded] = useState(false);
  const [scorerData, setScorerData] = useState<Scorers>();
  const forceUpdate = useForceUpdate();
  const [head2Head, setHead2Head] = useState<any>();
  const [standings, setStandings] = useState<any>();

  useEffect(() => {
    const interval = setInterval(() => {
      forceUpdate();
    }, 10000);
    return () => clearInterval(interval);
  }, [forceUpdate]);

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const matchDetails = await getMatchFullData(Number(id));
          setMatchDetails(matchDetails);
        } catch (error) {
          console.log(error);
        }
        setMatchDetailsLoaded(true);
      })();
    }
  }, [selectedLeaguedMatch, id]);

  const getHead2Head = async () => {
    try {
      const { matches = [] } = await getHead2HeadData(Number(id));
      setHead2Head(matches.filter((x: Match) => x.id !== Number(id)));
    } catch (error) {
      console.log(error);
      setHead2Head([]);
    }
  };

  const getLeagueMatchesOfTeams = () => {
    if (!league?.matchesData) {
      return {};
    }
    const selectedMatch = league.matchesData.find(
      (x: Match) => x.id === Number(id),
    );

    if (!selectedMatch) {
      return {};
    }
    const homeTeam = selectedMatch.homeTeam;
    const awayTeam = selectedMatch.awayTeam;
    const leagueMatches = (league?.matchesData || []).filter(
      (x) => x.id !== selectedMatch.id,
    );
    const homeTeamMatches = leagueMatches.filter(
      (x: Match) =>
        (x.homeTeam.id === homeTeam.id || x.awayTeam.id === homeTeam.id) &&
        isStarted(x),
    );
    const awayTeamMatches = leagueMatches.filter(
      (x: Match) =>
        (x.homeTeam.id === awayTeam.id || x.awayTeam.id === awayTeam.id) &&
        isStarted(x),
    );
    return { homeTeamMatches, awayTeamMatches };
  };

  const getStandings = async () => {
    if (!matchDetails) return;
    try {
      const standingsData = await getStandingsData(
        Number(
          matchDetails?.competition?.id ||
            selectedLeaguedMatch?.competition?.id,
        ),
      );
      setStandings({ ...standingsData });
    } catch (error) {
      console.log(error);
    }
  };

  const getScorerData = async () => {
    try {
      const scorerData = await getScorersData(
        Number(
          matchDetails?.competition?.id ||
            selectedLeaguedMatch?.competition?.id,
        ),
      );
      setScorerData(scorerData);
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeTab = (tab: number) => {
    setTab(tab);
  };

  const navigateToAdminMatch = () => {
    navigate("/admin/match/" + (selectedLeaguedMatch?.id || matchDetails?.id));
  };

  const getTeamNameGroupName = (matches: Match[], team: Team) => {
    const teamMatchInGroup = matches?.find(
      (x) =>
        [REGULAR_SEASON, GROUP_STAGE].indexOf(x.type) > -1 &&
        (x.homeTeam === team || x.awayTeam === team),
    );
    const teamGroupName = teamMatchInGroup && teamMatchInGroup.group;
    return teamGroupName;
  };
  const resetMatchDetails = () => {
    setMatchDetails(undefined);
    setMatchDetailsLoaded(false);
  };

  return {
    getTeamNameGroupName,
    navigateToAdminMatch,
    getLeagueMatchesOfTeams,
    handleChangeTab,
    tab,
    matchDetails,
    matchDetailsLoaded,
    resetMatchDetails,
    getStandings,
    getHead2Head,
    scorerData,
    getScorerData,
    head2Head,
    standings,
  };
};
