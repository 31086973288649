import _ from "lodash";
import React, { Component, FC, useState } from "react";
import styles from "./profileLarge.module.scss";
import { Create, Check } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { PerformanceIndicatorText } from "../../stats/performance/performanceIndicator";
import { useAuthentication } from "../../../contexts/AuthContext";
import styled from "@emotion/styled";
import Space from "../../styleGuide/atoms/space.atom";
import TextComponent from "../../styleGuide/atoms/textComponent.atom";
import { isOddsLeague } from "../../../services/matchService";
import CoinComponent from "../../styleGuide/atoms/coinComponent.atom";
import { Row } from "../../styleGuide/atoms/baseStyles";

type ProfileLargeProps = {
  user: any;
  updateMessage?: (message: string) => void;
  updateImage?: (file: any) => void;
  enableEditMessage?: boolean;
  enableEditImage?: boolean;
  picture?: string;
  message?: string;
  hideMessage?: boolean;
  league?: any;
};
export const ProfileLarge: FC<ProfileLargeProps> = (props) => {
  const {
    user,
    updateMessage,
    updateImage,
    enableEditMessage,
    enableEditImage,
    picture,
    message,
    hideMessage,
    league,
  } = props;
  const { lastLeaderboardThatFinished } = league || {};
  const { auth } = useAuthentication();
  const [showEditMessage, setShowEditMessage] = useState(false);
  const messageRef = null;
  const fileUploadRef: any = null;

  const isMe = auth.uid === user.uid;
  const authUserRank = lastLeaderboardThatFinished?.[user.uid] || {};
  const showFileDialog = () => {
    fileUploadRef.click();
  };

  // const editImage = (files) => {
  //   const file = _.get(files, "target.files[0]");
  //   updateImage(file);
  // };

  let diff = 0;
  if (isOddsLeague(league)) {
    diff = authUserRank?.balanceDiff;
  } else {
    diff = authUserRank?.scoreDiff;
  }

  let score = 0;
  if (isOddsLeague(league)) {
    score = authUserRank?.balance;
  } else {
    score = authUserRank?.score;
  }

  return (
    <Container>
      <FlexContainer>
        {league && (
          <div style={{ textAlign: "center", width: 80 }}>
            <div className={styles.scoreWrapper}>
              {/* <TextComponent>{user.displayName}</TextComponent> */}
              <Row>
                <TextComponent variant="large">{score}</TextComponent>
                {isOddsLeague(league) && (
                  <>
                    <CoinComponent variant="medium" />
                    <Space width={5} />
                  </>
                )}
              </Row>
            </div>
            <div className={styles.performance}>
              <Row>
                <PerformanceIndicatorText num={diff} style={{ fontSize: 11 }} />
              </Row>
            </div>
          </div>
        )}

        <div className={styles.profile}>
          <img
            src={user.avatarUrl}
            alt={user.displayName}
            className={styles.image}
          />
          <Space height={10} />
          <TextComponent>{user.displayName}</TextComponent>
        </div>

        {league && (
          <div style={{ width: 80 }}>
            <div className={styles.rank}>
              <TextComponent>Rank</TextComponent>
              <TextComponent variant="large">
                {authUserRank ? `#${authUserRank.rank}` : "N/A"}
              </TextComponent>
            </div>
            <div className={styles.performance}>
              <PerformanceIndicatorText
                num={authUserRank && authUserRank.rankDiff}
                style={{ fontSize: 15 }}
              />
            </div>
          </div>
        )}
      </FlexContainer>
      {/* <Container>
        <TextComponent>$4,234 away from first place</TextComponent>
      </Container> */}
    </Container>
  );
};

const Container = styled.div`
  flex-direction: column;
  text-align: center;
  background-color: ${(props) => props.theme.hotShotColors.cardBackground};
  border-radius: 12px;
  padding: 13px;
`;

const FlexContainer = styled.div`
  flex-direction: row;
  padding: 10px 0;
  justify-content: space-around;
  align-items: center;
  display: flex;
  text-align: center;
`;

{
  /* {!hideMessage && (showEditMessage ? (
          <div>
            <TextField
              className={styles.textField}
              id="message"
              label="Message"
              defaultValue={message}
              inputRef={ref => messageRef = ref}
              margin="normal"
            />
            <Check onClick={() => {
              setShowEditMessage(false)
              updateMessage(messageRef.value);
            }} />
          </div>
        ) : (
          <div className={styles.message}>
            {message || (enableEditMessage && <i style={{ color: 'silver' }}>Your tagline here</i>)}
            {enableEditMessage && <span style={{ marginLeft: 10 }}>
              <Create style={{ color: 'silver' }} onClick={() => setShowEditMessage(true)} />
            </span>}
          </div>
        ))}
        <input type="file" id="fileUpload" style={{ opacity: 0, height: 0, width: 0 }} ref={ref => fileUploadRef = ref} onChange={editImage} /> */
}
