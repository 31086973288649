import _ from "lodash";
import { defaultLeagueImage } from "../consts/general";
import { emptyLeaderboardEntry } from "./betService";
import {
  createLeaderboard,
  generateDynamicLeaderboard,
} from "./leaderboardService";
import { isCancelled, isFinished, isStarted } from "./matchService";
import {
  DateLeaderboard,
  League,
  LeagueExtended,
  Match,
  MatchBets,
  User,
} from "../../types";

export const LEAGUE_TYPE = {
  POINTS_LEAGUE: "POINTS_LEAGUE",
  ODDS_LEAGUE: "ODDS_LEAGUE",
  ODDS_SCORE: "ODDS_SCORE",
};

export const getTop3 = (lastLeaderboard: DateLeaderboard) => {
  if (!lastLeaderboard) {
    return [];
  }
  const ranked1 = _.filter(lastLeaderboard, (x) => x.rank === 1);
  const ranked2 = _.filter(lastLeaderboard, (x) => x.rank === 2);
  const ranked3 = _.filter(lastLeaderboard, (x) => x.rank === 3);
  const all = [...ranked1, ...ranked2, ...ranked3];
  const top3 = _.take(all, 3);
  return top3;
};

export const getProcessMatches = (matches: Match[] = []) => {
  return matches.filter((x) => !isCancelled(x));
};

export const processLeagueData = (
  league: League,
  matches: Match[],
  auth: User,
) => {
  const leagueMatches = getProcessMatches(
    matches.filter((x: Match) => league?.matchesIds?.includes(x.id)),
  );

  if (!leagueMatches || !league.users) {
    return {
      league: league as LeagueExtended,
      leagueMatches: [],
    };
  }

  const leagueExtended = league as LeagueExtended;

  if (leagueExtended) {
    ///TODO: START - THIS SHOULD BE REMOVED AFTER WE APDATE ALL CODE TO SUPPRRT THE NEW LEAGUE STRUCTURE
    leagueExtended.matches = leagueExtended.matchesIds.reduce(
      (acc: Record<number, MatchBets>, matchId: number) => {
        if (!acc[matchId]) {
          acc[matchId] = {
            bets: {},
          };
          _.forEach(leagueExtended.users, (user) => {
            acc[matchId].bets[user.uid] = user?.bets[matchId];
          });
        }
        return acc;
      },
      {},
    );
    ///TODO:  END - THIS SHOULD BE REMOVED AFTER WE APDATE ALL CODE TO SUPPRRT THE NEW LEAGUE STRUCTURE

    leagueExtended.validUsers = _.filter(leagueExtended.users, (x) => x.valid);
    leagueExtended.image = leagueExtended.image || defaultLeagueImage;
    leagueExtended.isAdmin = auth.uid === leagueExtended.admin.uid;
    leagueExtended.authUserRank = {};

    if (leagueMatches.length > 0) {
      const {
        dynamicLeaderboard,
        lastLeaderboard,
        lastLeaderboardThatFinished,
        lastLeaderboardThatStarted,
      } = generateDynamicLeaderboard(leagueExtended, leagueMatches);
      leagueExtended.dynamicLeaderboard = dynamicLeaderboard;
      leagueExtended.lastLeaderboard = lastLeaderboard;
      leagueExtended.lastLeaderboardThatFinished = lastLeaderboardThatFinished;
      leagueExtended.lastLeaderboardThatStarted = lastLeaderboardThatStarted;

      leagueExtended.validMatches = leagueMatches.filter(
        (x) => x.homeTeam && x.awayTeam && !x.ignored,
      );
      leagueExtended.nextAllMatches = _.orderBy(
        leagueMatches.filter((x) => !isFinished(x) && !x.ignored),
        ["date"],
      );
      leagueExtended.orderedValidMatches = _.orderBy(
        leagueExtended.validMatches,
        ["date"],
      );
      leagueExtended.groupedMatches = _.chain(
        leagueExtended.orderedValidMatches,
      )
        .groupBy(
          (x) =>
            new Date(
              (x.date as Date).getFullYear(),
              (x.date as Date).getMonth(),
              (x.date as Date).getDate(),
            ),
        )
        .value();

      leagueExtended.homePreviousMatches = leagueExtended.orderedValidMatches
        .filter((x) => isFinished(x))
        .reverse()
        .slice(0, 2);
      leagueExtended.homeLiveMatches = leagueExtended.orderedValidMatches
        .filter((x) => isStarted(x) && !isFinished(x))
        .slice(0, 2);
      leagueExtended.homeNextMatches = _.orderBy(
        leagueExtended.orderedValidMatches
          .filter((x) => !isStarted(x))
          .reverse(),
        (x) => x.date,
      ).slice(0, 2);
      leagueExtended.authUserRank =
        _.find(leagueExtended.lastLeaderboard, (x) => x.uid === auth.uid) || {};
    } else if (Object.keys(leagueExtended.users).length > 0) {
      //if no matches in league
      leagueExtended.dynamicLeaderboard = {
        0: createLeaderboard(leagueExtended),
      };
      leagueExtended.lastLeaderboard = leagueExtended.dynamicLeaderboard[0];
      leagueExtended.lastLeaderboardThatFinished =
        leagueExtended.dynamicLeaderboard[0];
      leagueExtended.lastLeaderboardThatStarted =
        leagueExtended.dynamicLeaderboard[0];
    }
    if (!leagueExtended.lastLeaderboard) {
      leagueExtended.lastLeaderboard = {};
      _.forEach(leagueExtended.users, (user, id) => {
        if (user.valid) {
          //@ts-expect-error
          leagueExtended.lastLeaderboard[id] = emptyLeaderboardEntry(id);
        }
      });
    }
  }
  return {
    league: leagueExtended,
    leagueMatches,
  };
};
