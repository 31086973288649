import React, { FC } from "react";
import { Alert } from "@mui/material";
import { useTheme } from "@mui/system";
import { isOddsLeague } from "../../../services/matchService";
import { Row } from "../../styleGuide/atoms/baseStyles";
import CoinComponent from "../../styleGuide/atoms/coinComponent.atom";
import Space from "../../styleGuide/atoms/space.atom";
import TextComponent from "../../styleGuide/atoms/textComponent.atom";
import {
  League,
  LeagueExtended,
  OddsLeaderboardEntry,
} from "../../../../types";

type EligibleToWinAlertProps = {
  league: LeagueExtended;
  userId: string;
};
export const EligibleToWinAlert: FC<EligibleToWinAlertProps> = ({
  league,
  userId,
}) => {
  const theme = useTheme();
  const leaderboardEntry = league?.lastLeaderboard?.[
    userId
  ] as OddsLeaderboardEntry;
  if (leaderboardEntry?.leftToBeEligible === 0) {
    return <></>;
  }
  return (
    <>
      <Alert severity="warning">
        <Row>
          <TextComponent
            variant="extra-small"
            color={(theme as any).hotShotColors.secondaryTextColor}
          >
            {"Bets amount left to be eligible for win: "}{" "}
            {
              (league?.lastLeaderboard?.[userId] as OddsLeaderboardEntry)
                .leftToBeEligible
            }
            <CoinComponent variant="small" />
          </TextComponent>
        </Row>
      </Alert>
      <Space height={10} />
    </>
  );
};
