import styled from "@emotion/styled";
import React, { FC } from "react";

type TeamLastMatchesFormProps = {
  form: string;
  reverse?: boolean;
};
export const TeamLastMatchesForm: FC<TeamLastMatchesFormProps> = ({
  form,
  reverse = false,
}) => {
  let formArr = form?.split(",");
  if (reverse) {
    formArr = formArr?.reverse();
  }
  const getColor = (result: string) => {
    switch (result) {
      case "W":
        return "#4CAF50";
      case "D":
        return "silver";
      case "L":
        return "#F44336";
      default:
        return "#000000";
    }
  };
  return (
    <FormWrapper>
      {formArr?.map((item, index) => {
        return (
          <ResultWrapper
            backgroundColor={getColor(item)}
            key={index}
          ></ResultWrapper>
        );
      })}
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  display: flex;
  align-items: center;
`;

type ResultWrapperProps = {
  backgroundColor: string;
};
const ResultWrapper = styled.div<ResultWrapperProps>`
  display: flex;
  align-items: center;
  font-size: 10px;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.theme.hotShotColors.primaryTextColor};
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 2px;
`;
