export const resizeImage = (file: File) => {
  return new Promise((resolve, reject) => {
    if (file.type.match(/image.*/)) {
      // Load the image
      const reader = new FileReader();
      reader.onload = function (readerEvent) {
        const image = new Image();
        image.onload = function (imageEvent) {
          // Resize the image
          const max_size = 544;
          const canvas: HTMLCanvasElement = document.createElement("canvas");
          let width = image.width;
          let height = image.height;
          if (width > height) {
            if (width > max_size) {
              height *= max_size / width;
              width = max_size;
            }
          } else {
            if (height > max_size) {
              width *= max_size / height;
              height = max_size;
            }
          }
          canvas.width = width;
          canvas.height = height;
          // @ts-expect-error
          canvas.getContext("2d").drawImage(image, 0, 0, width, height);
          const dataUrl = canvas.toDataURL("image/jpeg");
          resolve(dataURLToBlob(dataUrl));
        };
        image.src = readerEvent?.target?.result as string;
      };
      reader.readAsDataURL(file);
    }
  });
};
export const dataURLToBlob = (dataURL: string) => {
  const BASE64_MARKER = ";base64,";
  let parts, contentType, raw;
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    parts = dataURL.split(",");
    contentType = parts[0].split(":")[1];
    raw = parts[1];

    return new Blob([raw], { type: contentType });
  }

  parts = dataURL.split(BASE64_MARKER);
  contentType = parts[0].split(":")[1];
  raw = window.atob(parts[1]);
  const rawLength = raw.length;

  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
};
