import _ from "lodash";
import React, { FC, createContext, useContext, useEffect } from "react";
import { useFirestoreState } from "./firestoreContext";
import { useLeaguesInitializer } from "./firestoreListeners";
import { League, LeagueExtended, Match } from "../../types";

type LeaguesContextType = {
  leagues: LeagueExtended[];
  matches: Match[];
  leaguesInitialized: boolean;
  refreshLeagues: () => void;
};

type LeaguesProviderProps = {
  children: React.ReactNode;
};

export const LeaguesContext = createContext<LeaguesContextType>(
  {} as LeaguesContextType,
);

export const LeaguesProvider: FC<LeaguesProviderProps> = ({ children }) => {
  const { firestoreState, setFirestoreState } = useFirestoreState();
  const [refreshTime, setRefreshTime] = React.useState<number>(0);
  useLeaguesInitializer(setFirestoreState, firestoreState, [refreshTime]);

  const refresh = () => setRefreshTime((prev) => prev + 1);
  return (
    <LeaguesContext.Provider
      value={{
        leagues: firestoreState?.ordered?.leagues?.filter(
          (x: League) => x.active,
        ),
        matches: firestoreState?.ordered?.matches,
        leaguesInitialized: firestoreState?.leaguesInitialized,
        refreshLeagues: refresh,
      }}
    >
      {children}
    </LeaguesContext.Provider>
  );
};

export const useLeagues = () => {
  const { leagues, matches, leaguesInitialized, refreshLeagues } =
    useContext(LeaguesContext);
  return {
    leagues: _.orderBy(leagues, (x) => x.createdAt, "desc"),
    matches: _.orderBy(matches, (x) => x.date),
    leaguesInitialized,
    refreshLeagues,
  };
};
