import React from "react";
import styled from "@emotion/styled";
import { Match } from "../../../../types";

export const LiveMinute = ({ match }: { match: Match }) => {
  const isHalfTime =
    match.minute === 45 &&
    match.score.halfTime.home !== null &&
    match.score.halfTime.away !== null;

  return (
    <Text animate={!isHalfTime}>
      {isHalfTime ? "HT" : `${match.minute || "1"}'`}
    </Text>
  );
};

type TextProps = {
  animate: boolean;
};
const Text = styled.div<TextProps>`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  border-radius: 10px;
  color: white;
  padding: 2px 8px;
  border: 1px solid #029c51;
  background: #029c51;
  animation: ${({ animate }) => (animate ? "scale 2s infinite" : "none")};
  @keyframes scale {
    0% {
      padding: 1px 7px;
    }
    50% {
      padding: 1px 6px;
    }
    100% {
      padding: 1px 7px;
    }
  }
`;
