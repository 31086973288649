import _ from "lodash";
import moment from "moment";
import { REGULAR_SEASON, GROUP_STAGE } from "../../../consts/general";
import { orderMatchesByCompetition } from "../../../services/matchService";
import { Match } from "../../../../types";

export const groupMatchesByDate = (match: Match) =>
  moment(match.date).format("MMM Do YYYY") || "0";
export const groupMatchesByMatchday = (match: Match) =>
  match.matchday.toString();
export const groupMatchesByCompetition = (match: Match) =>
  match.competition.name;

export const useMatchesList = ({
  leagueMatches,
  showInvalid,
  reverse,
  groupBy,
  isInLeagueContext,
}: {
  leagueMatches: Match[];
  showInvalid?: boolean;
  reverse?: boolean;
  groupBy?: string;
  isInLeagueContext?: boolean;
}) => {
  const matchesFiltered = showInvalid
    ? leagueMatches
    : leagueMatches?.filter((x) => x.homeTeam && x.awayTeam && !x.ignored) ||
      [];
  let validMatches = _.orderBy(
    matchesFiltered,
    ["date", "id"],
    [reverse ? "desc" : "asc"],
  );
  if (groupBy === "competition") {
    validMatches = orderMatchesByCompetition(validMatches);
  }
  let groupedMatches, stage, keys;
  const hasDifferentCompetitions =
    _.chain(validMatches)
      .map((x) => x.competition.id)
      .uniq()
      .value().length > 1;

  if (groupBy === "date") {
    groupedMatches = _.chain(validMatches).groupBy(groupMatchesByDate).value();
    keys = reverse
      ? _.reverse(Object.keys(groupedMatches))
      : Object.keys(groupedMatches);
    keys = reverse
      ? _.reverse(Object.keys(groupedMatches))
      : Object.keys(groupedMatches);
  } else if (_.every(leagueMatches, (x) => x.stage === REGULAR_SEASON)) {
    stage = REGULAR_SEASON;
    let groupByFunc = groupMatchesByDate;
    if (groupBy === "matchday") {
      groupByFunc = groupMatchesByMatchday;
    } else if (groupBy === "competition") {
      groupByFunc = groupMatchesByCompetition;
    }
    groupedMatches = _.chain(validMatches).groupBy(groupByFunc).value();
    keys = reverse
      ? _.reverse(Object.keys(groupedMatches))
      : Object.keys(groupedMatches);
  } else if (hasDifferentCompetitions || !isInLeagueContext) {
    groupedMatches = _.chain(validMatches)
      .groupBy(groupMatchesByCompetition)
      .value();
    keys = Object.keys(groupedMatches).sort();
    if (reverse) {
      keys = _.reverse(keys);
    }
  } else {
    stage = GROUP_STAGE;
    groupedMatches = _.chain(validMatches).groupBy(groupMatchesByDate).value();
    keys = _.sortBy(Object.keys(groupedMatches), (x) => moment(x).unix());
    if (reverse) {
      keys = _.reverse(keys);
    }
  }
  return { stage, keys, groupedMatches };
};
