import _ from "lodash";
import React, { FC } from "react";
import styles from "./bet.module.scss";
import cx from "classnames";
// import FontAwesome from 'react-fontawesome';
import { Check, Close } from "@mui/icons-material";
import {
  isBetExact,
  isBetCorrect,
  getBetPoints,
  getBet,
  getOddsScorePoints,
} from "../../../services/betService";
import { isOddsScoreLeague, isStarted } from "../../../services/matchService";
import { Row } from "../../styleGuide/atoms/baseStyles";
import styled from "@emotion/styled";
import { League, Match, User } from "../../../../types";

type BetItemProps = {
  league: League;
  leagueMatch: Match;
  auth: User;
};
export const BetItem: FC<BetItemProps> = ({ league, leagueMatch, auth }) => {
  if (!league) {
    return null;
  }

  const myBet = getBet(league, leagueMatch, auth);
  if (myBet) {
    myBet.homeScore = Number(myBet.homeScore) || 0;
    myBet.awayScore = Number(myBet.awayScore) || 0;
  }
  const started = isStarted(leagueMatch);
  const exact = isBetExact(myBet, leagueMatch);
  const correct = isBetCorrect(myBet, leagueMatch) && !exact;
  const missed = !correct && !exact;

  let containerClass = styles.notStarted;
  if (started) containerClass = styles.started;
  if (started && correct) containerClass = styles.correct;
  if (started && exact) containerClass = styles.exact;
  if (started && missed) containerClass = styles.missed;

  return (
    <Container className={cx({ [containerClass]: true, [styles.bet]: true })}>
      <Row>
        {myBet.homeScore} : {myBet.awayScore}
        {isStarted(leagueMatch) && (correct || exact) && <Check />}
        {isStarted(leagueMatch) && missed && <Close />}
      </Row>
      {isStarted(leagueMatch) && (
        <div className={styles.exponentScore}>
          {isOddsScoreLeague(league)
            ? getOddsScorePoints(myBet, leagueMatch)
            : getBetPoints(myBet, leagueMatch)}
          &nbsp;Points
        </div>
      )}
      {/* {isStarted && (isCorrect || isExact) && <FontAwesome className={styles.icon} name='check' />}
      {isStarted && isMissed && <FontAwesome className={styles.icon} name='times' />}
      {isStarted && league.isExponentRanking && <span className={styles.exponentScore}>({getBetPoints(myBet, leagueMatch, auth)} Points)</span>} */}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
`;
