import _ from "lodash";
import {
  getMatchResult,
  isFinished,
  isStarted,
  USER_ODDS_BET,
} from "./matchService";
import { Bet, League, Match, OddsBet, PointsBet, User } from "../../types";

export const getBet = (league: League, leagueMatch: Match, auth: User) => {
  const betKey = `matches.${leagueMatch.id}.bets.${auth.uid}`;
  const myBet = _.get(league, betKey);
  return {
    ...myBet,
  };
};

export const isBetCorrect = (bet: PointsBet, match: Match) => {
  const { homeScore, awayScore } = getMatchResult(match);

  if (bet?.homeScore > bet?.awayScore && homeScore > awayScore) return true;
  if (bet?.homeScore === bet?.awayScore && homeScore === awayScore) return true;
  if (bet?.homeScore < bet?.awayScore && homeScore < awayScore) return true;
  return false;
};

export const isBetExact = (bet: PointsBet, match: Match) => {
  const { homeScore, awayScore } = getMatchResult(match);
  return bet?.homeScore === homeScore && bet?.awayScore === awayScore;
};

export const getBetPoints = (bet: PointsBet, match: Match) => {
  if (isBetExact(bet, match)) {
    return Number(match.exactScore) ? Number(match.exactScore) : 3;
  }
  if (isBetCorrect(bet, match)) {
    return Number(match.correctScore) ? Number(match.correctScore) : 1;
  }
  return 0;
};

export const getOddsWinType = (match: Match) => {
  const { homeScore, awayScore } = getMatchResult(match);
  if (homeScore > awayScore) return "homeWin";
  if (homeScore === awayScore) return "draw";
  return "awayWin";
};
export const getOddsScorePoints = (bet: PointsBet, match: Match) => {
  const winType = getOddsWinType(match);
  const odd = Math.round(Number(match.odds[winType] || 0) * 2) / 2;
  if (isBetExact(bet, match) && match.odds) {
    return (
      Number(match.exactWithMultiplyer || 4) +
      Number((match.correctMultiplier || 1) * odd)
    );
  }
  if (isBetCorrect(bet, match)) {
    return (match.correctMultiplier || 1) * odd;
  }
  return 0;
};

//Odds league
export const getOddBetResult = (bet: OddsBet = {}, match: Match) => {
  const { homeScore, awayScore } = getMatchResult(match);
  const result = {
    homeWin: {
      liveHit: false,
      hit: false,
      amount: 0,
      potential: 0,
      score: false,
      livePotential: 0,
    },
    draw: {
      liveHit: false,
      hit: false,
      amount: 0,
      potential: 0,
      score: false,
      livePotential: 0,
    },
    awayWin: {
      liveHit: false,
      hit: false,
      amount: 0,
      potential: 0,
      score: false,
      livePotential: 0,
    },
  };

  if (bet["homeWin"]) {
    const { amount, odd } = bet["homeWin"];
    if (!isFinished(match)) {
      result["homeWin"].potential = Math.round(odd * amount);
    }
    if (homeScore > awayScore) {
      result["homeWin"].hit = true;
      result["homeWin"].amount = Math.round(odd * amount) - amount;
    } else {
      result["homeWin"].hit = false;
      result["homeWin"].amount = -amount;
    }
  }
  if (bet["draw"]) {
    const { amount, odd } = bet["draw"];
    if (!isFinished(match)) {
      result["draw"].potential = Math.round(odd * amount);
    }
    if (homeScore === awayScore) {
      result["draw"].score = true;
      result["draw"].hit = true;
      result["draw"].amount = Math.round(odd * amount) - amount;
    } else {
      result["draw"].hit = false;
      result["draw"].amount = -amount;
    }
  }
  if (bet["awayWin"]) {
    const { amount, odd } = bet["awayWin"];
    if (!isFinished(match)) {
      result["awayWin"].potential = Math.round(odd * amount);
    }
    if (homeScore < awayScore) {
      result["awayWin"].score = true;
      result["awayWin"].hit = true;
      result["awayWin"].amount = Math.round(odd * amount) - amount;
    } else {
      result["awayWin"].hit = false;
      result["awayWin"].amount = -amount;
    }
  }

  result["homeWin"].score = homeScore > awayScore;
  result["draw"].score = homeScore === awayScore;
  result["awayWin"].score = homeScore < awayScore;

  if (!isFinished(match)) {
    result["homeWin"] = {
      ...result["homeWin"],
      hit: false,
      liveHit: result["homeWin"].hit,
      amount: -(bet["homeWin"]?.amount || 0),
      potential: result["homeWin"]?.potential || 0,
      livePotential: bet["homeWin"]
        ? result["homeWin"].hit
          ? result["homeWin"]?.potential - bet["homeWin"]?.amount
          : -bet["homeWin"]?.amount
        : 0,
    };
    result["draw"] = {
      ...result["draw"],
      hit: false,
      liveHit: result["draw"].hit,
      amount: -(bet["draw"]?.amount || 0),
      potential: result["draw"]?.potential || 0,
      livePotential: bet["draw"]
        ? result["draw"].hit
          ? result["draw"]?.potential - bet["draw"]?.amount
          : -bet["draw"]?.amount
        : 0,
    };
    result["awayWin"] = {
      ...result["awayWin"],
      hit: false,
      liveHit: result["awayWin"].hit,
      amount: -(bet["awayWin"]?.amount || 0),
      potential: result["awayWin"]?.potential || 0,
      livePotential: bet["awayWin"]
        ? result["awayWin"].hit
          ? result["awayWin"]?.potential - bet["awayWin"]?.amount
          : -bet["awayWin"]?.amount
        : 0,
    };
  }

  //score: the score of the match (F.E. for "homeWin", true if the match is finished and the home team won)
  //hit: Is the bet is a hit and the match is finished
  //liveHit: Is the bet is a hit and the match is in play
  //amount: The amount gained from the bet (always a a loss if the match didnt start)
  //potential: The amount gained from the bet if the match is on going
  return result;
};

export const matchHasOdds = (match: Match) =>
  match.odds["homeWin"] && match.odds["draw"] && match.odds["awayWin"];

export const emptyLeaderboardEntry = (uId: string) => {
  return {
    uid: uId,
    bets: 0,
    correct: 0,
    exact: 0,
    score: 0,
    rank: 1,
    correctDiff: 0,
    exactDiff: 0,
    rankDiff: 0,
    scoreDiff: 0,
  };
};

export const isMatchStarted = (match: Match) => {
  const matchTime1Min = new Date((match.date as number) - 60000);
  return matchTime1Min < new Date() || isStarted(match);
};

export const getMatchUserBets = (
  league: League,
  selectedLeaguedMatch: Match,
) => {
  const bets: Record<string, PointsBet> = {};
  Object.keys(league.users).forEach((uid) => {
    const user = league.users[uid];
    bets[user.uid] = user.bets[selectedLeaguedMatch.id] as PointsBet;
    if (!user.bets[selectedLeaguedMatch.id]) {
      bets[user.uid] = {
        homeScore: 0,
        awayScore: 0,
      };
    }
  });
  const result: PointsBet[] = _.chain(bets)
    .map((bet: PointsBet, uid) => {
      return {
        uid,
        ...bet,
        score: getBetPoints(bet, selectedLeaguedMatch),
      };
    })
    .sortBy((bet: any) => `${bet.homeScore}${bet.awayScore}`)
    .orderBy(["score"], ["desc"])
    .value();
  return result;
};

export const getBetToUpdateForPointsLeague = (
  matchId: number,
  type: string,
  score: number,
) => {
  const data = {
    bets: {
      [matchId]: {
        [type]: Number(score) || 0,
      },
    },
  };
  return data;
};

export const generateSoccerScore = (): [number, number] => {
  // Define possible scores with weights
  const scores = [
    { score: [0, 0], weight: 15 },
    { score: [1, 0], weight: 20 },
    { score: [0, 1], weight: 20 },
    { score: [1, 1], weight: 15 },
    { score: [2, 0], weight: 10 },
    { score: [0, 2], weight: 10 },
    { score: [2, 1], weight: 8 },
    { score: [1, 2], weight: 8 },
    { score: [3, 0], weight: 5 },
    { score: [0, 3], weight: 5 },
    { score: [3, 1], weight: 4 },
    { score: [1, 3], weight: 4 },
    { score: [2, 2], weight: 3 },
    { score: [3, 2], weight: 2 },
    { score: [2, 3], weight: 2 },
    { score: [4, 0], weight: 1 },
    { score: [0, 4], weight: 1 },
    { score: [4, 1], weight: 1 },
    { score: [1, 4], weight: 1 },
    { score: [4, 2], weight: 1 },
    { score: [2, 4], weight: 1 },
    { score: [3, 3], weight: 1 },
    { score: [4, 3], weight: 0.5 },
    { score: [3, 4], weight: 0.5 },
    { score: [4, 4], weight: 0.2 },
    { score: [5, 4], weight: 0.1 },
    { score: [4, 5], weight: 0.1 },
  ];

  // Calculate total weight
  const totalWeight = scores.reduce((sum, score) => sum + score.weight, 0);

  // Generate a random number between 0 and totalWeight
  let random = Math.random() * totalWeight;

  // Select a score based on the random number
  for (const score of scores) {
    if (random < score.weight) {
      return score.score as [number, number];
    }
    random -= score.weight;
  }

  // Fallback (should not reach here due to correct totalWeight calculation)
  return [0, 0];
};
