import React, { FC } from "react";
import Compare, {
  CompareItem,
} from "../../../../components/common/compare/compare";
import { LiveMatchProps } from "../lineUp/lineUpTypes";

const Statistics: FC<{ matchDetails: LiveMatchProps | undefined }> = ({
  matchDetails,
}) => {
  const arrayCompare: CompareItem[] = [];
  if (matchDetails?.awayTeam?.statistics) {
    for (const [key, value] of Object.entries(
      matchDetails?.awayTeam?.statistics,
    )) {
      arrayCompare.push({
        title: `${key.split("_").join(" ")}`,
        left: {
          label:
            key === "ball_possession"
              ? `${matchDetails?.homeTeam?.statistics[key] ?? 0}%`
              : matchDetails?.homeTeam?.statistics[key] ?? "0",
          value: matchDetails?.homeTeam?.statistics[key],
        },
        right: {
          label:
            key === "ball_possession"
              ? `${value ?? 0}%`
              : (value as string) ?? "0",
          value: (value as number) ?? 0,
        },
      });
    }
  }
  return <Compare compareItems={arrayCompare} />;
};

export default Statistics;
