import React from "react";
import styled from "@emotion/styled";
import { LeagueExtended, Match } from "../../../types";
import { isFinished } from "../../services/matchService";
import { FC } from "react";

type LeagueProgressProps = {
  league: LeagueExtended;
};
export const LeagueProgress: FC<LeagueProgressProps> = ({ league }) => {
  const finishedMatches =
    league?.matchesData?.filter((match: Match) => isFinished(match)) || [];

  const leagueProgress =
    league &&
    Math.round((finishedMatches.length / league.matchesData.length) * 100);

  if (!leagueProgress) {
    return null;
  }
  return <ProgressBarRow value={leagueProgress} />;
};

type ProgressBarRowProps = {
  value: number;
};

export const ProgressBarRow = styled.div<ProgressBarRowProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  border-radius: 10px;
  height: 3px;
  background-color: ${(props) => props.theme.hotShotColors.Grayscale50};

  &::after {
    content: "";
    width: ${(props) => props.value}%;
    background-color: ${(props) => props.theme.hotShotColors.Grayscale20};
    border-radius: 10px;
    height: 3px;
  }
`;
