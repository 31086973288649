import React, { FC, Fragment, useEffect, useState } from "react";
import styled from "@emotion/styled";
import TextComponent from "../../styleGuide/atoms/textComponent.atom";
import Space from "../../styleGuide/atoms/space.atom";
import { useTheme } from "@mui/material/styles";
import { animated, useSpring } from "@react-spring/web";

export interface CompareItem {
  title: string;
  left: {
    label: string;
    value: number;
  };
  right: {
    label: string;
    value: number;
  };
}

const Compare: FC<{ compareItems: CompareItem[] }> = (props) => {
  const { compareItems } = props;
  const theme = useTheme();

  return (
    <>
      {compareItems.map((item, index) => {
        const allValue = item.left.value + item.right.value;
        const itemLeftPercent = (item.left.value / allValue) * 100;
        const itemRightPercent = (item.right.value / allValue) * 100;

        const [expanded, setExpanded] = useState(false);

        useEffect(() => {
          setExpanded(true);
        }, []);

        const springsLeft = useSpring({
          width: expanded ? `${itemLeftPercent}%` : "0%",
          config: { duration: 700 },
        });

        const springsRight = useSpring({
          width: expanded ? `${itemRightPercent}%` : "0%",
          config: { duration: 700 },
        });

        return (
          <Fragment key={item.title + index}>
            <Container>
              <StyledHeaderRow>
                <TextComponent
                  color={theme.hotShotColors.iconSelectedColor}
                  variant={"medium"}
                >
                  {item.left.label}
                </TextComponent>
                <TextComponent
                  textTransform={"capitalize"}
                  color={theme.hotShotColors.Grayscale40}
                  variant={"small"}
                >
                  {item.title}
                </TextComponent>
                <TextComponent
                  color={theme.hotShotColors.primary}
                  variant={"medium"}
                >
                  {item.right.label}
                </TextComponent>
              </StyledHeaderRow>
              <Space height={13} />
              <StyledRow>
                <Progress flexDirection={"row-reverse"}>
                  <ProgressInner
                    style={springsLeft}
                    color={theme.hotShotColors.iconSelectedColor}
                  />
                </Progress>
                <Space width={10} />
                <Progress flexDirection={"row"}>
                  <ProgressInner
                    style={springsRight}
                    color={theme.hotShotColors.primary}
                  />
                </Progress>
              </StyledRow>
            </Container>
            <Space height={13} />
          </Fragment>
        );
      })}
    </>
  );
};

const StyledHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Progress = styled.div<{ flexDirection: "row" | "row-reverse" }>`
  background-color: ${(props) => props.theme.hotShotColors.cardBackground};
  height: 12px;
  border-radius: 20px;
  flex: 1;
  flex-direction: ${(props) => props.flexDirection};
  display: flex;
`;

const ProgressInner = styled(animated.div)<{ color: string }>`
  height: 12px;
  border-radius: 20px;
  background-color: ${(props) => props.color};
  width: 0;
`;

const Container = styled.div``;
const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
export default Compare;
