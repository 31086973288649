import React, { FC } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  SwipeableDrawer,
  Avatar,
} from "@mui/material";
import styles from "./drawer.module.scss";
import { useAuthentication } from "../../../contexts/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Person,
  SportsSoccer,
  QuestionMark,
  Info,
  Email,
  Logout,
  FormatListBulleted,
  Drafts,
  Group,
  Api,
} from "@mui/icons-material";
import styled from "@emotion/styled";
import TextComponent from "../../styleGuide/atoms/textComponent.atom";
import { useTheme } from "@mui/material/styles";
import { useLeagues } from "../../../contexts/leaguesContext";
import { useLeague } from "../../../contexts/leagueContext";
import { useLoading } from "../../../contexts/loadingContext";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { useHotShotTheme } from "../../styleGuide/useTheme";
import Space from "../../styleGuide/atoms/space.atom";

type DrawerProps = {
  open: boolean;
  closeDrawer: () => void;
  openDrawer: () => void;
};
export const Drawer: FC<DrawerProps> = (props) => {
  const { closeDrawer, openDrawer, open } = props;
  const { league } = useLeague();
  const { showLoading } = useLoading();
  const { profile, signOut } = useAuthentication();
  const { changeTheme, isDarkMode } = useHotShotTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    signOut();
  };
  const goToLogin = () => {
    if (location.pathname !== "/login") {
      showLoading();
      navigate("/login");
    }
  };

  const goToLeagues = () => {
    if (location.pathname !== "/") {
      showLoading();
      navigate("/");
    }
  };
  const goToProfile = () => {
    if (location.pathname !== "/profile") {
      showLoading();
      navigate("/profile");
    }
  };

  const goToRules = () => {
    if (location.pathname !== "/rules") {
      showLoading();
      navigate("/rules");
    }
  };
  const goToContactus = () => {
    if (location.pathname !== "/contactus") {
      showLoading();
      navigate("/contactus");
    }
  };

  const goToMessages = () => {
    if (location.pathname !== "/admin/messages") {
      showLoading();
      navigate("/admin/messages");
    }
  };

  const goToTeamsEditor = () => {
    if (location.pathname !== "/admin/teamsEditor") {
      showLoading();
      navigate("/admin/teamsEditor");
    }
  };

  const goToMatches = () => {
    if (location.pathname !== "/admin/matches") {
      showLoading();
      navigate("/admin/matches");
    }
  };

  const goToAllLeagues = () => {
    if (location.pathname !== "/admin/leagues") {
      showLoading();
      navigate("/admin/leagues");
    }
  };

  const goToAdminApi = () => {
    if (location.pathname !== "/admin/api") {
      showLoading();
      navigate("/admin/api");
    }
  };

  const goToLeague = (id: string) => {
    if (league?.id !== id) {
      if (location.pathname !== `/league/${id}`) {
        showLoading();
        navigate(`/league/${id}`);
        closeDrawer();
      }
    }
  };
  const theme = useTheme();
  function isRunningStandalone() {
    return (
      // @ts-ignore
      window.navigator.standalone === true ||
      window.matchMedia("(display-mode: standalone)").matches
    );
  }

  return (
    <SwipeableDrawer
      PaperProps={{
        sx: {
          backgroundColor: theme.hotShotColors.background,
        },
      }}
      open={open}
      onClose={closeDrawer}
      onOpen={openDrawer}
    >
      <Container className={styles.list}>
        <List component="nav">
          {profile && (
            <ListItem onClick={goToProfile}>
              <ListItemIcon>
                <Avatar alt={profile?.displayName} src={profile?.avatarUrl} />
              </ListItemIcon>
              <TextComponent>{profile?.displayName}</TextComponent>
            </ListItem>
          )}
          {profile && (
            <ListItem onClick={goToLeagues}>
              <ListItemIcon>
                <SportsSoccer
                  style={{ color: theme.hotShotColors.primaryTextColor }}
                />
              </ListItemIcon>
              <TextComponent>{"My Leagues"}</TextComponent>
            </ListItem>
          )}

          <ListItem onClick={goToContactus}>
            <ListItemIcon>
              <Email style={{ color: theme.hotShotColors.primaryTextColor }} />
            </ListItemIcon>
            <TextComponent>{"Contact Us"}</TextComponent>
          </ListItem>
          {profile ? (
            <ListItem onClick={logout}>
              <ListItemIcon>
                <Logout
                  style={{ color: theme.hotShotColors.primaryTextColor }}
                />
              </ListItemIcon>
              <TextComponent>{"Logout"}</TextComponent>
            </ListItem>
          ) : (
            <ListItem onClick={goToLogin}>
              <ListItemIcon>
                <Logout
                  style={{ color: theme.hotShotColors.primaryTextColor }}
                />
              </ListItemIcon>
              <TextComponent>{"Login"}</TextComponent>
            </ListItem>
          )}
        </List>
        {(profile?.canUpdateMatches || profile?.isAppAdmin) && [
          <Divider key="1" />,
          <List key="2" component="nav">
            <ListItem onClick={goToMatches}>
              <ListItemIcon>
                <FormatListBulleted
                  style={{ color: theme.hotShotColors.primaryTextColor }}
                />
              </ListItemIcon>
              <TextComponent>{"Matches"}</TextComponent>
            </ListItem>
            <ListItem onClick={goToTeamsEditor}>
              <ListItemIcon>
                <Drafts
                  style={{ color: theme.hotShotColors.primaryTextColor }}
                />
              </ListItemIcon>
              <TextComponent>{"Teams Editor"}</TextComponent>
            </ListItem>
            {/* <ListItem onClick={goToMessages}>
              <ListItemIcon>
                <Drafts
                  style={{ color: theme.hotShotColors.primaryTextColor }}
                />
              </ListItemIcon>
              <TextComponent>{"Messages"}</TextComponent>
            </ListItem> */}
            {profile?.isAppAdmin && (
              <ListItem onClick={goToAllLeagues}>
                <ListItemIcon>
                  <Group
                    style={{ color: theme.hotShotColors.primaryTextColor }}
                  />
                </ListItemIcon>
                <TextComponent>{"All Leagues"}</TextComponent>
              </ListItem>
            )}
            {profile?.isAppAdmin && (
              <ListItem onClick={goToAdminApi}>
                <ListItemIcon>
                  <Api
                    style={{ color: theme.hotShotColors.primaryTextColor }}
                  />
                </ListItemIcon>
                <TextComponent>{"API"}</TextComponent>
              </ListItem>
            )}
            <Space height={20} />
          </List>,
        ]}
        <List key="3" component="nav">
          <ListItem>
            <ListItemIcon>
              <DarkModeSwitch
                checked={isDarkMode}
                onChange={changeTheme}
                size={20}
              />
            </ListItemIcon>
            <TextComponent onClick={changeTheme}>
              {isDarkMode ? "Dark Mode" : "Light Mode"}
            </TextComponent>
          </ListItem>
        </List>
      </Container>
      <StyledFooter>
        <TextComponent
          color={theme.hotShotColors.Grayscale40}
          variant="extra-small"
        >
          AppVersion: {(window as any).appVersion}
        </TextComponent>
        {isRunningStandalone() && (
          <TextComponent
            color={theme.hotShotColors.Grayscale40}
            variant="extra-small"
          >
            Standalone
          </TextComponent>
        )}
      </StyledFooter>
    </SwipeableDrawer>
  );
};

const Container = styled.div`
  flex: 1;
  background-color: ${(props) => props.theme.hotShotColors.background};
`;

const StyledFooter = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.hotShotColors.background};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;
