import React, { createContext, useContext } from "react";
import firebaseConfig from "../firebase";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebase = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const firestore = getFirestore(firebase);
const analytics = getAnalytics(firebase);

type FirebaseContextType = {
  firebase: any;
  firestore: any;
  analytics: any;
};

type FirebaseProviderProps = {
  children: React.ReactNode;
};
export const FirebaseContext = createContext<FirebaseContextType>(
  {} as FirebaseContextType,
);

export const FirebaseProvider = ({ children }: FirebaseProviderProps) => {
  return (
    <FirebaseContext.Provider value={{ firebase, firestore, analytics }}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => {
  const { firebase } = useContext(FirebaseContext);
  return firebase;
};

export const useFirestore = () => {
  const { firestore } = useContext(FirebaseContext);
  return firestore;
};

export const useFirebaseAnalytics = () => {
  const { analytics } = useContext(FirebaseContext);
  return {
    logEvent: (name: string, params: any) => {
      logEvent(analytics, name, params);
    },
  };
};
