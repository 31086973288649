export const TITLES = {
  Matches: "Matches",
  Board: "Leaderboard",
  Settings: "Settings",
};

export const getMatchGroupName = (match: any) => {
  if (match && match.group) {
    return match.group.replace("_", " ");
  }
  return "";
};
