import React, { FC } from "react";
import styled from "@emotion/styled";
import Coin from "../../../assets/coin.png";
interface CoinProps {
  variant?: "small" | "medium" | "large";
}

const CoinComponent: FC<CoinProps> = ({ variant }) => {
  let coinSize = {
    width: 10,
    height: 10,
  };
  if (variant === "medium") {
    coinSize = {
      width: 20,
      height: 20,
    };
  }
  return <Container {...coinSize} src={Coin}></Container>;
};

const Container = styled.img<{ width: number; height: number }>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  margin-left: 2px;
`;

export default CoinComponent;
