import { Avatar, Typography } from "@mui/material";
import moment from "moment";
import styles from "./leagueMatchOdds.module.scss";
import styled from "@emotion/styled";
import cx from "classnames";
import { getMatchGroupName } from "../../../consts/texts";
import { useLeague } from "../../../contexts/leagueContext";
import { useAuthentication } from "../../../contexts/AuthContext";
import {
  getMatchResult,
  isFinished,
  isStarted,
  USER_ODDS_BET,
} from "../../../services/matchService";
import { getOddBetResult } from "../../../services/betService";
import React, { FC, useState } from "react";
import { PlaceBetOddsModal } from "../PlaceBet/placeBetOddsModal";
import TextComponent from "../../styleGuide/atoms/textComponent.atom";
import GreenTextComponent from "../../styleGuide/atoms/greenTextComponent";
import Space from "../../styleGuide/atoms/space.atom";
import { useTheme } from "@mui/material/styles";
import { AddGame, BlankButton, RemoveGame } from "./leagueMatchPoints";
import { isLineBreak } from "typescript";
import { TeamColorsAvatar } from "../../common/colorsAvatar/colorsAvatar";
import CoinComponent from "../../styleGuide/atoms/coinComponent.atom";
import { OddsBetsComponent } from "./OddsBetComponent";
import { LiveMinute } from "./liveMinute";
import { Match, User } from "../../../../types";

type LeagueMatchOddsProps = {
  leagueMatch: Match;
  onClick: () => void;
  showDate?: boolean;
  currentUserAuth: User;
  removeGame?: () => void;
  addGame?: () => void;
  showPoints?: boolean;
};
export const LeagueMatchOdds: FC<LeagueMatchOddsProps> = (props) => {
  const {
    leagueMatch,
    onClick,
    showDate = true,
    currentUserAuth,
    removeGame,
    addGame,
  } = props;
  const { auth } = useAuthentication();
  const { league } = useLeague();
  const { homeTeam, awayTeam } = leagueMatch;
  const isMatchLive = isStarted(leagueMatch) && !isFinished(leagueMatch);
  const isForLoggedInUser = currentUserAuth && currentUserAuth.uid === auth.uid;
  // if (!matchHasOdds(leagueMatch)) {
  //   return null;
  // }
  const showBet = isForLoggedInUser || isStarted(leagueMatch);
  const { homeScore, awayScore } = getMatchResult(leagueMatch);
  const leagueUser = league!.users[currentUserAuth.uid];
  const myBet = leagueUser.bets[leagueMatch.id];
  const betResult = getOddBetResult(myBet, leagueMatch);
  const theme = useTheme();

  return (
    <>
      <Wrapper>
        <Container onClick={onClick}>
          {addGame && (
            <>
              {!leagueMatch?.selected ? (
                <AddGame>{"+"}</AddGame>
              ) : (
                <BlankButton />
              )}
            </>
          )}
          <Row
            style={{
              justifyContent: "flex-end",
              width: 130,
              overflow: "hidden",
            }}
          >
            <TeamNameWrapper>
              <TextComponent textAlign={"right"} variant="extra-small">
                {" "}
                {homeTeam.shortName || homeTeam.name}
              </TextComponent>
            </TeamNameWrapper>
            <Space width={5} />
            {/* {homeTeam.crest ? <Avatar src={homeTeam.crest} /> : <Avatar>?</Avatar>} */}
            <TeamColorsAvatar team={homeTeam} size={30} />
          </Row>
          {!isStarted(leagueMatch) ? (
            <GreenTextContainer className={styles.matchDate}>
              <TextComponent textAlign={"center"} variant="small">
                {getMatchGroupName(leagueMatch)}
              </TextComponent>
              {showDate && (
                <TextComponent
                  color={theme.hotShotColors.Grayscale50}
                  textAlign={"center"}
                  variant={"extra-small"}
                >
                  {moment(leagueMatch.date).format("MMM Do")}
                </TextComponent>
              )}
              <TextComponent>
                {moment(leagueMatch.date).format("HH:mm")}
              </TextComponent>
            </GreenTextContainer>
          ) : (
            <GreenTextContainer className={styles.matchDate}>
              <div className={styles.groupName}>
                {getMatchGroupName(leagueMatch)}
              </div>
              {!isMatchLive && showDate && (
                <TextComponent
                  color={theme.hotShotColors.Grayscale50}
                  textAlign={"center"}
                  variant={"extra-small"}
                >
                  {moment(leagueMatch.date).format("MMM Do")}
                </TextComponent>
              )}
              {isFinished(leagueMatch) && (
                <TextComponent variant="extra-small">FT</TextComponent>
              )}
              {isMatchLive && <LiveMinute match={leagueMatch} />}
              <TextComponent fontWeight={500} variant="header">
                {homeScore} : {awayScore}
              </TextComponent>
            </GreenTextContainer>
          )}
          <Row
            style={{
              justifyContent: "flex-start",
              width: 130,
              overflow: "hidden",
            }}
          >
            {/* {awayTeam.crest ? <Avatar src={awayTeam.crest} /> : <Avatar>?</Avatar>} */}
            <TeamColorsAvatar team={awayTeam} size={30} />
            <Space width={5} />
            <TeamNameWrapper>
              <TextComponent textAlign={"left"} variant="extra-small">
                {" "}
                {awayTeam.shortName || awayTeam.name}
              </TextComponent>
            </TeamNameWrapper>
          </Row>
          {removeGame && (
            <>
              {leagueMatch?.selected ? (
                <RemoveGame>{"-"}</RemoveGame>
              ) : (
                <BlankButton />
              )}
            </>
          )}
        </Container>
        <Space height={5} />
        {showBet && (
          <OddsBetsComponent
            onClick={() => isStarted(leagueMatch) && onClick()}
            leagueMatch={leagueMatch}
            myBet={myBet}
            betResult={betResult}
          />
        )}
      </Wrapper>
    </>
  );
};

const GreenTextContainer = styled.div`
  width: 90px;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
`;
const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 10px 0;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props.theme.hotShotColors.cardBackground};
  border-radius: 12px;
  margin-bottom: 10px;
`;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100vw;
`;

const TeamNameWrapper = styled(TextComponent)`
  max-width: 92px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
