import React, { FC, createRef, useEffect } from "react";
import styled from "@emotion/styled";
import ButtonComponent from "../../../components/styleGuide/atoms/buttonComponent.atom";
import { useTheme } from "@mui/material/styles";

interface Item {
  title: string;
  id: number;
}

interface Props {
  items: Item[];
  selectedId: number;
  onClick: (id: number) => void;
  sticky?: boolean;
}

const TabsHeader: FC<Props> = ({ items, selectedId, onClick, sticky }) => {
  const theme = useTheme();
  const ref: any = createRef();
  const containerRef: any = createRef();

  useEffect(() => {
    if (ref?.current) {
      const itemRect = ref?.current.getBoundingClientRect();
      const scrollerRect = containerRef?.current?.getBoundingClientRect();
      containerRef.current.scrollTo({
        left:
          itemRect.left +
          containerRef.current.scrollLeft -
          (scrollerRect.width - itemRect.width) / 2,
        behavior: "smooth",
      });
    }
  }, [ref?.current, containerRef]);

  return (
    <Container ref={containerRef} sticky={sticky}>
      {items.map((item, index) => {
        return (
          <div ref={item.id === selectedId ? ref : undefined} key={index}>
            <ButtonComponent
              key={item.id}
              outlineColor={
                item.id === selectedId
                  ? undefined
                  : theme.hotShotColors.Grayscale50
              }
              onClick={() => onClick(item.id)}
              size={"small"}
              variant={item.id === selectedId ? "contained" : "text"}
            >
              {item.title}
            </ButtonComponent>
          </div>
        );
      })}
    </Container>
  );
};

type ContainerProps = {
  sticky?: boolean;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  padding: 0 0 5px 0;
  white-space: nowrap;
  position: ${({ sticky }) => (sticky ? "sticky" : "relative")};
  top: 0;
  z-index: 2;
  background-color: ${({ theme }) => theme.hotShotColors.background};
`;

export default TabsHeader;
