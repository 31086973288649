import _ from "lodash";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useFirestoreState } from "./firestoreContext";
import { useLeagueListener } from "./firestoreListeners";
import { League, LeagueExtended, Match } from "../../types";

type LeagueContextType = {
  league?: LeagueExtended;
  leagueMatches?: Match[];
  selectedLeaguedMatch?: Match;
  refreshLeague?: () => void;
};

type LeagueProviderProps = {
  children: React.ReactNode;
};

export const LeagueContext = createContext<LeagueContextType>(
  {} as LeagueContextType,
);

export const LeagueProvider: FC<LeagueProviderProps> = ({ children }) => {
  const { leagueId, matchId } = useParams();
  const { firestoreState, setFirestoreState } = useFirestoreState();
  const { ordered } = firestoreState;
  const [refreshTime, setRefreshTime] = useState<number>(0);
  useLeagueListener(setFirestoreState, firestoreState, leagueId!, [
    refreshTime,
  ]);

  const [state, setState] = useState<LeagueContextType>({
    selectedLeaguedMatch: undefined,
  });
  const league = (ordered.leagues || []).find((x: League) => x.id === leagueId);

  useEffect(() => {
    const selectedMatch = league?.matchesData?.find(
      (match: any) => match.id.toString() === matchId,
    );
    setState((prev) => ({ ...prev, selectedLeaguedMatch: selectedMatch }));
  }, [matchId, league?.matchesData]);

  const leagueMatches = league?.matchesData;
  const component = leagueMatches && league ? children : null;
  const refresh = () => setRefreshTime((prev) => prev + 1);
  return (
    <LeagueContext.Provider
      value={{
        ...state,
        league: (ordered.leagues || []).find((x: League) => x.id === leagueId),
        leagueMatches,
        refreshLeague: refresh,
      }}
    >
      {component}
    </LeagueContext.Provider>
  );
};

export const useLeague = () => {
  const { leagueMatches, league, selectedLeaguedMatch, refreshLeague } =
    useContext(LeagueContext);
  return { leagueMatches, league, selectedLeaguedMatch, refreshLeague };
};
