import React, { FC, createContext, useContext } from "react";
// @ts-expect-error
import { fullScreenAd, bannerAd } from "webtonative/AdMob";
// @ts-expect-error
import { deviceInfo } from "webtonative";
import { useAnalyticsLogEvent } from "../hooks/useAnalyticsHook";
import { useAuthentication } from "./AuthContext";
import _ from "lodash";

const showInterstitialAd = async () => {
  try {
    const device = await deviceInfo();
    if (device.platform === "android") {
      fullScreenAd({
        adId: "ca-app-pub-3704285102348664/4050426651",
        fullScreenAdCallback: _.noop,
      });
    } else if (device.platform === "ios") {
      fullScreenAd({
        adId: "ca-app-pub-3704285102348664/7247287240",
        fullScreenAdCallback: _.noop,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

type AdMobContextType = {
  showAd: () => void;
};
export const AdMobContext = createContext<AdMobContextType>({ showAd: _.noop });

type AdMobProviderProps = {
  children: React.ReactNode;
};
export const AdMobProvider: FC<AdMobProviderProps> = ({ children }) => {
  const logEvent = useAnalyticsLogEvent();
  const { auth } = useAuthentication();
  const showAd = () => {
    return;
    // logEvent("show_ad");
    // showInterstitialAd();
  };

  // useEffect(() => {
  //     auth && showAd();
  // }, [auth])
  return (
    <AdMobContext.Provider value={{ showAd }}>{children}</AdMobContext.Provider>
  );
};

export const useAdMob = () => {
  const { showAd } = useContext(AdMobContext);
  return { showAd };
};

// const showAppOpenAd = async () => {
//     try {
//         const device = await deviceInfo();
//         if (device.platform === 'android') {
//             // fullScreenAd({
//             //     adId: "ca-app-pub-3704285102348664/4820697788",
//             //     fullScreenAdCallback: (value) => {
//             //     }
//             // })
//         } else if (device.platform === 'ios') {
//             fullScreenAd({
//                 adId: "ca-app-pub-3704285102348664/2330265815",
//                 fullScreenAdCallback: (value) => {
//                 }
//             })
//         }
//     } catch (error) {
//         console.log(error)
//     }
// }

// export const showAdmobBanner = async () => {
//     try {
//         const device = await deviceInfo();
//         if (device.platform === 'android') {
//             // bannerAd({
//             //     adId: "ca-app-pub-3704285102348664/4360026185"
//             // })
//         } else if (device.platform === 'ios') {
//             bannerAd({
//                 adId: "ca-app-pub-3704285102348664/7429042657"
//             })
//         }
//     } catch (error) {
//         console.log(error)
//     }
// }
