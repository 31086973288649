import { Box, Typography } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/common/header/header";
import { MatchesList } from "../../components/matchComponents/matches/matchesList";
import { useAuthentication } from "../../contexts/AuthContext";
import { CreateLeagueContext } from "../../contexts/createLeagueContext";
import {
  useCompetitions,
  useLeagueMatches,
} from "../../contexts/firestoreContext";
import { UpdateLeagueMatchesContext } from "../../contexts/updateLeagueMatchesContext";
import {
  isStarted,
  ORDERED_COMPETITION_CODES,
} from "../../services/matchService";
import { MobileStepperWizard } from "./components/mobileStepperWizard";
import styles from "./updateLeagueMatchesStep2.module.scss";
import styled from "@emotion/styled";
import Space from "../../components/styleGuide/atoms/space.atom";
import TextComponent from "../../components/styleGuide/atoms/textComponent.atom";
import { useTheme } from "@mui/material";
import { Row } from "../../components/styleGuide/atoms/baseStyles";
import { TeamColorsAvatar } from "../../components/common/colorsAvatar/colorsAvatar";
import { Competition, Team } from "../../../types";
import _ from "lodash";

export const UpdateLeagueMatchesStep2 = () => {
  const competitions = useCompetitions();
  const { auth } = useAuthentication();
  const { matches } = useLeagueMatches();
  const navigate = useNavigate();
  const [fromPickerOpen, setFromPickerOpen] = useState(false);
  const [toPickerOpen, setToPickerOpen] = useState(false);
  const theme = useTheme();

  const {
    currentCompetitionId,
    setCurrentCompetitionId,
    teams,
    selectedTeamsIds,
    setSelectedTeamsIds,
    selectedMatches,
    updateSelectedMatches,
    fromDateFilter,
    setFromDateFilter,
    toDateFilter,
    setToDateFilter,
    resetDateFilters,
  } = useContext(UpdateLeagueMatchesContext);
  const filteredCompetitions = ORDERED_COMPETITION_CODES.map((code) =>
    competitions.find((x: Competition) => x.code === code),
  );
  const currentCompetition = filteredCompetitions.find(
    (x) => x?.id === currentCompetitionId,
  );
  let filteredMatches = matches.filter((x) => !isStarted(x));
  if (selectedTeamsIds.length > 0) {
    filteredMatches = filteredMatches.filter(
      (x) =>
        selectedTeamsIds.includes(x.homeTeam.id) ||
        selectedTeamsIds.includes(x.awayTeam.id),
    );
  }
  filteredMatches = filteredMatches.filter(
    //@ts-ignore
    (match) => match.date >= fromDateFilter && match.date <= toDateFilter,
  );

  const onMatchClick = (matchId: number) => {
    const match = matches.find((x) => x.id === matchId);
    if (!match) {
      return;
    }
    if (selectedMatches.find((x) => x.id === match.id)) {
      updateSelectedMatches([
        ...selectedMatches.filter((x) => x.id !== match.id),
      ]);
    } else {
      updateSelectedMatches([...selectedMatches, match]);
    }
  };

  const onTeamClick = (team: Team) => {
    const isSelectedTeam = selectedTeamsIds.includes(team.id);
    isSelectedTeam
      ? setSelectedTeamsIds(selectedTeamsIds.filter((x) => x !== team.id))
      : setSelectedTeamsIds([...selectedTeamsIds, team.id]);
  };

  const selectAll = () => {
    const matchesToAdd = filteredMatches.filter(
      (x) => !selectedMatches.find((y) => y.id === x.id),
    );
    updateSelectedMatches([...selectedMatches, ...matchesToAdd]);
  };

  const selectNone = () => {
    const matchesAfterRemove = selectedMatches.filter(
      (x) => !filteredMatches.find((y) => y.id === x.id),
    );
    updateSelectedMatches([...matchesAfterRemove]);
  };
  return (
    <>
      <Header onBack={() => navigate(-1)} title={"Update Matches"} />
      <Box sx={{ flexGrow: 1, overflow: "hidden" }} className={styles.content}>
        <div className={styles.container}>
          <SliderContainer className={styles.teamsSlider}>
            {filteredCompetitions.map((competition) => {
              if (!competition) {
                return;
              }
              return (
                <ImageContainer
                  className={
                    competition === currentCompetition
                      ? styles.selectedTeam
                      : styles.unSelectedTeam
                  }
                >
                  <Image
                    src={competition.area?.flag}
                    key={competition.id}
                    alt={competition.name}
                    onClick={() => setCurrentCompetitionId(competition.id)}
                  />
                  <Space height={3} />
                  <TextComponent
                    style={{ whiteSpace: "nowrap" }}
                    textAlign="center"
                    color={theme.hotShotColors.primaryTextColor}
                    fontWeight={600}
                    variant="tiny"
                  >
                    {competition.name}
                  </TextComponent>
                </ImageContainer>
              );
            })}
          </SliderContainer>
          <SliderContainer className={styles.teamsSlider}>
            {Object.values(teams).map((team) => {
              const isSelectedTeam = selectedTeamsIds.includes(team.id);
              return (
                <ImageContainer
                  onClick={() => onTeamClick(team)}
                  className={
                    isSelectedTeam ? styles.selectedTeam : styles.unSelectedTeam
                  }
                >
                  {/* <Image src={team.crest}
                                    key={team.id}
                                    alt={team.name}

                                    onClick={() => onTeamClick(team)}
                                />
                                 */}
                  <TeamColorsAvatar team={team} size={60} />
                  <Space height={3} />
                  <TextComponent
                    style={{ whiteSpace: "nowrap" }}
                    textAlign="center"
                    fontWeight={600}
                    color={(theme.hotShotColors as any).primaryTextColo}
                    variant="tiny"
                  >
                    {team.shortName ?? team.name}
                  </TextComponent>
                </ImageContainer>
              );
            })}
          </SliderContainer>
          <SelectContainer>
            <TextContainer>
              <TextComponent
                color={theme.hotShotColors.primary}
                variant={"small"}
                onClick={() => setFromPickerOpen(true)}
              >
                {moment(fromDateFilter).format("MM/DD/YYYY")}
              </TextComponent>
              <Space width={5} />
              <TextComponent
                color={theme.hotShotColors.primaryTextColor}
                variant={"small"}
              >
                to
              </TextComponent>
              <Space width={5} />
              <TextComponent
                color={theme.hotShotColors.primary}
                variant={"small"}
                onClick={() => setToPickerOpen(true)}
              >
                {moment(toDateFilter).format("MM/DD/YYYY")}
              </TextComponent>
              {/* <TextComponent
                    color={theme.hotShotColors.primaryTextColor}
                    variant="medium"
                     onClick={resetDateFilters}>(Clear)</TextComponent> */}
            </TextContainer>
            <Row>
              <TextComponent
                color={theme.hotShotColors.primary}
                variant={"small"}
                onClick={selectAll}
              >
                All
              </TextComponent>
              <Space width={20} />
              <TextComponent
                color={theme.hotShotColors.primary}
                variant={"small"}
                onClick={selectNone}
              >
                None
              </TextComponent>
            </Row>
          </SelectContainer>
          <MobileDatePicker
            label="From"
            inputFormat="MM/DD/YYYY"
            value={fromDateFilter}
            onChange={(date) => setFromDateFilter(date as any)}
            //@ts-ignore
            renderInput={(params) => <div {...params} />}
            closeOnSelect={true}
            onClose={() => setFromPickerOpen(false)}
            open={fromPickerOpen}
          />
        </div>
        <div className={styles.filter}>
          <MobileDatePicker
            label="To"
            inputFormat="MM/DD/YYYY"
            value={toDateFilter}
            onChange={(date) => setToDateFilter(date as any)}
            //@ts-ignore
            renderInput={(params) => <div {...params} />}
            closeOnSelect={true}
            onClose={() => setToPickerOpen(false)}
            open={toPickerOpen}
          />
          <MatchesList
            removeable={true}
            leagueMatches={filteredMatches}
            auth={auth}
            currentUserAuth={auth}
            onClick={onMatchClick}
            showAddRemove
          />
        </div>
        <MobileStepperWizard
          activeStep={1}
          maxSteps={3}
          backText={"Competitions"}
          handleBack={() => navigate(-1)}
          handleNext={
            selectedMatches.length > 0 ? () => navigate("../step3") : _.noop
          }
        />
      </Box>
    </>
  );
};
const PaddingContainer = styled.div`
  padding: 0 20px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 3px;
  padding: 7px;
`;
const Image = styled.img`
  width: 60px;
  height: 60px;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
`;

const SliderContainer = styled.div`
  background-color: ${(props) => props.theme.hotShotColors.cardBackground};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
