import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useScrollToTop } from "../../../hooks/useScrollToTop";
import { useLoading } from "../../../contexts/loadingContext";
import { useLeague } from "../../../contexts/leagueContext";
import { isFinished } from "../../../services/matchService";
import moment from "moment";
import { ItemType, Match } from "../../../../types";

export const useMatchesScreen = (matches?: Match[]) => {
  const { leagueMatches = [] } = useLeague();
  useLoading();

  const validMatches = _.orderBy(
    matches || leagueMatches.filter((x) => !x.ignored),
    (x) => x.date,
  );
  const matchesGroupedByDay = _.groupBy(validMatches, (match) =>
    (match.date as Date).toLocaleDateString("en-US"),
  );
  const activeMatches = validMatches.filter((x) => !isFinished(x));
  const nextMatch = activeMatches[0] || validMatches[validMatches.length - 1];
  // const currentDay = '2/18/2023';
  const currentDay = (nextMatch?.date as Date)?.toLocaleDateString("en-US");
  const scheduleItems: ItemType[] = _.map(
    matchesGroupedByDay,
    (matches, date) => {
      const isoDate = new Date(date).toISOString();
      const dayOfWeek = moment(isoDate).format("ddd");
      const dayOfMonth = moment(isoDate).format("DD");
      const month = moment(isoDate).format("MMM");
      return {
        id: date,
        day: dayOfWeek,
        date: dayOfMonth,
        month,
        isSelected: currentDay === date,
      };
    },
  );
  return {
    matchesGroupedByDay,
    nextMatch,
    currentDay,
    scheduleItems,
    validMatches,
  };
};
