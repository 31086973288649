import _, { Dictionary, findIndex } from "lodash";
import React, { FC, useCallback, useMemo, useRef, useEffect } from "react";
import { useMatchesList } from "./useMatchesList";
import { isStarted } from "../../../services/matchService";
import styled from "@emotion/styled";
import { League, LeagueUser, Match, User } from "../../../../types";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from "react-window";
import { RenderRowHelperData } from "./matchesListMatchComponent";
import MatchesListRenderRow from "./matchesListRenderRow";
import moment from "moment";

const ROW_SPACE = 5;

export type MatchesListProps = {
  league?: League;
  leagueMatches: Match[];
  auth?: User;
  onClick?: (id: number) => void;
  showInvalid?: boolean;
  currentUserAuth?: LeagueUser;
  reverse?: boolean;
  removeable?: boolean;
  showDate?: boolean;
  sectionTitle?: string;
  groupBy?: string;
  groupByPrefix?: string;
  showAddRemove?: boolean;
  showTitle?: boolean;
  showSingleSection?: boolean;
  showPoints?: boolean;
  setSelectedDayFromScroll?: (day: string) => void;
  selectedDay?: string | number;
  isCalendarControlled?: React.MutableRefObject<boolean>;
};

export const MatchesList: FC<MatchesListProps> = (props) => {
  const {
    league,
    leagueMatches,
    showInvalid,
    reverse,
    groupBy,
    showSingleSection,
    selectedDay,
    isCalendarControlled,
  } = props;
  const { keys, groupedMatches } = useMatchesList({
    leagueMatches,
    showInvalid,
    reverse,
    groupBy,
    isInLeagueContext: !!league,
  });

  if (showSingleSection && keys.length > 1) {
    keys.pop();
  }
  const orderedKeys = reverse ? keys.reverse() : keys;

  let lastTitle: string;
  const flatItems = _.chain(orderedKeys)
    .flatMap((key: string) => {
      return groupedMatches[key].map((match) => {
        const value: any[] = [match];
        if (lastTitle !== key) {
          lastTitle = key;
          value.unshift(key);
        }
        return value;
      });
    })
    .flatten()
    .value();

  const rowHeightsByItem = useCallback(
    (index: number) => {
      const rowItem: Match | string = flatItems[index];
      if (typeof rowItem === "string") {
        return 15 + ROW_SPACE;
      }
      if (league) {
        return 102 + ROW_SPACE;
      }
      return 72 + ROW_SPACE;
    },
    [flatItems],
  );
  const autoSizerRef = useRef<HTMLDivElement>(null);

  const daysVisibleInDaysView = useRef<Dictionary<string>>({});

  const itemDataHolder: RenderRowHelperData = useMemo(() => {
    return {
      matchListProps: props,
      autoSizerRef,
      flatItems,
      currentUserAuth: props.currentUserAuth,
      daysVisibleInDaysView: daysVisibleInDaysView.current,
    };
  }, [props, flatItems]);

  const listRef = useRef<any>();

  const getIndexFromDate = useCallback(
    (date?: any) => {
      if (date === undefined) {
        return undefined;
      }
      const index = findIndex(flatItems, (item: any) => {
        if (typeof item === "string") {
          const formatDate = moment(item, "MMM Do YYYY");
          return moment(formatDate).isSame(date, "day");
        }
      });
      return index !== -1 ? index : undefined;
    },
    [flatItems],
  );

  useEffect(() => {
    if (isCalendarControlled?.current) {
      // isCalendarControlled.current = false;

      const indexToJumpTo = getIndexFromDate(selectedDay);

      if (indexToJumpTo !== undefined) {
        setTimeout(() => {
          (listRef?.current as VariableSizeList)?.scrollToItem(
            indexToJumpTo,
            "start",
          );
        }, 0);
      }
    }
  }, [getIndexFromDate, selectedDay, isCalendarControlled]);

  const defaultIndex = getIndexFromDate(selectedDay) || 0;
  const defaultScroll = flatItems.reduce((acc, item, index) => {
    if (index < defaultIndex) {
      acc += rowHeightsByItem(index);
    }
    return acc;
  }, 0);
  return (
    <Container>
      <AutoSizer>
        {({ height, width }: { height: any; width: any }) => (
          <VariableSizeListContainer
            onTouchMove={() => {
              console.log("onScroll");
              if (isCalendarControlled?.current === true) {
                isCalendarControlled.current = false;
              }
            }}
            width={width}
            height={height}
            ref={autoSizerRef}
          >
            <VariableSizeList
              initialScrollOffset={defaultScroll}
              itemData={itemDataHolder}
              ref={listRef}
              height={height}
              itemCount={flatItems.length}
              itemSize={rowHeightsByItem}
              width={width}
            >
              {MatchesListRenderRow}
            </VariableSizeList>
          </VariableSizeListContainer>
        )}
      </AutoSizer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
`;

const VariableSizeListContainer = styled.div<{ width: number; height: number }>`
  display: flex;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;
