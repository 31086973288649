import React, { useEffect } from "react";
import styles from "./App.module.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./screens/login/login";
import { PrivateRoute } from "./components/common/routes/privateRoute";
import { Home } from "./screens/league/home/home";
import { LeaguesScreen } from "./screens/leagues/leaguesScreen";
import { LeaderboardScreen } from "./screens/league/leaderboard/leaderboard";
import MatchesScreen from "./screens/league/matches/matchesScreen";
import { MatchScreen } from "./screens/league/match/matchScreen";
import Settings from "./screens/league/settings/settings";
import { UserBetsScreen } from "./screens/user/UserBetsScreen";
import MyProfile from "./screens/myProfile/myProfile";
import Rules from "./screens/rules/rules";
import { ContactUs } from "./screens/contactus/contactus";
import AdminMessages from "./screens/admin/adminMessages/adminMessages";
import AdminMatches from "./screens/admin/adminMatches";
import { AdminMatch } from "./screens/admin/adminMatch";
import AdminLeagues from "./screens/admin/adminLeagues/adminLeagues";
import { LeagueScreen } from "./screens/league/leagueScreen";
import AdminApi from "./screens/admin/adminApi/adminApi";
import { CreateLeagueScreen } from "./screens/create/createLeagueScreen";
import { LeagueProvider } from "./contexts/leagueContext";
import { Update } from "@mui/icons-material";
import styled from "@emotion/styled";
import { useReactI18nNext } from "./components/styleGuide/translation/useReactI18nNext.hook";
import { CreateLeagueStep2 } from "./screens/create/createLeagueStep2";
import { CreateLeagueStep1 } from "./screens/create/createLeagueStep1";
import { UpdateLeagueMatchesStep1 } from "./screens/create/updateLeagueMatchesStep1";
import { UpdateLeagueMatchesStep2 } from "./screens/create/updateLeagueMatchesStep2";
import { UpdateLeagueMatchesScreen } from "./screens/create/updateLeagueScreen";
import { UpdateLeagueMatchesStep3 } from "./screens/create/updateLeagueMatchesStep3";
import { AppHomeWrapper } from "./screens/appHome/appHomeWrapper";
import { AppMatchesScreen } from "./screens/appHome/AppMatchesScreen";
import { PrivacyPolicy } from "./screens/privacyPolicy/privacyPolicy";
import { TeamsEditor } from "./screens/admin/teamsEditor/teamsEditor";
import { DownloadApp } from "./screens/downloadApp/downloadApp";
import { AuthStatus, useAuthentication } from "./contexts/AuthContext";
import { LeagueRules } from "./screens/league/leaderboard/leagueRules";
//@ts-expect-error
window.leagueCode = window.location.search.replace("?leagueCode=", "");

function App() {
  useReactI18nNext();
  const { authStatus } = useAuthentication();

  useEffect(() => {
    if (
      authStatus === AuthStatus.NOT_SIGEND_IN &&
      window.location.pathname !== "/leagues"
    ) {
      window.location.href = "/leagues";
    }
  }, [authStatus]);

  return (
    <StyledContainer>
      <div className={styles.App}>
        <BrowserRouter>
          <Routes>
            <Route path="downloadApp" element={<DownloadApp />} />
            <Route path="privacypolicy" element={<PrivacyPolicy />} />
            <Route path="contactusanonym" element={<ContactUs />} />
            <Route path="/" element={<AppHomeWrapper />}>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/leagues" element={<AppMatchesScreen />} />
              <Route path="/matches/:matchId" element={<MatchScreen />} />
              <Route path="/" element={<PrivateRoute />}>
                <Route index element={<LeaguesScreen />} />
              </Route>
            </Route>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="create" element={<CreateLeagueScreen />}>
                <Route index element={<CreateLeagueStep1 />} />
                <Route path="step2" element={<CreateLeagueStep2 />} />
              </Route>
              <Route path="update/:leagueId" element={<CreateLeagueScreen />} />
              {/* <Route path="update/:leagueId" element={<LeagueProvider><CreateLeagueScreen /></LeagueProvider>} /> */}
              <Route path="profile" element={<MyProfile />} />
              <Route path="rules" element={<Rules />} />
              <Route path="contactus" element={<ContactUs />} />
              <Route path="admin/messages" element={<AdminMessages />} />
              <Route path="admin/match/:matchId" element={<AdminMatch />} />
              <Route path="admin/matches" element={<AdminMatches />} />
              <Route path="admin/leagues" element={<AdminLeagues />} />
              <Route path="admin/api" element={<AdminApi />} />
              <Route path="admin/teamsEditor" element={<TeamsEditor />} />
              <Route path="/league/:leagueId/*" element={<LeagueScreen />}>
                <Route index element={<Home />} />
                <Route path="home" element={<Home />} />
                <Route path="board" element={<LeaderboardScreen />} />
                <Route path="rules" element={<LeagueRules />} />
                <Route path="users/:userId" element={<UserBetsScreen />} />
                <Route path="settings" element={<Settings />} />
                <Route path="update" element={<UpdateLeagueMatchesScreen />}>
                  <Route index element={<UpdateLeagueMatchesStep1 />} />
                  <Route path="step2" element={<UpdateLeagueMatchesStep2 />} />
                  <Route path="step3" element={<UpdateLeagueMatchesStep3 />} />
                </Route>
                <Route path="matches">
                  <Route index element={<MatchesScreen />} />
                  <Route path=":matchId" element={<MatchScreen />} />
                </Route>
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </StyledContainer>
  );
}

export default App;

const StyledContainer = styled.div`
  background-color: ${(props) => props.theme.hotShotColors.background};
  height: 100vh;
  flex: 1;
`;
