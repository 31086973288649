import _ from "lodash";
import React, { FC } from "react";
import styled from "@emotion/styled";
import TextComponent from "../../../../components/styleGuide/atoms/textComponent.atom";
import Space from "../../../../components/styleGuide/atoms/space.atom";
import { Row } from "../../../../components/styleGuide/atoms/baseStyles";
import { TeamColorsAvatar } from "../../../../components/common/colorsAvatar/colorsAvatar";
import { Match } from "../../../../../types";

type SimpleMatchesProps = {
  matches?: Match[];
  title?: string;
  flag?: string;
  onClick?: (match: Match) => void;
};
export const SimpleMatches: FC<SimpleMatchesProps> = ({
  matches,
  title,
  flag,
  onClick = _.noop,
}) => {
  return (
    <Container>
      {title && (
        <Row>
          {flag && (
            <>
              <Space width={10} />
              <img src={flag} style={{ width: 25, height: 25 }} />
              <Space width={5} />
            </>
          )}
          <TextComponent fontWeight={600} variant={"small"}>
            {title}
          </TextComponent>
          <Space width={10} />
        </Row>
      )}
      <Space height={10} />
      <Container>
        {matches?.map((match: any) => {
          const { homeTeam, awayTeam, score } = match;
          const isHomeWinner =
            match?.score?.fullTime?.home > match?.score?.fullTime?.away;
          const isAwayWinner =
            match?.score?.fullTime?.away > match?.score?.fullTime?.home;
          return (
            <StyledContainer key={match.id} onClick={() => onClick(match)}>
              <HalfCard style={{ justifyContent: "flex-end" }}>
                <TextComponent
                  variant={"small"}
                  fontWeight={isHomeWinner ? 600 : 400}
                >
                  {homeTeam.shortName}
                </TextComponent>
                <Space width={2} />
                {/* {homeTeam.crest ? <Avatar src={homeTeam.crest} /> : <Avatar>?</Avatar>} */}
                <TeamColorsAvatar team={homeTeam} size={25} />
              </HalfCard>
              <MiddleCard>
                <TextComponent
                  fontWeight={600}
                  variant={"small"}
                >{`${score.fullTime.home ?? 0} : ${score.fullTime.away ?? 0}`}</TextComponent>
              </MiddleCard>
              <HalfCard style={{ justifyContent: "flex-start" }}>
                {/* {awayTeam.crest ? <Avatar src={awayTeam.crest} /> : <Avatar>?</Avatar>} */}
                <TeamColorsAvatar team={awayTeam} size={25} />
                <Space width={2} />
                <TextComponent
                  variant={"small"}
                  fontWeight={isAwayWinner ? 600 : 400}
                >
                  {awayTeam.shortName}
                </TextComponent>
              </HalfCard>
            </StyledContainer>
          );
        })}
      </Container>
    </Container>
  );
};

const MiddleCard = styled.div`
  width: 60px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const HalfCard = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: Column;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 70px;
  align-items: center;
  background-color: ${(props) => props.theme.hotShotColors.cardBackground};
  border-radius: 12px;
  margin-bottom: 17px;
`;
