import { Divider, Typography } from "@mui/material";
import TextComponent from "../../styleGuide/atoms/textComponent.atom";
import styles from "./section.module.scss";
import React, { FC } from "react";

type SectionProps = {
  title?: string;
  children: React.ReactNode;
};
export const Section: FC<SectionProps> = ({ title, children }) => {
  return (
    <div className={styles.section}>
      <div className={styles.sectionTitle}>
        <TextComponent variant="caption">{title}</TextComponent>
      </div>
      <Divider />
      {children}
    </div>
  );
};
