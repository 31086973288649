import { Paper, Divider } from "@mui/material";
import React, { FC } from "react";
import { Section } from "../../common/section/section";
import { MultiColorProgressBar, Reading } from "../multiColorBar/multiColorBar";
import styles from "./distributionPanel.module.scss";

type DistributionPanelProps = {
  readings: Reading[];
  title?: string;
};
export const DistributionPanel: FC<DistributionPanelProps> = ({
  readings,
  title,
}) => {
  return (
    <Section title={title}>
      <div className={styles.distribution}>
        <MultiColorProgressBar readings={readings} />
      </div>
    </Section>
  );
};
