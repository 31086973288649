import _ from "lodash";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAuthentication } from "./AuthContext";
import { useFirestore } from "./firebaseContext";
import { Competition, League, LeagueUser, Match } from "../../types";
import { processLeagueData } from "../services/leagueProcessService";

export const useTeamsInitializer = (setFirestoreState: (prev: any) => void) => {
  // const firestore = useFirestore();
  // useEffect(() => {
  //   const teamsColl = collection(firestore, "teams");
  //   getDocs(teamsColl).then((snapshot) => {
  //     snapshot.forEach((doc) => {
  //       setFirestoreState((prev: any) => ({
  //         ...prev,
  //         ordered: {
  //           ...prev.ordered,
  //           teams: [...(prev?.ordered?.teams || []), doc.data()],
  //         },
  //       }));
  //     });
  //   });
  //   return () => {
  //     setFirestoreState((prev: any) => ({
  //       ...prev,
  //       ordered: {
  //         ...prev.ordered,
  //         teams: [],
  //       },
  //     }));
  //   };
  // }, []);
};

export const useCompetitionsInitializer = (setFirestoreState: any) => {
  const firestore = useFirestore();
  useEffect(() => {
    const competitionsColl = collection(firestore, "cache/competitions/data");
    getDocs(competitionsColl).then((snapshot) => {
      snapshot.forEach((doc) => {
        setFirestoreState((prev: any) => ({
          ...prev,
          ordered: {
            ...prev.ordered,
            competitions: [
              ...(prev?.ordered?.competitions || []),
              ...doc.data().data,
            ],
          },
        }));
      });
    });
    return () => {
      setFirestoreState((prev: any) => ({
        ...prev,
        ordered: {
          ...prev.ordered,
          competitions: [],
        },
      }));
    };
  }, []);
};

export const useMessagesInitializer = (setFirestoreState: any) => {
  const { profile } = useAuthentication();
  const firestore = useFirestore();
  useEffect(() => {
    if (profile?.isAppAdmin) {
      const messagesColl = collection(firestore, "messages");
      getDocs(messagesColl).then((snapshot) => {
        const messages: any[] = [];
        snapshot.forEach((doc) => {
          messages.push({ id: doc.id, ...doc.data() });
        });
        setFirestoreState((prev: any) => ({
          ...prev,
          ordered: { ...prev.ordered, messages },
        }));
      });
    }
  }, [profile?.isAppAdmin]);
};

export const useMatchesListener = (
  setFirestoreState: any,
  firestoreState: any,
) => {
  const [competitionIdsToListen, setCompetitionIdsToListen] = useState<
    number[]
  >([]);
  const firestore = useFirestore();
  useEffect(() => {
    const leagues = firestoreState?.ordered?.leagues;
    const competitions = firestoreState?.ordered?.competitions;
    const hasCompetitionIds = competitionIdsToListen?.length > 0;
    const competition =
      hasCompetitionIds &&
      competitions?.filter((x: Competition) =>
        competitionIdsToListen.includes(x.id),
      )[0];
    if (competition) {
      //For League Creation and update screen
      const q = query(
        collection(firestore, "matches"),
        where("competition.id", "in", competitionIdsToListen),
        where(
          "seasonYear",
          "==",
          competition?.currentSeason?.startDate?.split("-")[0] || "2024",
        ),
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const matches: Match[] = [];
        querySnapshot.forEach((doc) => {
          const match = { ...(doc.data() as Match) };
          match.date = new Date(match.utcDate);
          matches.push(match);
        });
        setFirestoreState((prev: any) => ({
          ...prev,
          ordered: { ...prev.ordered, matches },
        }));
      });
      return unsubscribe;
    } else if (leagues?.length > 0) {
      // For user leagues and their matches
      const matchesIdsChunkedTo10 = _.chain(leagues)
        .filter((x: League) => x.active)
        .map((x) => x.matchesIds)
        .flatten()
        .uniq()
        .chunk(10)
        .value();

      const deferreds = matchesIdsChunkedTo10.map(
        (matchesIdsChunk) => new Deferred(),
      );
      const unsubscribers = matchesIdsChunkedTo10.map(
        (matchesIdsChunk, index) => {
          const q = query(
            collection(firestore, "matches"),
            where("id", "in", matchesIdsChunk),
          );
          return onSnapshot(q, (querySnapshot) => {
            const matches: Match[] = [];
            const unsubscribe = querySnapshot
              .docChanges()
              .forEach(({ doc }) => {
                const match = { ...(doc.data() as Match) };
                match.date = new Date(match.utcDate);
                matches.push(match);
              });
            deferreds[index].resolve(matches);
            setFirestoreState((prev: any) => {
              const prevMatches =
                prev.ordered.matches?.filter(
                  (x: Match) => !matches.find((y) => y.id === x.id),
                ) || [];
              return {
                ...prev,
                ordered: {
                  ...prev.ordered,
                  matches: [...prevMatches, ...matches],
                },
              };
            });
            return unsubscribe;
          });
        },
      );
      Promise.all(deferreds.map((x) => x.promise)).then((matches) => {
        setFirestoreState((prev: any) => ({
          ...prev,
          ordered: { ...prev.ordered, matches: _.flatten(matches) },
        }));
      });

      return () => {
        unsubscribers.forEach((x) => x());
      };
    }
  }, [
    firestoreState?.ordered?.leagues?.length,
    competitionIdsToListen?.length,
    competitionIdsToListen?.[0],
  ]);
  return { setCompetitionIdsToListen };
};

export const useLeaguesInitializer = (
  setFirestoreState: any,
  firestoreState: any,
  deps: any[],
) => {
  const { auth } = useAuthentication();
  const firestore = useFirestore();

  useEffect(() => {
    if (auth?.uid) {
      const qUsers = query(
        collectionGroup(firestore, "users"),
        where("uid", "==", auth.uid),
      );
      getDocs(qUsers).then((userSnapshot) => {
        const leagueIds = userSnapshot.docs
          .map((x) => x?.ref?.parent?.parent?.id)
          .filter((x) => x);
        const qLeagues = leagueIds.map((id) =>
          doc(firestore, `leagues`, id as string),
        );
        const promises = qLeagues.map((x) => getDoc(x));
        Promise.all(promises).then(async (leaguesSnapshot) => {
          const leagues: League[] = [];
          leaguesSnapshot.forEach((doc) => {
            const data = doc.data() as League;
            if (!data.active) {
              return;
            }
            // @ts-ignore
            leagues.push({ id: doc.id, ...data });
          });

          const leaguesWithUsers: League[] = await Promise.all(
            leagues.map(async (league) => {
              const usersCollection = collection(
                firestore,
                `leagues/${league.id}/users`,
              );
              const usersSnapshot = await getDocs(usersCollection);
              const usersData = usersSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));

              const users = usersData.reduce(
                (acc, x) => ({ ...acc, [x.id]: x }),
                {},
              );
              if (league?.admin?.uid === auth.uid) {
                league.isAdmin = true;
              }

              return { ...league, users };
            }),
          );

          const leaguesExtended = leaguesWithUsers.map((league: League) => {
            const { league: leagueExtended, leagueMatches } = processLeagueData(
              league,
              firestoreState.ordered?.matches || [],
              auth,
            );
            leagueExtended.matchesData = leagueMatches;
            return leagueExtended;
          });

          setFirestoreState((prev: any) => {
            return {
              ...prev,
              ordered: {
                ...prev.ordered,
                leagues: leaguesExtended,
              },
            };
          });
        });
      });
    }
  }, [auth?.uid, ...deps]);

  useEffect(() => {
    if (
      firestoreState?.ordered?.leagues?.length > 0 &&
      firestoreState?.ordered?.matches?.length > 0
    ) {
      const leagues = firestoreState?.ordered?.leagues;
      const matches = firestoreState?.ordered?.matches;
      const leaguesExtended = leagues.map((league: League) => {
        const { league: leagueExtended, leagueMatches } = processLeagueData(
          league,
          matches,
          auth,
        );
        leagueExtended.matchesData = leagueMatches;
        return leagueExtended;
      });
      setFirestoreState((prev: any) => ({
        ...prev,
        ordered: {
          ...prev.ordered,
          leagues: leaguesExtended,
        },
        leaguesInitialized: true,
      }));
    } else if (
      firestoreState?.ordered?.leagues?.length > 0 &&
      firestoreState?.ordered?.matches?.length === 0
    ) {
      setFirestoreState((prev: any) => ({
        ...prev,
        leaguesInitialized: true,
      }));
    } else if (firestoreState?.ordered?.leagues?.length === 0) {
      setFirestoreState((prev: any) => ({
        ...prev,
        leaguesInitialized: true,
      }));
    }
  }, [
    firestoreState?.ordered?.leagues?.length,
    firestoreState?.ordered?.matches,
    ...deps,
  ]);
};

export const useLeagueListener = (
  setFirestoreState: any,
  firestoreState: any,
  leagueId: string,
  deps: any[],
) => {
  const firestore = useFirestore();
  const { auth } = useAuthentication();

  useEffect(() => {
    if (auth?.uid && leagueId && firestoreState.ordered?.matches?.length > 0) {
      const leagueDoc = doc(firestore, "leagues", leagueId);
      return onSnapshot(leagueDoc, (doc) => {
        if (doc.id) {
          const data = doc.data();
          const league = { id: doc.id, ...data } as League;
          const q = query(collection(firestore, `leagues/${leagueId}/users`));
          return onSnapshot(q, (snapshot) => {
            const leagueUsers: LeagueUser[] = [];
            snapshot.forEach((doc) => {
              leagueUsers.push({ ...(doc.data() as LeagueUser) });
            });
            league.users = leagueUsers.reduce(
              (acc, x) => ({ ...acc, [x.uid]: x }),
              {},
            );
            const { league: leagueExtended, leagueMatches } = processLeagueData(
              league,
              firestoreState.ordered?.matches || [],
              auth,
            );
            leagueExtended.matchesData = leagueMatches;

            setFirestoreState((prev: any) => {
              const prevLeagues =
                prev?.ordered?.leagues?.filter(
                  (x: League) => x.id !== leagueId,
                ) || [];
              return {
                ...prev,
                ordered: {
                  ...prev.ordered,
                  leagues: [...(prevLeagues || []), leagueExtended],
                },
              };
            });
          });
        }
      });
    }
  }, [auth, leagueId, firestoreState.ordered?.matches?.length, ...deps]);

  useEffect(() => {
    if (!leagueId) {
      return;
    }
    const q = query(
      collectionGroup(firestore, "users"),
      where("leagueId", "==", leagueId),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setFirestoreState((prev: any) => {
          const league = prev?.ordered?.leagues?.find(
            (x: League) => x.id === leagueId,
          );
          if (!league || !league.users) {
            return prev;
          }
          league.users[doc.id] = { id: doc.id, ...doc.data() };
          const prevLeagues =
            prev?.ordered?.leagues?.filter((x: League) => x.id !== league.id) ||
            [];
          return {
            ...prev,
            ordered: {
              ...prev.ordered,
              leagues: [...(prevLeagues || []), league],
            },
          };
        });
      });
    });

    return unsubscribe;
  }, [leagueId, firestoreState?.ordered?.leagues?.length]);
};

class Deferred<T> {
  public promise: Promise<T>;
  public resolve!: (value: T | PromiseLike<T>) => void;
  public reject!: (reason?: any) => void;

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}
