import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/common/header/header";
import TextComponent from "../../components/styleGuide/atoms/textComponent.atom";
import TextInputComponent from "../../components/styleGuide/atoms/textInputComponent.atom";
import { CreateLeagueContext } from "../../contexts/createLeagueContext";
import { useLoading } from "../../contexts/loadingContext";
import { MobileStepperWizard } from "./components/mobileStepperWizard";
import styled from "@emotion/styled";
import Space from "../../components/styleGuide/atoms/space.atom";
import { LEAGUE_TYPE } from "../../services/leagueProcessService";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  RadioGroup,
  Switch,
  ToggleButton,
  useTheme,
} from "@mui/material";
import styles from "./createLeagueStep1.module.scss";
// @ts-ignore
import { ReactComponent as Icon } from "./vMark.svg";
import { Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ButtonComponent from "../../components/styleGuide/atoms/buttonComponent.atom";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import euroMock from "../../assets/euro.json";
import { LeagueRules } from "../league/leaderboard/leagueRules";
import { Zoom } from "../../components/common/zoomList/zoomList";
import { useLeagues } from "../../contexts/leaguesContext";

// const VMark = ({ checked }) => {
//   return (
//     <>
//       {checked ? (
//         <Mark checked={checked}>
//           <Icon />
//         </Mark>
//       ) : (
//         <Mark></Mark>
//       )}
//     </>
//   );
// };

export const CreateLeagueStep1 = () => {
  const { leagueData, updateLeagueData, createLeague } =
    useContext(CreateLeagueContext);
  const navigate = useNavigate();
  const { showLoading } = useLoading();
  const { refreshLeagues } = useLeagues();
  const { t } = useTranslation();
  const theme = useTheme();
  const [leagueType, setLeagueType] = useState(LEAGUE_TYPE.ODDS_SCORE);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const onCreateClick = async (initialData: any) => {
    if (
      leagueData.initialBalance < 1000 ||
      leagueData.initialBalance > 100000
    ) {
      alert("Initial balance must be at least $1000 and at most $100,000");
      return;
    }
    if (leagueData.name.length < 4) {
      alert("League name must be at least 4 characters");
      return;
    }

    showLoading();
    const id = await createLeague(initialData);
    refreshLeagues();
    setTimeout(() => {
      navigate(`/league/${id}/home`);
    }, 2000);
  };

  const changeToPointsLeague = () => {
    updateLeagueData({ leagueType: LEAGUE_TYPE.POINTS_LEAGUE });
  };

  const changeToOddsLeague = () => {
    updateLeagueData({ leagueType: LEAGUE_TYPE.ODDS_LEAGUE });
  };

  //TODO: Allow reenter
  //Add fields for
  // * Allow reenters (switch)
  // * Number of reenters allowed
  // * Reenters amount
  // * Max date to reenter

  const createEuroLeague = () => {
    onCreateClick({ ...euroMock, leagueType });
  };

  const toggleLeagueType = () => {
    setLeagueType(
      leagueType === LEAGUE_TYPE.POINTS_LEAGUE
        ? LEAGUE_TYPE.ODDS_SCORE
        : LEAGUE_TYPE.POINTS_LEAGUE,
    );
  };

  return (
    <Container>
      <Header onBack={() => navigate(-1)} title={"Create League"} />
      {/* <GreetingContainer>
        <TextComponent variant={"medium"}>
          {t("CREATE_LEAGUE_GREETING")}
        </TextComponent>
      </GreetingContainer> */}
      <Zoom>
        <CardContainer>
          <TextInputComponent
            title="League Name"
            placeholder="My Awesome League"
            value={leagueData.name}
            onChange={(v) => updateLeagueData({ name: v })}
          />
          <FormControlLabel
            control={
              <Switch
                checked={leagueType === LEAGUE_TYPE.ODDS_SCORE}
                onChange={toggleLeagueType}
              />
            }
            label={
              <TextComponent variant="small">
                {leagueType === LEAGUE_TYPE.ODDS_SCORE
                  ? "Multiply Score By Odds"
                  : "Play Regular"}
              </TextComponent>
            }
            color={theme.hotShotColors.primary}
          />
        </CardContainer>
      </Zoom>
      <Zoom delay={100}>
        <RulesContainer>
          <LeagueRules leagueType={leagueType} />
        </RulesContainer>
      </Zoom>
      {/* <CardContainer>
                <Button onClick={createEuroLeague}>Create Euro 2024 league</Button>
            </CardContainer>
            <CardContainer>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={true}
                    onChange={() => { }}
                >
                    <div className={styles.option}>
                        <FormControlLabel onClick={changeToPointsLeague} value={LEAGUE_TYPE.POINTS_LEAGUE} control={<VMark checked={isPointsLeague} />} />
                        <RadioRowTitle onClick={changeToPointsLeague} fontWeight={600} color={theme.hotShotColors.primaryTextColor} variant={'medium'}>{t('POINTS_LEAGUE')}</RadioRowTitle>
                        <CollapseIcon onClick={() => setRadioDetailToShow(radioDetailToShow === 1 ? 0 : 1)}>
                            {radioDetailToShow === 1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </CollapseIcon>
                    </div>
                    <CollapseWrapper>
                        <Collapse in={radioDetailToShow === 1}>
                            <TextComponent variant={'small'}>
                                {t('POINTS_LEAGUE_DESCRIPTION')}
                            </TextComponent>
                        </Collapse>
                    </CollapseWrapper>

                    <Space height={20} />
                    <div className={styles.option}>
                        <FormControlLabel onClick={changeToOddsLeague} value={LEAGUE_TYPE.ODDS_LEAGUE} control={<VMark checked={isOddsLeague} />} />
                        <RadioRowTitle onClick={changeToOddsLeague} fontWeight={600} color={theme.hotShotColors.primaryTextColor} variant={'medium'}>{t('ODDS_LEAGUE')}</RadioRowTitle>
                        <CollapseIcon onClick={() => setRadioDetailToShow(radioDetailToShow === 2 ? 0 : 2)}>
                            {radioDetailToShow === 2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </CollapseIcon>
                    </div>
                    <CollapseWrapper>
                        <Collapse in={radioDetailToShow === 2}>
                            <TextComponent variant={'small'}>
                                {t('ODDS_LEAGUE_DESCRIPTION')}
                            </TextComponent>
                        </Collapse>
                    </CollapseWrapper>
                </RadioGroup>
            </CardContainer>
            {isOddsLeague && <CardContainer>
                <TextComponent variant={'medium'}>What is the initial balance every player will have upon joining the league. The default is $10,000</TextComponent>
                <Space height={10} />
                <TextInputComponent
                    id="outlined-required"
                    placeholder="Initial Balance"
                    value={leagueData.initialBalance}
                    type="number"
                    onChange={(v) => {
                        updateLeagueData({ initialBalance: v })
                    }}
                />
            </CardContainer>} */}
      <Zoom delay={200}>
        <BottomActionsBar>
          <ButtonComponent
            width={80}
            onClick={() => createEuroLeague()}
            variant="text"
            size="normal"
          >
            Create
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </ButtonComponent>
        </BottomActionsBar>
      </Zoom>
    </Container>
  );
};

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: ${(props) => props.theme.hotShotColors.cardBackground};
  border-radius: 12px;
  margin: 10px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.hotShotColors.background};
`;

const GreetingContainer = styled.div`
  padding: 10px;
  background-color: ${(props) => props.theme.hotShotColors.headerBackground};
`;

// const Mark = styled.div`
//   border: 1.5px solid ${(props) => props.theme.hotShotColors.Grayscale20};
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 25px;
//   height: 25px;
//   border-radius: 20px;
//   background-color: ${(props) =>
//     props.checked ? props.theme.hotShotColors.success : "transparent"};
// `;

const CollapseIcon = styled(TextComponent)`
  display: flex;
  justify-content: flex-end;
  width: 50px;
`;
const CollapseWrapper = styled.div`
  margin-left: 33px;
`;
const RadioRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const RadioRowTitle = styled(TextComponent)`
  white-space: nowrap;
  flex-grow: 1;
`;

const BottomActionsBar = styled.div`
  background-color: ${(props) => props.theme.hotShotColors.cardBackground};
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  box-sizing: border-box;
`;
const RulesContainer = styled.div`
  margin-bottom: 20px;
  padding: 10px;
`;
