/* eslint-disable @typescript-eslint/ban-ts-comment */
import styled from "@emotion/styled";
import React, { FC } from "react";
import { SummaryEventType } from "../lineUp/lineUpTypes";
import { useTheme } from "@mui/material/styles";
// @ts-ignore
import { ReactComponent as Football } from "./football.svg";
// @ts-ignore
import { ReactComponent as YellowCard } from "./yellowCard.svg";
// @ts-ignore
import { ReactComponent as RedCard } from "./redCard.svg";
// @ts-ignore
import { ReactComponent as TwoYellow } from "./twoYellowCard.svg";
import SyncAlt from "@mui/icons-material/SyncAlt";
import { Event } from "./summary";
import Sports from "@mui/icons-material/Sports";

const SummaryMiddleComponent: FC<{
  summaryEventType?: SummaryEventType;
  event: Event;
}> = ({ summaryEventType, event }) => {
  const theme = useTheme();

  return (
    <StyledColumn>
      <Separator />
      <SummaryStyledContainer>
        {summaryEventType === "goal" && (
          <Football style={{ color: theme.hotShotColors.primaryTextColor }} />
        )}
        {summaryEventType === "booking" && (
          <>
            {event.card === "YELLOW" && <YellowCard />}
            {event.card === "YELLOW_RED" && <TwoYellow />}
            {event.card === "RED" && <RedCard />}
          </>
        )}
        {summaryEventType === "sub" && (
          <SyncAlt style={{ color: theme.hotShotColors.primaryTextColor }} />
        )}
        {summaryEventType === "whistle" && (
          <Sports htmlColor={theme.hotShotColors.menuIconColor} />
        )}
      </SummaryStyledContainer>
      <Separator />
    </StyledColumn>
  );
};

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
`;
const Separator = styled.div`
  height: 40px;
  background-color: red;
  background-color: ${(props) => props.theme.hotShotColors.lineSummary};
  width: 4px;
  align-self: center;
  justify-self: center;
`;
export const SummaryStyledContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 45px;
  height: 45px;
  background: ${(props) => props.theme.hotShotColors.cardBackground};
  border: 1px solid ${(props) => props.theme.hotShotColors.lineSummary};
  box-shadow: 2px 2px 20px rgba(40, 42, 60, 0.06);
  border-radius: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export default SummaryMiddleComponent;
