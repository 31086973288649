import _ from "lodash";
import React, { useEffect, useState } from "react";
import styles from "./adminApi.module.scss";
import { Header } from "../../../components/common/header/header";
import { Button, TextField } from "@mui/material";
import { useLeagues } from "../../../contexts/leaguesContext";
import { useLoading } from "../../../contexts/loadingContext";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../../contexts/AuthContext";
import { COMPETITION_CODE } from "../../../services/matchService";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { useFirestore } from "../../../contexts/firebaseContext";
import ButtonComponent from "../../../components/styleGuide/atoms/buttonComponent.atom";
import TextInputComponent from "../../../components/styleGuide/atoms/textInputComponent.atom";
import { Competition, Team } from "../../../../types";

const AdminApi = () => {
  const navigate = useNavigate();
  const firestore = useFirestore();
  const { showLoading, hideLoading } = useLoading();
  const { profile } = useAuthentication();
  const [competitionId, setCompetitionId] = useState(COMPETITION_CODE.EC);
  const [season, setSeason] = useState("2024");

  useLoading();

  useEffect(() => {
    if (!profile?.isAppAdmin) {
      navigate("/");
    }
  }, [navigate, profile?.isAppAdmin]);

  const updateMatches = async () => {
    window.open(
      `https://us-central1-hotshotbet.cloudfunctions.net/competitionMatches?competitionId=${competitionId}&season=${season}`,
    );
  };
  const updateTeams = async () => {
    window.open(
      `https://us-central1-hotshotbet.cloudfunctions.net/teams?competitionId=${competitionId}&season=${season}`,
    );
  };

  const cacheTeams = async () => {
    showLoading();
    const teamsColl = collection(firestore, "teams");
    getDocs(teamsColl).then(async (snapshot) => {
      const teams: Team[] = [];
      snapshot.forEach((doc) => {
        // @ts-ignore
        teams.push({ id: doc.id, ...doc.data() });
      });

      const chunked = _.chunk(teams, 20);
      chunked.forEach(async (chunk, index) => {
        const docRef = doc(firestore, "cache/teams/data", index.toString());
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setDoc(docRef, { data: chunk }, { merge: true });
        } else {
          setDoc(docRef, { data: chunk }, { merge: true });
        }
        hideLoading();
      });
    });
  };

  const cacheCompetitions = async () => {
    showLoading();
    const competitionsColl = collection(firestore, "competitions");
    getDocs(competitionsColl).then(async (snapshot) => {
      const competitions: Competition[] = [];
      snapshot.forEach((doc) => {
        // @ts-ignore
        competitions.push({ id: doc.id, ...doc.data() });
      });

      const chunked = _.chunk(competitions, 20);
      chunked.forEach(async (chunk, index) => {
        const docRef = doc(
          firestore,
          "cache/competitions/data",
          index.toString(),
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setDoc(docRef, { data: chunk }, { merge: true });
        } else {
          setDoc(docRef, { data: chunk }, { merge: true });
        }
        hideLoading();
      });
    });
  };
  return (
    <div className={styles.root}>
      <Header onBack={() => navigate("/")} title="Admin API" />
      <div className={styles.container}>
        <div>
          <TextInputComponent
            title="Competition Id"
            value={competitionId}
            onChange={(val) => setCompetitionId(val)}
            onBlur={(val) => setCompetitionId(val)}
          />
        </div>
        <div>
          <TextInputComponent
            title="Season"
            value={season.toString()}
            onChange={(val) => setSeason(val)}
            onBlur={(val) => setSeason(val)}
          />
        </div>
        <div>
          <ButtonComponent
            onClick={updateMatches}
            variant="contained"
            size="normal"
          >
            Update Matches
          </ButtonComponent>
        </div>
        <div>
          <ButtonComponent
            onClick={updateTeams}
            variant="contained"
            size="normal"
          >
            Update Teams
          </ButtonComponent>
        </div>
        <div>
          <ButtonComponent
            onClick={cacheTeams}
            variant="contained"
            size="normal"
          >
            Cache Teams
          </ButtonComponent>
        </div>
        <div>
          <ButtonComponent
            onClick={cacheCompetitions}
            variant="contained"
            size="normal"
          >
            Cache Competitions
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

export default AdminApi;
