import React, { FC } from "react";
import { LeagueMatchPoints } from "../match/leagueMatchPoints";
import { useMatchesList } from "./useMatchesList";
import {
  isOddsLeague,
  isOddsScoreLeague,
} from "../../../services/matchService";
import { LeagueMatchOdds } from "../match/leagueMatchOdds";
import styled from "@emotion/styled";
import { LeagueExtended, LeagueUser } from "../../../../types";
import { MatchesListProps } from "./matchesList";
import { Dictionary } from "lodash";

export interface RenderRowHelperData {
  autoSizerRef: any;
  flatItems: any[];
  matchListProps: MatchesListProps;
  currentUserAuth?: LeagueUser;
  daysVisibleInDaysView: Dictionary<string>;
}

const MatchesListMatchComponent: FC<{
  data: RenderRowHelperData;
  index: number;
}> = ({ data, index }) => {
  const { matchListProps, flatItems, currentUserAuth } = data;

  const {
    league,
    leagueMatches,
    auth,
    onClick,
    showInvalid,
    reverse,
    removeable,
    showDate,
    groupBy,
    showAddRemove,
    showSingleSection,
    showPoints,
  } = matchListProps;

  const { keys } = useMatchesList({
    leagueMatches,
    showInvalid,
    reverse,
    groupBy,
    isInLeagueContext: !!league,
  });

  if (showSingleSection && keys.length > 1) {
    keys.pop();
  }

  const match = flatItems[index];

  return (
    <ContainerMatch>
      {isOddsLeague(league as LeagueExtended) && league ? (
        <LeagueMatchOdds
          currentUserAuth={currentUserAuth!}
          leagueMatch={match}
          showDate={showDate}
          addGame={
            showAddRemove ? () => onClick && onClick(match.id) : undefined
          }
          removeGame={
            showAddRemove ? () => onClick && onClick(match.id) : undefined
          }
          onClick={() => onClick && onClick(match.id)}
          showPoints={showPoints}
        />
      ) : (
        <LeagueMatchPoints
          currentUserAuth={currentUserAuth!}
          leagueMatch={match}
          auth={auth!}
          addGame={
            showAddRemove ? () => onClick && onClick(match.id) : undefined
          }
          removeGame={
            showAddRemove ? () => onClick && onClick(match.id) : undefined
          }
          showDate={showDate}
          removeable={removeable}
          // slim={!league}
          onClick={() => onClick && onClick(match.id)}
          showPoints={showPoints}
          showOdds={isOddsScoreLeague(league as LeagueExtended)}
        />
      )}
    </ContainerMatch>
  );
};

const ContainerMatch = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

export default MatchesListMatchComponent;
