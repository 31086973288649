import React, { FC, useMemo, useRef, useEffect } from "react";
import _ from "lodash";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
// @ts-ignore
import { ReactComponent as CalendarIcon } from "./calendar.svg";
import { useInView } from "react-intersection-observer";
import moment from "moment";
import Space from "../../styleGuide/atoms/space.atom";
import MatchesListMatchComponent, {
  RenderRowHelperData,
} from "./matchesListMatchComponent";
import { Zoom } from "../../common/zoomList/zoomList";

interface MatchesListRenderRowProps {
  data: RenderRowHelperData;
  index: number;
  style: any;
}

const MatchesListRenderRow: FC<MatchesListRenderRowProps> = ({
  index,
  data,
  style,
}) => {
  const dateOfCell = useMemo(() => {
    let dateToAdd = "";
    if (typeof data.flatItems[index] === "string") {
      dateToAdd = moment(data.flatItems[index], "MMM Do YYYY").format(
        "MM.DD.YYYY",
      );
    } else {
      dateToAdd = moment(data.flatItems[index].date).format("MM.DD.YYYY");
    }
    return dateToAdd;
  }, []);

  const cellId = useRef(dateOfCell + index);
  const daysVisibleInDaysView = data.daysVisibleInDaysView;
  const theme = useTheme();

  const { ref, entry } = useInView({
    threshold: [0.05, 0.1, 1],
    root: data.autoSizerRef?.current,
  });

  useEffect(() => {
    if (dateOfCell === undefined) {
      return;
    }
    const id = cellId.current;
    if ((entry?.intersectionRatio ?? 0) >= 0.05) {
      daysVisibleInDaysView[id] = dateOfCell;
    } else {
      if (daysVisibleInDaysView[id]) {
        delete daysVisibleInDaysView[id];
      }
    }

    const entries = Object.entries(daysVisibleInDaysView);
    entries.sort(function (a: any, b: any) {
      return b[1] > a[1] ? -1 : b[1] < a[1] ? 1 : 0;
    });
    const firstDay = entries[0];
    if (firstDay && data.matchListProps.setSelectedDayFromScroll) {
      data.matchListProps.setSelectedDayFromScroll(
        //eplaceAll(".", "/") is a fix for safari, cant handle element ids with dots
        new Date(firstDay[1].replaceAll(".", "/")).toLocaleDateString("en-US"),
      );
    }
    return () => {
      if (daysVisibleInDaysView[id]) {
        delete daysVisibleInDaysView[id];
      }
    };
  }, [entry, cellId.current, data.matchListProps.setSelectedDayFromScroll]);

  return (
    <Zoom>
      <RenderRowContainer style={style} ref={ref}>
        {typeof data.flatItems[index] === "string" && (
          <RowContainer>
            <Calendar />
            <Space width={10} />
            <TitleLabel>{data.flatItems[index]}</TitleLabel>
          </RowContainer>
        )}
        {typeof data.flatItems[index] !== "string" && (
          <MatchesListMatchComponent data={data} index={index} />
        )}
      </RenderRowContainer>
    </Zoom>
  );
};

const Calendar = styled(CalendarIcon)`
  color: ${({ theme }) => theme.hotShotColors.primaryTextColor};
  width: 14px;
  height: 14px;
`;
const RenderRowContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
`;

const TitleLabel = styled.div`
  color: white;
  font-size: 14px;
`;

const RowContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
`;

export default MatchesListRenderRow;
