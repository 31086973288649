import _ from "lodash";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { MatchesList } from "../../../components/matchComponents/matches/matchesList";
import { useLoading } from "../../../contexts/loadingContext";
import { useLeague } from "../../../contexts/leagueContext";
import { useAuthentication } from "../../../contexts/AuthContext";
import { useMatchesScreen } from "./useMatchesScreen";
import CalendarComponent from "./calendarComponent";
import { LeagueUser, Match } from "../../../../types";
import Space from "../../../components/styleGuide/atoms/space.atom";
import styled from "@emotion/styled";
import PoppingModal from "../../../components/common/poppingModal/poppingModal";
import { MatchScreen } from "../match/matchScreen";
import FullScreenContainer from "./FullScreenContainer";

let isInSelectingProcess = true;

const pauseScrollEvents = () => {
  isInSelectingProcess = true;
  setTimeout(() => {
    isInSelectingProcess = false;
  }, 500);
};

type MatchesScreenProps = {
  showTitle?: boolean;
  showPoints?: boolean;
  overrideMatches?: Match[];
  groupBy?: string;
  heightOffset?: number;
  showAsUser?: LeagueUser;
  reverse?: boolean;
};

const MatchesScreen: FC<MatchesScreenProps> = ({
  showTitle,
  showPoints,
  overrideMatches,
  groupBy = "date",
  heightOffset = 0,
  showAsUser,
  reverse,
}) => {
  const { league } = useLeague();
  const { auth, profile } = useAuthentication();
  const [currentMatch, setCurrentMatch] = useState<number | null>(null);
  const isInitialStart = useRef(true);
  const isCalendarControlled = useRef(true);
  console.log("isCalendarControlled", isCalendarControlled);
  useLoading();

  const { scheduleItems, currentDay, validMatches } =
    useMatchesScreen(overrideMatches);

  const [selectedDay, setSelectedDay] = useState(
    currentDay || new Date().getDate(),
  );
  useEffect(() => {
    pauseScrollEvents();
  }, []);

  useEffect(() => {
    setSelectedDay(currentDay);
    isInitialStart.current = false;
  }, [currentDay, setSelectedDay]);

  const onClickCalendarDay = (id: string) => {
    isCalendarControlled.current = true;
    setSelectedDay(id);
  };

  const onMatchClick = (id: number) => {
    setCurrentMatch(id);
  };

  const setSelectedDayFromScroll = useCallback(
    (day: string) => {
      if (!isInitialStart.current && !isCalendarControlled.current) {
        setSelectedDay(day);
      }
    },
    [isInitialStart.current, isCalendarControlled.current],
  );

  return (
    <FullScreenContainer decreaseBy={heightOffset}>
      <CalendarComponent
        items={scheduleItems}
        onClick={onClickCalendarDay}
        title={""}
        selectedDay={selectedDay}
      />
      <Space height={5} />
      <Content>
        <MatchesList
          groupBy={groupBy}
          showTitle={true}
          league={league!}
          groupByPrefix=""
          leagueMatches={validMatches}
          reverse={reverse}
          auth={auth}
          currentUserAuth={showAsUser}
          showInvalid={profile?.isAppAdmin}
          onClick={onMatchClick}
          showPoints={showPoints}
          selectedDay={selectedDay}
          setSelectedDayFromScroll={setSelectedDayFromScroll}
          isCalendarControlled={isCalendarControlled}
        />
      </Content>
      {currentMatch !== undefined && (
        <PoppingModal
          showModal={!!currentMatch}
          onClose={() => setCurrentMatch(null)}
        >
          <MatchScreen matchId={currentMatch!} />
        </PoppingModal>
      )}
    </FullScreenContainer>
  );
};

export default MatchesScreen;

const Content = styled.div`
  display: flex;
  flex: 1;
`;
