import React from "react";
import ReactDOM from "react-dom/client";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { FirebaseProvider } from "./contexts/firebaseContext";
import { LoadingProvider } from "./contexts/loadingContext";
import { ConfirmProvider } from "./contexts/confirmContext";
import { LeaguesProvider } from "./contexts/leaguesContext";
import { AuthProvider } from "./contexts/AuthContext";
import { FirestoreProvider } from "./contexts/firestoreContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { HotShotThemeProvider } from "./components/styleGuide/useTheme";
import { AdMobProvider } from "./contexts/adMobContext";
import { WebToMobileProvider } from "./contexts/webToMobileContext";
import { RateAppPromptProvider } from "./contexts/rateAppPromptContext";

//Deeplink - hotshotbetapp://hotshotbet.web.app/
// @ts-expect-error
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HotShotThemeProvider>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <LoadingProvider>
        <WebToMobileProvider>
          <ConfirmProvider>
            <FirebaseProvider>
              <AuthProvider>
                <AdMobProvider>
                  <FirestoreProvider>
                    <LeaguesProvider>
                      <RateAppPromptProvider>
                        <React.StrictMode>
                          <App />
                        </React.StrictMode>
                      </RateAppPromptProvider>
                    </LeaguesProvider>
                  </FirestoreProvider>
                </AdMobProvider>
              </AuthProvider>
            </FirebaseProvider>
          </ConfirmProvider>
        </WebToMobileProvider>
      </LoadingProvider>
    </LocalizationProvider>
  </HotShotThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
//https://play.google.com/apps/test/com.hotshotbet.web.app/3
