import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Button, MobileStepper, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import ButtonComponent from "../../../components/styleGuide/atoms/buttonComponent.atom";
import React, { FC } from "react";

type MobileStepperWizardProps = {
  handleNext: () => void;
  handleBack: () => void;
  activeStep: number;
  nextText?: string;
  backText?: string;
  maxSteps: number;
};
export const MobileStepperWizard: FC<MobileStepperWizardProps> = ({
  handleNext,
  handleBack,
  activeStep = 0,
  nextText = "Next",
  backText = "Back",
  maxSteps = 3,
}) => {
  const theme = useTheme();

  return (
    <MobileStepper
      style={{
        background: theme.hotShotColors.cardBackground,
        color: theme.hotShotColors.primaryTextColor,
      }}
      variant="text"
      steps={maxSteps}
      position="bottom"
      activeStep={activeStep}
      sx={{
        bgcolor: grey[100],
      }}
      nextButton={
        <ButtonComponent
          size="small"
          variant="text"
          onClick={() => handleNext && handleNext()}
          isDisable={!handleNext}
        >
          {nextText}
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </ButtonComponent>
      }
      backButton={
        <ButtonComponent
          size="small"
          variant="text"
          onClick={() => handleBack && handleBack()}
          isDisable={!handleBack}
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
          {backText}
        </ButtonComponent>
      }
    />
  );
};
