import React, { FC, Fragment } from "react";
import styled from "@emotion/styled";
import _ from "lodash";

interface TextInputProps {
  value: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  title?: string;
  placeholder?: string;
  ref?: any;
  type?: string;
}

const TextInputComponent: FC<TextInputProps> = ({
  value,
  onChange = _.noop,
  onBlur = _.noop,
  title,
  type,
  placeholder,
  ref,
}) => {
  return (
    <Fragment>
      {title && <StyledText>{title}</StyledText>}
      <Container>
        <StyledInput
          ref={ref}
          placeholder={placeholder}
          value={value}
          type={type}
          onChange={(event) => onChange(event.target.value)}
          onBlur={(event) => onBlur(event.target.value)}
        />
      </Container>
    </Fragment>
  );
};

const StyledText = styled.div`
  font-size: 14px;
  font-weight: 15px;
  margin-left: 10px;
  margin-bottom: 5px;
  color: ${(props) => props.theme.hotShotColors.primaryTextColor};
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  display: flex;
  flex-direction: column;
  /* height: 45px; */
  border: 1px solid #38384c;
  border-radius: 24px;
  color: ${(props) => props.theme.hotShotColors.primaryTextColor};
  background-color: ${(props) => props.theme.hotShotColors.background};
`;

const StyledInput = styled.input`
  border: none;
  flex: 1;
  display: flex;
  font-size: 14px;
  font-weight: 15px;
  color: ${(props) => props.theme.hotShotColors.primaryTextColor};
  border-radius: 24px;
  padding: 015px;
  background-color: ${(props) => props.theme.hotShotColors.background};
`;
export default TextInputComponent;
