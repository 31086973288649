import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  deleteUser,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithCredential,
  User as FirebaseUser,
} from "firebase/auth";
import { DocumentData, doc, getDoc, setDoc } from "firebase/firestore";
import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  FC,
} from "react";
import { useFirebase, useFirestore } from "./firebaseContext";
import { useLoading } from "./loadingContext";
//@ts-expect-error
import { socialLogin } from "webtonative/SocialLogin";
import { useWebToMobile } from "./webToMobileContext";
import { User } from "../../types";

type AuthStatusType = {
  authStatus?: string;
  profile?: any;
  setProfile?: any;
  authUser?: any;
  signInWithGoogle?: any;
  registerWithEmailAndPassword?: any;
  signInWithEmail?: any;
  authInitialized?: any;
  signInWithApple?: any;
  deleteUserAuth?: any;
  signOut?: any;
};

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthContext = createContext<AuthStatusType>({});
const provider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");
appleProvider.addScope("email");
appleProvider.addScope("name");

let profileAlreadySet = false;
export const AuthStatus = {
  LOADING: "loading",
  SIGNED_IN: "signed_in",
  NOT_SIGEND_IN: "not_signed_in",
};

interface ProfileData extends DocumentData, User {}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const { showLoading, hideLoading } = useLoading();
  const authService = getAuth(firebase);
  const { isMobilePlatform, platform } = useWebToMobile();

  const [authUser, setAuthUser] = useState<FirebaseUser>();
  const [authInitialized, setAuthInitialized] = useState<boolean>();
  const [authStatus, setAuthStatus] = useState(AuthStatus.LOADING);
  const [profile, setProfile] = useState<ProfileData | undefined>();

  useEffect(() => {
    const unsubscribe = authService.onAuthStateChanged((user) => {
      if (user) {
        setAuthUser(user);
        setAuthStatus(AuthStatus.SIGNED_IN);
      } else {
        setAuthStatus(AuthStatus.NOT_SIGEND_IN);
        setAuthUser(undefined);
        setProfile(undefined);
      }
      setAuthInitialized(true);
    });
    showLoading();
    return unsubscribe;
  }, []);

  useEffect(() => {
    (async () => {
      if (authUser?.uid) {
        const docSnap = await getDoc(doc(firestore, "users", authUser.uid));
        if (docSnap.exists()) {
          const userProfile = docSnap.data();
          if (
            userProfile.isAppAdmin &&
            window.location.search.indexOf("impersonateToUserId=") > -1
          ) {
            const userToImpersonateId = window.location.search.split(
              "impersonateToUserId=",
            )[1];
            if (userToImpersonateId) {
              const userToImpersonateData = await getDoc(
                doc(firestore, "users", userToImpersonateId),
              );
              // @ts-ignore
              setProfile(userToImpersonateData);
              setAuthUser({
                ...authUser,
                uid: userToImpersonateId,
              });
            } else {
              setProfile(userProfile as ProfileData);
            }
          } else {
            setProfile(userProfile as ProfileData);
          }
        } else if (!profileAlreadySet) {
          const profile: ProfileData = {
            email: authUser.email || authUser?.providerData?.[0]?.email,
            isAppAdmin: false,
            displayName:
              authUser.displayName ||
              authUser?.providerData?.[0]?.displayName ||
              "Guest",
            avatarUrl: authUser.photoURL || "",
            uid: authUser.uid,
          };
          await setDoc(doc(firestore, "users", authUser.uid), profile, {
            merge: true,
          });
          setProfile(profile);
        }
      }
    })();
  }, [authUser?.uid]);

  const signInWithGoogle = async () => {
    try {
      if (isMobilePlatform) {
        socialLogin.google.login({
          callback: async function (value: any) {
            // alert('1111' + value.idToken);
            // alert(value.isSuccess)
            // alert(value.errorCode) //10
            // alert(value.error) //10
            // alert(value.type)//googleLoginToken

            const credential = GoogleAuthProvider.credential(value.idToken);
            try {
              // alert(value.idToken);
              const result = await signInWithCredential(
                authService,
                credential,
              );
              // alert(result);
              setProfile(undefined);
            } catch (error: any) {
              const errorCode = error?.code;
              const errorMessage = error?.message;
              alert(`${errorCode} ${errorMessage}`);
              // The email of the user's account used.
              const email = error.email;
              // The credential that was used.
              const credential = GoogleAuthProvider.credentialFromError(error);
            }
          },
        });
      } else {
        loginWithGoogleWeb();
      }
    } catch (e) {
      loginWithGoogleWeb();
    }
  };

  const loginWithGoogleWeb = async () => {
    const { user } = await signInWithPopup(authService, provider);
    const docSnap = await getDoc(doc(firestore, "users", user.uid));
    if (!docSnap.exists()) {
      profileAlreadySet = true;
      const data: ProfileData = {
        uid: user.uid,
        email: user.email,
        displayName:
          user.displayName || user?.providerData?.[0]?.displayName || "Guest",
        avatarUrl: user.photoURL || user?.providerData?.[0]?.email || "",
        isAppAdmin: false,
      };
      await setDoc(doc(firestore, "users", user.uid), data);
      setProfile(data);
    }
  };

  const signInWithApple = async () => {
    try {
      if (platform === "ios") {
        socialLogin.apple.login({
          callback: async function (value: any) {
            const {
              idToken,
              firstName = "",
              lastName = "",
              emailId,
              code,
              isSuccess,
            } = value;
            try {
              const authCredential = appleProvider.credential({
                idToken,
              });
              let name = firstName + " " + lastName;
              if (name === " ") {
                name = "Guest";
              }
              try {
                await signInWithCredential(authService, authCredential).catch(
                  (e) => alert(e?.message),
                );
              } catch (e: any) {
                alert(e.code + ", " + e.message);
              }
            } catch (e: any) {
              alert(e.code + ", " + e.message);
            }
          },
        });
      } else {
        signInWithAppleWeb();
      }
    } catch (e) {
      signInWithAppleWeb();
    }
  };

  const signInWithAppleWeb = async () => {
    const { user } = await signInWithPopup(authService, appleProvider);
    const docSnap = await getDoc(doc(firestore, "users", user.uid));
    if (!docSnap.exists()) {
      profileAlreadySet = true;
      const email = user?.providerData?.[0]?.email || "";
      const displayName =
        user?.providerData?.[0]?.displayName || email.split("@")[0] || "Guest";
      const data: ProfileData = {
        uid: user.uid,
        email,
        displayName,
        avatarUrl: user?.photoURL || "",
        isAppAdmin: false,
      };
      await setDoc(doc(firestore, "users", user.uid), data);
      setProfile(data);
    }
  };

  const registerWithEmailAndPassword = async (
    email: string,
    password: string,
    nickname: string,
  ) => {
    const { user } = await createUserWithEmailAndPassword(
      authService,
      email,
      password,
    );
    const docSnap = await getDoc(doc(firestore, "users", user.uid));
    if (!docSnap.exists()) {
      profileAlreadySet = true;
      const data: ProfileData = {
        uid: user.uid,
        email: user.email,
        displayName: nickname || user?.email?.split("@")[0] || user.email || "",
        avatarUrl: "https://www.jea.com/cdn/images/avatar/avatar-alt.svg",
        isAppAdmin: false,
      };
      setProfile(data);
      await setDoc(doc(firestore, "users", user.uid), data);
    }
  };

  const signInWithEmail = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(authService, email, password);
      profileAlreadySet = true;
    } catch (error) {
      alert("Error Authenticating");
      hideLoading();
    }
  };

  const deleteUserAuth = async () => {
    try {
      await deleteUser(authUser!);
    } catch (error) {
      alert("Error Deleting User");
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authStatus,
        profile,
        setProfile,
        authUser,
        signInWithGoogle,
        registerWithEmailAndPassword,
        signInWithEmail,
        authInitialized,
        signInWithApple,
        deleteUserAuth,
        signOut: () => authService.signOut(),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthentication = () => {
  const {
    setProfile,
    profile,
    authUser,
    authStatus,
    signInWithGoogle,
    signInWithApple,
    signOut,
    deleteUserAuth,
    registerWithEmailAndPassword,
    signInWithEmail,
    authInitialized,
  } = useContext(AuthContext);
  return {
    setProfile,
    profile,
    auth: authUser,
    authStatus,
    signInWithGoogle,
    signInWithApple,
    signOut,
    deleteUserAuth,
    registerWithEmailAndPassword,
    signInWithEmail,
    authInitialized,
  };
};
