import {
  addDoc,
  collection,
  doc,
  setDoc,
  deleteDoc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadString,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import _ from "lodash";
// import { usersCollection } from '../consts/queries';
import { resizeImage } from "./imageService";
import { isStarted } from "./matchService";
import { League, LeagueExtended, LeagueUser } from "../../types";

export const getDb = ({ firestore, auth, profile }: any) => {
  const getTeams = async () => {
    const teamsColl = collection(firestore, "cache/teams/data");
    const snapshot = await getDocs(teamsColl);
    let teams: any[] = [];
    snapshot.forEach((doc) => {
      teams = [...teams, ...doc.data().data];
    });
    return teams;
  };

  const updateTeam = async ({ id, data }: any) => {
    const docRef = doc(firestore, "teams", String(id));
    return await setDoc(docRef, data, { merge: true });
  };

  const addUserToLeague = (item: LeagueUser, leagueId: string) => {
    const data: any = {
      avatarUrl: item.avatarUrl,
      displayName: item.displayName,
      valid: true,
      dateAdded: item.dateAdded || new Date().getTime(),
      uid: item.uid,
      bets: item.bets || {},
      leagueId: leagueId,
    };

    const docRef = doc(firestore, `leagues/${leagueId}/users`, item.uid);
    return setDoc(docRef, data, { merge: true });
  };

  const updateUserInLeague = (
    userId: string,
    data: Partial<LeagueUser>,
    leagueId: string,
  ) => {
    const docRef = doc(firestore, `leagues/${leagueId}/users`, userId);
    return setDoc(docRef, data, { merge: true });
  };

  const removeUserFromLeague = (uid: string, leagueId: string) => {
    if (!uid) {
      return Promise.resolve();
    }
    const docRef = doc(firestore, `leagues/${leagueId}/users`, uid);
    return setDoc(docRef, { valid: false }, { merge: true });
  };

  const updateUser = (
    uid: string,
    data: LeagueUser,
    leagues: LeagueExtended[],
  ) => {
    const docRef = doc(firestore, "users", uid);
    leagues.forEach(async (league) => {
      if (league.users[uid]) {
        await updateUserInLeague(uid, data, league.id);
      }
    });
    return setDoc(docRef, data, { merge: true });
  };

  const updateLeague = async (league: League, data: League) => {
    const docRef = doc(firestore, "leagues", league.id);
    return setDoc(docRef, data, { merge: true });
  };

  const getLeague = async (leagueId: string) => {
    const docRef = doc(firestore, "leagues", leagueId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  };

  const getLeagueUsersById = async (leagueId: string, userId: string) => {
    const docRef = doc(firestore, `leagues/${leagueId}/users`, userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  };
  const setBet = async ({ league, match, data }: any) => {
    if (new Date() >= match.date || isStarted(match)) {
      alert("No no no..., I marked you " + auth.displayName);
      throw new Error("No no no..., I marked you " + auth.displayName);
    }

    const docRef = doc(firestore, `leagues/${league.id}/users`, auth.uid);
    return await setDoc(docRef, data, { merge: true });
  };
  const deleteLeague = (id: string) => {
    return deleteDoc(doc(firestore, "leagues", id));
  };

  const addMessage = (data: any) => {
    return addDoc(collection(firestore, "messages"), data);
  };

  const saveImage = async (image: any) => {
    const storage = getStorage();
    const resized = (await resizeImage(image)) as Blob;
    const fileRef = ref(
      storage,
      "images/users/" + auth.uid + "_" + image.name.split(".").pop(),
    );
    const ouploadResult = await uploadBytes(fileRef, resized);
    const downloadUrl = await getDownloadURL(ouploadResult.ref);
    return downloadUrl;
  };

  const updateAdminMatch = async (matchId: string, data: any) => {
    const docRef = doc(firestore, "matches", matchId);
    return setDoc(docRef, data, { merge: true });
  };

  const addLeague = async ({ data, users }: any) => {
    const docRef = await addDoc(collection(firestore, "leagues"), data);
    const leagueId = docRef.id;
    users.forEach(async (user: LeagueUser) => {
      user.leagueId = leagueId;
      const docRef = doc(firestore, `leagues/${leagueId}/users`, user.uid);
      return setDoc(docRef, user, { merge: true });
    });
    return leagueId;
  };

  const deleteUser = async (uid: string) => {
    const docRef = doc(firestore, "users", uid);
    return deleteDoc(docRef);
  };

  return {
    addLeague,
    updateAdminMatch,
    saveImage,
    addMessage,
    deleteLeague,
    updateLeague,
    updateUser,
    addUserToLeague,
    removeUserFromLeague,
    setBet,
    getLeague,
    getLeagueUsersById,
    deleteUser,
    getTeams,
    updateTeam,
  };
};
