import {
  Typography,
  Avatar,
  Divider,
  ListItem,
  ListItemText,
  useTheme,
  Badge,
} from "@mui/material";
import { PerformanceIndicatorText } from "../../../components/stats/performance/performanceIndicator";
import TextComponent from "../../../components/styleGuide/atoms/textComponent.atom";
import styles from "./leaderboard.module.scss";
import { useLeaderboardItem } from "./useLeaderboardItem";
import styled from "@emotion/styled";
import { useAuthentication } from "../../../contexts/AuthContext";
import {
  DateLeaderboard,
  LeaderboardEntry,
  OddsLeaderboardEntry,
  PointsLeaderboardEntry,
} from "../../../../types";
import React, { FC } from "react";

type LeaderboardItemProps = {
  leaderboard: DateLeaderboard;
  item: PointsLeaderboardEntry;
  uid: string;
  badgeIcon?: string;
};
export const LeaderboardItem: FC<LeaderboardItemProps> = ({
  leaderboard,
  item,
  uid,
  badgeIcon,
}) => {
  const leaderboardItemData = useLeaderboardItem({ leaderboard, item, uid });
  if (!leaderboardItemData) {
    return null;
  }

  const { itemClass, showUserBets, users, rankToDisplay } = leaderboardItemData;
  const { auth } = useAuthentication();
  const theme = useTheme();
  const isFirstPlace = leaderboard[uid].rank === 1 && leaderboard[uid].bets > 0;
  const isMyPlace = !isFirstPlace && uid === auth.uid;
  const rowStyle = isMyPlace
    ? {
        backgroundColor: theme.hotShotColors.lightBackground,
        borderRadius: "10px",
      }
    : {};

  return (
    <div>
      <Container
        style={rowStyle}
        className={itemClass}
        onClick={() => showUserBets(uid)}
      >
        <ListItemText style={{ width: 0 }}>
          <div className={styles.rankDiff}>
            <div className={styles.score}>
              <TextComponent>{rankToDisplay || "---"}</TextComponent>
            </div>
            {item.score > 0 && (
              <div className={styles.rankDiffIndicator}>
                <PerformanceIndicatorText num={item.rankDiff} />
              </div>
            )}
          </div>
        </ListItemText>
        <UserPicture>
          {badgeIcon && <UserIcon>{badgeIcon}</UserIcon>}
          <Avatar src={users[item.uid].avatarUrl} />
        </UserPicture>
        <div className={styles.username}>
          <TextComponent>{users[item.uid].displayName}</TextComponent>
        </div>

        <ListItemText className={styles.textItemRoot}>
          <TextComponent>{String(item.bets)}</TextComponent>
        </ListItemText>
        <ListItemText className={styles.textItemRoot} style={{ width: 0 }}>
          <div className={styles.scoreDiff}>
            <TextComponent className={styles.score}>
              {item.correct}
            </TextComponent>
            <div className={styles.rankDiffIndicator}>
              <PerformanceIndicatorText num={item.correctDiff} />
            </div>
          </div>
        </ListItemText>
        <ListItemText className={styles.textItemRoot} style={{ width: 0 }}>
          <div className={styles.scoreDiff}>
            <TextComponent className={styles.score}>{item.exact}</TextComponent>
            <div className={styles.rankDiffIndicator}>
              <PerformanceIndicatorText num={item.exactDiff} />
            </div>
          </div>
        </ListItemText>
        <ListItemText className={styles.textItemRoot} style={{ width: 0 }}>
          <div className={styles.scoreDiff}>
            <TextComponent className={styles.score}>{item.score}</TextComponent>
            <div className={styles.rankDiffIndicator}>
              <PerformanceIndicatorText num={item.scoreDiff} />
            </div>
          </div>
        </ListItemText>
      </Container>
    </div>
  );
};

const Container = styled(ListItem)`
  background-color: ${(props) => props.theme.hotShotColors.cardBackground};
  margin: 3px 0;
`;

const UserPicture = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: relative;
`;
const UserIcon = styled.div`
  position: absolute;
  top: -22px;
  left: 10px;
  z-index: 2;
  display: flex;
  font-size: 24px;
  color: ${(props) => props.theme.hotShotColors.primary};
`;
