import React, { FC, createContext, useEffect, useState } from "react";
import _ from "lodash";
import { useDb, useLeagueMatches } from "./firestoreContext";
import { useLeague } from "./leagueContext";
import { useLoading } from "./loadingContext";
import { Match, Team } from "../../types";

type UpdateLeagueMatchesContextType = {
  fromDateFilter: Date | number | undefined;
  setFromDateFilter: (date: Date | number | undefined) => void;
  toDateFilter: Date | number | undefined;
  setToDateFilter: (date: Date | number | undefined) => void;
  resetDateFilters: () => void;
  saveLeague: () => Promise<void>;
  currentCompetitionId: number | undefined;
  setCurrentCompetitionId: (id?: number) => void;
  teams: Team[];
  selectedTeamsIds: number[];
  setSelectedTeamsIds: (teamIds: number[]) => void;
  selectedMatches: Match[];
  updateSelectedMatches: (matches: Match[]) => void;
};

type UpdateLeagueMatchesProviderProps = {
  children: React.ReactNode;
};

export const UpdateLeagueMatchesContext =
  createContext<UpdateLeagueMatchesContextType>(
    {} as UpdateLeagueMatchesContextType,
  );

export const UpdateLeagueMatchesProvider: FC<
  UpdateLeagueMatchesProviderProps
> = ({ children }) => {
  const { league, leagueMatches } = useLeague();
  const { updateLeague } = useDb();
  const { matches, updateMatchesListener } = useLeagueMatches();
  const { hideLoading, showLoading } = useLoading();

  const [currentCompetitionId, setCurrentCompetitionId] = useState<
    number | undefined
  >(2021);
  const [teams, setTeams] = useState<Team[]>([]);

  const [selectedTeamsIds, setSelectedTeamsIds] = useState<number[]>([]);
  const [selectedMatches, setSelectedMatches] = useState<Match[]>(
    leagueMatches || [],
  );

  const [fromDateFilter, setFromDateFilter] = useState<
    number | Date | undefined
  >();
  const [toDateFilter, setToDateFilter] = useState<number | Date | undefined>();

  useEffect(() => {
    resetDateFilters();
  }, [matches[0]?.id]);

  useEffect(() => {
    setSelectedTeamsIds([]);
    showLoading();
    updateMatchesListener(
      currentCompetitionId ? [currentCompetitionId] : undefined,
    );
    return () => updateMatchesListener(undefined);
  }, [currentCompetitionId]);

  useEffect(() => {
    hideLoading();
  }, [matches]);

  useEffect(() => {
    const teams = matches.reduce((acc: Team[], m) => {
      if (m.homeTeam) {
        acc[m.homeTeam.id] = m.homeTeam;
      }
      if (m.awayTeam) {
        acc[m.awayTeam.id] = m.awayTeam;
      }
      return acc;
    }, {} as Team[]);

    setTeams(teams);
  }, [matches.length, matches[0]?.id]);

  matches.forEach((match) => {
    const isSelected = !!selectedMatches.find(({ id }) => id === match.id);
    match.selected = isSelected;
  });

  const updateSelectedMatches = (matches: Match[]) => {
    setSelectedMatches(matches);
  };
  const saveLeague = async () => {
    const data = {
      competitionIds: _.uniq(
        selectedMatches.map((x) => x.competition?.id).filter((x) => x),
      ),
      matchesIds: selectedMatches.map((x) => x.id),
    };
    await updateLeague(league, data);
  };

  const resetDateFilters = () => {
    const futureMatches =
      matches?.filter(
        (x) => (x.date as Date).getTime() > new Date().getTime(),
      ) || [];
    const minMatchDate = _.minBy(futureMatches, (m) =>
      (m.date as Date).getTime(),
    )?.date;
    const maxMatchDate = _.maxBy(futureMatches, (m) =>
      (m.date as Date).getTime(),
    )?.date;
    setFromDateFilter(minMatchDate);
    setToDateFilter(maxMatchDate);
  };

  const value: UpdateLeagueMatchesContextType = {
    fromDateFilter,
    setFromDateFilter,
    toDateFilter,
    setToDateFilter,
    resetDateFilters,
    saveLeague,
    currentCompetitionId,
    setCurrentCompetitionId,
    teams,
    selectedTeamsIds,
    setSelectedTeamsIds,
    selectedMatches,
    updateSelectedMatches,
  };

  return (
    <UpdateLeagueMatchesContext.Provider value={value}>
      {children}
    </UpdateLeagueMatchesContext.Provider>
  );
};
