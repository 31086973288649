import styled from "@emotion/styled";
import { BetComponentContainer } from "./ScorePicker";

export const HitComponentContainer = styled<any>(BetComponentContainer)`
  background: transparent;
  color: ${(props) => props.theme.hotShotColors.primaryTextColor};
  border: none;
  display: flex;
  gap: -10px;
  flex-direction: row;
  border-radius: 50%;
  scale: 1.1;
  width: 40px;
  height: 40px;
  > svg:nth-of-type(1) {
    width: 18px;
    height: 18px;
    animation: tilt 2s infinite;
    margin-right: -1px;
  }
  > svg:nth-of-type(2) {
    margin-left: -1px;
    width: 18px;
    height: 18px;
    animation: tiltMirror 2s infinite;
  }
  @keyframes tilt {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-15deg);
    }
    80% {
      transform: rotate(15deg);
    }
    85% {
      transform: rotate(-15deg);
    }
    90% {
      transform: rotate(15deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes tiltMirror {
    0% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(15deg);
    }
    70% {
      transform: rotate(-15deg);
    }
    75% {
      transform: rotate(15deg);
    }
    80% {
      transform: rotate(-15deg);
    }
    85% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;
