import React, { FC } from "react";
import { LiveMatchProps } from "./lineUpTypes";
import styled from "@emotion/styled";
import { Avatar, useTheme } from "@mui/material";
import TextComponent from "../../../../components/styleGuide/atoms/textComponent.atom";
import {
  Column,
  Row,
} from "../../../../components/styleGuide/atoms/baseStyles";
import Space from "../../../../components/styleGuide/atoms/space.atom";
import { TeamColorsAvatar } from "../../../../components/common/colorsAvatar/colorsAvatar";
import { TeamLastMatchesForm } from "../TeamLastMatchesForm/TeamLastMatchesForm";

const LineUpHeader: FC<{
  matchDetails: LiveMatchProps | undefined;
  homeTeamForm?: string;
  awayTeamForm?: string;
}> = ({ matchDetails, homeTeamForm, awayTeamForm }) => {
  const theme = useTheme();
  if (!matchDetails) {
    return null;
  }
  const { awayTeam, homeTeam } = matchDetails as LiveMatchProps;

  return (
    <StyledContainer>
      <Row>
        {/* <StyledAvatar src={homeTeam.crest} /> */}
        <TeamColorsAvatar team={homeTeam} size={25} />
        <Space width={5} />
        <Column style={{ alignItems: "flex-start" }}>
          <TeamLastMatchesForm form={homeTeamForm!} reverse />
          <TextComponent variant="small">
            {homeTeam.shortName || homeTeam.name}
          </TextComponent>
          <TextComponent color={theme.hotShotColors.Grayscale40} variant="tiny">
            {homeTeam.formation}
          </TextComponent>
        </Column>
      </Row>
      <Row>
        <Column style={{ alignItems: "flex-end" }}>
          <TeamLastMatchesForm form={awayTeamForm!} />
          <TextComponent variant="small">
            {awayTeam.shortName || awayTeam.name}
          </TextComponent>
          <TextComponent color={theme.hotShotColors.Grayscale40} variant="tiny">
            {awayTeam.formation}
          </TextComponent>
        </Column>
        <Space width={5} />
        {/* <StyledAvatar src={awayTeam.crest} /> */}
        <TeamColorsAvatar team={awayTeam} size={25} />
      </Row>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledAvatar = styled(Avatar)`
  width: 32px;
  height: 32px;
`;
export default LineUpHeader;
