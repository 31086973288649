import { Backdrop } from "@mui/material";
import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  FC,
} from "react";
import { FootballLoader } from "./footballLoader/footballLoader";

type LoadingContextType = {
  showLoading: () => void;
  hideLoading: () => void;
  isLoading: boolean;
};
type LoadingProviderProps = {
  children: React.ReactNode;
};
export const LoadingContext = createContext<LoadingContextType>(
  {} as LoadingContextType,
);

export const LoadingProvider: FC<LoadingProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const hideLoading = () => setIsLoading(false);
  const showLoading = () => setIsLoading(true);

  return (
    <LoadingContext.Provider
      value={{
        showLoading,
        hideLoading,
        isLoading,
      }}
    >
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <FootballLoader />
        </Backdrop>
      )}
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  const { showLoading, hideLoading, isLoading } = useContext(LoadingContext);
  useEffect(() => {
    hideLoading();
  }, []);
  return { showLoading, hideLoading, isLoading };
};
