import React, { FC, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useAuthentication } from "../../../contexts/AuthContext";
import { useLoading } from "../../../contexts/loadingContext";
import { useDb } from "../../../contexts/firestoreContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useLeague } from "../../../contexts/leagueContext";
import { Close } from "@mui/icons-material";
import styled from "@emotion/styled";
import TextComponent from "../../styleGuide/atoms/textComponent.atom";
import { useTheme } from "@mui/material/styles";
import ButtonComponent from "../../styleGuide/atoms/buttonComponent.atom";
import Space from "../../styleGuide/atoms/space.atom";
import { isStarted } from "../../../services/matchService";
import TextInputComponent from "../../styleGuide/atoms/textInputComponent.atom";
import { useAnalyticsLogEvent } from "../../../hooks/useAnalyticsHook";
import CoinComponent from "../../styleGuide/atoms/coinComponent.atom";
import { useAdMob } from "../../../contexts/adMobContext";
import { Match, Odds, OddsBet, OddsLeaderboardEntry } from "../../../../types";

type PlaceBetOddsModalProps = {
  open: boolean;
  match: Match;
  onClose: () => void;
  bet: OddsBet;
  betType: keyof Odds;
};
export const PlaceBetOddsModal: FC<PlaceBetOddsModalProps> = (props) => {
  const { league } = useLeague();
  const { open, match, onClose, bet, betType } = props;
  const { auth } = useAuthentication();
  const { hideLoading, showLoading } = useLoading();
  const { setBet } = useDb();
  const betData = bet?.[betType];
  const [initialAmount, setInitialAmount] = useState(betData?.amount || 0);
  const logEvent = useAnalyticsLogEvent();
  const [amount, setAmount] = useState<number | undefined>(betData?.amount);
  const { lastLeaderboard } = league || {};
  const ref = useRef();
  const theme = useTheme();
  const { showAd } = useAdMob();

  useEffect(() => {
    if (!open) {
      setAmount(undefined);
      setInitialAmount(0);
    } else {
      setAmount(betData?.amount);
      setInitialAmount(betData?.amount || 0);
    }
  }, [open]);

  useEffect(() => {
    setAmount(betData?.amount);
  }, [betType]);

  const leaderboardEntry = lastLeaderboard?.[auth.uid] as OddsLeaderboardEntry;
  const maxBetAmount = Math.round(
    leaderboardEntry?.balance +
      initialAmount -
      leaderboardEntry?.pendingBetsAmount,
  );
  const updateBet = async (type: keyof Odds, amount = 0) => {
    if (isStarted(match)) {
      alert(`You can't if the match is already started`);
      return;
    }
    if (amount !== 0 && amount > maxBetAmount) {
      alert(`You can't bet more than your spendable balance (${maxBetAmount})`);
      return;
    }
    // if (amount % 10 > 0 && maxBetAmount > 10 && amount !== maxBetAmount) {
    //     alert(`You can only bet in multiples of 10`)
    //     return;
    // }
    closeHandler();
    showLoading();
    try {
      const data = {
        bets: {
          [match.id]: {
            [type]:
              amount === 0
                ? null
                : { amount: Number(amount), odd: match.odds[type] },
          },
        },
      };
      await setBet({ league, match: match, data });
      logEvent("place_bet", {
        league_id: league?.id,
        match_id: match.id,
        bet_type: type,
        amount: amount,
      });
      if (Math.random() > 0.65) {
        showAd();
      }
    } catch (error) {
      console.log(error);
    }
    hideLoading();
  };

  const closeHandler = () => {
    onClose();
    setAmount(undefined);
  };

  let modalTitle = `Draw (${match?.odds["draw"]})`;
  if (betType === "homeWin") {
    modalTitle = `${match.homeTeam.name} (${match?.odds["homeWin"]})`;
  } else if (betType === "awayWin") {
    modalTitle = `${match.awayTeam.name} (${match?.odds["awayWin"]})`;
  }

  return (
    <Dialog
      PaperProps={{ style: { backgroundColor: "transparent" } }}
      open={open}
      onClose={closeHandler}
      fullWidth={true}
    >
      <ModalBody style={{ borderColor: theme.hotShotColors.background }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogTitle>
          <TextComponent variant={"medium"}>{modalTitle}</TextComponent>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextComponent
              color={theme.hotShotColors.primaryTextColor}
              variant={"small"}
            >
              {match.homeTeam.name} - {match.awayTeam.name}
            </TextComponent>
            <TextComponent variant={"extra-small"}>
              (Maximum bet {maxBetAmount}
              <CoinComponent />)
            </TextComponent>
          </DialogContentText>
          <Space height={5} />
          <TextInputComponent
            value={String(amount) || ""}
            type={"number"}
            placeholder={"Bet amount"}
            onChange={(value) => setAmount(Number(value))}
            ref={ref}
          ></TextInputComponent>
          <Space height={5} />
          <DialogContentText>
            <TextComponent color={theme.hotShotColors.success} variant="small">
              (Possible Profit +
              {Math.round(amount! * match?.odds[betType] || 0)}
              <CoinComponent />)
            </TextComponent>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonComponent variant="text" onClick={closeHandler} size="normal">
            Close
          </ButtonComponent>
          <Space grow />
          <ButtonComponent
            size={"small"}
            variant="outline"
            onClick={() => updateBet(betType, 0)}
          >
            Clear
          </ButtonComponent>
          <ButtonComponent
            isDisable={!amount}
            size={"small"}
            variant="contained"
            onClick={() => updateBet(betType, amount)}
          >
            Set
          </ButtonComponent>
        </DialogActions>
      </ModalBody>
    </Dialog>
  );
};

const ModalBody = styled.div`
  border: 1px solid #37003c;
  border-radius: 12px;
  background-color: ${(props) => props.theme.hotShotColors.background};
`;
