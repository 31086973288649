import _ from "lodash";
import { LeagueProvider, useLeague } from "../../contexts/leagueContext";
import React, { FC, useEffect } from "react";
import {
  useParams,
  useNavigate,
  Outlet,
  useLocation,
  To,
} from "react-router-dom";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { Gavel, Leaderboard } from "@mui/icons-material";

//@ts-ignore
import { ReactComponent as HomeIcon } from "./icons/home.svg";
//@ts-ignore
import { ReactComponent as SettingsIcon } from "./icons/settings.svg";
import styles from "./league.module.scss";
import { Header } from "../../components/common/header/header";
import { useLoading } from "../../contexts/loadingContext";
import { useAuthentication } from "../../contexts/AuthContext";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useLeagueAnalytics } from "../../hooks/useAnalyticsHook";
//@ts-ignore
import { OddsLeagueBalanceBar } from "./oddsLeagueBalanceBar";
import { useLeagues } from "../../contexts/leaguesContext";
import { LeaguePointsMyOverall } from "../../components/common/LeaguePointsMyOverall";
import { PointsLeaderboardEntry } from "../../../types";

type LeagueRouterProps = {
  children: React.ReactNode;
};
export const LeagueRouter: FC<LeagueRouterProps> = ({ children }) => {
  const { leaguesInitialized } = useLeagues();
  const { league } = useLeague();
  const navigate = useNavigate();
  const location = useLocation();
  const page =
    location.pathname.indexOf("home") > -1
      ? "home"
      : location.pathname.split("/").pop() || "";
  const { leagueId, matchId, userId } = useParams();
  const { profile, auth } = useAuthentication();
  useLeagueAnalytics({
    screenName: page,
    leagueId: leagueId!,
    matchId,
    userId,
  });
  const { hideLoading, showLoading } = useLoading();

  useEffect(() => {
    showLoading();
    if (league && leaguesInitialized) {
      hideLoading();
    }
  }, [league, leaguesInitialized]);

  const showBottomNav =
    page.indexOf("update") === -1 && page.indexOf("step") === -1;
  const handleChange = (obj: any, value: string) => {
    setTimeout(() => {
      navigate(`/league/${leagueId}/${value}`);
    }, 0);
  };
  let title = league!.name;
  switch (page) {
    case "matches": {
      title = "Matches Schedule";
      break;
    }
    case "board": {
      title = "Leaderboard";
      break;
    }
    case "rules": {
      title = "League Rules";
      break;
    }
    case "settings": {
      title = "League Settings";
      break;
    }
    default: {
      title = league?.name || "Error loading league";
    }
  }

  const backPath = page === "home" ? "/" : -1;
  const theme = useTheme();
  let HeaderRightElement = null;
  if (league && leaguesInitialized) {
    const myLeaderboardEntry = league.lastLeaderboardThatStarted?.[
      auth.uid
    ] as PointsLeaderboardEntry;
    if (myLeaderboardEntry) {
      HeaderRightElement = (
        <RightElementWrapper>
          <LeaguePointsMyOverall
            leaderboardEntry={myLeaderboardEntry}
            league={league}
            onClick={() => navigate(`/league/${league.id}/users/${auth.uid}`)}
            showProgress
          />
        </RightElementWrapper>
      );
    }
  }

  return (
    <div className={styles.root}>
      <Header
        onBack={() => navigate(backPath as To)}
        title={title}
        rightElement={HeaderRightElement}
      />
      <OddsLeagueBalanceBar />
      <OutletContainer>
        <Outlet />
      </OutletContainer>
      {showBottomNav && (
        <StyledBottomNavigation
          value={page}
          showLabels
          onChange={handleChange}
          style={{
            minHeight: 68,
            width: "100%",
            backgroundColor: theme.hotShotColors.background,
          }}
          className={styles.navigation}
        >
          <BottomNavigationAction
            label={page === "home" ? "*" : ""}
            value="home"
            icon={
              <HomeIcon
                color={
                  page === "home"
                    ? theme.hotShotColors.iconSelectedColor
                    : theme.hotShotColors.tabsIconColor
                }
              />
            }
          />
          <BottomNavigationAction
            label={page === "board" ? "*" : ""}
            value="board"
            icon={
              <Leaderboard
                style={{
                  color:
                    page === "board"
                      ? theme.hotShotColors.iconSelectedColor
                      : theme.hotShotColors.tabsIconColor,
                }}
              />
            }
          />
          <BottomNavigationAction
            label={page === "rules" ? "*" : ""}
            value="rules"
            icon={
              <Gavel
                style={{
                  color:
                    page === "rules"
                      ? theme.hotShotColors.iconSelectedColor
                      : theme.hotShotColors.tabsIconColor,
                }}
              />
            }
          />
          {(league!.isAdmin || profile.isAppAdmin) && (
            <BottomNavigationAction
              label={page === "settings" ? "*" : ""}
              value="settings"
              icon={
                <SettingsIcon
                  color={
                    page === "settings"
                      ? theme.hotShotColors.iconSelectedColor
                      : theme.hotShotColors.tabsIconColor
                  }
                />
              }
            />
          )}
        </StyledBottomNavigation>
      )}
    </div>
  );
};

const StyledBottomNavigation = styled(BottomNavigation)`
  box-shadow: 0px 1px 10px 0px
    ${(props) => props.theme.hotShotColors.headerNavigationShadow};
`;

type LeagueScreenProps = {
  children?: React.ReactNode;
};
export const LeagueScreen: FC<LeagueScreenProps> = ({ children }) => {
  return (
    <LeagueProvider>
      <LeagueRouter>{children}</LeagueRouter>
    </LeagueProvider>
  );
};

const OutletContainer = styled.div`
  background-color: ${(props) => props.theme.hotShotColors.background};
  height: 100%;
  overflow: hidden;
`;

const RightElementWrapper = styled.div`
  margin-right: 10px;
`;
