export const defaultLeagueImage =
  "https://firebasestorage.googleapis.com/v0/b/euro2021-53c8a.appspot.com/o/qatar2022.svg?alt=media&token=7f651659-afbb-42eb-bd01-4ebeea07f288";
export const defaultTeamFlag =
  "https://png.pngtree.com/element_pic/17/02/19/4e10739872e26990dfa2dc99a7f106d3.jpg";
export const REGULAR_SEASON = "REGULAR_SEASON";
export const GROUP_STAGE = "GROUP_STAGE";

export const capitalizeFirstLetter = (string: string) => {
  const str = string.replace("_", " ").toLowerCase();
  const strArr = str.split(" ");
  const strArrFinal = strArr.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1),
  );
  return strArrFinal.join(" ");
};
