import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { MatchesList } from "../../components/matchComponents/matches/matchesList";
import { useLoading } from "../../contexts/loadingContext";
import { useAnalytics } from "../../hooks/useAnalyticsHook";
import CalendarComponent from "../league/matches/calendarComponent";
import { ItemType, Match } from "../../../types";
import { useAuthentication } from "../../contexts/AuthContext";
import ButtonComponent from "../../components/styleGuide/atoms/buttonComponent.atom";
import { LoginOutlined } from "@mui/icons-material";
import { Zoom } from "../../components/common/zoomList/zoomList";
import { ScrollableProvider } from "../../contexts/Scrollable";
import PoppingModal from "../../components/common/poppingModal/poppingModal";
import { MatchScreen } from "../league/match/matchScreen";
import MatchesScreen from "../league/matches/matchesScreen";

export const AppMatchesScreen = () => {
  const { allMatches } = useOutletContext<{ allMatches: Match[] }>();
  const navigate = useNavigate();
  const { auth, authInitialized } = useAuthentication();
  useAnalytics({ screenName: "schedule" });

  return (
    <Container>
      <Zoom>
        {!auth && authInitialized && (
          <LoginButtonContainer>
            <ButtonComponent
              variant="outline"
              size="normal"
              width={240}
              onClick={() => navigate("/login")}
            >
              <LoginOutlined />
              &nbsp; Login and start playing
            </ButtonComponent>
          </LoginButtonContainer>
        )}
        <Container>
          <StylesMatchList>
            {allMatches.length > 0 && (
              <MatchesScreen overrideMatches={allMatches} heightOffset={100} />
            )}
            {allMatches.length === 0 && (
              <LoadingWrapper>
                <CircularProgress />
              </LoadingWrapper>
            )}
          </StylesMatchList>
        </Container>
      </Zoom>
    </Container>
  );
};
const Container = styled.div`
  overflow: hidden;
  padding: 0 5px;
`;

const StylesMatchList = styled.div`
  box-sizing: border-box;
  padding-bottom: 100px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  margin: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;
