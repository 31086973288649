import React from "react";
import styled from "@emotion/styled";
import TextComponent from "../../../components/styleGuide/atoms/textComponent.atom";

export const NoDataMessage = () => {
  return (
    <EmptyContainer>
      <TextComponent variant="medium">Data not available yet</TextComponent>
    </EmptyContainer>
  );
};

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 40px 0;
`;
