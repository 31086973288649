import React, { useRef, useState } from "react";
import styles from "./contactus.module.scss";
import { Header } from "../../components/common/header/header";
import { Close } from "@mui/icons-material";
import {
  Snackbar,
  List,
  ListItem,
  ListItemText,
  Zoom,
  Button,
  Avatar,
  IconButton,
  TextField,
} from "@mui/material";
import { useLoading } from "../../contexts/loadingContext";
import { useAuthentication } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useDb } from "../../contexts/firestoreContext";
import { ZoomList } from "../../components/common/zoomList/zoomList";
import TextComponent from "../../components/styleGuide/atoms/textComponent.atom";
import styled from "@emotion/styled";
import Space from "../../components/styleGuide/atoms/space.atom";
import TextInputComponent from "../../components/styleGuide/atoms/textInputComponent.atom";
import ButtonComponent from "../../components/styleGuide/atoms/buttonComponent.atom";

export const ContactUs = () => {
  const { hideLoading, showLoading } = useLoading();
  const { auth, profile } = useAuthentication();
  const navigate = useNavigate();
  const { addMessage } = useDb();
  const [text, setText] = useState("");
  const [state, setState] = useState({
    snackbarMessage: "",
    snackbarOpen: false,
  });

  const send = () => {
    showLoading();
    addMessage({
      uid: auth?.uid,
      displayName: profile?.displayName,
      avatarUrl: profile?.avatarUrl,
      read: false,
      email: profile?.email,
      message: text,
      sentAt: new Date(),
    })
      .then(() => {
        setState({
          snackbarMessage: "Message sent, thanks you very much",
          snackbarOpen: true,
        });
        hideLoading();
      })
      .catch(() => {
        setState({
          snackbarMessage: "Error sending message, please try again later.",
          snackbarOpen: true,
        });
        hideLoading();
      });
  };

  return (
    <div className={styles.root}>
      <Header onBack={() => navigate("/")} title="Contact Us" />
      <Space height={60} />
      <Column>
        <Avatar src={profile?.avatarUrl} />
        <Space height={10} />
        <TextComponent variant="large">{profile?.displayName}</TextComponent>
      </Column>
      <Space height={30} />
      <InputColumn>
        <TextInputComponent
          value={text}
          placeholder={"Tell us what you think"}
          onChange={(value) => setText(value)}
        ></TextInputComponent>
        <Space height={30} />
        <ButtonComponent size={"small"} onClick={send} variant="contained">
          Send
        </ButtonComponent>
      </InputColumn>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={state.snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setState((prev) => ({ ...prev, snackbarOpen: false }))}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{state.snackbarMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={styles.close}
            onClick={() =>
              setState((prev) => ({ ...prev, snackbarOpen: false }))
            }
          >
            <Close />
          </IconButton>,
        ]}
      />
    </div>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 60px;
`;
