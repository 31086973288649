import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useContext, useEffect } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { Header } from "../../components/common/header/header";
import { Column } from "../../components/styleGuide/atoms/baseStyles";
import TextComponent from "../../components/styleGuide/atoms/textComponent.atom";
import { CreateLeagueContext } from "../../contexts/createLeagueContext";
import {
  useCompetitions,
  useLeagueMatches,
} from "../../contexts/firestoreContext";
import { useLoading } from "../../contexts/loadingContext";
import { UpdateLeagueMatchesContext } from "../../contexts/updateLeagueMatchesContext";
import { ORDERED_COMPETITION_CODES } from "../../services/matchService";
import { MobileStepperWizard } from "./components/mobileStepperWizard";
import styles from "./updateLeagueMatchesStep1.module.scss";
import styled from "@emotion/styled";
import { Competition } from "../../../types";

export const UpdateLeagueMatchesStep1 = () => {
  const competitions = useCompetitions();
  const { setCurrentCompetitionId } = useContext(UpdateLeagueMatchesContext);
  const navigate = useNavigate();
  const filteredCompetitions = ORDERED_COMPETITION_CODES.map((x) =>
    competitions.find((y: Competition) => x === y.code),
  ).filter((x) => x);
  const onItemClick = (item: Competition) => {
    setCurrentCompetitionId(item.id);
    navigate("./step2");
  };

  const imageList = (
    <ImageList cols={3} gap={2}>
      {filteredCompetitions.map((item, index) => (
        <ImageListItem key={index} onClick={() => onItemClick(item!)}>
          <img
            className={styles.item}
            src={`${item?.area?.flag}`}
            srcSet={`${item?.area?.flag}`}
            alt={item?.name}
            loading="lazy"
          />
          <ImageListItemBar subtitle={item?.name} />
        </ImageListItem>
      ))}
    </ImageList>
  );

  return (
    <>
      <Header onBack={() => navigate(-1)} title={"Update Matches"} />
      <ColumnText>
        <TextComponent fontWeight={600} variant={"medium"}>
          Select a competition to start from
        </TextComponent>
        <TextComponent variant={"small"}>
          Click on the competition you want to add matches from to your new
          league
        </TextComponent>
      </ColumnText>
      {imageList}
      <MobileStepperWizard
        activeStep={0}
        maxSteps={3}
        handleBack={() => navigate(-1)}
        handleNext={() => navigate("./step2")}
      />
    </>
  );
};

const ColumnText = styled.div``;
