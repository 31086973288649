import React, { useRef, useEffect, useState, FC } from "react";
import _ from "lodash";
import moment from "moment";
import ChartJS from "chart.js/auto";
import styles from "./chart.module.scss";
import { useTheme } from "@mui/material/styles";
import TextComponent from "../../styleGuide/atoms/textComponent.atom";
import styled from "@emotion/styled";
import { isOddsLeague } from "../../../services/matchService";
import {
  Leaderboard,
  League,
  OddsLeaderboardEntry,
  PointsLeaderboardEntry,
} from "../../../../types";

type ChartProps = {
  userId: string;
  dynamicLeaderboard: Leaderboard;
  league: League;
};
export const Chart: FC<ChartProps> = ({
  userId,
  dynamicLeaderboard = {},
  league,
}) => {
  const myChartRef = useRef(null);
  const [myChart, setMyChart] = useState<any>(null);
  let isUsed = false;
  const theme = useTheme();

  useEffect(() => {
    if (_.isEmpty(dynamicLeaderboard)) {
      return;
    }
    const days =
      Object.keys(dynamicLeaderboard).filter(
        (day) => dynamicLeaderboard[day]?.[userId]?.rank,
      ) || [];
    const ranks = _.map(days, (key) => dynamicLeaderboard[key][userId].rank);
    let points = _.map(
      days,
      (key) =>
        (dynamicLeaderboard[key][userId] as PointsLeaderboardEntry).scoreDiff,
    );
    const totalPlayers = Object.keys(
      dynamicLeaderboard[days[days.length - 1]] || {},
    )?.length;
    const labels = _.map(days, (key) => moment(new Date(key)).format("DD/MM"));

    if (isOddsLeague(league)) {
      points = _.map(
        days,
        (key) =>
          (dynamicLeaderboard[key][userId] as OddsLeaderboardEntry).balance,
      );
    }
    if (myChart) {
      (myChart as any).destroy();
    }
    try {
      if (myChartRef && ChartJS && !isUsed) {
        isUsed = true;
        const ctx = myChartRef.current;
        const chart = new ChartJS(ctx!, {
          data: {
            labels: labels,
            datasets: [
              {
                type: "line",
                label: "Rank",
                data: ranks,
                backgroundColor: theme.hotShotColors.thirdBackground,
                borderColor: theme.hotShotColors.thirdBackground,
                borderWidth: 1,
                yAxisID: "y1",
              },
              {
                type: "bar",
                label: isOddsLeague(league) ? "Balance" : "Points",
                data: points,
                backgroundColor: theme.hotShotColors.warning,
                borderColor: theme.hotShotColors.warning,
                borderWidth: 1,
                yAxisID: "y2",
              },
            ],
          },
          options: {
            scales: {
              y1: {
                reverse: true,
                min: 1,
                max: Math.max(totalPlayers, 2),
                position: "right",
                beginAtZero: true,
                offset: true,
                ticks: {
                  maxTicksLimit: 0,
                },
                title: {
                  display: true,
                  text: "Rank",
                  color: theme.hotShotColors.primaryTextColor,
                  padding: 1,
                },
              },
              y2: {
                min: 0,
                max: (_.max(points) ?? 0) * 2,
                title: {
                  display: true,
                  text: isOddsLeague(league) ? "Balance" : "Points",
                  color: theme.hotShotColors.primaryTextColor,
                  padding: 1,
                },
              },
            },
          },
        });
        setMyChart(chart);
      }
    } catch (e) {
      console.error(e);
    }
  }, [myChartRef?.current, theme]);

  if (_.isEmpty(dynamicLeaderboard)) {
    return (
      <StyledRow>
        <TextComponent variant="header">No data to show</TextComponent>
      </StyledRow>
    );
  }
  return (
    <div className={styles.container}>
      <canvas id="myChart" ref={myChartRef}></canvas>
    </div>
  );
};

const StyledRow = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
`;
