import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { Header } from "../../components/common/header/header";
import styles from "./createLeagueScreen.module.scss";
import { CreateLeagueProvider } from "../../contexts/createLeagueContext";
import { Outlet } from "react-router-dom";
import { MobileStepperWizard } from "./components/mobileStepperWizard";
import { UpdateLeagueMatchesProvider } from "../../contexts/updateLeagueMatchesContext";
import React from "react";

const CreateLeagueScreenComp = () => {
  return (
    <div className={styles.container}>
      <Outlet />
    </div>
  );
};

export const CreateLeagueScreen = () => {
  return (
    <CreateLeagueProvider>
      <CreateLeagueScreenComp />
    </CreateLeagueProvider>
  );
};
