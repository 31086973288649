import React, { FC, useState } from "react";
import styled from "@emotion/styled";
import TextComponent from "../../styleGuide/atoms/textComponent.atom";
import { useTheme } from "@emotion/react";
import Space from "../../styleGuide/atoms/space.atom";
import { useLeague } from "../../../contexts/leagueContext";
import { useAuthentication } from "../../../contexts/AuthContext";
import {
  isFinished,
  isStarted,
  isOddsLeague,
  isPointsLeague,
  isOddsScoreLeague,
} from "../../../services/matchService";
import { getMatchResult } from "../../../services/matchService";
import { getOddBetResult } from "../../../services/betService";
import { Column } from "../../styleGuide/atoms/baseStyles";
import { Avatar } from "@mui/material";
import moment from "moment";
import { TeamColorsAvatar } from "../../common/colorsAvatar/colorsAvatar";
import { OddsBetsComponent } from "../match/OddsBetComponent";
import { OddsBet, PointsBet } from "../../../../types";
import { PointsText } from "./PointsText";
import { LiveMinute } from "../match/liveMinute";
import { PointsBetComponent } from "../match/PointsBetComponent";

type DetailedMatchProps = {
  leagueMatch: any;
  currentUserAuth: any;
};
const DetailedMatch: FC<DetailedMatchProps> = (props) => {
  const theme = useTheme();
  const { leagueMatch, currentUserAuth } = props;
  const { auth } = useAuthentication();
  const { league } = useLeague();
  const { homeTeam, awayTeam } = leagueMatch;
  const isMatchLive = isStarted(leagueMatch) && !isFinished(leagueMatch);
  const isForLoggedInUser = currentUserAuth && currentUserAuth.uid === auth.uid;

  // if (!matchHasOdds(leagueMatch)) {
  //   return null;
  // }
  const showBet = isForLoggedInUser || isStarted(leagueMatch);
  const { homeScore, awayScore } = getMatchResult(leagueMatch);
  const leagueUser = league?.users[currentUserAuth.uid];
  const myBet = leagueUser?.bets[leagueMatch.id] || {};
  const betResult = getOddBetResult(myBet as OddsBet, leagueMatch);

  return (
    <>
      <Container>
        <Column>
          <TextComponent
            color={theme.hotShotColors.primaryTextColor}
            variant={"extra-small"}
          >
            {moment(leagueMatch.utcDate).format("MMM Do YYYY")},{" "}
          </TextComponent>
          <Space height={5} />
          <TextComponent
            color={theme.hotShotColors.primaryTextColor}
            variant={"extra-small"}
          >
            {leagueMatch.venue}
          </TextComponent>
          <Space height={5} />
          <TextComponent
            color={theme.hotShotColors.Grayscale50}
            variant={"extra-small"}
          >
            Matchday {leagueMatch.matchday}
          </TextComponent>
          {isPointsLeague(league!) && (
            <PointsReference>
              <TextComponent
                color={theme.hotShotColors.Grayscale50}
                variant={"extra-small"}
              >
                Points: {leagueMatch.correctScore || 1} /
                {leagueMatch.exactScore || 3}
              </TextComponent>
            </PointsReference>
          )}
        </Column>
        <StyledRow>
          <SideColumn style={{ justifyContent: "flex-end" }}>
            {/* {homeTeam.crest ? <StyledAvatar src={homeTeam.crest} /> : <StyledAvatar>?</StyledAvatar>} */}
            <TeamColorsAvatar team={homeTeam} size={60} />
            <Space width={5} />
            <TextComponent textAlign={"center"} variant="small">
              {" "}
              {homeTeam.shortName || homeTeam.name}
            </TextComponent>
          </SideColumn>
          <StyledColumn>
            <StyledRow>
              {league && !isOddsLeague(league) && (
                <>
                  <TextComponent variant={"extra-small"}>
                    ({(myBet as PointsBet)?.homeScore || 0})
                  </TextComponent>
                  <Space width={5} />
                </>
              )}
              <TextComponent variant={"extra-large"} whiteSpace="nowrap">
                {homeScore}:{awayScore}
              </TextComponent>
              {league && !isOddsLeague(league) && (
                <>
                  <Space width={5} />
                  <TextComponent variant={"extra-small"}>
                    ({(myBet as PointsBet)?.awayScore || 0})
                  </TextComponent>
                </>
              )}
            </StyledRow>
            {isMatchLive && <LiveMinute match={leagueMatch} />}
            {!isMatchLive && isFinished(leagueMatch) && (
              <TextComponent variant={"small"}>{"FT"}</TextComponent>
            )}
            {!isStarted(leagueMatch) && (
              <TextComponent variant={"small"}>
                {moment(leagueMatch.utcDate).format("HH:mm")}
              </TextComponent>
            )}
          </StyledColumn>
          <SideColumn style={{ justifyContent: "flex-start" }}>
            {/* {awayTeam.crest ? <StyledAvatar src={awayTeam.crest} /> : <StyledAvatar>?</StyledAvatar>} */}
            <TeamColorsAvatar team={awayTeam} size={60} />
            <Space width={5} />
            <TextComponent textAlign={"center"} variant="small">
              {awayTeam.shortName || awayTeam.name}
            </TextComponent>
          </SideColumn>
        </StyledRow>
        {league && !isOddsLeague(league) && myBet && isStarted(leagueMatch) && (
          <PointsText
            bet={myBet as PointsBet}
            match={leagueMatch}
            useOdds={isOddsScoreLeague(league)}
          />
        )}
        <Space height={10} />
        {league && (
          <>
            {(isOddsLeague(league) || isOddsScoreLeague(league)) && (
              <OddsBetsComponent
                leagueMatch={leagueMatch}
                myBet={myBet}
                betResult={betResult}
                betClickable={isOddsLeague(league)}
                roundBet={isOddsScoreLeague(league)}
              />
            )}
          </>
        )}

        {league && !isStarted(leagueMatch) && (
          <PointsBetComponent match={leagueMatch} league={league} />
        )}
        {isOddsScoreLeague(league!) && (
          <AbsoluteRow full={true} justifyContent="space-between">
            {leagueMatch.correctMultiplier > 1 && (
              <TextComponentMatchPoints>
                Correct: X{leagueMatch.correctMultiplier}
              </TextComponentMatchPoints>
            )}
            <TextComponentMatchPoints fontSize={12} variant="extra-small">
              Exact: +{leagueMatch.exactWithMultiplyer || 4}&nbsp;
            </TextComponentMatchPoints>
          </AbsoluteRow>
        )}
        {isPointsLeague(league!) && (
          <AbsoluteRow full={true} justifyContent="space-between">
            <TextComponentMatchPoints>
              Correct: +{leagueMatch.correctScore}
            </TextComponentMatchPoints>
            <TextComponentMatchPoints>
              Exact: +{leagueMatch.exactScore}&nbsp;
            </TextComponentMatchPoints>
          </AbsoluteRow>
        )}
      </Container>
    </>
  );
};
const SideColumn = styled(Column)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledColumn = styled(Column)`
  width: 90px;
`;

const StyledAvatar = styled(Avatar)`
  width: 74px;
  height: 74px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* padding:5px; */
  width: 100%;
  // background-color: ${(props) => props.theme.hotShotColors.cardBackground};
  align-items: center;
  padding: 12px 16px;
  width: 400px;
  min-height: 191px;
  align-self: center;
  border-radius: 20px;
  justify-content: space-between;
`;

const PointsReference = styled.div`
  width: 100%;
`;
const TextComponentMatchPoints = styled(TextComponent)`
  color: ${(props) => props.theme.hotShotColors.primaryTextColor};
  font-size: ${(props) => props.fontSize || 12}px;
  font-weight: 400;
  text-align: center;
  padding: 10px 20px;
`;
type RowProps = {
  full?: boolean;
  justifyContent?: string;
};
const Row = styled.div<RowProps>`
  align-items: center;
  justify-content: ${(props) => props.justifyContent || "center"};
  display: flex;
  flex: 1;
  padding: 0 5px;
  width: ${(props) => (props.full ? "100%" : "auto")};
`;

const StyledRow = styled(Row)`
  width: 100%;
  justify-content: center;
`;
const AbsoluteRow = styled(Row)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export default DetailedMatch;
