import React, { FC, useState } from "react";
import { AppBar, Toolbar, IconButton, useTheme, Avatar } from "@mui/material";
import { Drawer } from "../drawer/drawer";
import { useAuthentication } from "../../../contexts/AuthContext";
import { useHotShotTheme } from "../../styleGuide/useTheme";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { useTranslation } from "react-i18next";
import MenuIconComponent from "./menuIconComponent";
import TextComponent from "../../styleGuide/atoms/textComponent.atom";
import styled from "@emotion/styled";
import { ArrowBackIos, Login } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useLoading } from "../../../contexts/loadingContext";

type HeaderProps = {
  title?: string;
  onBack?: () => void;
  small?: boolean;
  showLogin?: boolean;
  rightElement?: any;
};
export const Header: FC<HeaderProps> = (props) => {
  const { title, onBack, small, showLogin, rightElement } = props;
  const { showLoading } = useLoading();
  const { profile, authInitialized, auth } = useAuthentication();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Container>
      <StyledAppBar position="static">
        <StyledToolBar>
          <StyledRow>
            {profile && onBack && (
              <IconButton
                onClick={() => {
                  showLoading();
                  setTimeout(() => {
                    if (onBack) {
                      onBack();
                    } else {
                      navigate(-1);
                    }
                  }, 0);
                }}
                color="inherit"
              >
                <ArrowBackIos
                  htmlColor={theme.hotShotColors.primaryTextColor}
                />
              </IconButton>
            )}
            {showLogin && profile && (
              <IconButton onClick={() => navigate("/profile")} color="inherit">
                <Avatar
                  sx={{ width: 34, height: 34 }}
                  alt={profile?.displayName}
                  src={profile?.avatarUrl}
                />
              </IconButton>
            )}
            {showLogin && !profile && (
              <IconButton
                onClick={
                  onBack ||
                  (() => {
                    navigate("/login");
                  })
                }
                color="inherit"
              >
                <AccountCircleIcon
                  fontSize="large"
                  htmlColor={theme.hotShotColors.primaryTextColor}
                />
              </IconButton>
            )}

            {title && (
              <TextComponent
                onClick={profile?.isAppAdmin ? () => setOpen(true) : undefined}
                variant="medium"
                color={theme.hotShotColors.primaryTextColor}
              >
                &nbsp;{title}
              </TextComponent>
            )}
          </StyledRow>
          {!title && (
            <TextComponent
              variant="medium"
              color={theme.hotShotColors.primaryTextColor}
            >
              {t("APP_NAME")}
            </TextComponent>
          )}
          <RightContainer>
            {rightElement}
            {authInitialized && !auth && (
              <IconButton
                onClick={
                  onBack ||
                  (() => {
                    navigate("/login");
                  })
                }
                color="inherit"
              >
                <Login htmlColor={theme.hotShotColors.primaryTextColor} />
              </IconButton>
            )}
          </RightContainer>
        </StyledToolBar>
      </StyledAppBar>
      <Drawer
        open={open}
        closeDrawer={() => {
          setOpen(false);
        }}
        openDrawer={() => {
          setOpen(true);
        }}
      />
    </Container>
  );
};

const Container = styled.div``;

const StyledAppBar = styled(AppBar)`
  background-color: ${(props) => props.theme.hotShotColors.headerBackground};
  box-shadow: 0px 0px 10px 1px
    ${(props) => props.theme.hotShotColors.headerNavigationShadow};
`;
const StyledRow = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const StyledToolBar = styled(Toolbar)`
  padding-right: 0;
  padding-left: 6px;
  justify-content: space-between;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
