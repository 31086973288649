import React from "react";
import styled from "@emotion/styled";
import {
  LeaderboardEntry,
  LeagueExtended,
  PointsLeaderboardEntry,
} from "../../../types";
import { Badge } from "@mui/material";
import { useTheme } from "@emotion/react";
import { isFinished, isStarted } from "../../services/matchService";
import { North, South } from "@mui/icons-material";
import _ from "lodash";
import { LeagueProgress } from "./progressBarRow";
import TextComponent from "../styleGuide/atoms/textComponent.atom";

type LeaguePointsMyOverallProps = {
  leaderboardEntry: LeaderboardEntry;
  league: LeagueExtended;
  onClick?: () => void;
  showProgress?: boolean;
};
export const LeaguePointsMyOverall = ({
  leaderboardEntry,
  league,
  onClick = _.noop,
  showProgress,
}: LeaguePointsMyOverallProps) => {
  const theme = useTheme();
  const leagueDidntStart = league.matchesData?.every(
    (match) => !isStarted(match) && !isFinished(match),
  );
  if (!leaderboardEntry) {
    return null;
  }
  return (
    <RightHeaderElement onClick={onClick}>
      <LeagueDataContainer>
        <PointsScore>
          <Badge
            invisible={!(leaderboardEntry as PointsLeaderboardEntry).scoreDiff}
            color="success"
            badgeContent={`+${(leaderboardEntry as PointsLeaderboardEntry).scoreDiff}`}
          >
            <BadgeBody>
              <TextComponent
                variant="medium"
                color={theme.hotShotColors.primaryTextColor}
              >
                {(leaderboardEntry as PointsLeaderboardEntry).score}
              </TextComponent>
              <TextComponent
                variant="tiny"
                color={theme.hotShotColors.Grayscale50}
              >
                Pts
              </TextComponent>
            </BadgeBody>
          </Badge>
        </PointsScore>
        <Rank>
          <Badge
            color={leaderboardEntry.rankDiff! < 0 ? "error" : "success"}
            badgeContent={
              <BadgeContainer>
                {leaderboardEntry.rankDiff! < 0 ? (
                  <South sx={{ fontSize: 11 }} />
                ) : (
                  <North sx={{ fontSize: 11 }} />
                )}
                {leaderboardEntry.rankDiff}
              </BadgeContainer>
            }
            variant="standard"
            invisible={!leaderboardEntry.rankDiff}
          >
            <BadgeBody>
              <TextComponent
                variant="medium"
                color={theme.hotShotColors.primaryTextColor}
              >
                {leagueDidntStart ? "--" : `#${leaderboardEntry?.rank}`}
              </TextComponent>
              <TextComponent
                variant="tiny"
                color={theme.hotShotColors.Grayscale50}
              >
                Pos
              </TextComponent>
            </BadgeBody>
          </Badge>
        </Rank>
      </LeagueDataContainer>
      {showProgress && <LeagueProgress league={league} />}
    </RightHeaderElement>
  );
};

const RightHeaderElement = styled.div`
  display: flex;
  flex-direction: column;
`;

const BadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const Rank = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  gap: 3px;
`;

const CircleBox = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 16px;
`;

const LeagueDataContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-left: auto;
  margin-top: 2px;
  gap: 20px;
`;

const PointsScore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10px;
  justify-content: flex-end;
`;

const BadgeBody = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 3px;
`;
