import _ from "lodash";
import { useLeague } from "../../contexts/leagueContext";
import React, { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import styles from "./league.module.scss";
import { useLoading } from "../../contexts/loadingContext";
import { useAuthentication } from "../../contexts/AuthContext";
import { useScrollToTop } from "../../hooks/useScrollToTop";
import { useTheme } from "@emotion/react";
import { isOddsLeague } from "../../services/matchService";
import styled from "@emotion/styled";
import Space from "../../components/styleGuide/atoms/space.atom";
import TextComponent from "../../components/styleGuide/atoms/textComponent.atom";
import { Row } from "../../components/styleGuide/atoms/baseStyles";
import { useLeagueAnalytics } from "../../hooks/useAnalyticsHook";
import CoinComponent from "../../components/styleGuide/atoms/coinComponent.atom";
import { LeagueExtended, OddsLeaderboardEntry } from "../../../types";

export const OddsLeagueBalanceBar = () => {
  const { league: _league } = useLeague();
  const location = useLocation();
  const page = location.pathname.split("/").pop();
  const { leagueId, matchId, userId } = useParams();
  const { profile, auth } = useAuthentication();
  const theme = useTheme();

  const league = _league as LeagueExtended;
  if (!league || !page || !leagueId) {
    return null;
  }
  useLeagueAnalytics({ screenName: page, leagueId, matchId, userId });
  const [platform, setPlatform] = useState();

  const showBottomNav =
    page.indexOf("update") === -1 && page.indexOf("step") === -1;

  const lastLeaderboardUserEntry = league?.lastLeaderboard?.[
    auth.uid
  ] as OddsLeaderboardEntry;
  return isOddsLeague(league) && showBottomNav ? (
    <>
      <OddsContainer>
        <div className={styles.headerSummary}>
          <div className={styles.balanceWrapper}>
            <Row>
              <TextComponent
                style={{ minWidth: 130, marginRight: 2 }}
                variant="small"
                fontWeight={600}
              >
                {" "}
                Balance:{" "}
                {Math.round(
                  (
                    league?.lastLeaderboardThatFinished?.[
                      auth.uid
                    ] as OddsLeaderboardEntry
                  )?.balance,
                )}
                <CoinComponent />
              </TextComponent>
              <Row>
                <TextComponent variant="extra-small">Pending:</TextComponent>
                <Space width={5} />
                <TextComponent
                  variant="extra-small"
                  color={theme.hotShotColors.error}
                >
                  -{lastLeaderboardUserEntry?.pendingBetsAmount}
                  <CoinComponent />
                </TextComponent>
              </Row>
            </Row>
            <Row>
              <Row style={{ minWidth: 130, marginRight: 2 }}>
                <TextComponent variant="extra-small">Spendable:</TextComponent>
                <Space width={4} />
                <TextComponent variant="extra-small">
                  {Math.round(
                    lastLeaderboardUserEntry?.balance -
                      lastLeaderboardUserEntry?.pendingBetsAmount,
                  )}
                  <CoinComponent />
                </TextComponent>
              </Row>
              <Row className={styles.pendingBalanceItem}>
                <TextComponent variant="extra-small">Potential:</TextComponent>
                <Space width={2} />
                <TextComponent
                  variant="extra-small"
                  color={theme.hotShotColors.success}
                >
                  +{Math.round(lastLeaderboardUserEntry?.potential)}
                  <CoinComponent />
                </TextComponent>
              </Row>
            </Row>
          </div>
          <div className={styles.rankWrapper}>
            <TextComponent variant="small" fontWeight={600}>
              Rank: #{lastLeaderboardUserEntry?.rank}
            </TextComponent>
          </div>
        </div>
      </OddsContainer>
      <Space height={20} />
    </>
  ) : (
    <></>
  );
};

const OddsContainer = styled.div`
  background-color: ${(props) => props.theme.hotShotColors.headerBackground};
  position: fixed;
  top: 55px;
  z-index: 1;
  width: 100%;
`;
