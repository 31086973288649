import _ from "lodash";
import React, { Fragment } from "react";
import {
  Avatar,
  IconButton,
  List,
  Switch,
  FormControlLabel,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
} from "@mui/material";
import styles from "./settings.module.scss";
// import SelectWrapper from '../../../components/selectWrapper';
import { Delete, Add, Save } from "@mui/icons-material";
import { useLeague } from "../../../contexts/leagueContext";
import { useAuthentication } from "../../../contexts/AuthContext";
import { useSettingsScreen } from "./useSettingsScreen";
import ButtonComponent from "../../../components/styleGuide/atoms/buttonComponent.atom";
import { useNavigate } from "react-router-dom";
import TextComponent from "../../../components/styleGuide/atoms/textComponent.atom";
import TextInputComponent from "../../../components/styleGuide/atoms/textInputComponent.atom";
import Space from "../../../components/styleGuide/atoms/space.atom";
import styled from "@emotion/styled";
import TabsHeader from "../tabsHeader/tabsHeader";
import { LinkButton } from "../../../components/styleGuide/atoms/LinkComponent.Atom";
import { useTheme } from "@emotion/react";
import { isOddsLeague } from "../../../services/matchService";
import { LEAGUE_TYPE } from "../../../services/leagueProcessService";
import { Zoom } from "../../../components/common/zoomList/zoomList";
import { ScrollableProvider } from "../../../contexts/Scrollable";

const Settings = () => {
  const { league } = useLeague();
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    addUser,
    removeUser,
    save,
    showDeleteLeague,
    setLeagueName,
    leagueName,
    allowUserToJoin,
    setAllowUserToJoin,
    toggleExponentRanking,
    togglePointsLeagueType,
    leagueType,
    exponentRanking,
  } = useSettingsScreen();

  if (!league) {
    return null;
  }
  const showUserBets = (id: string) => {
    navigate("/league/" + league.id + "/users/" + id);
  };
  const [tab, setTab] = React.useState(0);

  const tabsItems = [
    { title: "Users", id: 0 },
    { title: "Settings", id: 1 },
  ];

  //TODO: Allow reenter
  //Add fields for
  // * Allow reenters (switch)
  // * Number of reenters allowed
  // * Reenters amount
  // * Max date to reenter

  return (
    <ScrollableProvider>
      <div className={styles.root}>
        <Zoom>
          <TabsHeader selectedId={tab} onClick={setTab} items={tabsItems} />
        </Zoom>
        <Space height={20} />
        <Space height={10} />
        {tab === 1 && (
          <Zoom delay={100}>
            <Container>
              <div style={{ flexGrow: 1 }}>
                <TextInputComponent
                  title="League Name"
                  onChange={(value) => setLeagueName(value)}
                  value={leagueName || ""}
                  placeholder="Name"
                />
                <SwitchFieldWrapper>
                  <StyledSwitch
                    edge="start"
                    checked={allowUserToJoin}
                    color="primary"
                    onChange={() => setAllowUserToJoin(!allowUserToJoin)}
                  />
                  <TextComponent variant="small" textAlign="left">
                    Allow Joining to league
                  </TextComponent>
                </SwitchFieldWrapper>
                {!isOddsLeague(league) && (
                  <SwitchFieldWrapper>
                    <StyledSwitch
                      edge="start"
                      checked={exponentRanking}
                      onChange={toggleExponentRanking}
                    />
                    <TextComponent variant="small" textAlign="left">
                      Allow Exponent Ranking
                    </TextComponent>
                  </SwitchFieldWrapper>
                )}
                {!isOddsLeague(league) && (
                  <SwitchFieldWrapper>
                    <StyledSwitch
                      edge="start"
                      checked={leagueType === LEAGUE_TYPE.ODDS_SCORE}
                      onChange={togglePointsLeagueType}
                    />
                    <TextComponent variant="small" textAlign="left">
                      Multiply Score By Odds
                    </TextComponent>
                  </SwitchFieldWrapper>
                )}
                <div className={styles.actions}>
                  <ButtonComponent
                    size={"normal"}
                    onClick={save}
                    variant="outline"
                  >
                    <Save />
                    Save
                  </ButtonComponent>
                </div>
              </div>
            </Container>
            <ButtonComponent
              size={"normal"}
              variant={"contained"}
              onClick={() => navigate("../update")}
            >
              {"Add Matches to your league"}
            </ButtonComponent>
            <div className={styles.delete}>
              <ButtonComponent
                size={"normal"}
                variant="outline"
                onClick={showDeleteLeague}
              >
                Delete
                <Delete />
              </ButtonComponent>
            </div>
          </Zoom>
        )}
        {tab === 0 && (
          <Zoom delay={100}>
            <InviteWrapper>
              <TextComponent variant="small">
                Share League Invite Code
              </TextComponent>
              <ButtonComponent
                size={"normal"}
                variant="text"
                onClick={() => {
                  navigator.clipboard.writeText(league.id);
                  setTimeout(() => alert("Copied to clipboard"), 100);
                }}
              >
                {league.id}
              </ButtonComponent>
              <TextComponent>-- OR --</TextComponent>
              <LinkButton
                url={`whatsapp://send?text=You are invited to play in ${league.name} Join https://${window.location.host}/?leagueCode=${league.id}`}
                displayLabel={"Invite friends to play on Whatsapp"}
              />
              <TextComponent>-- OR --</TextComponent>
              <ButtonComponent
                size={"normal"}
                variant="text"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://${window.location.host}/?leagueCode=${league.id}`,
                  );
                  setTimeout(() => alert("Copied to clipboard"), 100);
                }}
              >
                {"Copy league join link"}
              </ButtonComponent>
            </InviteWrapper>
            <List>
              {_.map(league.users, (item, key) => {
                const isAdmin = league.admin.uid === key;
                return (
                  <Fragment key={item.uid}>
                    <Container onClick={() => showUserBets(item.uid)} key={key}>
                      <ListItemAvatar>
                        <Avatar src={item.avatarUrl} />
                      </ListItemAvatar>
                      <ListItemText>
                        <TextComponent>{item.displayName}</TextComponent>
                      </ListItemText>
                      <ListItemSecondaryAction>
                        {!isAdmin && item.valid && (
                          <IconButton
                            onClick={() => removeUser(key)}
                            aria-label="Delete"
                          >
                            <Delete
                              style={{ color: theme.hotShotColors.Grayscale40 }}
                            />
                          </IconButton>
                        )}
                        {!isAdmin && !item.valid && (
                          <IconButton aria-label="Add">
                            <Add onClick={() => addUser(item)} />
                          </IconButton>
                        )}
                        {isAdmin && (
                          <TextComponent variant="medium">Admin</TextComponent>
                        )}
                      </ListItemSecondaryAction>
                    </Container>
                    <Space height={16} />
                  </Fragment>
                );
              })}
            </List>
          </Zoom>
        )}
      </div>
    </ScrollableProvider>
  );
};

const StyledSwitch = styled(Switch)`
  & .MuiSwitch-switchBase.Mui-checked {
    color: ${(props) => props.theme.hotShotColors.primary};
    &:hover {
      background-color: rgba(255, 0, 0, 0.08);
    }
  }
  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${(props) => props.theme.hotShotColors.primary};
  }
  & .MuiSwitch-track {
    background-color: ${(props) => props.theme.hotShotColors.primary};
  }
  & .MuiSwitch-thumb {
    color: ${(props) => props.theme.hotShotColors.primary};
  }
`;

const Container = styled(ListItem)`
  padding: 15px;
  justify-content: space-between;
  display: flex;
  background-color: ${(props) => props.theme.hotShotColors.cardBackground};
  border-radius: 10px;
`;

const InviteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SwitchFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export default Settings;
