import _ from "lodash";
import React, { useEffect, useState } from "react";
import styles from "./adminLeagues.module.scss";
import { Header } from "../../../components/common/header/header";

import { useConfirm } from "../../../contexts/confirmContext";
import { useLoading } from "../../../contexts/loadingContext";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../../contexts/AuthContext";
import { useDb } from "../../../contexts/firestoreContext";
import { League, LeagueExtended } from "../../../../types";
import { collectionGroup, getDocs, orderBy, query } from "firebase/firestore";
import { useFirestore } from "../../../contexts/firebaseContext";
import { LeaguesList } from "../../leagues/leaguesList";
import TabsHeader from "../../league/tabsHeader/tabsHeader";

const AdminLeagues = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { deleteLeague } = useDb();
  const firestore = useFirestore();
  const [allLeagues, setAllLeagues] = useState<League[]>([]);
  const { profile, auth } = useAuthentication();
  useLoading();
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (!profile?.isAppAdmin || !firestore) {
      return;
    }
    const qLeagues = query(
      collectionGroup(firestore, "leagues"),
      // orderBy("createdAt", "desc"),
    );
    getDocs(qLeagues)
      .then((leagueSnapshot) => {
        const leagues: League[] = [];
        leagueSnapshot.forEach((doc) => {
          leagues.push({ id: doc.id, ...doc.data() } as League);
        });
        const orderedLeagues = _.orderBy(
          leagues,
          (x: League) => new Date(x.createdAt),
          ["desc"],
        );
        setAllLeagues(orderedLeagues);
        console.log(orderedLeagues);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [profile]);
  if (!profile?.isAppAdmin) {
    navigate("/");
  }
  const handleChangeTab = (t: number) => {
    setTab(t);
  };

  const showLeague = (id: string) => {
    navigate(`/league/${id}/home`);
  };

  const activeLeagues = allLeagues.filter((x) => x.active);
  const inactiveLeagues = allLeagues.filter((x) => !x.active);
  const leaguesToShow = tab === 1 ? inactiveLeagues : activeLeagues;
  return (
    <div className={styles.root}>
      <Header onBack={() => navigate("/")} title="All Leagues" />
      <div className={styles.container}>
        {/* @ts-ignore */}
        <TabsHeader
          selectedId={tab}
          onClick={handleChangeTab}
          items={[
            { title: "Active", id: 0 },
            { title: "Inactive", id: 1 },
          ]}
        />

        <LeaguesList
          leagues={leaguesToShow as LeagueExtended[]}
          onLeagueClick={showLeague}
        />
        {leaguesToShow && leaguesToShow.length === 0 && <h2>No leagues yet</h2>}
      </div>
    </div>
  );
};

export default AdminLeagues;
