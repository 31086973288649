import {
  Typography,
  Avatar,
  Divider,
  ListItem,
  ListItemText,
} from "@mui/material";
import { PerformanceIndicatorText } from "../../../components/stats/performance/performanceIndicator";
import styles from "./leaderboard.module.scss";
import { useLeaderboardItem } from "./useLeaderboardItem";
import styled from "@emotion/styled";
import TextComponent from "../../../components/styleGuide/atoms/textComponent.atom";
import { useAuthentication } from "../../../contexts/AuthContext";
import CoinComponent from "../../../components/styleGuide/atoms/coinComponent.atom";
import Space from "../../../components/styleGuide/atoms/space.atom";
import { DateLeaderboard, OddsLeaderboardEntry } from "../../../../types";
import React, { FC } from "react";
import { useTheme } from "@emotion/react";

type LeaderboardItemOddsProps = {
  leaderboard: DateLeaderboard;
  item: OddsLeaderboardEntry;
  uid: string;
  showLive: boolean;
  onlySummary: boolean;
  itemIndex: number;
};
export const LeaderboardItemOdds: FC<LeaderboardItemOddsProps> = ({
  leaderboard,
  item,
  uid,
  showLive,
  onlySummary,
  itemIndex,
}) => {
  const { auth } = useAuthentication();
  const leaderboardItemData = useLeaderboardItem({ leaderboard, item, uid });
  if (!leaderboardItemData) {
    return null;
  }

  const { itemClass, showUserBets, users, rankToDisplay } = leaderboardItemData;
  const theme = useTheme();
  const isFirstPlace = leaderboard[uid].rank === 1 && leaderboard[uid].bets > 0;
  const isMyPlace = !isFirstPlace && uid === auth.uid;
  let rowStyle =
    !onlySummary && isFirstPlace
      ? { backgroundColor: theme.hotShotColors.lightBackground }
      : {};
  if (isMyPlace && !onlySummary) {
    rowStyle = { backgroundColor: theme.hotShotColors.lightBackground };
  }
  return (
    <div className={styles.oddsLeaderboardItemWrapper}>
      <Container
        style={rowStyle}
        className={itemClass}
        onClick={() => showUserBets(uid)}
      >
        <ListItemText style={{ width: 0 }}>
          <div className={styles.rankDiff}>
            <div className={styles.score}>
              <TextComponent>
                {showLive ? itemIndex : rankToDisplay || "---"}
              </TextComponent>
              {item.leftToBeEligible > 0 && (
                <div className={styles.noteligible} />
              )}
            </div>
            <div className={styles.rankDiffIndicator}>
              <PerformanceIndicatorText num={item.rankDiff!} />
            </div>
          </div>
        </ListItemText>
        <Avatar src={users[item.uid].avatarUrl} />
        <div className={styles.usernameOdds}>
          <TextComponent>{users[item.uid].displayName}</TextComponent>
        </div>

        <ListItemText className={styles.textItemRoot}>
          <TextComponent>{showLive ? item.liveBets : item.bets}</TextComponent>
        </ListItemText>
        <ListItemText className={styles.textItemRoot} style={{ width: 0 }}>
          <div className={styles.scoreDiff}>
            <TextComponent className={styles.score}>
              {showLive ? item.hits + item.liveHits : item.hits}
            </TextComponent>
            <div className={styles.rankDiffIndicator}>
              <PerformanceIndicatorText
                num={showLive ? item.liveHits : item.hitsDiff}
              />
            </div>
          </div>
        </ListItemText>
        <ListItemText className={styles.textItemRoot} style={{ width: 0 }}>
          <div className={styles.scoreDiff}>
            <TextComponent className={styles.score}>
              {Math.round(
                showLive ? item.balance + item.livePotential : item.balance,
              )}
            </TextComponent>
            <div className={styles.rankDiffIndicator}>
              <PerformanceIndicatorText
                num={showLive ? item.livePotential : item.balanceDiff}
              />
            </div>
          </div>
        </ListItemText>
      </Container>
    </div>
  );
};

const Container = styled(ListItem)`
  background-color: ${(props) => props.theme.hotShotColors.cardBackground};
`;
