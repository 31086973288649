import styled from "@emotion/styled";
import React, { FC, Fragment } from "react";
import Space from "../../../../components/styleGuide/atoms/space.atom";
import TextComponent from "../../../../components/styleGuide/atoms/textComponent.atom";
import {
  BookingsItem,
  Card_COLOR_TYPE,
  Goal,
  LiveMatchProps,
  Sub,
  WhistleItem,
} from "../lineUp/lineUpTypes";
import { useTheme } from "@mui/material/styles";
import {
  Column,
  Row,
} from "../../../../components/styleGuide/atoms/baseStyles";
import SummaryMiddleComponent, {
  SummaryStyledContainer,
} from "./summaryMiddleComponent";
import Sports from "@mui/icons-material/Sports";
import { NoDataMessage } from "../noDataMessage";
import { IconButton } from "@mui/material";

export const getPlayerName = (playerName: string) => {
  if (!playerName) {
    return {
      fullName: "",
      shortName: "",
    };
  }

  try {
    const playerNameStringArray = playerName?.split(" ", 2);
    const firstNameStringArray = playerNameStringArray[0]?.split("-");
    let playerOutSecondNameString = firstNameStringArray[0]?.[0];
    if (firstNameStringArray.length > 1) {
      playerOutSecondNameString = `${firstNameStringArray[0]?.[0]}.${firstNameStringArray[1]?.[0]}`;
    }
    return {
      fulName: playerName,
      firstName: playerNameStringArray[0],
      lastName: playerNameStringArray[1],
      shortName: playerNameStringArray[1]
        ? `${playerOutSecondNameString}.${playerNameStringArray[1]}`
        : playerName,
    };
  } catch (error) {
    return {
      fulName: "",
      firstName: "",
      lastName: "",
      shortName: "",
    };
  }
};

const GameSummary: FC<{ matchDetails: LiveMatchProps | undefined }> = ({
  matchDetails,
}) => {
  const theme = useTheme();
  const { substitutions, goals, bookings } = matchDetails as LiveMatchProps;

  if (!substitutions?.length && !goals?.length && !bookings?.length) {
    return <NoDataMessage />;
  }
  const bookingTypeArray = bookings.map((book) => {
    book.summaryEventType = "booking";
    return book;
  });
  const substitutionsTypeArray = substitutions.map((book) => {
    book.summaryEventType = "sub";
    return book;
  });
  const goalsTypeArray = goals.map((book) => {
    book.summaryEventType = "goal";
    return book;
  });

  const halfTime: WhistleItem = {
    summaryEventType: "whistle",
    minute: 45,
    text: "Half Time",
  };
  let gameEventArray: (Sub | Goal | BookingsItem | WhistleItem)[] =
    matchDetails?.score?.halfTime?.home !== null ? [halfTime] : [];
  gameEventArray = [
    ...bookingTypeArray,
    ...goalsTypeArray,
    ...substitutionsTypeArray,
    ...gameEventArray,
  ].sort((a, b) => a.minute - b.minute);

  return (
    <StyledContainer>
      {(matchDetails?.status === "IN_PLAY" ||
        matchDetails?.status === "PAUSED" ||
        matchDetails?.status === "FINISHED") && (
        <GameStartColumn>
          <Space height={3} />
          <TextComponent
            textAlign="center"
            fontWeight={600}
            color={theme.hotShotColors.primaryTextColor}
            variant="small"
          >
            {"Game Start"}
          </TextComponent>
          <Space height={3} />
          <TextComponent
            fontWeight={600}
            color={theme.hotShotColors.success}
            variant="extra-small"
          >
            {"0'"}
          </TextComponent>
          <SummaryStyledContainer>
            <Sports htmlColor={theme.hotShotColors.menuIconColor} />
          </SummaryStyledContainer>
        </GameStartColumn>
      )}
      {gameEventArray.map(
        (item: Sub | Goal | BookingsItem | WhistleItem, index) => {
          let eventText = "";
          let playerName = "";
          let playerOut = undefined;
          let teamId;
          let cardColor;
          if (item.summaryEventType === "goal") {
            const eventItem = { ...item } as Goal;
            eventText = "Goal";
            playerName = getPlayerName(eventItem.scorer.name).shortName;
            teamId = eventItem.team.id;
          }
          if (item.summaryEventType === "sub") {
            const eventItem = { ...item } as Sub;
            eventText = "substitutions";
            playerName = getPlayerName(eventItem.playerIn.name).shortName;
            playerOut = getPlayerName(eventItem.playerOut.name).shortName;
            teamId = eventItem.team.id;
          }
          if (item.summaryEventType === "booking") {
            const eventItem = { ...item } as BookingsItem;
            eventText =
              eventItem.card === "YELLOW"
                ? "yellow card"
                : eventItem.card === "YELLOW_RED"
                  ? "second yellow card"
                  : eventItem.card === "RED"
                    ? "red card"
                    : "card";
            playerName = getPlayerName(eventItem.player.name).shortName;
            teamId = eventItem.team.id;
            cardColor = eventItem.card;
          }

          if (item.summaryEventType === "whistle") {
            const eventItem = { ...item } as WhistleItem;
            eventText = "";
            playerName = eventItem.text;
          }

          const event: Event = {
            eventText,
            playerName,
            minute: item.minute,
            playerOut,
            card: cardColor,
          };
          const isHomeTeamEvent = matchDetails?.homeTeam.id === teamId;
          const sepHeight =
            (gameEventArray[index]?.minute -
              (gameEventArray[index - 1]?.minute ?? 0)) *
            3;

          return (
            <Fragment key={event.minute + event.playerName}>
              <StyledRow>
                <StyledColumn>
                  <Row style={{ flex: 1 }}>
                    {isHomeTeamEvent ? (
                      <EventComponent {...event} textAlignRight={true} />
                    ) : (
                      <Flex />
                    )}
                    <Space width={10} />
                    <SummaryMiddleComponent
                      event={event}
                      summaryEventType={item.summaryEventType}
                    />
                    <Space width={10} />
                    {!isHomeTeamEvent ? (
                      <EventComponent {...event} />
                    ) : (
                      <Flex />
                    )}
                  </Row>
                  {/* <Separator sepHeight={sepHeight} /> */}
                </StyledColumn>
              </StyledRow>
            </Fragment>
          );
        },
      )}
      {matchDetails?.status === "FINISHED" && (
        <GameStartColumn>
          <SummaryStyledContainer style={{ color: "green" }}>
            <Sports htmlColor={theme.hotShotColors.menuIconColor} />
          </SummaryStyledContainer>
          <Space height={3} />
          <TextComponent
            fontWeight={600}
            color={theme.hotShotColors.success}
            variant="extra-small"
          >
            {"90'"}
          </TextComponent>
          <Space height={3} />
          <TextComponent
            textAlign="center"
            fontWeight={600}
            color={theme.hotShotColors.primaryTextColor}
            variant="small"
          >
            {"Game End"}
          </TextComponent>
        </GameStartColumn>
      )}
      <Space height={130} />
    </StyledContainer>
  );
};
const GameStartColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

const Separator = styled.div<{ sepHeight: number }>`
  height: ${(props) => props.sepHeight}px;
  background-color: ${(props) => props.theme.hotShotColors.lineSummary};
  width: 4px;
  align-self: center;
  justify-self: center;
`;

export interface Event {
  minute: number;
  playerName: string;
  playerOut?: string;
  eventText: string;
  textAlignRight?: boolean;
  card?: Card_COLOR_TYPE;
}

const EventComponent = ({
  minute,
  playerName,
  playerOut,
  eventText,
  textAlignRight,
}: Event) => {
  const theme = useTheme();

  return (
    <StyledColumn textAlignRight={textAlignRight}>
      <TextComponent
        fontWeight={600}
        textAlign={textAlignRight ? "right" : "left"}
        color={theme.hotShotColors.success}
        variant="extra-small"
      >
        {minute + "'"}
      </TextComponent>
      <TextComponent
        color={theme.hotShotColors.primaryTextColor}
        variant="small"
      >
        {playerOut ? (
          <>
            <TextComponent
              color={theme.hotShotColors.Grayscale40}
              variant="small"
            >
              {"in"}
            </TextComponent>
            {`${playerName}`}
          </>
        ) : (
          playerName
        )}
      </TextComponent>
      {playerOut && (
        <TextComponent
          color={theme.hotShotColors.primaryTextColor}
          variant="small"
        >
          <TextComponent
            color={theme.hotShotColors.Grayscale40}
            variant="small"
          >
            {"out"}
          </TextComponent>
          {`${playerOut}`}
        </TextComponent>
      )}
      <TextComponent color={theme.hotShotColors.Grayscale40} variant="tiny">
        {eventText}
      </TextComponent>
    </StyledColumn>
  );
};
const Flex = styled.div`
  flex: 1;
`;

const StyledColumn = styled.div<{ textAlignRight?: boolean }>`
  flex: 1;
  display: flex;
  white-space: nowrap;
  overflow: scroll;
  flex-direction: column;
  text-align: ${(props) => (props.textAlignRight ? "right" : "left")};
`;

const StyledContainer = styled.div`
  margin-top: 10px;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export default GameSummary;
