import React, { FC, useState } from "react";
import styled from "@emotion/styled";
import { useTeams } from "../../../contexts/firestoreContext";
import { Team } from "../../../../types";

type TeamColorsAvatarProps = {
  team: any;
  size: number;
};
export const TeamColorsAvatar: FC<TeamColorsAvatarProps> = ({ team, size }) => {
  const teams = useTeams();
  const detailedTeam = team; //teams.find((x: Team) => x.id === team.id);
  const defaultImage =
    "https://firebasestorage.googleapis.com/v0/b/hotshotbet.appspot.com/o/questionmark.png?alt=media&token=28896123-197b-4fa1-b1aa-500e8064bcf6";
  const [imgSrc, setImgSrc] = useState(detailedTeam?.crest || defaultImage);

  const handleError = () => {
    setImgSrc(defaultImage);
  };
  return (
    <Image
      src={detailedTeam?.crest}
      key={detailedTeam?.id}
      alt={detailedTeam?.name}
      width={size}
      height={size}
      onError={handleError}
    />
  );
  // if (!detailedTeam) return null;
  // return <ColorsAvatar {...detailedTeam.pallete} size={size} />
};

type ColorsAvatarProps = {
  colors?: string[];
  vertical?: boolean;
  size?: number;
};
export const ColorsAvatar: FC<ColorsAvatarProps> = ({
  colors = ["#ffffff", "#000000", "green"],
  vertical = false,
  size = 150,
}) => {
  const filteredColors = colors.filter((x) => x);
  const boxSize = size / filteredColors.length;
  return (
    <Circle vertical={vertical} size={size}>
      {filteredColors.map((color, index) => (
        <ColorBox
          key={color + index}
          color={color}
          size={boxSize}
          vertical={vertical}
        />
      ))}
    </Circle>
  );
};

type CircleProps = {
  size: number;
  vertical?: boolean;
};
const Circle = styled.div<CircleProps>`
  display: flex;
  flex-direction: ${(props) => (props.vertical ? "row" : "column")};
  align-items: center;
  border-radius: 10px;
  border: solid 1px ${(props) => props.theme.hotShotColors.primaryTextColor};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  overflow: hidden;
`;

type ColorBoxProps = {
  color: string;
  size: number;
  vertical?: boolean;
};
const ColorBox = styled.div<ColorBoxProps>`
  height: ${(props) => (props.vertical ? "100%" : props.size + "px")};
  width: ${(props) => (props.vertical ? props.size + "px" : "100%")};
  background-color: ${(props) => props.color};
`;
const Image = styled.img`
  width: 30px;
  height: 30px;
`;
