import React, {
  createRef,
  FC,
  forwardRef,
  useEffect,
  useState,
  useMemo,
} from "react";
import styled from "@emotion/styled";
import TextComponent from "../../../components/styleGuide/atoms/textComponent.atom";
import { useTheme } from "@mui/material/styles";
import Space from "../../../components/styleGuide/atoms/space.atom";
import { ItemType } from "../../../../types";
import _ from "lodash";
import { playSound } from "../../../services/audioService";

const Item: FC<ItemType> = forwardRef(
  ({ isSelected, day, date, month, onClick, isToday }, ref) => {
    const theme = useTheme<any>();
    const itemColor = isSelected
      ? theme.hotShotColors.Grayscale60
      : theme.hotShotColors.Grayscale40;
    return (
      // @ts-ignore
      <div ref={ref}>
        <ItemWrapper onClick={onClick}>
          <ItemContainer isSelected={isSelected} today={!isSelected && isToday}>
            <TextComponent
              textTransform={"capitalize"}
              color={itemColor}
              variant={"extra-small"}
            >
              {day}
            </TextComponent>
            <TextComponent
              color={isSelected ? theme.hotShotColors.white : undefined}
              variant={"extra-small"}
              fontWeight={500}
            >
              {date}
            </TextComponent>
          </ItemContainer>
        </ItemWrapper>
        <Space width={7} />
      </div>
    );
  },
);

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 52px;
`;

const ItemContainer = styled.div<{ isSelected: boolean; today?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 39px;
  border: solid 1px ${(props) => (props.today ? "#555" : "none")};
  box-shadow: ${(props) =>
    props.isSelected ? "16px 16px 32px rgba(46, 47, 58, 0.08)" : undefined};
  border-radius: ${(props) =>
    props.isSelected || props.today ? "16px" : undefined};
  background-color: ${(props) =>
    props.isSelected ? props.theme.hotShotColors.primary : "transparent"};
`;
interface CalendarComponentProps {
  items: ItemType[];
  onClick: (id: string) => void;
  title: string;
  sticky?: boolean;
  selectedDay?: string | number;
}

const CalendarComponent: FC<CalendarComponentProps> = ({
  items,
  onClick,
  title,
  sticky = false,
  selectedDay,
}) => {
  const theme = useTheme<any>();
  const ref: any = createRef();
  const containerRef: any = createRef();
  const [shouldSmoothScroll, setShouldSmoothScroll] = useState(false);
  const [canPlaySound, setCanPlaySound] = useState(false);
  const sortedItemsArray = useMemo(() => {
    const itemsSorted = _.sortBy(items, (item) => new Date(item.id));
    itemsSorted.forEach((item) => (item.isSelected = item.id === selectedDay));
    return itemsSorted;
  }, [items, selectedDay]);

  // useEffect(() => {
  //   if (selectedDay) {
  //     canPlaySound ? playSound("tick") : setCanPlaySound(true);
  //   }
  // }, [selectedDay]);

  useEffect(() => {
    if (ref?.current) {
      const itemRect = ref?.current.getBoundingClientRect();
      const scrollerRect = containerRef?.current?.getBoundingClientRect();
      setTimeout(() => {
        if (!containerRef.current) {
          return;
        }
        containerRef.current.scrollTo({
          left:
            itemRect.left +
            containerRef.current.scrollLeft -
            (scrollerRect.width - itemRect.width) / 2,
          behavior: shouldSmoothScroll ? "smooth" : "instant",
        });
        setShouldSmoothScroll(true);
      }, 20);
    }
  }, [ref?.current, containerRef]);

  return (
    <Container style={{ position: sticky ? "sticky" : undefined }}>
      <Row ref={containerRef}>
        {sortedItemsArray.map((item) => {
          const isToday = item.id === new Date().toLocaleDateString("en-US");
          if (item.isSelected) {
            return (
              <Item
                key={item.id}
                ref={ref}
                {...item}
                onClick={() => onClick(item.id)}
                isToday={isToday}
              />
            );
          }
          return (
            <Item
              key={item.id}
              {...item}
              onClick={() => onClick(item.id)}
              isToday={isToday}
            />
          );
        })}
      </Row>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100vw - 10px);
  align-items: center;
  background-color: ${(props) => props.theme.hotShotColors.headerBackground};
  z-index: 1;
  position: sticky;
  top: 0;
  z-index: 110;
`;

const TitleRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
`;

export default CalendarComponent;
