import _ from "lodash";
import React, { createRef, useEffect, useState } from "react";
import styles from "./myProfile.module.scss";
import { Header } from "../../components/common/header/header";
import { ProfileLarge } from "../../components/common/profileLarge/profileLarge";
import { Snackbar, Zoom } from "@mui/material";
import { useLoading } from "../../contexts/loadingContext";
import { useAuthentication } from "../../contexts/AuthContext";
import { useFirebase, useFirestore } from "../../contexts/firebaseContext";
import { useNavigate } from "react-router-dom";
import { useDb, useDeleteUser } from "../../contexts/firestoreContext";
import ButtonComponent from "../../components/styleGuide/atoms/buttonComponent.atom";
import TextInputComponent from "../../components/styleGuide/atoms/textInputComponent.atom";
import { useTranslation } from "react-i18next";
import { useAnalytics } from "../../hooks/useAnalyticsHook";
import { useConfirm } from "../../contexts/confirmContext";
import Space from "../../components/styleGuide/atoms/space.atom";
import styled from "@emotion/styled";
import { useRateAppPrompt } from "../../contexts/rateAppPromptContext";
import { useLeagues } from "../../contexts/leaguesContext";

const MyProfile = () => {
  const { leagues } = useLeagues();
  const { hideLoading, showLoading } = useLoading();
  const { auth, profile, setProfile, signOut } = useAuthentication();
  const firebase = useFirebase();
  const { saveImage, updateUser } = useDb();
  const firestore = useFirestore();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { t } = useTranslation();
  const deleteUser = useDeleteUser();
  useAnalytics({ screenName: "my_profile" });
  const fileUploadRef = createRef();
  const [nickname, setNickname] = useState(profile?.displayName || "");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { showRateApp } = useRateAppPrompt();

  useEffect(() => {
    if (profile) {
      setNickname(profile.displayName);
    }
  }, [profile]);

  if (!profile) {
    return null;
  }
  const updateImage = async (file: File) => {
    try {
      showLoading();
      const imageUrl = await saveImage(file, "user_" + auth.uid, firebase);
      await updateUser(auth.uid, { avatarUrl: imageUrl }, leagues);
      setProfile({ ...profile, avatarUrl: imageUrl });
      hideLoading();
    } catch (e) {
      alert("Error, Error changing image");
      console.log({ e });
      hideLoading();
    }
  };

  const updateUserData = async () => {
    showLoading();
    await updateUser(auth.uid, { displayName: nickname }, leagues);
    setProfile({ ...profile, displayName: nickname });
    setSnackbarOpen(true);
    hideLoading();
  };

  const editImage = (files: File[]) => {
    const file = _.get(files, "target.files[0]");
    updateImage(file!);
  };
  const showFileDialog = () => {
    (fileUploadRef.current as any).click();
  };

  const showDeleteUserModal = () => {
    //@ts-expect-error
    confirm({
      title: "Delete User",
      message:
        "This will delete all of your data, are you sure you want to proceed?",
      okText: "Delete",
      hideTextField: true,
      okHandler: deleteUser,
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className={styles.root}>
      <Header onBack={() => navigate(-1)} title={profile.displayName} />
      <div className={styles.container}>
        <Zoom in={true} style={{ transitionDelay: "0" }}>
          <div>
            <ProfileLarge
              enableEditMessage={true}
              enableEditImage={true}
              picture={profile.avatarUrl}
              message={profile.tagline}
              user={profile}
            />
            <ButtonComponent
              variant="text"
              onClick={showFileDialog}
              size="normal"
            >
              Change Picture
            </ButtonComponent>
          </div>
        </Zoom>
        <div>
          <TextInputComponent
            title={t<string>("Nickname")}
            placeholder={t<string>("Enter your nickname")}
            value={nickname}
            onChange={setNickname}
          />
          <ButtonComponent
            onClick={updateUserData}
            variant="contained"
            size="normal"
          >
            Save
          </ButtonComponent>
          <Space height={200} />
          <BottomButtons>
            <ButtonComponent
              width={200}
              size="normal"
              variant="outline"
              onClick={signOut}
            >
              Log out
            </ButtonComponent>
            <ButtonComponent
              width={200}
              size="small"
              variant="outline"
              outlineColor="red"
              onClick={showDeleteUserModal}
            >
              Delete Account
            </ButtonComponent>
            {profile.isAppAdmin && (
              <ButtonComponent
                width={200}
                size="small"
                variant="outline"
                onClick={() => showRateApp()}
              >
                Rate App
              </ButtonComponent>
            )}
            {profile.isAppAdmin && (
              <ButtonComponent
                width={200}
                outlineColor="green"
                size="small"
                variant="outline"
                onClick={() =>
                  (window.location.href = "https://hotshotbet-dev.web.app")
                }
              >
                DEV ENV
              </ButtonComponent>
            )}
          </BottomButtons>
        </div>
      </div>
      <input
        type="file"
        id="fileUpload"
        style={{ opacity: 0, height: 0, width: 0 }}
        //@ts-expect-error
        ref={fileUploadRef}
        //@ts-expect-error
        onChange={editImage}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message="Changes may take a few minutes to reflect."
      />
    </div>
  );
};

export default MyProfile;

const BottomButtons = styled.div`
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  width: 100vw;
  padding: 20px;
  box-sizing: border-box;
`;
