import { Match } from "../../types";

const matchCache: { [key: number]: any } = {};
const head2HeadCache: { [key: number]: any } = {};
const standingsCache: { [key: number]: any } = {};
const scorersCache: { [key: number]: any } = {};
const personCache: { [key: number]: any } = {};

let interval: any;

export const getMatchFullData = async (id: number) => {
  if (matchCache[id]) {
    return matchCache[id];
  }
  clearTimeout(interval);
  interval = setTimeout(
    () => {
      matchCache[id] = null;
      head2HeadCache[id] = null;
      standingsCache[id] = null;
      scorersCache[id] = null;
      personCache[id] = null;
    },
    1000 * 60 * 5,
  );
  const result = await fetch(
    `https://us-central1-hotshotbet.cloudfunctions.net/getMatch?id=${id}`,
  );
  const data = await result.json();
  matchCache[id] = data;
  return data;
};

export const getHead2HeadData = async (id: number) => {
  if (head2HeadCache[id]) {
    return head2HeadCache[id];
  }
  const result = await fetch(
    `https://us-central1-hotshotbet.cloudfunctions.net/getHead2Head?matchId=${id}`,
  );
  const data = await result.json();
  head2HeadCache[id] = data;
  return data;
};

export const getStandingsData = async (id: number) => {
  if (standingsCache[id]) {
    return standingsCache[id];
  }
  const result = await fetch(
    `https://us-central1-hotshotbet.cloudfunctions.net/getStandings?competitionId=${id}`,
  );
  const data = await result.json();
  standingsCache[id] = data;
  return data;
};

export const getMatchesData = async (dateFrom: string, dateTo: string) => {
  const result = await fetch(
    `https://us-central1-hotshotbet.cloudfunctions.net/getMatches?dateFrom=${dateFrom}&dateTo=${dateTo}`,
  );
  const data = await result.json();
  return data as Match[];
};

export const getScorersData = async (competitionId: number) => {
  if (scorersCache[competitionId]) {
    return scorersCache[competitionId];
  }
  const result = await fetch(
    `https://us-central1-hotshotbet.cloudfunctions.net/getScorers?competitionId=${competitionId}`,
  );
  const data = await result.json();
  scorersCache[competitionId] = data;
  return data;
};

export const getPersonsData = async (personId: number) => {
  if (personCache[personId]) {
    return personCache[personId];
  }
  const result = await fetch(
    `https://us-central1-hotshotbet.cloudfunctions.net/getPerson?id=${personId}`,
  );
  const data = await result.json();
  personCache[personId] = data;
  return data;
};
