import {
  CommonLeaderboardEntry,
  DateLeaderboard,
  Leaderboard,
  LeaderboardEntry,
  League,
  Match,
  OddsBet,
  OddsLeaderboardEntry,
  PointsBet,
  PointsLeaderboardEntry,
} from "../../types";
import {
  getBetPoints,
  getOddBetResult,
  getOddsScorePoints,
  isBetCorrect,
  isBetExact,
} from "./betService";
import {
  isCancelled,
  isFinished,
  isOddsLeague,
  isOddsScoreLeague,
  isPointsLeague,
  isStarted,
  USER_ODDS_BET,
} from "./matchService";
import _ from "lodash";

type GenerateDynamicLeaderboard = (
  league: League,
  leagueMatches: Match[],
) => GenerateDynamicLeaderboardResult;

type GenerateDynamicLeaderboardResult = {
  dynamicLeaderboard: Leaderboard;
  lastLeaderboard?: DateLeaderboard;
  lastLeaderboardThatFinished?: DateLeaderboard;
  lastLeaderboardThatStarted?: DateLeaderboard;
};

export const generateDynamicLeaderboard: GenerateDynamicLeaderboard = (
  league: League,
  leagueMatches: Match[],
) => {
  const validMatches = leagueMatches.filter((x) => !isCancelled(x));
  const matchesGroupedByDay = _.groupBy(validMatches, (match) =>
    (match.date as Date).toLocaleDateString("en-US"),
  );
  const matchesGroupedByDayKeys = _.orderBy(
    Object.keys(matchesGroupedByDay),
    (x) => new Date(x),
  );
  const dynamicLeaderboard: Leaderboard = {};
  const result: GenerateDynamicLeaderboardResult = {
    dynamicLeaderboard,
    lastLeaderboard: undefined,
    lastLeaderboardThatFinished: undefined,
    lastLeaderboardThatStarted: undefined,
  };
  let untilNowMatches: Match[] = [];
  _.forEach(matchesGroupedByDayKeys, (day, index) => {
    const matches = matchesGroupedByDay[day];
    untilNowMatches = [...untilNowMatches, ...matches];
    const dayLeaderboard = generateLeaderboard(league, untilNowMatches);
    dynamicLeaderboard[day] = _.mapKeys(dayLeaderboard, "uid");
    if (index === 0) {
      _.forEach(dayLeaderboard, (user) => initializeDiff(user, league));
    } else if (result.lastLeaderboard) {
      try {
        _.forEach(
          dayLeaderboard,
          (user: CommonLeaderboardEntry) =>
            result.lastLeaderboard &&
            updateDiff(result.lastLeaderboard, user, league),
        );
      } catch (e) {
        console.error("generateDynamicLeaderboard Error", e);
      }
    }
    result.lastLeaderboard = dynamicLeaderboard[day] || result.lastLeaderboard;
    if (matches.find((x) => isFinished(x))) {
      result.lastLeaderboardThatFinished = dynamicLeaderboard[day];
    } else if (
      !matches.find((x) => isFinished(x)) &&
      !result.lastLeaderboardThatFinished
    ) {
      result.lastLeaderboardThatFinished = result.lastLeaderboard; //For new and fresh league
    }

    if (matches.find((x) => isStarted(x))) {
      result.lastLeaderboardThatStarted = dynamicLeaderboard[day];
    } else if (
      !matches.find((x) => isStarted(x)) &&
      !result.lastLeaderboardThatStarted
    ) {
      result.lastLeaderboardThatStarted = result.lastLeaderboard; //For new and fresh league
    }
  });
  return result;
};

export const generateLeaderboard = (league: League, matches: Match[]) => {
  const leaderboard = createLeaderboard(league);
  calculateLeaderboard(league, leaderboard, matches);
  const validLeaderboard = _.filter(leaderboard, (x) =>
    _.get(league, `users[${x.uid}].valid`),
  );

  // _.forEach(league.users, (user, userId) => {
  //   if (user?.reenters) {
  //     user.reenters?.forEach((reenter) => {
  //       if (
  //         matches.find(
  //           (x) =>
  //             new Date(new Date(reenter.date).toDateString()) <=
  //             new Date(x.date),
  //         )
  //       ) {
  //         leaderboard[userId].balance += reenter?.amount || 0;
  //       }
  //     });
  //   }
  // });
  const sortedLeaderboard = sortLeaderboard(league, validLeaderboard);
  return sortedLeaderboard;
};

type EmptyLeaderboardEntryPointsFunc = (uId: string) => PointsLeaderboardEntry;

const emptyLeaderboardEntryPoints: EmptyLeaderboardEntryPointsFunc = (
  uId: string,
) => {
  return {
    uid: uId,
    bets: 0,
    correct: 0,
    exact: 0,
    score: 0,
    rank: 1,
    correctDiff: 0,
    exactDiff: 0,
    rankDiff: 0,
    scoreDiff: 0,
  };
};

type EmptyLeaderboardEntryOddsFunc = ({
  userId,
  league,
}: {
  userId: string;
  league: League;
}) => OddsLeaderboardEntry;
const emptyLeaderboardEntryOdds: EmptyLeaderboardEntryOddsFunc = ({
  userId,
  league,
}) => {
  return {
    uid: userId,
    bets: 0, //Number of bet made for finished matches
    hits: 0, //Number of hits for finished matches
    liveBets: 0, //Number of bet made for matches that are not finished
    liveHits: 0, //Number of hits for matches that are not finished
    balance: league.initialBalance, //Initial balance of league
    pendingBetsAmount: 0, //Bet amount for matches that are not finished
    livePotential: 0, //Balance after all bets are finished
    potential: 0, //Potential balance if all bets are correct,
    leftToBeEligible: league.initialBalance, //Amount to be eligible for prize
    hitsDiff: 0, //Difference in hits between this and last leaderboard
    balanceDiff: 0, //Difference in balance between this and last leaderboard
  };
};

const initializeDiff = (user: CommonLeaderboardEntry, league: League) => {
  if (isOddsLeague(league)) {
    const oddsUser = user as OddsLeaderboardEntry;
    oddsUser.hitsDiff = oddsUser.hits || 0;
    oddsUser.balanceDiff = oddsUser.balance - league.initialBalance || 0;
  } else {
    const pointsUser = user as PointsLeaderboardEntry;
    pointsUser.correctDiff = pointsUser.correct;
    pointsUser.exactDiff = pointsUser.exact;
    pointsUser.rankDiff = pointsUser?.rank || 0;
    pointsUser.scoreDiff = pointsUser.score;
  }
};

const updateDiff = (
  lastLeaderboard: DateLeaderboard,
  user: CommonLeaderboardEntry,
  league: League,
) => {
  const uid = user.uid;
  if (isOddsLeague(league)) {
    const userLeaderboardEntry = lastLeaderboard[uid] as OddsLeaderboardEntry;
    const oddsUser = user as OddsLeaderboardEntry;
    oddsUser.hitsDiff = oddsUser.hits - userLeaderboardEntry.hits;
    oddsUser.balanceDiff = oddsUser.balance - userLeaderboardEntry.balance;
  } else {
    const userLeaderboardEntry = lastLeaderboard[uid] as PointsLeaderboardEntry;
    const pointsUser = user as PointsLeaderboardEntry;
    pointsUser.correctDiff = pointsUser.correct - userLeaderboardEntry.correct;
    pointsUser.exactDiff = pointsUser.exact - userLeaderboardEntry.exact;
    pointsUser.rankDiff = (userLeaderboardEntry.rank || 0) - (user.rank || 0);
    pointsUser.scoreDiff = pointsUser.score - userLeaderboardEntry.score;
  }
};

const calculateLeaderboard = (
  league: League,
  leaderboard: DateLeaderboard,
  matches: Match[],
) => {
  if (isOddsLeague(league)) {
    _.forEach(matches, (match) => {
      _.forEach(league.users, (user, userId) => {
        let leaderboardUserEntry = leaderboard[userId] as OddsLeaderboardEntry;
        const bets = user.bets;
        const bet = bets[match.id] as OddsBet;
        if (isFinished(match) && leaderboardUserEntry) {
          bet?.["homeWin"] && leaderboardUserEntry.bets++;
          bet?.["draw"] && leaderboardUserEntry.bets++;
          bet?.["awayWin"] && leaderboardUserEntry.bets++;
        }
        if (isStarted(match) && leaderboardUserEntry) {
          bet?.["homeWin"] && leaderboardUserEntry.liveBets++;
          bet?.["draw"] && leaderboardUserEntry.liveBets++;
          bet?.["awayWin"] && leaderboardUserEntry.liveBets++;
        }

        leaderboardUserEntry = leaderboardUserEntry || {};
        leaderboardUserEntry.leftToBeEligible -= bet?.["homeWin"]?.amount || 0;
        leaderboardUserEntry.leftToBeEligible -= bet?.["draw"]?.amount || 0;
        leaderboardUserEntry.leftToBeEligible -= bet?.["awayWin"]?.amount || 0;
        leaderboardUserEntry.leftToBeEligible = Math.max(
          0,
          leaderboardUserEntry.leftToBeEligible,
        );

        const result = getOddBetResult(bet, match);
        const maxPotentialForBet = Math.max(
          result["homeWin"]?.potential || 0,
          result["draw"]?.potential || 0,
          result["awayWin"]?.potential || 0,
        );
        leaderboardUserEntry.potential += maxPotentialForBet;
        _.forEach(result, (result, type) => {
          const { hit, amount, livePotential = 0, liveHit } = result;
          if (isStarted(match) && !isFinished(match)) {
            leaderboardUserEntry.livePotential += livePotential;
            leaderboardUserEntry.liveHits += liveHit ? 1 : 0;
          }
          if (!isFinished(match)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            leaderboardUserEntry.pendingBetsAmount += bet?.[type]?.amount || 0;
          } else {
            leaderboardUserEntry.hits += hit ? 1 : 0;
            leaderboardUserEntry.balance += amount;
          }
        });
        leaderboard[userId] = leaderboardUserEntry;
      });
    });
  } else {
    _.forEach(matches, (match) => {
      if (!isStarted(match)) {
        return;
      }

      _.forEach(league.users, (user, userId) => {
        if ((match.date as number) < user.dateAdded) {
          //Dont count matches that started before user joined the league
          return;
        }
        const bets = user.bets;
        const bet = {
          ...bets[match.id],
        } as PointsBet;
        bet.homeScore = bet.homeScore || 0;
        bet.awayScore = bet.awayScore || 0;
        const leaderboardUserEntry = leaderboard[
          userId
        ] as PointsLeaderboardEntry;
        if (!leaderboardUserEntry) {
          return;
        }
        leaderboardUserEntry.bets += 1;
        leaderboardUserEntry.exact += isBetExact(bet, match) ? 1 : 0;
        leaderboardUserEntry.correct +=
          !isBetExact(bet, match) && isBetCorrect(bet, match) ? 1 : 0;
        if (isPointsLeague(league)) {
          leaderboardUserEntry.score += getBetPoints(bet, match);
        } else if (isOddsScoreLeague(league)) {
          leaderboardUserEntry.score += getOddsScorePoints(bet, match);
        }
      });
    });
  }
};

const sortLeaderboard = (
  league: League,
  validLeaderboard: LeaderboardEntry[],
) => {
  let sortedLeaderboard;
  if (!isOddsLeague(league)) {
    sortedLeaderboard = _.orderBy(
      validLeaderboard,
      (x) => (x as PointsLeaderboardEntry).score,
      "desc",
    );
    let lastScore = 0;
    let lastRank = 1;
    _.forEach(sortedLeaderboard, (item, index) => {
      const isScoreChanged =
        lastScore > 0 && lastScore !== (item as PointsLeaderboardEntry).score;
      item.rank = isScoreChanged ? index + 1 : lastRank;
      if (isScoreChanged) {
        lastRank = index + 1;
      }
      lastScore = (item as PointsLeaderboardEntry).score;
    });
    sortedLeaderboard = _.orderBy(sortedLeaderboard, (x) => x.rank);
  } else {
    sortedLeaderboard = _.orderBy(
      validLeaderboard,
      (x) => (x as OddsLeaderboardEntry).balance,
      "desc",
    );
    let lastBalance = 0;
    let lastRank = 1;
    _.forEach(sortedLeaderboard, (item, index) => {
      const isBalanceChanged =
        lastBalance > 0 &&
        lastBalance !== (item as OddsLeaderboardEntry).balance;
      item.rank = isBalanceChanged ? index + 1 : lastRank;
      if (isBalanceChanged) {
        lastRank = index + 1;
      }
      lastBalance = (item as OddsLeaderboardEntry).balance;
    });
    sortedLeaderboard = _.orderBy(sortedLeaderboard, (x) => x.rank);
  }

  return sortedLeaderboard;
};

export const createLeaderboard = (league: League) => {
  const leaderboard: DateLeaderboard = {};

  _.forEach(league.users, (user, userId) => {
    if (!user || !user.valid) {
      return;
    }
    if (isOddsLeague(league)) {
      leaderboard[userId] = emptyLeaderboardEntryOdds({ userId, league });
    } else {
      leaderboard[userId] = emptyLeaderboardEntryPoints(userId);
    }
  });
  return leaderboard;
};
