import React, { FC } from "react";
import _ from "lodash";
import { DistributionPanel } from "./distributionPanel";
import { useTheme } from "@emotion/react";

type UserStatsDistributionPanelProps = {
  userRank: any;
  title?: string;
};
export const UserStatsDistributionPanel: FC<
  UserStatsDistributionPanelProps
> = ({ userRank, title }) => {
  const theme = useTheme();
  const { bets, correct, exact } = userRank;

  const userStatsDistributionReadings = [
    {
      name: "Exact",
      value: Math.floor((exact * 100) / bets),
      color: theme.hotShotColors.thirdBackground,
    },
    {
      name: "Correct",
      value: Math.floor((correct * 100) / bets),
      color: theme.hotShotColors.success,
    },
    {
      name: "Miss",
      value: Math.floor(((bets - correct - exact) * 100) / bets),
      color: theme.hotShotColors.error,
    },
  ];
  return (
    <DistributionPanel readings={userStatsDistributionReadings} title={title} />
  );
};
