import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/common/header/header";
import { MatchesList } from "../../components/matchComponents/matches/matchesList";
import TextComponent from "../../components/styleGuide/atoms/textComponent.atom";
import { useAuthentication } from "../../contexts/AuthContext";
import { CreateLeagueContext } from "../../contexts/createLeagueContext";
import {
  useCompetitions,
  useLeagueMatches,
} from "../../contexts/firestoreContext";
import { useLoading } from "../../contexts/loadingContext";
import { UpdateLeagueMatchesContext } from "../../contexts/updateLeagueMatchesContext";
import { isStarted } from "../../services/matchService";
import { MobileStepperWizard } from "./components/mobileStepperWizard";
import styles from "./updateLeagueMatchesStep3.module.scss";

export const UpdateLeagueMatchesStep3 = () => {
  const competitions = useCompetitions();
  const { auth } = useAuthentication();
  const { matches } = useLeagueMatches();
  const { showLoading, hideLoading } = useLoading();
  const navigate = useNavigate();
  const [fromPickerOpen, setFromPickerOpen] = useState(false);
  const [toPickerOpen, setToPickerOpen] = useState(false);

  const { selectedMatches, updateSelectedMatches, saveLeague } = useContext(
    UpdateLeagueMatchesContext,
  );

  const selectedFilteredMatches = selectedMatches.filter((x) => !isStarted(x));

  const onMatchClick = (matchId: number) => {
    if (selectedMatches.find((x) => x.id === matchId)) {
      updateSelectedMatches([
        ...selectedMatches.filter((x) => x.id !== matchId),
      ]);
    }
  };

  const finishAndSave = async () => {
    showLoading();
    await saveLeague();
    hideLoading();
    navigate("../../home");
  };
  return (
    <>
      <Header onBack={() => navigate(-1)} title={"Update Matches"} />
      <Box className={styles.container}>
        <GreetingContainer>
          <TextComponent component="div" sx={{ flexGrow: 1 }}>
            Take a last glimpse of your league matches.If all looks good, press
            Next.
          </TextComponent>
        </GreetingContainer>

        <PaddingContainer>
          <MatchesList
            removeable={true}
            leagueMatches={selectedFilteredMatches}
            auth={auth}
            currentUserAuth={auth}
            showAddRemove
            onClick={onMatchClick}
          />
          <MobileStepperWizard
            activeStep={1}
            maxSteps={3}
            handleBack={() => navigate(-1)}
            handleNext={() => finishAndSave()}
            nextText="Finish"
          />
        </PaddingContainer>
      </Box>
    </>
  );
};

const GreetingContainer = styled.div`
  padding: 20px;
  background-color: ${(props) => props.theme.hotShotColors.headerBackground};
`;
const PaddingContainer = styled.div``;
