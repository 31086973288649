import React, { FC } from "react";
import styled from "@emotion/styled";

interface LinkButtonComponentProps {
  url: string;
  displayLabel: string;
  target?: "_blank" | "_self";
  onClick?: () => any;
}

export const LinkButton: FC<LinkButtonComponentProps> = ({
  url,
  displayLabel,
  target = "_blank",
  onClick,
}) => {
  return (
    <ExternalLink
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      href={url}
      target={target}
    >
      {displayLabel}
    </ExternalLink>
  );
};

const ExternalLink = styled.a`
  text-decoration: none;
  border-radius: 20px;
  border-color: ${(props) => props.theme.hotShotColors.primary};
  border-width: 1px;
  border-style: solid;
  color: ${(props) => props.theme.hotShotColors.primary};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  margin: 10px;
`;
