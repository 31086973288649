import React, { FC, useEffect } from "react";
import { NativeSelect } from "@mui/material";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";

type ScorePickerProps = {
  value: number | undefined;
  leftComponent?: boolean;
  onSelect: (value: number, e?: any) => void;
};

export const ScorePicker: FC<ScorePickerProps> = ({
  value,
  leftComponent,
  onSelect,
}) => {
  const betOptions = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];

  const theme = useTheme();
  const textColor = {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "15px",
    color: theme.hotShotColors.primaryTextColor,
  };

  const onSelectHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onSelect(e.target.value);
  };
  if (value === undefined) {
    betOptions.unshift("--");
  }
  return (
    <BetComponentContainer
      leftComponent={leftComponent}
      animated={value === undefined}
      onClick={(e) => e.stopPropagation()}
    >
      <NativeSelect
        disableUnderline
        value={value === undefined ? "--" : value}
        IconComponent={() => <></>}
        inputProps={{
          style: {
            padding: 0,
            ...textColor,
            textAlign: "center",
            fontSize: 20,
            textAlignLast: "center",
          },
        }}
        onChange={onSelectHandler}
      >
        {betOptions.map((item) => {
          return (
            <option key={item} value={item}>
              {item}
            </option>
          );
        })}
      </NativeSelect>
    </BetComponentContainer>
  );
};

type BetComponentContainerProps = {
  leftComponent?: boolean;
  animated?: boolean;
};
export const BetComponentContainer = styled.div<BetComponentContainerProps>`
  box-sizing: border-box;
  width: 35px;
  height: 35px;
  background: ${(props) => props.theme.hotShotColors.background};
  border: 1px solid #eaedf1;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  animation: ${(props) => (props.animated ? "rotate 2s infinite" : "none")};
  cursor: pointer;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-15deg);
    }
    80% {
      transform: rotate(15deg);
    }
    85% {
      transform: rotate(-15deg);
    }
    90% {
      transform: rotate(15deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;
