import _ from "lodash";
import {
  Avatar,
  AvatarGroup,
  Badge,
  LinearProgress,
  List,
  Zoom,
} from "@mui/material";
import TextComponent from "../../components/styleGuide/atoms/textComponent.atom";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { LeagueExtended, Match, PointsLeaderboardEntry } from "../../../types";
import { useAuthentication } from "../../contexts/AuthContext";
import { isFinished, isStarted } from "../../services/matchService";
import moment from "moment";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { useTheme } from "@emotion/react";
import { LeagueProgress } from "../../components/common/progressBarRow";
import { LeaguePointsMyOverall } from "../../components/common/LeaguePointsMyOverall";

type LeaguesListProps = {
  leagues: LeagueExtended[];
  onLeagueClick: (id: string) => void;
};
export const LeaguesList: FC<LeaguesListProps> = ({
  leagues,
  onLeagueClick,
}) => {
  const theme = useTheme();
  const { auth } = useAuthentication();
  const hasLeagues = leagues.length > 0;
  if (!hasLeagues) {
    return null;
  }

  return (
    <List>
      {_.map(leagues, (league, key) => {
        const {
          lastLeaderboardThatStarted = {},
          dynamicLeaderboard = {},
          users = {},
        } = league;
        const activeUsers = Object.values(users).filter((user) => user.valid);
        const usersCount = activeUsers.length;
        const myLeaderboardEntry = lastLeaderboardThatStarted?.[auth.uid];
        const leagueEnded = league.matchesData?.every((match) =>
          isFinished(match),
        );
        const leagueDidntStart = league.matchesData?.every(
          (match) => !isStarted(match) && !isFinished(match),
        );
        const days =
          Object.keys(dynamicLeaderboard).filter(
            (day) => dynamicLeaderboard[day]?.[auth.uid]?.rank,
          ) || [];
        const ranks = _.map(
          days,
          (key) => dynamicLeaderboard[key]?.[auth.uid].rank || 0,
        );
        const showSparkline = ranks.some((rank) => rank != 1);
        const leagueInProgress = !leagueEnded && !leagueDidntStart;
        const firstPlace = _.first(
          Object.values(lastLeaderboardThatStarted),
        ) as PointsLeaderboardEntry;

        const amIFirst = myLeaderboardEntry?.rank === 1;
        const lastPlace = _.last(
          Object.values(lastLeaderboardThatStarted),
        ) as PointsLeaderboardEntry;

        const amILast = lastPlace?.uid === auth.uid;
        const scoreGapFromFirstPlace =
          firstPlace?.score -
          (myLeaderboardEntry! as PointsLeaderboardEntry)?.score;

        const scoreGapFromLastPlace =
          (myLeaderboardEntry! as PointsLeaderboardEntry)?.score -
          lastPlace?.score;
        const firstMatch = _.minBy(
          league.matchesData,
          (match: Match) => match.date,
        );
        const timeToFirstMatch = moment(firstMatch?.utcDate).fromNow();

        const liveMatches =
          league.matchesData?.filter(
            (match) => isStarted(match) && !isFinished(match),
          ) || [];
        const futureMatches =
          league.matchesData?.filter((match) => !isStarted(match)) || [];

        const finishedMatches =
          league.matchesData?.filter((match) => isFinished(match)) || [];

        const leagueProgress = Math.round(
          (finishedMatches.length / league.matchesData.length) * 100,
        );
        return (
          <Zoom
            key={key}
            in={leagues.length > 0}
            style={{ transitionDelay: `${key * 100}` }}
          >
            <Container onClick={() => onLeagueClick(league.id)}>
              <LeagueProgress league={league} />
              <Row>
                <TextComponent
                  variant="medium"
                  color={theme.hotShotColors.primary}
                >
                  {league.name}
                </TextComponent>
                <TextComponent
                  variant="tiny"
                  color={
                    leagueInProgress
                      ? theme.hotShotColors.primaryTextColor
                      : leagueEnded
                        ? theme.hotShotColors.warning
                        : theme.hotShotColors.primaryTextColor
                  }
                >
                  {leagueDidntStart ? `Starts ${timeToFirstMatch}` : ""}
                  {leagueEnded ? "League Finished" : ""}
                  {leagueInProgress
                    ? `League progress: ${leagueProgress}%`
                    : ""}
                </TextComponent>
              </Row>
              <Row>
                <MatchesData>
                  <TextComponent
                    variant="extra-small"
                    color={theme.hotShotColors.primaryTextColor}
                  >
                    {`${league?.matchesIds?.length || 0} Matches`}
                  </TextComponent>
                  <MatchDataDetails>
                    {liveMatches.length > 0 && (
                      <TextComponent
                        variant="tiny"
                        color={theme.hotShotColors.success}
                      >
                        {liveMatches.length} live
                      </TextComponent>
                    )}
                    {leagueInProgress && futureMatches.length > 0 && (
                      <TextComponent
                        variant="tiny"
                        color={theme.hotShotColors.primaryTextColor}
                      >
                        {futureMatches.length} remaining
                      </TextComponent>
                    )}
                  </MatchDataDetails>
                </MatchesData>
                <RankingWrapper>
                  <LeaguePointsMyOverall
                    leaderboardEntry={myLeaderboardEntry}
                    league={league}
                  />
                </RankingWrapper>
              </Row>
              <Row verticalAlign="flex-start">
                <AvatarGroupSmall max={6}>
                  {activeUsers.map((user, index) => {
                    return (
                      <Avatar
                        key={index}
                        alt={user.displayName}
                        src={user.avatarUrl}
                      />
                    );
                  })}
                </AvatarGroupSmall>

                <PlacementText>
                  {showSparkline && (
                    <SparkDiv>
                      <Sparklines data={ranks} min={usersCount} max={1}>
                        <SparklinesLine color="white" />
                      </Sparklines>
                    </SparkDiv>
                  )}{" "}
                  {!amIFirst && (
                    <TextComponent variant="tiny">
                      {!!scoreGapFromFirstPlace &&
                        `${scoreGapFromFirstPlace} pts from #1`}
                    </TextComponent>
                  )}
                </PlacementText>
              </Row>
            </Container>
          </Zoom>
        );
      })}
    </List>
  );
};

type RowProps = {
  verticalAlign?: "center" | "flex-start" | "flex-end" | "stretch" | "baseline";
};
const Row = styled.div<RowProps>`
  flex-direction: row;
  display: flex;
  align-items: ${(props) => props.verticalAlign || "center"};
  justify-content: space-between;
  margin: 5px 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  background-color: ${(props) => props.theme.hotShotColors.cardBackground};
  border-radius: 12px;
  margin-bottom: 10px;
`;

const AvatarGroupSmall = styled(AvatarGroup)`
  .MuiAvatar-root {
    width: 32px;
    height: 32px;
    text-transform: capitalize;
    &:first-of-type {
      letter-spacing: -2px;
      font-size: 1.2rem;
    }
  }
`;

const MatchesData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MatchDataDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const PlacementText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SparkDiv = styled.div`
  width: 70px;
  height: 25px;
`;
const RankingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
