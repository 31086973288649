import React, { FC } from "react";
import { Match, PointsBet } from "../../../../types";
import {
  getOddsScorePoints,
  isBetCorrect,
  isBetExact,
} from "../../../services/betService";
import TextComponent from "../../styleGuide/atoms/textComponent.atom";
import { useTheme } from "@emotion/react";

type PointsTextProps = {
  bet: PointsBet;
  match: Match;
  useOdds?: boolean;
};
export const PointsText: FC<PointsTextProps> = ({ bet, match, useOdds }) => {
  const theme = useTheme();

  let text = "";
  let color = theme.hotShotColors.primary;

  const isExact = isBetExact(bet, match);
  const isCorrect = isBetCorrect(bet, match);
  const suffix = " Pts";
  if (useOdds) {
    const points = getOddsScorePoints(bet, match);
    if (points > 0) {
      text = `+${points}${suffix}`;
      color = isExact
        ? theme.hotShotColors.thirdBackground
        : theme.hotShotColors.success;
    }
  } else if (isExact) {
    text = `+${match.exactScore || 3}${suffix}`;
    color = theme.hotShotColors.exact;
  } else if (!isExact && isCorrect) {
    text = `+${match.correctScore || 1}${suffix}`;
    color = theme.hotShotColors.success;
  }

  return text ? (
    <TextComponent fontSize={12} color={color}>
      {text}
    </TextComponent>
  ) : null;
  // return text ? (
  //   <Chip
  //     variant="outlined"
  //     label={text}
  //     sx={{
  //       height: 20,
  //       color: isExact
  //         ? theme.hotShotColors.exact
  //         : theme.hotShotColors.success,
  //       borderColor: isExact
  //         ? theme.hotShotColors.exact
  //         : theme.hotShotColors.success,
  //       backgroundColor: "transparent",
  //       border: "none",
  //     }}
  //   />
  // ) : null;
};
