import styled from "@emotion/styled";
import React, { useEffect, useRef } from "react";
import { useSpring, animated } from "react-spring";

interface PoppingModalProps {
  showModal: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Overlay = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
`;

const ModalWrapper = styled(animated.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 95%;
  background: ${({ theme }) => theme.hotShotColors.background};
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-top: 1px solid ${({ theme }) => theme.hotShotColors.Grayscale50};
  z-index: 1000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 3px;
  color: ${({ theme }) => theme.hotShotColors.primaryTextColor};
`;

const CloseButton = styled.button`
  position: absolute;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1001;
  top: 0px;
  right: 10px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.7);
  width: 30px;
  height: 30px;
  color: ${({ theme }) => theme.hotShotColors.primaryTextColor};
`;

const PoppingModal: React.FC<PoppingModalProps> = ({
  showModal,
  onClose,
  children,
}) => {
  const modalAnimation = useSpring({
    transform: showModal ? "translateY(0%)" : "translateY(100%)",
    opacity: showModal ? 1 : 0,
    config: { tension: 300, friction: 30 },
  });

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showModal && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [showModal]);
  return (
    <>
      <Overlay show={showModal} onClick={onClose} />
      {showModal && <CloseButton onClick={onClose}>×</CloseButton>}
      <ModalWrapper style={modalAnimation}>
        <Content ref={contentRef}>{children}</Content>
      </ModalWrapper>
    </>
  );
};

export default PoppingModal;

const Content = styled.div`
  overflow-y: auto;
`;
