import styles from "./leagueMatchOdds.module.scss";
import styled from "@emotion/styled";
import { isStarted, USER_ODDS_BET } from "../../../services/matchService";
import React, { FC, useState } from "react";
import { PlaceBetOddsModal } from "../PlaceBet/placeBetOddsModal";
import TextComponent from "../../styleGuide/atoms/textComponent.atom";
import _ from "lodash";
import { Bet, Match, Odds, OddsBet, PointsBet } from "../../../../types";
import { useTheme } from "@emotion/react";
import { getOddsWinType, isBetExact } from "../../../services/betService";

const fixed = (x: number, roundBet: boolean) => {
  return roundBet ? Math.round(Number(x) * 2) / 2 : Number(x).toFixed(2);
};

type OddsBetsComponentProps = {
  leagueMatch: Match;
  myBet: Bet;
  betResult?: any;
  onClick?: () => void;
  betClickable?: boolean;
  roundBet?: boolean;
};
export const OddsBetsComponent: FC<OddsBetsComponentProps> = ({
  leagueMatch,
  myBet,
  betResult,
  onClick = _.noop,
  betClickable = true,
  roundBet = false,
}) => {
  const [selectedBetType, setSelectedBetType] = useState<
    keyof Odds | undefined
  >();
  const [open, setOpen] = useState(false);
  const isSmall = true; //isStarted(leagueMatch);

  const openBetModal = (betType: keyof Odds) => {
    if (!isStarted(leagueMatch)) {
      setSelectedBetType(betType);
      setOpen(true);
    }
  };

  return (
    <>
      <OddsContainer
        isSmall={isSmall}
        className={styles.oddsWrapper}
        onClick={onClick}
      >
        <OddsSides>
          <MatchOddsBetItem
            type={"homeWin"}
            leagueMatch={leagueMatch}
            bet={myBet}
            betResult={betResult}
            onClick={betClickable ? () => openBetModal("homeWin") : undefined}
            roundBet={roundBet}
          />
        </OddsSides>
        <OddsCenter>
          <MatchOddsBetItem
            type={"draw"}
            leagueMatch={leagueMatch}
            bet={myBet}
            betResult={betResult}
            onClick={betClickable ? () => openBetModal("draw") : undefined}
            roundBet={roundBet}
          />
        </OddsCenter>
        <OddsSides>
          <MatchOddsBetItem
            type={"awayWin"}
            leagueMatch={leagueMatch}
            bet={myBet}
            betResult={betResult}
            onClick={betClickable ? () => openBetModal("awayWin") : undefined}
            roundBet={roundBet}
          />
        </OddsSides>
      </OddsContainer>
      <PlaceBetOddsModal
        open={open}
        onClose={() => setOpen(false)}
        match={leagueMatch}
        betType={selectedBetType!}
        bet={myBet}
      />
    </>
  );
};

type MatchOddsBetItemProps = {
  type: keyof Odds;
  leagueMatch: Match;
  bet: OddsBet;
  betResult?: any;
  onClick?: () => void;
  style?: any;
  roundBet?: boolean;
};
export const MatchOddsBetItem: FC<MatchOddsBetItemProps> = ({
  type,
  leagueMatch,
  bet,
  betResult = {},
  onClick,
  style,
  roundBet = false,
}) => {
  const { hotShotColors } = useTheme();
  let bgColor = hotShotColors.cardBackground;
  let color = hotShotColors.primary;
  let borderColor = hotShotColors.Grayscale30;
  const winType = getOddsWinType(leagueMatch);
  const betWinType =
    (bet as PointsBet)?.homeScore > (bet as PointsBet)?.awayScore
      ? "homeWin"
      : (bet as PointsBet)?.homeScore === (bet as PointsBet)?.awayScore
        ? "draw"
        : "awayWin";

  if (isStarted(leagueMatch)) {
    bgColor = hotShotColors.cardBackground;
    color = hotShotColors.Grayscale30;
    if (bet?.[type as keyof OddsBet]) {
      if (
        betResult[type]?.liveHit ||
        betResult[type]?.hit ||
        type === winType
      ) {
        bgColor = hotShotColors.success;
        borderColor = hotShotColors.success;
        color = "white";
      } else {
        bgColor = hotShotColors.error;
        borderColor = hotShotColors.error;
        color = "white";
      }
    } else if (type === betWinType && type === winType && roundBet) {
      bgColor = isBetExact(bet as any, leagueMatch)
        ? hotShotColors.thirdBackground
        : hotShotColors.success;
      borderColor = bgColor;
      color = "white";
    } else if (type === winType && roundBet) {
      bgColor = hotShotColors.disabledBackground;
      borderColor = hotShotColors.disabledBackground;
      color = "white";
    } else if (type === betWinType && roundBet) {
      bgColor = hotShotColors.error;
      borderColor = hotShotColors.error;
      color = "white";
    } else {
      if (betResult[type]?.score) {
        bgColor = hotShotColors.disabledBackground;
        color = "white";
      }
    }
  } else {
    if (betResult[type]?.amount || type === betWinType) {
      bgColor = hotShotColors.disabledBackground;
      color = "white";
      borderColor = hotShotColors.disabledBackground;
    } else if (!onClick) {
      bgColor = "transparent";
      color = hotShotColors.disabledBackground;
    }
  }

  if (!leagueMatch.odds[type]) {
    color = hotShotColors.primaryTextColor;
    borderColor = hotShotColors.secondaryTextColor;
  }
  return (
    <div
      className={styles.oddItem}
      onClick={leagueMatch?.odds[type] ? onClick : _.noop}
    >
      <div
        className={styles.betButton}
        style={{ background: bgColor, borderColor }}
      >
        <TextComponent variant="extra-small" fontWeight={600} color={color}>
          {leagueMatch.odds[type]
            ? fixed(leagueMatch.odds[type] || 0, roundBet)
            : "-?-"}
        </TextComponent>
      </div>
      {bet?.[type]?.amount && (
        <div className={styles.potentialGain}>
          {!isStarted(leagueMatch) ? (
            <BetContainer>
              <TextComponent variant="small">{bet[type]?.amount}</TextComponent>
              <TextComponent
                variant="extra-small"
                color={hotShotColors.success}
              >
                (+
                {Math.round(
                  (bet[type]?.amount || 0) * (leagueMatch?.odds[type] || 0),
                )}
                )
              </TextComponent>
            </BetContainer>
          ) : (
            <div>
              {(betResult[type].liveHit || betResult[type].hit) && (
                <BetContainer>
                  <TextComponent variant="small">
                    {bet[type]?.amount}
                  </TextComponent>
                  <TextComponent
                    variant="extra-small"
                    color={hotShotColors.success}
                  >
                    (+
                    {Math.round(
                      betResult[type]?.potential ||
                        betResult[type]?.amount + bet[type]?.amount,
                    )}
                    )
                  </TextComponent>
                </BetContainer>
              )}
              {!(betResult[type].liveHit || betResult[type].hit) && (
                <BetContainer>
                  <TextComponent variant="small">&nbsp;</TextComponent>
                  <TextComponent
                    variant="extra-small"
                    color={hotShotColors.error}
                  >
                    (-{Math.round(bet[type]?.amount || 0)})
                  </TextComponent>
                </BetContainer>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
type OddsContainerProps = {
  isSmall?: boolean;
};
const OddsContainer = styled.div<OddsContainerProps>`
  width: ${(props) => (props.isSmall ? "45%" : "100%")};
  gap: 0px;
  display: flex;
`;
const OddsCenter = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
`;
const OddsSides = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const BetContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
