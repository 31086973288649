import React from "react";
import styles from "./rules.module.scss";
import { Header } from "../../components/common/header/header";
import { useLoading } from "../../contexts/loadingContext";
import { useNavigate } from "react-router-dom";
import { ZoomList } from "../../components/common/zoomList/zoomList";
import TextComponent from "../../components/styleGuide/atoms/textComponent.atom";

const Rules = () => {
  const navigate = useNavigate();
  useLoading();
  return (
    <div className={styles.root}>
      <Header onBack={() => navigate("/")} title="Rules" />
      <div className={styles.container}>
        <ZoomList>
          <div>
            <TextComponent textAlign="center" variant={"large"}>
              Basic rules
            </TextComponent>
            <ol>
              <TextComponent>
                <li>You can bet until the match has started</li>
              </TextComponent>
              <TextComponent>
                <li>
                  The final score is set when the game ends (90 minutes or 120
                  if extra time)
                </li>
              </TextComponent>
            </ol>
          </div>
          <div>
            <TextComponent textAlign="center" variant={"large"}>
              Points
            </TextComponent>
            <ol>
              <TextComponent>
                <li>Groups state: Correct is 1 point. Exact is 3 points</li>
              </TextComponent>
              <TextComponent>
                <li>1/8 Final: Correct is 2 point. Exact is 6 points</li>
              </TextComponent>
              <TextComponent>
                <li>1/4 Final: Correct is 3 point. Exact is 9 points</li>
              </TextComponent>
              <TextComponent>
                <li>1/2 Final: Correct is 4 point. Exact is 12 points</li>
              </TextComponent>
              <TextComponent>
                <li>Final: Correct is 5 point. Exact is 15 points</li>
              </TextComponent>
            </ol>
          </div>
        </ZoomList>
      </div>
    </div>
  );
};

export default Rules;
