import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../contexts/AuthContext";
import { useConfirm } from "../../contexts/confirmContext";
import { useDb } from "../../contexts/firestoreContext";
import { useLeagues } from "../../contexts/leaguesContext";
import { useLoading } from "../../contexts/loadingContext";
import { getFromToDate } from "../../services/matchService";

export const useLeaguesScreen = () => {
  const { leaguesInitialized } = useLeagues();
  const { addUserToLeague, getLeague, getLeagueUsersById } = useDb();
  const { showLoading, hideLoading } = useLoading();
  const confirm = useConfirm();
  const { auth, profile } = useAuthentication();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const leagueCode = (window as any).leagueCode;
    if (auth?.uid && profile && leagueCode) {
      (async () => {
        const league = await getLeague(leagueCode);
        const user = await getLeagueUsersById(leagueCode, auth.uid);

        if (league && !user) {
          confirm({
            title: t("JOIN_LEAGUE_MODAL_HEADER"),
            okText: t("JOIN"),
            label: t("LEAGUE_CODE"),
            hideTextField: true,
            description: `${t("JOIN_LEAGUE_MODAL_DESCRIPTION")} "${league.name}"`,
            okHandler: () => performJoinLeague(leagueCode),
          });
        } else if (league && user) {
          (window as any).leagueCode = null;
          navigate(`/league/${leagueCode}/home`);
        }
      })();
    }
  }, [auth, profile]);

  useEffect(() => {
    showLoading();
    if (!auth || leaguesInitialized) {
      hideLoading();
    }
  }, [leaguesInitialized]);

  const performJoinLeague = async (code: string) => {
    const league = await getLeague(code);
    const user = await getLeagueUsersById(code, auth.uid);
    if (!league) {
      alert("Invalid code");
      return;
    }
    if (!league.allowUserToJoin) {
      alert("The league is closed for joining");
      return;
    }
    showLoading();
    if (league && !user) {
      profile.uid = auth.uid;
      await addUserToLeague(profile, code);
    }
    setTimeout(() => {
      navigate(`/league/${code}/home`);
    }, 2000);
  };

  const showLeague = (id: string) => {
    setTimeout(() => {
      navigate(`/league/${id}/home`);
    }, 0);
  };

  const showJoinModal = async () => {
    confirm({
      title: t("JOIN_LEAGUE_MODAL_HEADER"),
      okText: t("JOIN"),
      label: t("LEAGUE_CODE"),
      okHandler: (code) => performJoinLeague(code),
      // @ts-expect-error
      description: t("IF_YOU_HAVE_CODE"),
    });
  };

  return {
    showLeague,
    showJoinModal,
  };
};
