/* eslint-disable no-mixed-operators */
import React, { useState, createContext, useContext, FC } from "react";
import { useAuthentication } from "./AuthContext";
import { useFirestore } from "./firebaseContext";
import { getDb } from "../services/dbService";
import {
  useCompetitionsInitializer,
  useMatchesListener,
  useMessagesInitializer,
  useTeamsInitializer,
} from "./firestoreListeners";
import { useLoading } from "./loadingContext";
import { Competition, Match } from "../../types";

type FirestoreContextType = {
  firestoreState: any;
  db: any;
  updateMatchesListener: (competitionIds?: number[]) => void;
  setFirestoreState: (data: any) => void;
  deleteUser: () => void;
};

type FirestoreProviderProps = {
  children: React.ReactNode;
};

export const FirestoreContext = createContext<FirestoreContextType>(
  {} as FirestoreContextType,
);

export const FirestoreProvider: FC<FirestoreProviderProps> = ({ children }) => {
  const { profile, auth, deleteUserAuth } = useAuthentication();
  const firestore = useFirestore();
  const { showLoading } = useLoading();
  const [firestoreState, setFirestoreState] = useState({ ordered: {} });
  useTeamsInitializer(setFirestoreState);
  const { setCompetitionIdsToListen } = useMatchesListener(
    setFirestoreState,
    firestoreState,
  );
  useMessagesInitializer(setFirestoreState);
  useCompetitionsInitializer(setFirestoreState);

  const db = getDb({ firestore, auth, profile });
  const updateMatchesListener = (competitionIds: number[] | undefined) => {
    setCompetitionIdsToListen(competitionIds || []);
  };

  const deleteUser = async () => {
    showLoading();
    await db.deleteUser(auth.uid);
    await deleteUserAuth();
  };

  return (
    <FirestoreContext.Provider
      value={{
        firestoreState,
        db,
        updateMatchesListener,
        setFirestoreState,
        deleteUser,
      }}
    >
      {children}
    </FirestoreContext.Provider>
  );
};

export const useFirestoreState = () => {
  const { firestoreState, setFirestoreState } = useContext(FirestoreContext);
  return { firestoreState, setFirestoreState };
};

export const useTeams = () => {
  const { firestoreState } = useContext(FirestoreContext);
  return (firestoreState.ordered && firestoreState.ordered.teams) || [];
};

export const useCompetitions = () => {
  const { firestoreState } = useContext(FirestoreContext);
  return ((firestoreState.ordered && firestoreState.ordered.competitions) ||
    []) as Competition[];
};

export const useLeagueMatches = () => {
  const { firestoreState, updateMatchesListener } =
    useContext(FirestoreContext);

  return {
    matches: ((firestoreState.ordered && firestoreState.ordered.matches) ||
      []) as Match[],
    updateMatchesListener,
  };
};

export const useDb = () => {
  const { db } = useContext(FirestoreContext);
  return db;
};

export const useDeleteUser = () => {
  const { deleteUser } = useContext(FirestoreContext);
  return deleteUser;
};
