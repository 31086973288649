import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  getPersonsData,
  getScorersData,
} from "../../../../services/footballDataService";
import { Player } from "../lineUp/lineUpTypes";
import { CircularProgress } from "@mui/material";
import TextComponent from "../../../../components/styleGuide/atoms/textComponent.atom";
import Space from "../../../../components/styleGuide/atoms/space.atom";
import { ZoomList } from "../../../../components/common/zoomList/zoomList";
import { TeamColorsAvatar } from "../../../../components/common/colorsAvatar/colorsAvatar";
import { Competition } from "../../../../../types";

export type Stages = "REGULAR_SEASON";

interface TeamScorer {
  clubColors: string;
  name: string;
  shortName: string;
  id: number;
}

export interface Scorer {
  assists: number | null;
  goals: number | null;
  penalties: number | null;
  player: Player;
  team: TeamScorer;
}

export interface Scorers {
  competition: Competition;
  filters: {
    limit: number;
    season: string;
  };
  season: {
    currentMatchday: number;
    endDate: string;
    id: number;
    stages: Stages[];
    startDate: string;
    winner: any;
  };
  scorers: Scorer[];
}

const TopScorer: FC<{ scorerData: Scorers; teamsToHighlight?: any[] }> = ({
  scorerData,
  teamsToHighlight,
}) => {
  if (_.isEmpty(scorerData)) {
    return (
      <LoadingWrapper>
        <CircularProgress />
      </LoadingWrapper>
    );
  }

  if (!scorerData?.scorers?.length) {
    return null;
  }

  return (
    <>
      <StyledContainer>
        <TableHeader>
          <StyledRow>
            <ListItem>
              <TextComponent fontWeight={600} variant={"small"}>
                {"POS"}
              </TextComponent>
            </ListItem>
            <ListItemTeam>
              <TextComponent fontWeight={600} variant={"small"}>
                {""}
              </TextComponent>
            </ListItemTeam>
            <ListItem>
              <TextComponent fontWeight={600} variant={"small"}>
                {"G"}
              </TextComponent>
            </ListItem>
            <ListItem>
              <TextComponent fontWeight={600} variant={"small"}>
                {"A"}
              </TextComponent>
            </ListItem>
            <ListItem>
              <TextComponent fontWeight={600} variant={"small"}>
                {"P"}
              </TextComponent>
            </ListItem>
          </StyledRow>
        </TableHeader>
        <Space height={3} />
        <TableContainer>
          <ZoomList>
            {scorerData.scorers?.map((item: Scorer, index: any) => {
              return (
                <StyledRow
                  key={index + item.player.name}
                  selected={teamsToHighlight?.find(
                    (x: any) => x === item?.team?.id,
                  )}
                >
                  <ListItem>
                    <TextComponent variant={"small"}>{index + 1}</TextComponent>
                  </ListItem>
                  <ListItemTeam>
                    <TeamColorsAvatar team={item?.team} size={30} />
                    <Space width={10} />
                    <StyledColumn>
                      <TextComponent
                        textAlign="left"
                        variant={"small"}
                        fontWeight={600}
                      >
                        <EllipsisSpan>{item.player.name}</EllipsisSpan>
                      </TextComponent>
                      <TextComponent textAlign="left" variant={"extra-small"}>
                        <EllipsisSpan>
                          {item?.team?.shortName || item?.team?.name}
                        </EllipsisSpan>
                      </TextComponent>
                    </StyledColumn>
                  </ListItemTeam>
                  <ListItem>
                    <TextComponent variant={"extra-small"}>
                      {item.goals ?? 0}
                    </TextComponent>
                  </ListItem>
                  <ListItem>
                    <TextComponent variant={"extra-small"}>
                      {item.assists ?? 0}
                    </TextComponent>
                  </ListItem>
                  <ListItem>
                    <TextComponent variant={"extra-small"}>
                      {item.penalties ?? 0}
                    </TextComponent>
                  </ListItem>
                </StyledRow>
              );
            })}
          </ZoomList>
        </TableContainer>
      </StyledContainer>
      <Space height={40} />
    </>
  );
};

const StyledColumn = styled.div`
  flex-direction: column;
  display: flex;
  align-items: flex-start;
`;

const ListItem = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 6px 0;
`;

const ListItemTeam = styled(ListItem)`
  flex: 5;
  flex-direction: row;
  justify-content: flex-start;
`;

const TitleRow = styled.div`
  margin-left: 10px;
`;

const StyledRow = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: ${(props) =>
    props.selected ? props.theme.hotShotColors.tableHeader : undefined};
`;

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.theme.hotShotColors.cardBackground};
  border-radius: 12px 12px 0px 0px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: Column;
  flex: 1;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: Column;
  justify-content: space-between;
  flex: 1;
  border-radius: 20px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  margin: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const EllipsisSpan = styled.span`
  text-align: left;
`;

export default TopScorer;
