import _ from "lodash";
import { useEffect, useRef, useState } from "react";
// import SelectWrapper from '../../../components/selectWrapper';
import { useLeague } from "../../../contexts/leagueContext";
import { useLoading } from "../../../contexts/loadingContext";
import { useConfirm } from "../../../contexts/confirmContext";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../../contexts/AuthContext";
import { useDb } from "../../../contexts/firestoreContext";
import { LeagueUser } from "../../../../types";
import { LEAGUE_TYPE } from "../../../services/leagueProcessService";
import { useLeagues } from "../../../contexts/leaguesContext";

export const useSettingsScreen = () => {
  const { league } = useLeague();
  const { addUserToLeague, removeUserFromLeague, updateLeague } = useDb();
  const { showLoading, hideLoading } = useLoading();
  const confirm = useConfirm();
  const { profile } = useAuthentication();
  const navigate = useNavigate();
  const { refreshLeagues } = useLeagues();
  const [exponentRanking, setExponentRanking] = useState(
    !!league?.isExponentRanking,
  );
  const [leagueType, setLeagueType] = useState(league?.leagueType);

  const [leagueName, setLeagueName] = useState(league?.name);
  const [allowUserToJoin, setAllowUserToJoin] = useState(
    league?.allowUserToJoin,
  );
  const [betAmount, setBetAmount] = useState(league?.betAmount);

  useEffect(() => {
    if (league) {
      return () => setExponentRanking(!!league.isExponentRanking);
    }
  }, []);

  const toggleExponentRanking = () => {
    setExponentRanking(!exponentRanking);
  };

  const togglePointsLeagueType = () => {
    setLeagueType(
      leagueType === LEAGUE_TYPE.ODDS_SCORE
        ? LEAGUE_TYPE.POINTS_LEAGUE
        : LEAGUE_TYPE.ODDS_SCORE,
    );
  };

  const addUser = (item: LeagueUser) => {
    if (!league) {
      return;
    }
    showLoading();
    addUserToLeague(item, league.id).then(hideLoading).catch(hideLoading);
  };

  const removeUser = async (uid: string) => {
    if (!league) {
      return;
    }
    showLoading();
    await removeUserFromLeague(uid, league.id)
      .then(hideLoading)
      .catch(hideLoading);
  };

  const save = () => {
    const name = leagueName;
    showLoading();
    const data = {
      name,
      isExponentRanking: false,
      allowUserToJoin: false,
      leagueType,
      betAmount: 0,
    };
    if (profile.isAppAdmin) {
      data.isExponentRanking = exponentRanking;
    }
    if (betAmount) {
      data.betAmount = betAmount;
    }
    if (typeof allowUserToJoin === "boolean") {
      data.allowUserToJoin = allowUserToJoin;
    }
    updateLeague(league, data).then(hideLoading).catch(hideLoading);
  };

  const showDeleteLeague = () => {
    if (!league) {
      return;
    }
    //@ts-expect-error
    confirm({
      title: "Delete league",
      message: "Do you want to delete league " + league.name,
      okText: "Delete",
      hideTextField: true,
      okHandler: deleteLeague,
    });
  };

  const deleteLeague = async () => {
    showLoading();
    refreshLeagues();
    await updateLeague(league, { active: false });
    navigate("/");
  };

  // const updateMessage = (message) => {
  //   updateLeague(league, { message });
  // }

  // const updateImage = async (file) => {
  //   try {
  //     showLoading();
  //     const imageUrl = await saveImage(file, league.id, firebase)
  //     await updateLeague(league, { image: imageUrl });
  //     hideLoading();
  //   } catch (e) {
  //     alert("Error", "Error changing image");
  //     console.log({ e })
  //     hideLoading();
  //   }
  // }

  return {
    exponentRanking,
    toggleExponentRanking,
    addUser,
    removeUser,
    save,
    showDeleteLeague,
    leagueName,
    setBetAmount,
    betAmount,
    setLeagueName,
    allowUserToJoin,
    setAllowUserToJoin,
    togglePointsLeagueType,
    leagueType,
    // updateMessage,
    // updateImage
  };
};
