import _ from "lodash";
import React from "react";
import Space from "../../../components/styleGuide/atoms/space.atom";
import { Leaderboard } from "./leaderboardComponent";
import { Zoom } from "../../../components/common/zoomList/zoomList";
import { useScrollToTop } from "../../../hooks/useScrollToTop";
import { ScrollableProvider } from "../../../contexts/Scrollable";

export const LeaderboardScreen = () => {
  useScrollToTop();

  return (
    <ScrollableProvider>
      <div style={{ margin: "0 10px" }}>
        <Zoom>
          <Leaderboard showLiveToggle={true} />
        </Zoom>
        <Space height={50} />
      </div>
    </ScrollableProvider>
  );
};
