import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import { AuthStatus, useAuthentication } from "../../../contexts/AuthContext";
import LoginPage from "../../../screens/login/login";

type PrivateRouteProps = {
  children?: any;
};
export const PrivateRoute: FC<PrivateRouteProps> = ({ children, ...rest }) => {
  const { authStatus } = useAuthentication();

  if (authStatus === AuthStatus.SIGNED_IN) {
    return <Outlet />;
  } else if (authStatus === AuthStatus.NOT_SIGEND_IN) {
    return <LoginPage />;
  } else {
    return null;
  }
};
