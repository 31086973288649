import React, { FC } from "react";
import styled from "@emotion/styled";

interface Props {
  width?: number;
  height?: number;
  grow?: boolean;
}

const StyledSpace = styled.div<Props>`
  height: ${(p) => `${p.height}px`};
  width: ${(p) => `${p.width}px`};
  flex: ${(p) => (p.grow ? 1 : 0)} 0 auto;
  display: flex;
`;

const Space: FC<Props> = ({
  width = 1,
  height = 1,
  grow = false,
  ...props
}) => <StyledSpace width={width} height={height} grow={grow} {...props} />;

export default Space;
