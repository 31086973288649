import React, { FC, useState, PropsWithChildren } from "react";
import styled from "@emotion/styled";

type FullScreenContainerProps = PropsWithChildren & {
  decreaseBy?: number;
};
const FullScreenContainer: FC<FullScreenContainerProps> = ({
  children,
  decreaseBy = 0,
}) => {
  const [height, setHeight] = useState<number>(window.innerHeight - decreaseBy);

  function reportWindowSize() {
    setHeight(window.innerHeight - decreaseBy);
  }

  window.onresize = reportWindowSize;

  return <FullScreenView height={height}>{children}</FullScreenView>;
};

const FullScreenView = styled.div<{ height: number }>`
  flex-direction: column;
  min-height: ${(p) => p.height}px;
  flex: 1;
  display: flex;
  /* overflow: auto; */
`;

export default FullScreenContainer;
