import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
// @ts-expect-error
import { fullScreenAd, bannerAd } from "webtonative/AdMob";
// @ts-expect-error
import { deviceInfo } from "webtonative";
import { useAnalyticsLogEvent } from "../hooks/useAnalyticsHook";

type WebToMobileContextType = {
  platform: string;
  isMobilePlatform: boolean;
};

type WebToMobileProviderProps = {
  children: React.ReactNode;
};

export const WebToMobileContext = createContext<WebToMobileContextType>(
  {} as WebToMobileContextType,
);

export const WebToMobileProvider: FC<WebToMobileProviderProps> = ({
  children,
}) => {
  const [value, setValue] = useState({});
  useEffect(() => {
    (async () => {
      const device = await deviceInfo();
      setValue({
        platform: device.platform,
        isMobilePlatform:
          device.platform === "android" || device.platform === "ios",
      });
    })();
  }, []);
  return (
    <WebToMobileContext.Provider value={value as WebToMobileContextType}>
      {children}
    </WebToMobileContext.Provider>
  );
};

export const useWebToMobile = () => {
  const { platform, isMobilePlatform } = useContext(WebToMobileContext);
  return { platform, isMobilePlatform };
};
