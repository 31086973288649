import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ENTranslations from "./en-US.translations.json";

export const useReactI18nNext = () => {
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: ENTranslations,
      },
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },
  });
};
