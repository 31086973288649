import React, { FC, useState } from "react";
import styles from "./confirmModal.module.scss";
import { Modal, Typography, TextField, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextComponent from "../../styleGuide/atoms/textComponent.atom";
import Space from "../../styleGuide/atoms/space.atom";
import styled from "@emotion/styled";
import TextInputComponent from "../../styleGuide/atoms/textInputComponent.atom";
import ButtonComponent from "../../styleGuide/atoms/buttonComponent.atom";

type ConfirmModalProps = {
  opened: boolean;
  closeClicked: () => void;
  okClicked: (text?: string | boolean) => void;
  title: string;
  message?: string;
  description?: string;
  okText?: string;
  cancelText?: string;
  label?: string;
  hideTextField?: boolean;
};
export const ConfirmModal: FC<ConfirmModalProps> = (props) => {
  const [text, setText] = useState("");
  const {
    opened,
    closeClicked,
    okClicked,
    title,
    message,
    description,
    okText = "OK",
    cancelText = "Cancel",
    label,
    hideTextField,
  } = props;
  const { t } = useTranslation();

  return (
    <div>
      <Modal
        className={styles.root}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={!!opened}
        onClose={closeClicked}
      >
        <ModalBody className={styles.paper}>
          <TextComponent variant="large">{title}</TextComponent>

          {description && (
            <>
              <Space height={10} />
              <TextComponent variant="medium">{description}</TextComponent>
            </>
          )}
          {message && <TextComponent>{message}</TextComponent>}
          {!hideTextField && (
            <TextInputComponentContainer>
              <TextInputComponent
                value={text}
                placeholder={label}
                onChange={(value) => setText(value)}
              ></TextInputComponent>
            </TextInputComponentContainer>
          )}
          <div className={styles.actions}>
            <ButtonContainer>
              <ButtonComponent
                size={"normal"}
                variant="outline"
                onClick={closeClicked}
              >
                {cancelText}
              </ButtonComponent>
            </ButtonContainer>
            <Space grow />
            <ButtonContainer>
              <ButtonComponent
                variant="contained"
                size={"normal"}
                onClick={() => okClicked(!hideTextField && text)}
              >
                {okText}
              </ButtonComponent>
            </ButtonContainer>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

const TextInputComponentContainer = styled.div`
  width: 100%;
  margin: 15px 0;
`;
const ButtonContainer = styled.div`
  width: 113px;
`;
const ModalBody = styled.div`
  background-color: ${(props) => props.theme.hotShotColors.background};
`;
