import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./bets.module.scss";
import { MatchesList } from "../../components/matchComponents/matches/matchesList";
import { Header } from "../../components/common/header/header";
import { ProfileLarge } from "../../components/common/profileLarge/profileLarge";
import { UserStatsDistributionPanel } from "../../components/stats/distributionPanel/userStatsDistributionPanel";
import { Chart } from "../../components/stats/chart/chart";
import { Divider, Zoom } from "@mui/material";
import { useScrollToTop } from "../../hooks/useScrollToTop";
import { useLoading } from "../../contexts/loadingContext";
import { useLeague } from "../../contexts/leagueContext";
import { useAuthentication } from "../../contexts/AuthContext";
import { isOddsLeague, isStarted } from "../../services/matchService";
import { useTheme } from "@mui/material/styles";
import TabsHeader from "../league/tabsHeader/tabsHeader";
import { EligibleToWinAlert } from "../../components/common/eligibleToWinAlert/eligibleToWinAlert";
import { LeagueExtended } from "../../../types";
import Space from "../../components/styleGuide/atoms/space.atom";
import PoppingModal from "../../components/common/poppingModal/poppingModal";
import { MatchScreen } from "../league/match/matchScreen";
import FullScreenContainer from "../league/matches/FullScreenContainer";
import MatchesScreen from "../league/matches/matchesScreen";

export const UserBetsScreen = () => {
  const { league, leagueMatches = [] } = useLeague();
  const location = useLocation();
  useScrollToTop();
  const [currentMatch, setCurrentMatch] = useState<number | null>(null);
  const [currentTab, setCurrentTab] = useState(0);

  useLoading();

  if (!league) {
    return null;
  }
  const onMatchClick = (id: number) => {
    setCurrentMatch(id);
  };

  const userId = location.pathname.split("/").pop();
  if (!userId) {
    return null;
  }
  const userToShow = league.users[userId];
  userToShow.uid = userId;
  const userRank =
    _.find(
      (league as LeagueExtended).lastLeaderboard,
      (x) => x.uid === userId,
    ) || {};
  const userBetsForMatch = leagueMatches.filter(
    (x) =>
      (x.date as number) > league.users[userId].dateAdded &&
      ((x.date as number) <= new Date().getTime() || isStarted(x)),
  );

  const isStatsTab = currentTab === 0;
  const isBetsTab = currentTab === 1;

  return (
    <div className={styles.root}>
      {isOddsLeague(league) && (
        <EligibleToWinAlert league={league as LeagueExtended} userId={userId} />
      )}
      <TabsHeader
        items={[
          { title: "Stats", id: 0 },
          { title: "Bets", id: 1 },
        ]}
        selectedId={currentTab}
        onClick={setCurrentTab}
      />
      {isStatsTab && (
        <>
          <Zoom in={true} style={{ transitionDelay: "0" }}>
            <div style={{ marginBottom: 10 }}>
              <ProfileLarge
                hideMessage={true}
                league={league}
                user={userToShow}
              />
            </div>
          </Zoom>
          <Chart
            dynamicLeaderboard={(league as LeagueExtended).dynamicLeaderboard!}
            userId={userId}
            league={league}
          />
          {!isOddsLeague(league) && (
            <Zoom in={true} style={{ transitionDelay: "100" }}>
              <div className={styles.stats}>
                <UserStatsDistributionPanel userRank={userRank} />
              </div>
            </Zoom>
          )}
        </>
      )}
      {isBetsTab && (
        <MatchesScreen
          overrideMatches={userBetsForMatch}
          heightOffset={165}
          showAsUser={userToShow}
          reverse={true}
        />
      )}
      <PoppingModal
        showModal={!!currentMatch}
        onClose={() => setCurrentMatch(null)}
      >
        <MatchScreen matchId={currentMatch!} />
      </PoppingModal>
    </div>
  );
};
