import _ from "lodash";
import React, { useEffect } from "react";
import { createContext, useState } from "react";
// @ts-expect-error
import { prompt } from "webtonative/AppReview";

type RateAppPromptContextType = {
  showRateApp: () => void;
};

export const RateAppPromptContext = createContext<RateAppPromptContextType>({
  showRateApp: () => _.noop,
});

type RateAppPromptProviderProps = {
  children: React.ReactNode;
};

export const RateAppPromptProvider = ({
  children,
}: RateAppPromptProviderProps) => {
  const [prompted, setPrompted] = useState(true);

  useEffect(() => {
    const lastTimePrompted = localStorage.getItem("lastTimePrompted");
    const currentTime = new Date().getTime();
    if (lastTimePrompted) {
      const diff = currentTime - parseInt(lastTimePrompted);
      if (diff > 1000 * 60 * 60 * 24 * 7) {
        setPrompted(false);
      }
    } else {
      setPrompted(false);
    }
  }, []);
  const showRateApp = () => {
    if (!prompted) {
      setPrompted(true);
      localStorage.setItem("lastTimePrompted", new Date().getTime().toString());
      prompt();
    }
  };

  return (
    <RateAppPromptContext.Provider value={{ showRateApp }}>
      {children}
    </RateAppPromptContext.Provider>
  );
};

export const useRateAppPrompt = () => {
  const { showRateApp } = React.useContext(RateAppPromptContext);
  return { showRateApp };
};
