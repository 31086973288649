import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/common/header/header";
import { CreateLeagueContext } from "../../contexts/createLeagueContext";
import {
  useCompetitions,
  useLeagueMatches,
} from "../../contexts/firestoreContext";
import { useLoading } from "../../contexts/loadingContext";
import { LEAGUE_TYPE } from "../../services/leagueProcessService";
import { MobileStepperWizard } from "./components/mobileStepperWizard";
import styles from "./createLeagueStep1.module.scss";
import styled from "@emotion/styled";
import TextComponent from "../../components/styleGuide/atoms/textComponent.atom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Space from "../../components/styleGuide/atoms/space.atom";
//@ts-ignore
import { ReactComponent as Icon } from "./vMark.svg";
import _ from "lodash";

type VMarkProps = {
  checked: boolean;
};

const VMark: FC<VMarkProps> = ({ checked }) => {
  return (
    <>
      {checked ? (
        <Mark checked={checked}>
          <Icon />
        </Mark>
      ) : (
        <Mark checked={false}></Mark>
      )}
    </>
  );
};

type MarkProps = {
  checked: boolean;
};
const Mark = styled.div<MarkProps>`
  border: 1.5px solid ${(props) => props.theme.hotShotColors.Grayscale20};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  background-color: ${(props) =>
    props.checked ? props.theme.hotShotColors.success : "transparent"};
`;

export const CreateLeagueStep2 = () => {
  const { createLeague, leagueData, updateLeagueData } =
    useContext(CreateLeagueContext);
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const isOddsLeague = leagueData?.leagueType === LEAGUE_TYPE.ODDS_LEAGUE;
  const isPointsLeague = leagueData?.leagueType === LEAGUE_TYPE.POINTS_LEAGUE;
  const { t } = useTranslation();
  const theme = useTheme();

  const onCreateClick = async () => {
    showLoading();
    const id = await createLeague({});
    hideLoading();
    navigate(`/league/${id}/home`);
  };

  const changeToPointsLeague = () => {
    updateLeagueData({ leagueType: LEAGUE_TYPE.POINTS_LEAGUE });
  };

  const changeToOddsLeague = () => {
    updateLeagueData({ leagueType: LEAGUE_TYPE.ODDS_LEAGUE });
  };

  return (
    <>
      <Header onBack={() => navigate(-1)} title={"Create League"} />
      <GreetingContainer>
        <TextComponent variant={"small"}>
          {t("FIRST_SELECT_TEXT")}
        </TextComponent>
      </GreetingContainer>
      <CardContainer>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={true}
          onChange={_.noop}
        >
          <div onClick={changeToPointsLeague} className={styles.option}>
            <FormControlLabel
              label=""
              value={LEAGUE_TYPE.POINTS_LEAGUE}
              control={<VMark checked={isPointsLeague} />}
            />
            <div>
              <TextComponent
                fontWeight={600}
                color={theme.hotShotColors.primaryTextColor}
                variant={"medium"}
              >
                {t("POINTS_LEAGUE")}
              </TextComponent>
              <TextComponent variant={"small"}>
                {t("POINTS_LEAGUE_DESCRIPTION")}
              </TextComponent>
            </div>
          </div>
          <Space height={20} />
          <div className={styles.option} onClick={changeToOddsLeague}>
            <FormControlLabel
              label=""
              value={LEAGUE_TYPE.ODDS_LEAGUE}
              control={<VMark checked={isOddsLeague} />}
            />

            <div>
              <TextComponent
                fontWeight={600}
                color={theme.hotShotColors.primaryTextColor}
                variant="medium"
              >
                {t("ODDS_LEAGUE")}
              </TextComponent>
              <TextComponent variant="small">
                {t("ODDS_LEAGUE_DESCRIPTION")}
              </TextComponent>
            </div>
          </div>
        </RadioGroup>
      </CardContainer>
      <MobileStepperWizard
        maxSteps={2}
        activeStep={1}
        nextText="Finish"
        handleNext={onCreateClick}
        handleBack={() => navigate(-1)}
      />
    </>
  );
};

const GreetingContainer = styled.div`
  padding: 10px;
  background-color: ${(props) => props.theme.hotShotColors.headerBackground};
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: ${(props) => props.theme.hotShotColors.headerBackground};
  border-radius: 12px;
  margin: 10px;
`;
