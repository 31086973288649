import styled from "@emotion/styled";
import styles from "./teamsEditor.module.scss";
import { Switch, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useCompetitions,
  useDb,
  useTeams,
} from "../../../contexts/firestoreContext";
import { ORDERED_COMPETITION_CODES } from "../../../services/matchService";
import { Header } from "../../../components/common/header/header";
import { Box } from "@mui/system";
import Space from "../../../components/styleGuide/atoms/space.atom";
import TextComponent from "../../../components/styleGuide/atoms/textComponent.atom";
import {
  ColorsAvatar,
  TeamColorsAvatar,
} from "../../../components/common/colorsAvatar/colorsAvatar";
import ButtonComponent from "../../../components/styleGuide/atoms/buttonComponent.atom";
import { useLoading } from "../../../contexts/loadingContext";
import { Competition, Team } from "../../../../types";

const colorsList = [
  "none",
  "#ffffff",
  "#377E54",
  "#000000",
  "#0217AA",
  "#005FAE",
  "#E10B17",
  "#EF8604",
  "#FDEA14",
  "#009540",
  "#198CCD",
  "#02509C",
  "#921C80",
  "#FFEE00",
  "#D9D9D9",
  "#97c1e7",
  "#00285e",
  "#034694",
  "#6A7AB5",
];

export const TeamsEditor = () => {
  const navigate = useNavigate();
  const competitions = useCompetitions();
  const teams = useTeams();
  const filteredCompetitions = ORDERED_COMPETITION_CODES.map((x) =>
    competitions.find((y: Competition) => x === y.code),
  ).filter((x) => x);
  const { showLoading, hideLoading } = useLoading();
  const theme = useTheme();
  const { updateTeam } = useDb();
  const [currentCompetitionId, setCurrentCompetitionId] = useState<number>();
  const [selectedTeamId, setSelectedTeamId] = useState();
  const [colorIndex, setColorIndex] = useState(0);
  const [pallete, setPallete] = useState<any>({
    vertical: false,
    colors: [undefined, undefined, undefined],
  });

  if (!teams) {
    return null;
  }

  const visibleTeams = teams.filter((x: Team) =>
    // @ts-ignore
    x.runningCompetitions.find((c: any) => c.id === currentCompetitionId),
  );
  const onTeamClick = (team: any) => {
    setSelectedTeamId(team.id);
    setPallete(team.pallete);
  };

  const competitionClick = (id: number) => {
    setCurrentCompetitionId(id);
  };

  const onColorClick = (color: string) => {
    pallete.colors = pallete.colors || [];
    if (!pallete.colors[colorIndex]) {
      pallete.colors.push(color);
    } else {
      pallete.colors[colorIndex] = color === "none" ? undefined : color;
    }
    setPallete({
      ...pallete,
      colors: [...pallete.colors.filter((x: string) => x)],
    });
  };
  const setVertical = (vertical: boolean) => {
    setPallete({
      ...pallete,
      vertical,
    });
  };

  const save = async () => {
    showLoading();
    await updateTeam({ id: selectedTeamId, data: { pallete } });
    hideLoading();
  };

  return (
    <>
      <Header onBack={() => navigate(-1)} title={"Update Matches"} />
      <Box
        sx={{ flexGrow: 1, overflow: "hidden", marginTop: 10 }}
        className={styles.content}
      >
        <div className={styles.container}>
          <SliderContainer className={styles.teamsSlider}>
            {filteredCompetitions.map((competition) => {
              if (!competition) {
                return;
              }
              return (
                <ImageContainer
                  className={
                    competition.id === currentCompetitionId
                      ? styles.selectedTeam
                      : styles.unSelectedTeam
                  }
                >
                  <Image
                    src={competition?.area?.flag}
                    key={competition.id}
                    alt={competition.name}
                    onClick={() => {
                      competitionClick(competition.id);
                    }}
                  />
                  <Space height={3} />
                  <TextComponent
                    style={{ whiteSpace: "nowrap" }}
                    textAlign="center"
                    color={theme.hotShotColors.primaryTextColor}
                    fontWeight={600}
                    variant="tiny"
                  >
                    {competition.name}
                  </TextComponent>
                </ImageContainer>
              );
            })}
          </SliderContainer>
          <SliderContainer className={styles.teamsSlider}>
            {visibleTeams.map((team: Team) => {
              const isSelectedTeam = selectedTeamId === team.id;
              return (
                <ImageContainer
                  onClick={() => onTeamClick(team)}
                  className={
                    isSelectedTeam ? styles.selectedTeam : styles.unSelectedTeam
                  }
                >
                  {/* <Image src={team.crest}
                                    key={team.id}
                                    alt={team.name}

                                    onClick={() => onTeamClick(team)}
                                /> */}
                  <TeamColorsAvatar team={team} size={60} />
                  <Space height={3} />
                  <TextComponent
                    style={{ whiteSpace: "nowrap" }}
                    textAlign="center"
                    fontWeight={600}
                    color={(theme.hotShotColors as any).primaryTextColor}
                    variant="tiny"
                  >
                    {team.shortName ?? team.name}
                  </TextComponent>
                </ImageContainer>
              );
            })}
          </SliderContainer>
        </div>
        <ContentWrapper>
          <div>
            <Switch onChange={() => setVertical(!pallete.vertical)} />
            Vertical
            <ContentBox
              color={pallete.colors[0]}
              selected={colorIndex === 0}
              onClick={() => setColorIndex(0)}
            />
            <ContentBox
              color={pallete.colors[1]}
              selected={colorIndex === 1}
              onClick={() => setColorIndex(1)}
            />
            <ContentBox
              color={pallete.colors[2]}
              selected={colorIndex === 2}
              onClick={() => setColorIndex(2)}
            />
          </div>
          <ColorsAvatar {...pallete} size={150} />
        </ContentWrapper>
        <ColorsGrid>
          {colorsList.map((color) => {
            return (
              <ColorButton color={color} onClick={() => onColorClick(color)} />
            );
          })}
        </ColorsGrid>
        <div>
          <ButtonComponent onClick={save} variant="text" size="normal">
            Save
          </ButtonComponent>
        </div>
      </Box>
    </>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
`;

type ContentBoxProps = {
  color: string;
  selected: boolean;
};
const ContentBox = styled.div<ContentBoxProps>`
  width: 100px;
  height: 40px;
  background-color: ${(props) => props.color};
  margin-bottom: 10px;
  border: solid 1px silver;
  transform: scale(${(props) => (props.selected ? "0.9" : "1")});
`;
const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 3px;
  padding: 7px;
`;
const ColorButton = styled.div`
  background-color: ${(props) => props.color};
  width: 40px;
  height: 40px;
  border: solid 1px ${(props) => (props.color === "none" ? "red" : "silver")};
`;
const ColorsGrid = styled.div`
  margin: 20px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(6, 40px);
`;

const Image = styled.img`
  width: 60px;
  height: 60px;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
`;

const SliderContainer = styled.div`
  background-color: ${(props) => props.theme.hotShotColors.cardBackground};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
