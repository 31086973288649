import React, { FC } from "react";
import { useSpring, animated } from "@react-spring/web";

type ZoomListProps = {
  children: any[];
  delay?: number;
  initialDelay?: number;
};
export const ZoomList: FC<ZoomListProps> = ({
  children,
  delay = 50,
  initialDelay = 0,
}) => {
  const zoomChildren = children.map((child, index) => (
    <Zoom delay={initialDelay + delay * index} key={index}>
      {child}
    </Zoom>
  ));
  return <>{zoomChildren}</>;
};

type ZoomProps = {
  delay?: number;
  children: any;
};
export const Zoom: FC<ZoomProps> = ({ delay, children }) => {
  const springs = useSpring({
    from: { translateY: 20, opacity: 0.7 },
    to: { translateY: 0, opacity: 1 },
    delay,
  });

  return <animated.div style={{ ...springs }}>{children}</animated.div>;
};
