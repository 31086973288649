import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Header } from "../../components/common/header/header";
import styles from "./adminMatch.module.scss";
import {
  InputLabel,
  FormControl,
  Select,
  Switch,
  FormControlLabel,
  Input,
  Button,
  TextField,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import { useLoading } from "../../contexts/loadingContext";
import { useAuthentication } from "../../contexts/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useDb,
  useLeagueMatches,
  useTeams,
} from "../../contexts/firestoreContext";
import { getProcessMatches } from "../../services/leagueProcessService";
import { LeagueMatchPoints } from "../../components/matchComponents/match/leagueMatchPoints";
import { STATUS, USER_ODDS_BET } from "../../services/matchService";
import TextInputComponent from "../../components/styleGuide/atoms/textInputComponent.atom";

export const AdminMatch = () => {
  const { matches } = useLeagueMatches();
  const { profile, auth } = useAuthentication();
  const location = useLocation();
  const navigate = useNavigate();
  const { updateAdminMatch } = useDb();
  const { showLoading, hideLoading } = useLoading();
  const [state, setState] = useState<any>();
  const matchId = location.pathname.split("/").pop();
  const leagueMatches = getProcessMatches(matches);

  useEffect(() => {
    if (profile && !profile?.isAppAdmin) {
      navigate("/");
    }
  }, [navigate, profile]);

  useEffect(() => {
    const selectedMatch = leagueMatches?.find(
      (m) => String(m.id) === String(matchId),
    );
    if (selectedMatch) {
      setState({
        started:
          selectedMatch.status === STATUS.IN_PLAY ||
          selectedMatch.status === STATUS.PAUSED ||
          selectedMatch.status === STATUS.FINISHED,
        finished: selectedMatch.status === STATUS.FINISHED,
        ignored: !!selectedMatch.ignored,
        homeScore:
          selectedMatch.score.regularTime?.home ||
          selectedMatch.score.fullTime?.home ||
          0,
        date: selectedMatch.utcDate,
        awayScore:
          selectedMatch.score.regularTime?.away ||
          selectedMatch.score.fullTime?.away ||
          0,
        correctScore: selectedMatch.correctScore || 1,
        exactScore: selectedMatch.exactScore || 3,
        correctMultiplier: selectedMatch.correctMultiplier || 1,
        exactWithMultiplyer: selectedMatch.exactWithMultiplyer || 4,
        videoId: selectedMatch.videoId || "",
        group: selectedMatch.group || "",
        homeOdd: selectedMatch?.odds?.homeWin || 0,
        drawOdd: selectedMatch?.odds?.draw || 0,
        awayOdd: selectedMatch?.odds?.awayWin || 0,
      });
    }
  }, [leagueMatches.length]);

  if (!leagueMatches || _.isEmpty(leagueMatches)) {
    return null;
  }
  const selectedMatch = leagueMatches.find(
    (m) => String(m.id) === String(matchId),
  );

  const toggleFinished = () => {
    setState((prev: any) => ({ ...prev, finished: !state.finished }));
  };

  const toggleStarted = () => {
    setState((prev: any) => ({ ...prev, started: !state.started }));
  };

  const toggleIngored = () => {
    setState((prev: any) => ({ ...state, ignored: !state.ignored }));
  };

  const handleChange = (field: any) => {
    return (event: any) => {
      let value = event?.target?.value || event;
      if (field === "homeTeam" || field === "awayTeam") {
        value = Number(value);
      }
      setState((prev: any) => ({ ...prev, [field]: value }));
    };
  };

  const save = async () => {
    showLoading();

    const data = {
      ignored: !!state.ignored,
      score: {
        regularTime: {
          home: state.homeScore || 0,
          away: state.awayScore || 0,
        },
      },
      group: state.group || "",
      utcDate: state.date,
      odds: {
        [USER_ODDS_BET.HOME]: state.homeOdd || 0,
        [USER_ODDS_BET.DRAW]: state.drawOdd || 0,
        [USER_ODDS_BET.AWAY]: state.awayOdd || 0,
      },
      status: "",
      correctScore: 0,
      exactScore: 0,
      correctMultiplier: 1,
      exactWithMultiplyer: 4,
      videoId: "",
    };

    if (state.started) {
      data.status = STATUS.IN_PLAY;
    }
    if (state.finished) {
      data.status = STATUS.FINISHED;
    }
    if (!state.started && !state.finished) {
      data.status = STATUS.TIMED;
    }

    if (profile.isAppAdmin) {
      data.correctScore = Number(state.correctScore);
      data.exactScore = Number(state.exactScore);
      data.correctMultiplier = Number(state.correctMultiplier);
      data.exactWithMultiplyer = Number(state.exactWithMultiplyer);
      data.videoId = state.videoId;
    }

    await updateAdminMatch(matchId, { ...selectedMatch, ...data });
    hideLoading();
  };

  if (!state) {
    return <div />;
  }
  const title = "Match " + selectedMatch!.id;
  return (
    <div>
      <Header small title={title} onBack={() => navigate(-1)} />
      <div className={styles.container}>
        {selectedMatch && (
          <LeagueMatchPoints
            leagueMatch={selectedMatch}
            currentUserAuth={auth}
            auth={auth}
            onClick={_.noop}
          />
        )}
        {/* <FormControl className={styles.formControl}>
          <InputLabel htmlFor="age-native-simple">Home Team</InputLabel>
          <Select
            native
            value={state.homeTeam}
            onChange={handleChange('homeTeam')}
            input={<Input id="age-native-helper" />}
          >
            <option value="" key={0}>N/A</option>
            {_.map(teams, team =>
              <option value={team.id} key={team.id}>{team.name}</option>
            )}
          </Select>
        </FormControl> */}
        <TextInputComponent
          title="Home Team Score"
          value={state.homeScore || 0}
          onChange={handleChange("homeScore")}
        />
        {/* <FormControl className={styles.formControl}>
          <InputLabel htmlFor="age-native-simple">Away Team</InputLabel>
          <Select
            native
            value={state.awayTeam}
            onChange={handleChange('awayTeam')}
            input={<Input id="age-native-helper" />}
          >
            <option value="" key={0}>N/A</option>
            {_.map(teams, team =>
              <option value={team.id} key={team.id}>{team.name}</option>
            )}
          </Select>
        </FormControl> */}

        <TextInputComponent
          title="Away Team Score"
          value={state.awayScore || 0}
          onChange={handleChange("awayScore")}
        />
        <div>
          <div>
            <TextInputComponent
              title="Date"
              value={state.date}
              onBlur={handleChange("date")}
            />
          </div>
          <div>
            <TextInputComponent
              title="Group"
              value={state.group}
              onBlur={handleChange("group")}
            />
          </div>
          <div>
            <TextInputComponent
              title="Home Win Odd"
              value={state.homeOdd}
              onChange={handleChange("homeOdd")}
            />
          </div>

          <div>
            <TextInputComponent
              title="Tie Odd"
              value={state.drawOdd}
              onChange={handleChange("drawOdd")}
            />
          </div>
          <div>
            <TextInputComponent
              title="Away Win Odd"
              value={state.awayOdd}
              onChange={handleChange("awayOdd")}
            />
          </div>
          {profile.isAppAdmin && (
            <div>
              <div>
                <TextInputComponent
                  title="Correct Score"
                  value={state.correctScore}
                  onChange={handleChange("correctScore")}
                />
              </div>
              <div>
                <TextInputComponent
                  title="Exact Score"
                  value={state.exactScore}
                  onChange={handleChange("exactScore")}
                />
              </div>
              <div>
                <TextInputComponent
                  title="Correct Multiplier"
                  value={state.correctMultiplier}
                  onChange={handleChange("correctMultiplier")}
                />
              </div>
              <div>
                <TextInputComponent
                  title="Exact For Multiplier"
                  value={state.exactWithMultiplyer}
                  onChange={handleChange("exactWithMultiplyer")}
                />
              </div>
              <div>
                <TextInputComponent
                  title="Youtube Video Id"
                  value={state.videoId}
                  onChange={handleChange("videoId")}
                />
              </div>
            </div>
          )}

          <FormControlLabel
            control={
              <Switch
                checked={!!state.started}
                onChange={toggleStarted}
                value="started"
              />
            }
            label="Is Started"
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={!!state.finished}
                onChange={toggleFinished}
                value="finished"
              />
            }
            label="Is Finished"
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={!!state.ignored}
                onChange={toggleIngored}
                value="ignored"
              />
            }
            label="Ignore Game"
          />
        </div>
        <Button
          onClick={save}
          className={styles.button}
          variant="contained"
          size="small"
        >
          <Save />
          Save
        </Button>
      </div>
    </div>
  );
};
