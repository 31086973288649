import _ from "lodash";
import React, { FC, useState } from "react";
import styles from "./leaderboard.module.scss";
import styled from "@emotion/styled";
import { Link, List, ListItem, ListItemText, Switch } from "@mui/material";
import { useScrollToTop } from "../../../hooks/useScrollToTop";
import { useLoading } from "../../../contexts/loadingContext";
import { useLeague } from "../../../contexts/leagueContext";
import { ZoomList } from "../../../components/common/zoomList/zoomList";
import { LeaderboardItem } from "./leaderboardItemPoints";
import {
  isFinished,
  isOddsLeague,
  isStarted,
} from "../../../services/matchService";
import { LeaderboardItemOdds } from "./leaderboardItemOdds";
import TextComponent from "../../../components/styleGuide/atoms/textComponent.atom";
import Space from "../../../components/styleGuide/atoms/space.atom";
import { useTheme } from "@mui/material/styles";
import { useAuthentication } from "../../../contexts/AuthContext";
import CoinComponent from "../../../components/styleGuide/atoms/coinComponent.atom";
import {
  OddsLeaderboardEntry,
  PointsLeaderboardEntry,
} from "../../../../types";
import { useNavigate } from "react-router-dom";

type LeaderboardProps = {
  onlySummary?: boolean;
  showLiveToggle?: boolean;
};
export const Leaderboard: FC<LeaderboardProps> = ({
  onlySummary = false,
  showLiveToggle = false,
}) => {
  const { league, leagueMatches } = useLeague();
  const { auth } = useAuthentication();
  useLoading();
  const navigate = useNavigate();
  const theme = useTheme();

  const [showLive, setShowLive] = useState(false);

  if (!league) {
    return null;
  }
  const { lastLeaderboardThatStarted, lastLeaderboardThatFinished } = league;
  let leaderboard = isOddsLeague(league)
    ? showLive
      ? lastLeaderboardThatStarted
      : lastLeaderboardThatFinished
    : lastLeaderboardThatStarted;

  if (onlySummary) {
    const myPlace = _.find(leaderboard, (item, uid) => uid === auth.uid);
    const firstPlace = _.minBy(
      Object.values(leaderboard || {}),
      (item) => item.rank,
    );
    leaderboard = {};
    if (firstPlace) leaderboard[firstPlace.uid] = firstPlace;
    if (myPlace) leaderboard[myPlace.uid] = myPlace;
  }

  const hasLiveMatches = !!leagueMatches?.find(
    (x) => isStarted(x) && !isFinished(x),
  );
  const leagueStarted = !!leagueMatches?.find((x) => isStarted(x));
  const hasLiveLeaderboard =
    hasLiveMatches && showLiveToggle && isOddsLeague(league); // && lastLeaderboardThatStarted !== lastLeaderboardThatFinished;
  let leaderboardArr = Object.values(leaderboard || {});
  if (!isOddsLeague(league)) {
    leaderboardArr = _.orderBy(leaderboard, (x) => x.rank, "desc");
  }
  if (isOddsLeague(league) && showLive) {
    leaderboardArr = _.orderBy(
      leaderboard,
      (x) =>
        (x as OddsLeaderboardEntry).balance +
        (x as OddsLeaderboardEntry).livePotential,
      "desc",
    );
  }
  //TODO: Allow reenter
  //Add indication below the lederboard that the user has reentered the league, with what amount and by dates
  //Every user that reentered will have a * next to their name as the times the reentered

  return (
    <>
      <div
        className={styles.root}
        style={{ backgroundColor: theme.hotShotColors.cardBackground }}
      >
        {hasLiveLeaderboard && (
          <DailyPrefContainer>
            <Switch
              edge="start"
              checked={showLive}
              onChange={(e) => setShowLive(!showLive)}
            />
            <TextComponent variant="extra-small" textAlign="left">
              Show Live Leaderboard
            </TextComponent>
          </DailyPrefContainer>
        )}

        {isOddsLeague(league) ? (
          <List>
            <ListItem key={"title"} classes={{ root: styles.listTitle }}>
              <ListItemText className={styles.textItemRoot}>
                <TextComponent
                  color={theme.hotShotColors.Grayscale50}
                  variant="small"
                >
                  {"Rank"}
                </TextComponent>
              </ListItemText>
              <div style={{ width: "30px" }}></div>
              <ListItemText className={styles.textItemRoot}>
                <TextComponent
                  color={theme.hotShotColors.Grayscale50}
                  variant="small"
                >
                  {"Player"}
                </TextComponent>
              </ListItemText>
              <div style={{ width: "50px" }}></div>
              <ListItemText className={styles.textItemRoot}>
                <TextComponent
                  color={theme.hotShotColors.Grayscale50}
                  variant="small"
                >
                  {"Bets"}
                </TextComponent>
              </ListItemText>
              <div style={{ width: "10px" }}></div>
              <ListItemText className={styles.textItemRoot}>
                <TextComponent
                  color={theme.hotShotColors.Grayscale50}
                  variant="small"
                >
                  {"Hits"}
                </TextComponent>
              </ListItemText>
              <div style={{ width: "10px" }}></div>
              <ListItemText className={styles.textItemRoot}>
                <TextComponent
                  color={theme.hotShotColors.Grayscale50}
                  variant="small"
                >
                  {"Balance"}
                </TextComponent>
              </ListItemText>
            </ListItem>
            <ZoomList>
              {_.map(leaderboardArr, (item) => (
                <LeaderboardItemOdds
                  key={item.uid}
                  itemIndex={leaderboardArr.indexOf(item) + 1}
                  item={item as OddsLeaderboardEntry}
                  uid={item.uid}
                  showLive={showLive}
                  onlySummary={onlySummary}
                  leaderboard={leaderboard!}
                />
              ))}
            </ZoomList>
          </List>
        ) : (
          <List>
            <div style={{ padding: "0 20px 0 0" }}>
              <ListItem key={"title"} classes={{ root: styles.listTitle }}>
                <ListItemText className={styles.textItemRoot}>
                  <TextComponent
                    color={theme.hotShotColors.Grayscale50}
                    variant="small"
                  >
                    {"Rank"}
                  </TextComponent>
                </ListItemText>
                <div style={{ width: "10px" }}></div>
                <ListItemText className={styles.textItemRoot}>
                  <TextComponent
                    color={theme.hotShotColors.Grayscale50}
                    variant="small"
                  >
                    {"Player"}
                  </TextComponent>
                </ListItemText>
                <div style={{ width: "60px" }}></div>
                <ListItemText className={styles.textItemRoot}>
                  <TextComponent
                    color={theme.hotShotColors.Grayscale50}
                    variant="small"
                  >
                    {"Bets"}
                  </TextComponent>
                </ListItemText>
                <Space width={10} />
                <ListItemText className={styles.textItemRoot}>
                  <TextComponent
                    color={theme.hotShotColors.Grayscale50}
                    variant="small"
                  >
                    {"C"}
                  </TextComponent>
                </ListItemText>
                <Space width={15} />
                <ListItemText className={styles.textItemRoot}>
                  <TextComponent
                    color={theme.hotShotColors.Grayscale50}
                    variant="small"
                  >
                    {"E"}
                  </TextComponent>
                </ListItemText>
                <Space width={5} />
                <ListItemText className={styles.textItemRoot}>
                  <TextComponent
                    color={theme.hotShotColors.Grayscale50}
                    variant="small"
                  >
                    {"Score"}
                  </TextComponent>
                </ListItemText>
              </ListItem>
            </div>
            <ZoomList>
              {_.map(leaderboard, (item, uid) => {
                const isFirstPlace = item.rank === 1;
                const lastEntry = _.maxBy(
                  Object.values(leaderboard!),
                  (x) => x.rank,
                );
                const isLastPlace = item.rank === lastEntry?.rank;
                return (
                  <LeaderboardItem
                    key={uid}
                    item={item as PointsLeaderboardEntry}
                    uid={uid}
                    leaderboard={leaderboard!}
                    badgeIcon={
                      (leagueStarted &&
                        (isFirstPlace
                          ? "👑"
                          : isLastPlace && !onlySummary
                            ? "💩"
                            : undefined)) ||
                      ""
                    }
                  />
                );
              })}
            </ZoomList>
          </List>
        )}
      </div>
      {onlySummary && (
        <FullLeaderboardLink onClick={() => navigate("../board")} fontSize={12}>
          View full leaderboard
        </FullLeaderboardLink>
      )}
      {!onlySummary && <Space height={10} />}
      {!onlySummary && (
        <DailyPrefContainer>
          <TextComponent variant="extra-small" textAlign="left">
            <span>(</span>
            <span style={{ color: "green" }}>+</span> /{" "}
            <span style={{ color: "red" }}>-</span>) - Daily Performance
            <br />
            (Resets on the end todays first match)
          </TextComponent>
          {isOddsLeague(league) && (
            <>
              <Space height={10} />
              <div className={styles.score}>
                <div className={styles.noteligible} />
                <TextComponent variant="extra-small" textAlign="left">
                  Not eligable to win.
                  <br />
                  still didn't spend the entire 10,000
                  <CoinComponent />
                </TextComponent>
              </div>
            </>
          )}
        </DailyPrefContainer>
      )}
    </>
  );
};

const DailyPrefContainer = styled.div`
  margin: 0 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: left;
`;

const FullLeaderboardLink = styled(TextComponent)`
  cursor: pointer;
  margin: 10px 0;
  text-align: right;
  color: ${(props) => props.theme.hotShotColors.primary};
  margin-right: 5px;
  text-decoration: underline;
`;
