import React, { FC } from "react";
import styled from "@emotion/styled";
import _ from "lodash";

type size = "large" | "normal" | "small";
type variant = "outline" | "text" | "contained";

interface ButtonProps {
  onClick: () => any;
  children: any;
  size: size;
  variant: variant;
  isDisable?: boolean;
  outlineColor?: string;
  width?: number;
  ref?: any;
}

const ButtonComponent: FC<ButtonProps> = ({
  onClick,
  children,
  size,
  variant,
  isDisable,
  outlineColor,
  width,
  ref,
}) => {
  return (
    <Container
      ref={ref}
      onClick={isDisable ? _.noop : onClick}
      size={size}
      variant={variant}
      isDisable={isDisable}
      outlineColor={outlineColor}
      width={width}
    >
      {children}
    </Container>
  );
};

const Container = styled.div<{
  size: size;
  variant: variant;
  isDisable?: boolean;
  outlineColor?: string;
  width?: number;
}>`
  width: ${(props) => props.width}px;
  margin: 10px 5px;
  background-color: ${(props) =>
    props.isDisable
      ? props.theme.hotShotColors.disabledBackground
      : props.variant === "contained"
        ? props.theme.hotShotColors.primary
        : props.variant === "outline" || props.variant === "text"
          ? props.theme.hotShotColors.background
          : props.theme.hotShotColors.primary};
  border-radius: 20px;
  border-color: ${(props) =>
    props.outlineColor
      ? props.outlineColor
      : props.theme.hotShotColors.primary};
  border-width: ${(props) =>
    props.isDisable ? "0" : props.variant === "text" ? "0" : "1px"};
  border-style: solid;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${(props) =>
    props.size === "large"
      ? "16px"
      : props.size === "normal"
        ? "12px 16px"
        : props.size === "small"
          ? "4px 16px"
          : "16px"};
  color: ${(props) =>
    props.isDisable
      ? props.theme.hotShotColors.disabledFont
      : props.outlineColor
        ? props.outlineColor
        : props.variant === "contained"
          ? props.theme.hotShotColors.white
          : props.variant === "outline" || props.variant === "text"
            ? props.theme.hotShotColors.primary
            : props.theme.hotShotColors.white};

  min-height: ${(props) => (props.size === "small" ? "0" : "inerit")};
  height: ${(props) => (props.size === "small" ? "fit-content" : "inerit")};
`;

export default ButtonComponent;
