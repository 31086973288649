import React, { FC } from "react";
import styled from "@emotion/styled";
import { ScorePicker } from "./ScorePicker";
import { LeagueExtended, Match, PointsBet } from "../../../../types";
import { isStarted } from "../../../services/matchService";
import { useDb } from "../../../contexts/firestoreContext";
import {
  generateSoccerScore,
  getBet,
  getBetToUpdateForPointsLeague,
} from "../../../services/betService";
import { useAnalyticsLogEvent } from "../../../hooks/useAnalyticsHook";
import CircularIntegration from "./CircularIntegration";
import { HitComponentContainer } from "./HitButton";
import { Casino } from "@mui/icons-material";
import { useAuthentication } from "../../../contexts/AuthContext";
import { playSound } from "../../../services/audioService";

export enum SaveStatus {
  SAVING = "saving",
  SAVED = "saved",
  ERROR = "error",
}

type PointsBetComponentProps = {
  match: Match;
  league: LeagueExtended;
};

const WAIT_TIME = 2000;

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const PointsBetComponent: FC<PointsBetComponentProps> = ({
  match,
  league,
}) => {
  const { setBet } = useDb();
  const logEvent = useAnalyticsLogEvent();
  const { auth } = useAuthentication();
  const [saveStatus, setSaveStatus] = React.useState<SaveStatus>();
  const bet = getBet(league, match, auth);
  const isUserBetOnGame =
    bet && bet.homeScore !== undefined && bet.awayScore !== undefined;

  const updateBet = async (type: string, value: number) => {
    try {
      if (isStarted(match)) {
        alert(`You can't if the match is already started`);
        return;
      }
      const data = getBetToUpdateForPointsLeague(match.id, type, value);
      await setBet({ league, match: match, data });
      logEvent("place_bet", {
        league_id: league?.id,
        match_id: match.id,
        bet_type: type,
        score: value,
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  return (
    <StyledRow>
      <ScorePicker
        value={isUserBetOnGame ? bet.homeScore : undefined}
        leftComponent
        onSelect={async (val: number) => {
          try {
            setSaveStatus(SaveStatus.SAVING);
            await Promise.all([
              (updateBet("homeScore", val),
              updateBet("awayScore", bet?.awayScore || 0)),
            ]);
            setSaveStatus(SaveStatus.SAVED);
            playSound("success");
          } catch (error) {
            setSaveStatus(SaveStatus.ERROR);
          }
          await wait(WAIT_TIME);
          setSaveStatus(undefined);
        }}
      />
      {saveStatus ? (
        <CircularIntegration
          status={saveStatus}
          onClick={(e) => {
            e.stopPropagation();
            e.preentDefault();
            setSaveStatus(undefined);
          }}
        />
      ) : (
        <HitComponentContainer
          onClick={async (e: any) => {
            try {
              e.stopPropagation();
              e.preventDefault();
              setSaveStatus(SaveStatus.SAVING);
              const randomScore = generateSoccerScore();
              await Promise.all([
                updateBet("homeScore", randomScore[0]),
                updateBet("awayScore", randomScore[1]),
              ]);
              setSaveStatus(SaveStatus.SAVED);
              playSound("success");
            } catch (error) {
              setSaveStatus(SaveStatus.ERROR);
            }
            await wait(WAIT_TIME);
            setSaveStatus(undefined);
          }}
        >
          <Casino />
          <Casino />
        </HitComponentContainer>
      )}

      <ScorePicker
        value={isUserBetOnGame ? bet?.awayScore : undefined}
        onSelect={async (val: number) => {
          try {
            setSaveStatus(SaveStatus.SAVING);
            await Promise.all([
              updateBet("awayScore", val),
              updateBet("homeScore", bet?.homeScore || 0),
            ]);
            setSaveStatus(SaveStatus.SAVED);
            playSound("success");
          } catch (error) {
            setSaveStatus(SaveStatus.ERROR);
          }
          await wait(WAIT_TIME);
          setSaveStatus(undefined);
        }}
      />
    </StyledRow>
  );
};

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
`;
