/* eslint-disable prettier/prettier */
//@ts-nocheck
import _ from "lodash";
import React, { FC } from "react";
import { useLeague } from "../../../contexts/leagueContext";
import { isOddsLeague, isPointsLeague } from "../../../services/matchService";
import styled from "@emotion/styled";
import { LEAGUE_TYPE } from "../../../services/leagueProcessService";
import Markdown from "react-markdown";
import { Zoom } from "../../../components/common/zoomList/zoomList";
import { useLoading } from "../../../contexts/loadingContext";
import { ScrollableProvider } from "../../../contexts/Scrollable";

type LeagueRulesProps = {
  leagueType?: (typeof LEAGUE_TYPE)[keyof typeof LEAGUE_TYPE];
};

const leagueOddsScoreRules = `
## 🌟 How to Earn Points: 🌟
Guess the results of the matches and earn points based on the final game result:

### 🏆 Group Stage Matches:
**Correct Guess:** 🟢 If you guess the winner or a draw correctly but not the exact score, you get the points *(Odd)* given before the match.

**Exact Guess:** 🔵 If you guess the exact score correctly, you get a **4-point** bonus!

### ⚔️ Knockout Stage Matches:
**Correct Guess:** 🟢 If you guess the winner or a draw correctly (90 mins) but not the exact score, your points *(Odd)* will be **doubled**!

**Exact Guess:** 🔵 If you guess the exact score correctly (90 mins), you get a **6-point** bonus!

### 🏅 Final Match:
**Correct Guess:** 🟢 If you guess the winner or a draw correctly (90 mins) but not the exact score, your points *(Odd)* will be **tripled**!

**Exact Guess:** 🔵 If you guess the exact score correctly (90 mins), you get an **8-point** bonus!

## Group Stage Example:
**User's Bet:** England will win against Germany with a score of 2:1.

**Match Result:** England wins with a score of 2:1.

**Odds:** England's odds were 3.5.

### Points Earned:

🟢 **Correct Guess:** You guessed that England would win, which is correct! You get the points based on the odds: **3.5 points**.

🔵 **Exact Guess:** You also guessed the exact score correctly! You get a **4-point** bonus.

**Total Points Earned: 3.5 + 4 = 7.5 points**

Have fun guessing and good luck! 🍀
`

const leaguePointsRules = `
## 🌟 How to Earn Points: 🌟

### 🌟 Your Goal: 🌟
**Get the most points!** You want to have more points than everyone else.

### 🏆 How to Win: 🏆
**Be the player with the highest points!** The one with the most points wins the game.

### 📋 Betting Rules:
🕹️ **Place your bets!** If you don't place a bet, your bet will automatically be (0 - 0).

🎯 **Earn points:**

**Correct score:** 🟢 You get 1 point.

**Exact score:** 🔵 You get 3 points.


## ⚽ Special Tournaments:

### 🏆 In Tournaments like the World Cup:
**Round of 16:**  

Correct score:  🟢 2 points.  
Exact score: 🔵 6 points.

**Quarter-finals:**  
Correct score: 🟢 3 points.  
Exact score: 🔵 9 points.  

**Semi-finals / 3rd Place Match:**  
Correct score: 🟢 4 points.  
Exact score: 🔵 12 points.  

**Final:**  
Correct score: 🟢 5 points.  
Exact score: 🔵 15 points.  

## Group Stage Example:
**User's Bet:** England will win against Germany with a score of 2:1.

**Match Result:** England wins with a score of 2:1.

### Points Earned:

🥇 **Correct score:** You guessed that England would win, which is correct! You get **1 point**.

🏅 **Exact score:** You also guessed the exact score correctly! You get a **3-point** bonus.

**Total Points Earned: 1 + 3 = 4 points**

Have fun guessing and good luck! 🍀
`

const leagueOddsRules = `
## 🎮 How to Play the Coin Game!

### 🎯 Your Goal: 🎯
**Get the most coins!** You want to have more coins than everyone else by the end of the game.

### 📋 Betting Rules:
1. 🪙 **Start with 10,000 coins.** You must use all of these coins before the game ends to be able to win.   
2. 🎉 **Win by having the most coins.** Whoever has the most coins when the game ends, wins!  

### 🏅 How to Earn Coins:
1. 🤔 **Place your bets!** For example, if you think Manchester United will win and their odds are 1.5, you can bet 100 coins on them.  
2. 🤑 **Win coins if you're right!** If they win, you get 150 coins! If they lose or draw, you lose your 100 coins.  

Good luck and have fun! 🍀
`
export const LeagueRules: FC<LeagueRulesProps> = ({ leagueType }) => {
  const { league } = useLeague();
  useLoading();
  if (isOddsLeague(league!) || leagueType === LEAGUE_TYPE.ODDS_LEAGUE) {
    return (
      <ScrollableProvider>
      <Zoom>
      <Container>
        <Markdown children={leagueOddsRules} />
      </Container>
      </Zoom>
      </ScrollableProvider>
    );
  } else if (
    isPointsLeague(league!) ||
    leagueType === LEAGUE_TYPE.POINTS_LEAGUE
  ) {
    return (
      <ScrollableProvider>
      <Zoom>
      <Container>
        <Markdown children={leaguePointsRules} />
      </Container>
      </Zoom>
      </ScrollableProvider>
    );
  } else {
    return (
      <ScrollableProvider>
      <Zoom>
      <Container>
        <Markdown children={leagueOddsScoreRules} />
      </Container>
      </Zoom>
      </ScrollableProvider>
    );
  }
};

const Container = styled.div`
.markdown > * {
  all: revert;
}
  margin: 0 20px 60px;
  color: ${props => props.theme.hotShotColors.primaryTextColor};
`;
