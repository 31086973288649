import { useTheme } from "@emotion/react";
import { Badge, Typography } from "@mui/material";
import React, { FC } from "react";
import styles from "./performanceIndicator.module.scss";

type PerformanceIndicatorProps = {
  num: number;
  style?: React.CSSProperties;
  prefix?: string;
};
export const PerformanceIndicatorText: FC<PerformanceIndicatorProps> = ({
  num,
  style = { fontSize: 10 },
  prefix = "",
}) => {
  const theme = useTheme();
  if (num > 0) {
    return (
      <div>
        {/* <NorthIcon style={{ color: 'green' }} fontSize="10" /> */}
        <Typography
          style={{ color: theme.hotShotColors.success, ...style }}
          variant={"caption"}
          display="block"
        >
          +{prefix}
          {num}
        </Typography>
      </div>
    );
  } else if (num < 0) {
    return (
      <div>
        {/* <SouthIcon style={{ color: 'red' }} fontSize="10" /> */}
        <Typography
          style={{ color: theme.hotShotColors.error, ...style }}
          variant={"caption"}
          display="block"
        >
          {prefix}
          {num}
        </Typography>
      </div>
    );
  } else {
    return <div />;
  }
};

type PerformanceIndicatorBadgeProps = {
  num: number;
  variant?: string;
};
export const PerformanceIndicatorBadge: FC<PerformanceIndicatorBadgeProps> = ({
  num,
}) => {
  if (num > 0) {
    return <Badge badgeContent={`+${num}`} className={styles.green} />;
  } else if (num < 0) {
    return <Badge badgeContent={num} className={styles.red} />;
  } else {
    return <div />;
  }
};
