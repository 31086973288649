import React, { useEffect } from "react";
import { useAuthentication } from "../contexts/AuthContext";
import { useFirebaseAnalytics } from "../contexts/firebaseContext";

export const useAnalytics = ({ screenName }: { screenName: string }) => {
  const { logEvent } = useFirebaseAnalytics();
  const { auth } = useAuthentication();
  useEffect(() => {
    document.title = screenName;
    logEvent("screen_view", {
      screen_name: screenName,
      authId: auth?.uid,
    });
  }, []);
  return { logEvent };
};

export const useLeagueAnalytics = ({
  screenName,
  leagueId,
  matchId,
  userId,
}: {
  screenName: string;
  leagueId: string;
  matchId?: string;
  userId?: string;
}) => {
  const { auth } = useAuthentication();
  const { logEvent } = useFirebaseAnalytics();
  useEffect(() => {
    const data: any = {
      screen_name: screenName,
      league_id: leagueId,
      authId: auth.uid,
    };
    if (matchId) {
      data.match_id = matchId;
    }
    if (userId) {
      data.user_id = userId;
    }
    document.title = screenName;
    logEvent("screen_view", data);
  }, [screenName]);

  return { logEvent };
};

export const useAnalyticsLogEvent = () => {
  const { auth } = useAuthentication();
  const { logEvent } = useFirebaseAnalytics();
  return (eventName: string, data?: any) => {
    logEvent(eventName, {
      ...data,
      authId: auth.uid,
    });
  };
};
