import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../../contexts/AuthContext";
import { useLeague } from "../../../contexts/leagueContext";
import { isOddsLeague } from "../../../services/matchService";
import styles from "./leaderboard.module.scss";
import {
  DateLeaderboard,
  LeaderboardEntry,
  OddsLeaderboardEntry,
  PointsLeaderboardEntry,
} from "../../../../types";

export const useLeaderboardItem = ({
  leaderboard,
  item,
  uid,
}: {
  leaderboard: DateLeaderboard;
  item: OddsLeaderboardEntry | PointsLeaderboardEntry;
  uid: string;
}) => {
  const { league } = useLeague();
  const { auth } = useAuthentication();
  const navigate = useNavigate();

  if (!league) {
    return;
  }

  const showUserBets = (id: string) => {
    navigate("/league/" + league.id + "/users/" + id);
  };
  const { users } = league;
  const index = Object.keys(leaderboard).indexOf(uid);
  const lastRankKey = Object.keys(leaderboard)[index - 1];
  let lastScore;
  if (lastRankKey) {
    lastScore = isOddsLeague(league)
      ? (leaderboard[lastRankKey] as OddsLeaderboardEntry)?.balance
      : (leaderboard[lastRankKey] as PointsLeaderboardEntry)?.score;
  } else {
    lastScore = isOddsLeague(league)
      ? (leaderboard[uid] as OddsLeaderboardEntry)?.balance
      : 0;
  }
  let rankToDisplay = item.rank?.toString();

  if ((item as PointsLeaderboardEntry).score === lastScore) {
    rankToDisplay = "---";
  }
  const isFirstPlace =
    league.firstPlaceScore === (item as PointsLeaderboardEntry).score &&
    (item as PointsLeaderboardEntry).score > 0;
  const isMyRank = auth.uid === uid;
  const itemClass = isFirstPlace
    ? styles.firstPlace
    : isMyRank
      ? styles.myRank
      : "";

  return {
    isFirstPlace,
    isMyRank,
    itemClass,
    showUserBets,
    users,
    rankToDisplay,
    index,
  };
};
