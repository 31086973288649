import styled from "@emotion/styled";
import _ from "lodash";
import React, {
  createContext,
  useContext,
  useRef,
  ReactNode,
  useEffect,
} from "react";

interface ScrollableContextType {
  scrollableRef: React.RefObject<HTMLDivElement>;
  scrollTo: (element: HTMLElement) => void;
  scrollToTop: () => void;
  currentScroll?: number;
}

const ScrollableContext = createContext<ScrollableContextType | undefined>(
  undefined,
);

export const ScrollableProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const scrollableRef = useRef<HTMLDivElement>(null);
  // const [currentScroll, setCurrentScroll] = React.useState(0);

  // useEffect(() => {
  //   scrollableRef.current?.addEventListener(
  //     "scroll",
  //     _.throttle(() => {
  //       if (scrollableRef.current) {
  //         const { scrollTop } = scrollableRef.current;
  //         setCurrentScroll(scrollTop);
  //       }
  //     }, 100),
  //   );
  // }, []);
  const scrollTo = (element: HTMLElement) => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({
        top: element.offsetTop - 55,
        // behavior: "smooth",
      });
    }
  };

  const scrollToTop = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({
        top: 0,
      });
    }
  };

  return (
    <ScrollableContext.Provider
      value={{ scrollableRef, scrollTo, scrollToTop }}
    >
      <Scrollable ref={scrollableRef}>{children}</Scrollable>
    </ScrollableContext.Provider>
  );
};

export const useScrollable = () => {
  const context = useContext(ScrollableContext);
  if (!context) {
    throw new Error("useScrollable must be used within a ScrollableProvider");
  }
  return context;
};
const Scrollable = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex-grow: 1;
  height: 100%;
`;
